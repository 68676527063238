/* eslint-disable security/detect-object-injection */
/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch } from 'react-redux';
import { ASSET_MANAGEMENT_FILTER_KEYS } from 'store/constants';
import { assetManagementSlice } from 'store/slices';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AssetFieldComplexTypeAhead from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AssetFieldComplexTypeAhead';
import AssetFieldComplexDropDown from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/AssetFieldComplexDropDown';
import ComplexTypeAheadContainer from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/components/ComplexTypeAheadContainer';
import {
  GET_ASSET_NO_SUGGESTION,
  SEARCH_DEPOTS_FOR_LOOKUP,
  SEARCH_ORGANIZATIONS_FOR_LOOKUP,
} from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/queries';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { AMDashComplexFilterTestIds } from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/constants';
import * as AMDashComplexFilterStyles from 'pages/AssetManagement/components/AMDashComplexFilterDrawer/styles';
import { AllwhereInfoFilterSectionProps } from './types';
import {
  ASSET_NUMBERS_AVATAR_TESTID,
  DEPOT_LOCATIONS_AVATAR_TESTID,
  ORGANIZATION_AVATAR_TESTID,
  ASSET_STATUS_AVATAR_TESTID,
} from './constants';
import { FilterPlaceholders } from 'pages/AssetManagement/constants';

const AllwhereInfoFilterSection = (props: AllwhereInfoFilterSectionProps) => {
  const dispatch = useDispatch();
  const drawerStyles = AMDashComplexFilterStyles;

  // Organization look ahead section
  const [fetchOrganizationInfo] = useSafeLazyQuery(
    SEARCH_ORGANIZATIONS_FOR_LOOKUP,
    {
      onCompleted: ({ getOrganizationAutocompleteSuggestions = {} }) => {
        const matches = getOrganizationAutocompleteSuggestions?.matches ?? [];
        const matchIds = getOrganizationAutocompleteSuggestions?.matchIds ?? [];

        const valueIdOptions = matches.map((match: string, index: number) => [
          match,
          matchIds[index],
        ]);

        dispatch(
          assetManagementSlice.actions.populateAvailableTypeAHeadsReducer({
            optionKey: ASSET_MANAGEMENT_FILTER_KEYS.ORGS,
            options: valueIdOptions,
          })
        );
      },
    }
  );

  const handleOrgNameDebounceEffect = (debounceValue: string) => {
    fetchOrganizationInfo({
      variables: {
        name: debounceValue,
      },
    });
  };

  // Depot dropdown section
  useSafeQuery(SEARCH_DEPOTS_FOR_LOOKUP, {
    variables: {},
    onCompleted: ({ searchDepots }) => {
      const depots = searchDepots?.depots ?? [];
      if (!depots.length) return;

      const depotOptions = depots.map((depot: any) => [depot.name, depot.id]);

      const availableDepotOptions = [
        [FilterPlaceholders.CLEAR_ALL_FILTER_OPTION, ''],
        ...depotOptions,
      ];

      dispatch(
        assetManagementSlice.actions.populateAvailableOptionReducer({
          optionKey: ASSET_MANAGEMENT_FILTER_KEYS.DEPOTS,
          options: availableDepotOptions,
        })
      );
    },
  });

  const clearFiltersByField = (filterKey: string) => {
    dispatch(assetManagementSlice.actions.clearFilterByTypeReducer(filterKey));
  };

  const clearDropdownFiltersByField = (filterKey: string) => {
    dispatch(
      assetManagementSlice.actions.clearSelectedFiltersByTypeReducer(filterKey)
    );
  };

  const clearOptionsByField = (filterKey: string) => {
    dispatch(assetManagementSlice.actions.clearOptionsByTypeReducer(filterKey));
  };

  return (
    <Stack
      direction="column"
      data-testid={AMDashComplexFilterTestIds.ALLWHERE_INFO}
      sx={drawerStyles.AMComplexFilterStackSx}
    >
      <Typography sx={drawerStyles.AMComplexFilterSectionTitleSx}>
        Allwhere Info
      </Typography>
      <Stack direction="column" columnGap="12px" rowGap="12px">
        <Stack direction="row" rowGap="12px" justifyContent="space-between">
          <AssetFieldComplexTypeAhead
            alias="ASSET_NO_FILTER"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.ASSET_NO}
            responseField="getAssetAutocompleteSuggestions"
            lazyQueryDocumentNode={GET_ASSET_NO_SUGGESTION}
            counterAvatarTestId={ASSET_NUMBERS_AVATAR_TESTID}
            renderInputLabel="Asset Number"
            clearFiltersByField={clearFiltersByField}
            clearOptionsByField={clearOptionsByField}
          />
          <AssetFieldComplexDropDown
            alias="DEPOT_LOCATION_FILTER"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.DEPOTS}
            counterAvatarTestId={DEPOT_LOCATIONS_AVATAR_TESTID}
            inputLabel="Depot Location"
            makeSelectionText="Select Location"
            clearFiltersByField={clearDropdownFiltersByField}
          />
        </Stack>
        <Stack direction="row" rowGap="12px" justifyContent="space-between">
          <AssetFieldComplexDropDown
            alias="ASSET_STATUS_FILTER"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.STATUS}
            counterAvatarTestId={ASSET_STATUS_AVATAR_TESTID}
            inputLabel="Status"
            makeSelectionText="Select status"
            clearFiltersByField={clearDropdownFiltersByField}
          />
          <ComplexTypeAheadContainer
            alias="ORGANIZATION_FILTER"
            filterKey={ASSET_MANAGEMENT_FILTER_KEYS.ORGS}
            handleDebounceEffect={handleOrgNameDebounceEffect}
            counterAvatarTestId={ORGANIZATION_AVATAR_TESTID}
            renderInputLabel="Organization"
            clearFiltersByField={clearFiltersByField}
            clearOptionsByField={clearOptionsByField}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AllwhereInfoFilterSection;
