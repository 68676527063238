import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Update } from 'ts-toolbelt/out/Object/Update';
import { Container, FilledInput, Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useCollaboratorControllerSearch } from 'services/openapi/apiComponents';
import { CollaboratorList } from 'services/openapi/apiSchemas';
import { Collaborator, HomeAddress, SortOrder, WorkAddress } from 'types';
import AddNewPersonModal from 'components/AddNewPersonModal';
import NewAndImprovedModal from '../NewAndImprovedModal';
import CollaboratorTable from './components/CollaboratorTable';
import SelectedCollaboratorBar from './components/SelectedCollaboratorBar';
import { DEFAULT_COLLABORATOR_SORT_ORDER, DEFAULT_LIMIT } from './constants';
import { CollaboratorViewProps } from './types';
import { InnerProcurementStackSX } from './styles';
import { ProcurementFlowModals } from 'pages/Procurement/hooks/useProcurementService/constants';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { Button } from 'components';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import { DRAWER_WIDTH } from 'global-styles';
import { GlobalModals } from 'store/constants';
import { modalSlice } from 'store/slices';
import { GlobalState } from 'store/types';
import SentEquipmentConfirmationModal from 'pages/Procurement/components/SentEquipmentConfirmationModal';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { CollaboratorDataGrid } from './components/CollaboratorDataGrid';
import { GridScrollParams } from '@mui/x-data-grid-pro';
import { RETRIEVE_COLLABORATOR_ADDRESSES } from './queries';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';

const CollaboratorView = (props: CollaboratorViewProps) => {
  const [isFocused, setIsFocused] = React.useState(false);

  // const [{ page, pageSize }, setCollaboratorPagination] =
  //   React.useState(defaultPagination);
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState(DEFAULT_LIMIT);
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [searchInputDebounced, setSearchInputDebounced] =
    React.useState<string>('');

  const [collaboratorSortOrder, setCollaboratorSortOrder] =
    React.useState<SortOrder | null>(DEFAULT_COLLABORATOR_SORT_ORDER);
  const [gridScrollIndex, setGridScrollIndex] = React.useState<
    GridScrollParams | undefined
  >(undefined);

  const userOrganization = useGetUserOrganization();

  useGetGlobalPurchaser(); // this will populate the purchaser toggle on load if user is in org
  const { user: auth0User } = useAuth0();

  const {
    OnFlagTrueWrapper: SelectRecipientEnabledDisplay,
    OnFlagFalseWrapper: SelectRecipientEmailDisabledDisplay,
  } = useFeatureFlagService(FeatureFlagNames.SELECT_RECIPIENT_EMAIL, {
    debugFlags: true,
  });

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // eslint-disable-next-line
  const [openMessageModal, _setOpenMessageModal] = React.useState<boolean>(
    () => {
      if (localStorage.improvementModalNeverSeen) {
        return (
          auth0User?.loginCount < 2 &&
          localStorage.getItem('improvementModalNeverSeen') !== 'false'
        );
      }

      return auth0User?.loginCount < 2;
    }
  );

  const dispatch = useDispatch();
  const currentModal = useSelector<GlobalState>(
    (state) => state.modal.currentModal
  );

  const showSentEquipmentConfirmationModal =
    currentModal === GlobalModals.EQUIPMENT_SENT_CONFIRMATION;

  const closeSentEquipmentConfirmationModal = () => {
    dispatch(modalSlice.actions.reset());
  };

  const closeMessageModal = () => {
    _setOpenMessageModal(false);
    localStorage.setItem('improvementModalNeverSeen', 'false');
  };

  const setCollaboratorPagination = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  };

  type CollaboratorSearchResponse = Update<
    CollaboratorList,
    'items',
    Collaborator[]
  >;
  const { data, isLoading } =
    useCollaboratorControllerSearch<CollaboratorSearchResponse>(
      {
        queryParams: {
          ...(pageSize && { limit: pageSize }),
          ...(page && { offset: page * pageSize }),
          ...(searchInputDebounced.length > 0
            ? {
                // TODO allow to search for firstName, lastName OR email once backend provides proper search endpoint
                fullTextSearch: searchInputDebounced,
              }
            : {}),
          ...(collaboratorSortOrder && {
            [collaboratorSortOrder.direction]: collaboratorSortOrder.field,
          }),
          organizationId: userOrganization?.id,
        },
      },

      { enabled: !!userOrganization?.id }
    );

  const collaborators = data?.items || [];
  const totalNumOfCollaboratorsInOrg = data?.count || 0;

  const hasSelectedCollaborators =
    props.workflow.selectedCollaborators.length > 0;

  const showAddNewPersonModal =
    props.workflow.currentModal === ProcurementFlowModals.ADD_NEW_PERSON;

  const [retrieveCollaboratorAddresses] = useSafeLazyQuery(
    RETRIEVE_COLLABORATOR_ADDRESSES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const availableAddresses = [
          ...(data?.collaborator?.homeAddresses?.map(
            (address: HomeAddress) => ({
              ...address,
              isHome: true,
              isWork: false,
            })
          ) || []),
          ...(data?.collaborator?.workAddresses?.map(
            (address: WorkAddress) => ({
              ...address,
              isHome: false,
              isWork: true,
            })
          ) || []),
        ];
        availableAddresses.sort((a, b) =>
          b.id === data?.collaborator?.primaryAddress?.id ? 1 : -1
        );
        if (availableAddresses.length === 0) return;
        props.workflow.addSelectedCollaboratorAddresses(
          data?.collaborator?.id,
          data?.collaborator?.homeAddresses || [],
          data?.collaborator?.workAddresses || []
        );
        props.workflow.addSelectedCollaboratorSelectableAddresses(
          data?.collaborator.id,
          availableAddresses
        );
      },
    }
  );

  const onAddSelectedCollaborator = (collaborator: Collaborator) => {
    props.workflow.addSelectedCollaborator(collaborator);
    retrieveCollaboratorAddresses({ variables: { id: collaborator.id } });
  };

  React.useEffect(() => {
    const setDebounced = setTimeout(() => {
      setSearchInputDebounced(searchInput);
      setPage(0);
      setPageSize(DEFAULT_LIMIT);
    }, 500);

    return () => clearTimeout(setDebounced);
  }, [searchInput]);

  const handleCloseModal = () => {
    props.workflow.dismissCurrentModal();
  };

  const onClickShowAddNewPersonModal = () => {
    props.workflow.setCurrentModal(ProcurementFlowModals.ADD_NEW_PERSON);
  };

  React.useEffect(() => {
    //Set the page to 0 when internal admin changes organization
    setPage(0);
    setPageSize(DEFAULT_LIMIT);
  }, [userOrganization?.id]);

  return (
    <>
      <Container
        sx={{
          '@media (min-width: 1536px)': {
            left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
            maxWidth: '100%',
            width: '95%',
          },
        }}
      >
        <Stack
          spacing={1.25}
          flexWrap="nowrap"
          marginLeft={{ xs: 'none', lg: `${DRAWER_WIDTH}px` }}
        >
          <Stack sx={InnerProcurementStackSX}>
            <Stack spacing={1.25}>
              <Typography variant="h4" className="send-equipment-title-copy">
                Send equipment
              </Typography>
              <Typography variant="body2" style={{ marginBottom: '10px' }}>
                Select or add employee(s) to send equipment and merchandise.
              </Typography>
            </Stack>
            <Button
              gaContext={{
                navigates_to: 'N/A',
                textCopy: 'New employee',
                purpose: 'Opens New Employee Modal From Collaborator View',
              }}
              color="secondary"
              size="small"
              variant="contained"
              onClick={onClickShowAddNewPersonModal}
            >
              <Typography variant="button">+&nbsp; New employee</Typography>
            </Button>
          </Stack>
          <FilledInput
            placeholder="Search employees"
            disableUnderline={true}
            startAdornment={<SearchIcon />}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            data-testid="search-employees-a5rpz1g8b7"
            sx={{
              width: '45%',
              height: '34px',
              border: `2px solid ${isFocused ? 'black' : 'transparent'}`,
            }}
          />
          <SelectRecipientEnabledDisplay>
            <CollaboratorDataGrid
              collaboratorsData={{
                collaborators,
                count: totalNumOfCollaboratorsInOrg,
              }}
              selectedCollaborators={props.workflow.selectedCollaborators}
              loadingCollaborators={isLoading}
              paginationModel={{ page, pageSize }}
              renderGridScrollIndex={gridScrollIndex}
              handleAddSelectedCollaborator={onAddSelectedCollaborator}
              handleRemoveSelectedCollaborator={
                props.workflow.removeSelectedCollaborator
              }
              handleSetCollaboratorSortOrder={setCollaboratorSortOrder}
              handleSetCollaboratorPagination={setCollaboratorPagination}
              handleUpdateCurrentGridScrollIndex={setGridScrollIndex}
            />
          </SelectRecipientEnabledDisplay>
          <SelectRecipientEmailDisabledDisplay>
            <CollaboratorTable
              addSelectedCollaborator={onAddSelectedCollaborator}
              collaborators={collaborators}
              page={page}
              removeSelectedCollaborator={
                props.workflow.removeSelectedCollaborator
              }
              selectedCollaborators={props.workflow.selectedCollaborators}
              setPage={setPage}
              totalNumOfCollaboratorsInOrg={totalNumOfCollaboratorsInOrg}
            />
          </SelectRecipientEmailDisabledDisplay>
        </Stack>
      </Container>
      {hasSelectedCollaborators && (
        <SelectedCollaboratorBar
          deselectAllCollaborators={props.workflow.deselectAllCollaborators}
          selectedCollaborators={props.workflow.selectedCollaborators}
          updateProcurementFlowStep={props.workflow.updateProcurementFlowStep}
        />
      )}
      <AddNewPersonModal
        open={showAddNewPersonModal}
        handleClose={() => handleCloseModal()}
        onSuccessCallbackFunction={props.workflow.addSelectedCollaborator}
      />
      <NewAndImprovedModal
        open={openMessageModal}
        handleClose={() => closeMessageModal()}
      />
      <SentEquipmentConfirmationModal
        open={showSentEquipmentConfirmationModal}
        handleClose={closeSentEquipmentConfirmationModal}
      />
    </>
  );
};

export default CollaboratorView;
