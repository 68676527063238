import { OrderView } from 'pages/OrderManagement/enum';

export const OM_VIEW_META = {
  [OrderView.ALL_NEW_ORDERS]:
    'Shows orders with status of Created or Pending Recipient Info',
  [OrderView.CANCELLED]: 'Shows only Cancelled (status) orders',
  [OrderView.EXPIRED]:
    'Shows orders with statuses of either Expired Pending Recipient Information or Expired Pending Return',
  [OrderView.KITS]: 'Shows orders with hasKits field of true',
  [OrderView.PROCUREMENT]: 'Shows only Procurement (order type) orders',
  [OrderView.REDEPLOYMENT]: 'Shows only Deployment type orders',
  [OrderView.RETRIEVALS]:
    'Shows orders of either type Bulk Retrieval or Retrieval',
  [OrderView.ALL_ORDERS]: 'Shows all orders',
};
