import React from 'react';
import omit from 'lodash/omit';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCredentialedCollaborators,
  postCredentialedCollaborators,
} from 'store/slices/manage_users/table/actions';
import {
  setButtonActionDropdownById,
  resetState,
  setPaginationModel,
  setShowRoleChangeConfirmationModal,
  setRoleConfirmationData,
  setShowRevokeConfirmationModal,
} from 'store/slices/manage_users/table';
import { AppDispatch } from 'store';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import {
  selectGetCredentialedCollaboratorsLoadingState,
  selectCredentialedCollaboratorsPaginationModel,
  selectManageUsersCredentialedCollaborators,
  selectManageUsersCredentialedCollaboratorsCount,
  selectButtonActionDropdownById,
  selectPostCredentialedCollaboratorsLoadingStateMap,
} from 'store/slices/manage_users/table/selectors';
import {
  CredentialedCollaboratorResponse,
  CredentialedCollaboratorRole,
} from 'api/rest/credentialedCollaborators/types';
import {
  ACTION_OPTIONS,
  COLUMNS,
  ROLE_OPTIONS,
  STATUS_COLOR_MAP,
} from './constants';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { Collaborator } from 'types';
import {
  useCanCurrentUserToggleOrganizations,
  useCanWriteCollaborators,
} from '../../shared/hooks';
import { selectGlobalAuth0Collaborator } from 'store/slices/global/selectors';
import { getAuthenticationModelLabel, getCredentialStatusLabel } from './utils';

export const useTransformToTableData = (
  collaborators: CredentialedCollaboratorResponse[],
  canToggleOrganizations: boolean,
  postCredentialedCollaboratorsLoadingStateMap: Record<string, string>,
  canWriteCollaborators: boolean,
  currentCollaborator?: Collaborator
) => {
  const dispatch = useDispatch();
  const appDispatch = useDispatch<AppDispatch>();
  const buttonActionDropdownById = useSelector(selectButtonActionDropdownById);
  let currentCollaboratorId = '';
  if (currentCollaborator) {
    currentCollaboratorId = currentCollaborator?.id;
  }
  const actionsEnabled =
    canToggleOrganizations ||
    (!canToggleOrganizations && canWriteCollaborators);
  const actionsDisabled = !actionsEnabled;
  if (!collaborators.length) return [];
  return collaborators.map((collaborator) => {
    return {
      id: collaborator.id,
      name: {
        text: `${collaborator.firstName} ${collaborator.lastName}${
          currentCollaboratorId === collaborator.id ? ' (You)' : ''
        }`,
      },
      email: {
        email: collaborator.email,
      },
      authenticationModes: {
        text: getAuthenticationModelLabel(collaborator.authenticationModes),
      },
      role: {
        selected: collaborator.role,
        onSelectionClick: (event: { target: { value: string } }) => {
          dispatch(setShowRoleChangeConfirmationModal(true));
          dispatch(
            setRoleConfirmationData({
              id: collaborator.id,
              role: event.target.value as CredentialedCollaboratorRole,
            })
          );
        },
        options: ROLE_OPTIONS,
        disabled: actionsDisabled || collaborator.id === currentCollaboratorId,
      },
      credentialStatus: {
        text: getCredentialStatusLabel(collaborator.credentialStatus),
        color: STATUS_COLOR_MAP[collaborator.credentialStatus],
      },
      action: {
        text: 'Revoke access',
        hide: collaborator.id === currentCollaboratorId,
        loading:
          postCredentialedCollaboratorsLoadingStateMap[collaborator?.id] ===
          'pending',
        disabled: actionsDisabled,
        // Button
        onButtonClick: () => {
          dispatch(setShowRevokeConfirmationModal(true));
          dispatch(
            setRoleConfirmationData({
              id: collaborator.id,
              role: null,
            })
          );
        },
        // Dropdown
        selected: buttonActionDropdownById[collaborator.id]?.selected,
        onSelectClick: (event: { target: { innerText: string } }) => {
          const value = event.target.innerText;
          if (value === 'Resend invite') {
            appDispatch(
              postCredentialedCollaborators({
                data: {
                  ...omit(collaborator, 'id', 'authenticationModes'),
                  authenticationMode: collaborator.authenticationModes[0],
                },
              })
            );
          } else if (value === 'Revoke access') {
            dispatch(setShowRevokeConfirmationModal(true));
            dispatch(
              setRoleConfirmationData({
                id: collaborator.id,
                role: null,
              })
            );
          }
        },
        onMenuItemClick: (event: {
          target: { dataset: { value: string } };
        }) => {
          dispatch(
            setButtonActionDropdownById({
              key: collaborator.id,
              value: event.target.dataset.value,
            })
          );
        },
        options: ACTION_OPTIONS,
        type:
          collaborator.credentialStatus === 'PENDING' ||
          collaborator.credentialStatus === 'EXPIRED'
            ? 'dropdown'
            : 'button',
      },
    };
  });
};

export const userGetInitialCredentialedCollaborators = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();
  return React.useEffect(() => {
    dispatch(resetState());
    return () => {
      dispatch(resetState());
    };
  }, [organization?.id]);
};

export const userGetCredentialedCollaborators = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();
  const paginationModel = useSelector(
    selectCredentialedCollaboratorsPaginationModel
  );
  return React.useEffect(() => {
    if (organization?.id) {
      dispatch(
        getCredentialedCollaborators({
          orgId: organization.id,
          offset: paginationModel.page * paginationModel.pageSize,
          limit: paginationModel.pageSize,
        })
      );
    }
  }, [organization?.id, paginationModel.page, paginationModel.pageSize]);
};

export const useHandlePaginationModelChange = () => {
  const dispatch = useDispatch();
  return React.useCallback((paginationModel: GridPaginationModel) => {
    dispatch(setPaginationModel(paginationModel));
  }, []);
};

export const useGetUsersTableConfig = () => {
  const getCredentialedCollaboratorsLoadingState = useSelector(
    selectGetCredentialedCollaboratorsLoadingState
  );
  const paginationModel = useSelector(
    selectCredentialedCollaboratorsPaginationModel
  );
  const data = useSelector(selectManageUsersCredentialedCollaborators);
  const count = useSelector(selectManageUsersCredentialedCollaboratorsCount);
  const currentCollaborator = useSelector(selectGlobalAuth0Collaborator);
  const canToggleOrganizations = useCanCurrentUserToggleOrganizations();
  const canWriteCollaborators = useCanWriteCollaborators();
  const postCredentialedCollaboratorsLoadingStateMap = useSelector(
    selectPostCredentialedCollaboratorsLoadingStateMap
  );
  const rows = useTransformToTableData(
    data,
    canToggleOrganizations,
    postCredentialedCollaboratorsLoadingStateMap,
    canWriteCollaborators,
    currentCollaborator
  );
  const columns = COLUMNS;
  const rowCount = count;
  const loading = getCredentialedCollaboratorsLoadingState === 'pending';
  const onPaginationModelChange = useHandlePaginationModelChange();
  const pageSizeOptions = [5, 10, 25];
  return {
    rows,
    columns,
    rowCount,
    pagination: true,
    paginationModel,
    loading,
    onPaginationModelChange,
    pageSizeOptions,
  };
};
