export const ShippingPopoverSx = {
  pointerEvents: 'none',
  height: 'auto',
  top: '-5px',
  ml: '18px',
};

export const ShippingPopoverStackSx = {
  maxWidth: '400px',
  height: 'auto',
  padding: '16px',
  gap: '16px',
  borderBottom: '1px solid #E0E0E0',
  pointerEvents: 'auto',
};

export const ShipmentStatusPillSx = {
  height: '24px',
  maxWidth: '150px',
};

export const TrackingDetailsBoxSx = {
  display: 'flex',
  px: '2px',
  justifyContent: 'space-between',
  gap: '12px',
};

export const OrderItemDetailsBoxSx = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  px: '2px',
};

export const MoreOrderItemsLinkSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  cursor: 'pointer',
};
