/* eslint-disable prefer-const */
import React from 'react';
import { StatusTransitionRow } from 'types';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { getAssetStatusTransitions } from 'api/apollo/queries/v1';
import {
  ASSET_STATUS_HISTORY_QUERY_OUTPUT,
  DEFAULT_ITEMS_COUNT_OFFSET,
  REVEAL_ITEMS_DEFAULT,
} from './constants';

const startingCount = REVEAL_ITEMS_DEFAULT;

const useGetAssetStatusTransitions = (assetId: string) => {
  const [revealItemsCount, setRevealItemsCount] =
    React.useState<number>(startingCount);
  const { data, loading: statusTransitionLoading } = useSafeQuery(
    getAssetStatusTransitions(ASSET_STATUS_HISTORY_QUERY_OUTPUT),
    {
      variables: {
        assetId,
        limit: DEFAULT_ITEMS_COUNT_OFFSET,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    }
  );

  const statusTransitionRecords: StatusTransitionRow[] =
    data?.assetStatusTransitions?.items || [];

  const revealMoreItems = () => {
    const actualRecords =
      statusTransitionRecords.length > 0
        ? statusTransitionRecords.length + 1
        : statusTransitionRecords.length;
    if (actualRecords > revealItemsCount) {
      setRevealItemsCount((prevCount) => prevCount + REVEAL_ITEMS_DEFAULT);
    }
  };

  return {
    statusTransitionRecords,
    statusTransitionLoading,
    revealMoreItems,
    revealItemsCount,
  };
};

export default useGetAssetStatusTransitions;
