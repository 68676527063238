import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/immer';
import { OrdersCSVInitialState } from './types';
import { getOrdersCSV, getOrdersCSVDirect } from './actions';

export const getOrdersPendingReducer: CaseReducer<
  OrdersCSVInitialState,
  ReturnType<typeof getOrdersCSV.pending>
> = (state) => {
  state.loadingState = 'pending';
  state.url = '';
  state.errorMessage = '';
};

export const getOrdersFulfilledReducer: CaseReducer<
  OrdersCSVInitialState,
  ReturnType<typeof getOrdersCSV.fulfilled>
> = (state, action) => {
  state.loadingState = 'fulfilled';
  state.url = action.payload.downloadUrl;
  state.errorMessage = '';
};

export const getOrdersRejectedReducer: CaseReducer<
  OrdersCSVInitialState,
  ReturnType<typeof getOrdersCSV.rejected>
> = (state, action) => {
  state.loadingState = 'rejected';
  state.url = '';
  const { error } = action;
  if (
    error &&
    error.code === 'ERR_BAD_REQUEST' &&
    error?.message?.match('413')
  ) {
    state.errorMessage = 'CSV file is too large. Please contact support';
  } else {
    state.errorMessage = 'Unknown error occurred. Please contact support';
  }
};

export const getOrdersDirectPendingReducer: CaseReducer<
  OrdersCSVInitialState,
  ReturnType<typeof getOrdersCSVDirect.pending>
> = (state) => {
  state.loadingState = 'pending';
  state.data = '';
  state.url = '';
  state.errorMessage = '';
};

export const getOrdersDirectFulfilledReducer: CaseReducer<
  OrdersCSVInitialState,
  ReturnType<typeof getOrdersCSVDirect.fulfilled>
> = (state, action) => {
  state.loadingState = 'fulfilled';
  state.data = action.payload.data;
  state.url = '';
  state.errorMessage = '';
};

export const getOrdersDirectRejectedReducer: CaseReducer<
  OrdersCSVInitialState,
  ReturnType<typeof getOrdersCSVDirect.rejected>
> = (state, action) => {
  state.loadingState = 'rejected';
  state.data = '';
  const { error } = action;
  if (
    error &&
    error.code === 'ERR_BAD_REQUEST' &&
    error?.message?.match('413')
  ) {
    state.errorMessage = 'CSV file is too large. Please contact support';
  } else {
    state.errorMessage = 'Unknown error occurred. Please contact support';
  }
};

export const setOrdersCSVDownloadURLReducer = (
  state: WritableDraft<OrdersCSVInitialState>,
  action: PayloadAction<string>
) => {
  state.url = action.payload;
};
