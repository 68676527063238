import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectManageUsersShowInviteAdminModal } from 'store/slices/manage_users/actions/selectors';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { postCredentialedCollaborators } from 'store/slices/manage_users/table/actions';
import { InviteAdminFormInput } from '../../forms/InviteAdminModalForm/types';
import { AppDispatch } from 'store';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { CredentialedCollaboratorRole } from 'api/rest/credentialedCollaborators/types';
import { setManageUsersShowInviteAdminModal } from 'store/slices/manage_users/actions';
import { setCredentialedCollaboratorsLoadingState } from 'store/slices/manage_users/table/index';

export const useResetFormOnClose = (
  form: UseFormReturn<InviteAdminFormInput>
) => {
  const open = useSelector(selectManageUsersShowInviteAdminModal);
  const { reset } = form;

  return React.useEffect(() => {
    return () => {
      if (!open) {
        reset();
      }
    };
  }, [open]);
};

export const useHandleOnSubmit = (
  form: UseFormReturn<InviteAdminFormInput>
) => {
  const { handleSubmit } = form;
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();
  const onSubmit: SubmitHandler<InviteAdminFormInput> = React.useCallback(
    async (data) => {
      if (organization?.id) {
        await dispatch(
          postCredentialedCollaborators({
            data: {
              ...data,
              organizationId: organization?.id,
              role: data.role as CredentialedCollaboratorRole,
              authenticationMode: data.authenticationMode,
            },
          })
        );
        await dispatch(setManageUsersShowInviteAdminModal(false));
        await dispatch(setCredentialedCollaboratorsLoadingState('idle'));
      }
    },
    [organization?.id]
  );

  return handleSubmit(onSubmit);
};
