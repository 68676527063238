import {
  upsertDropdownOptionsHOF,
  getDropDownEntries,
} from 'components/complex_filter/utils';
import {
  FilterLayoutSection,
  FilterModalConfig,
  FilterTestIds,
  BaseFilterBankConfig,
  DropDownOption,
} from 'components/complex_filter/types';
import {
  getAssetQueryVariablesHOF,
  getAssetTypeaheadOnCompletedHOF,
  getAssigneeTypeaheadOnCompletedHOF,
  stringsToDropDownOptions,
  getDepotsOnCompletedHOF, // exported elsewhere
  getAssetTypesOnCompletedHOF,
} from 'pages/Assets/filters/utils';
import {
  GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
  GET_ASSIGNEE_NAME_OPTIONS,
  GET_ASSIGNEE_EMAIL_OPTIONS,
  GET_FIRST_100_DEPOTS,
  GET_ALL_ASSET_TYPES,
  DUMMY_NODE_GET_NOTHING,
} from 'pages/Assets/filters/queries';
import {
  AssetsDropdownConfig,
  AssetsTypeaheadConfig,
} from 'store/slices/assets/filter/types';
import { assetFilterActions } from 'store/slices/assets/filter';

const ASSET_STATUSES = [
  'Activation Locked',
  'Allocated',
  'Donated',
  'HOLD',
  'In Inventory',
  'In inventory - device issue',
  'Lost',
  'MDM Locked',
  'Pending Allocation',
  'Pending Certification',
  'Pending Quote Approval',
  'Pending Recycle',
  'Pending Repair',
  'Pending Retrieval',
  'Pending Sale',
  'Pending Wipe',
  'Processing Device',
  'Quote in Progress',
  'Recovery Locked',
  'Recycled',
  'Sold',
  'Transferred',
  'Intake',
];

const ASSET_CONDITIONS = [
  'S - New',
  'A - Like New',
  'B - Very Good',
  'C - Good',
  'D - Fair',
];

export const AssetFilterKeys = {
  ASSET_NUMBER: 'asset-number',
  DEPOT: 'depot',
  STATUS: 'status',
  ASSIGNEE: 'assignee',
  ASSIGNEE_EMAIL: 'assignee-email',
  TYPE: 'type',
  MAKE: 'make',
  MODEL: 'model',
  CONDITION: 'condition',
  KEYBOARD: 'keyboard-config',
  SERIAL_NO: 'serial-no',
  DEVICE_ISSUE: 'device-issue',
  COLOR: 'color',
  DISPLAY_SIZE: 'display-size',
  PROCESSOR: 'processor',
  MEMORY: 'memory',
  STORAGE: 'storage',
};

export const ASSET_DEVICES_FILTER_BANK_CONFIG: BaseFilterBankConfig = {
  actions: assetFilterActions,
  componentTestId: 'asset-devices-filter-bank-outer',
  bankTestId: 'asset-devices-filter-bank-inner',
};

export const ASSET_DEVICES_FILTER_LAYOUT: FilterLayoutSection[] = [
  {
    section: 'Allwhere Info',
    layout: [
      {
        filterKey: AssetFilterKeys.ASSET_NUMBER,
        placeholder: 'Asset Number',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.DEPOT,
        placeholder: 'Depot Location',
        fieldType: 'dropdown',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.STATUS,
        placeholder: 'Status',
        fieldType: 'dropdown',
        span: 'half',
      },
    ],
  },
  {
    section: 'Assignee Info',
    layout: [
      {
        filterKey: AssetFilterKeys.ASSIGNEE,
        placeholder: 'Assignee',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.ASSIGNEE_EMAIL,
        placeholder: 'Assignee Email',
        fieldType: 'typeahead',
        span: 'half',
      },
    ],
  },
  {
    section: 'Device Info',
    layout: [
      {
        filterKey: AssetFilterKeys.TYPE,
        placeholder: 'Type',
        fieldType: 'dropdown',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.MAKE,
        placeholder: 'Make',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.MODEL,
        placeholder: 'Model',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.CONDITION,
        placeholder: 'Condition',
        fieldType: 'dropdown',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.KEYBOARD,
        placeholder: 'Keyboard Config',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.SERIAL_NO,
        placeholder: 'Serial Number',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.DEVICE_ISSUE,
        placeholder: 'Device Issue',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.COLOR,
        placeholder: 'Color',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.DISPLAY_SIZE,
        placeholder: 'Display Size',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.PROCESSOR,
        placeholder: 'Processor',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.MEMORY,
        placeholder: 'Memory',
        fieldType: 'typeahead',
        span: 'half',
      },
      {
        filterKey: AssetFilterKeys.STORAGE,
        placeholder: 'Storage',
        fieldType: 'typeahead',
        span: 'half',
      },
    ],
  },
];

export const ASSET_DEVICES_FILTER_TEST_IDS: FilterTestIds = {
  outerBoxTestId: 'asset-devices-filter-drawer-outer-box',
  filterButtonTestId: 'asset-devices-filter-drawer-toggle-btn',
  selectFilterBadgeTestId: 'asset-devices-filter-counter-badge',
};

export const ASSET_DEVICES_TYPEAHEAD_HANDLERS: AssetsTypeaheadConfig = {
  'asset-number': {
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 55,
  },
  assignee: {
    lookupQuery: GET_ASSIGNEE_NAME_OPTIONS,
    onCompletedHOF: getAssigneeTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 96,
  },
  'assignee-email': {
    lookupQuery: GET_ASSIGNEE_EMAIL_OPTIONS,
    onCompletedHOF: getAssigneeTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 51,
  },
  make: {
    getQueryVariables: getAssetQueryVariablesHOF('make', { searchBy: 'iLike' }),
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 124,
  },
  model: {
    getQueryVariables: getAssetQueryVariablesHOF('model', {
      searchBy: 'iLike',
    }),
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 125,
  },
  'keyboard-config': {
    getQueryVariables: getAssetQueryVariablesHOF('keyboard', {
      searchBy: 'iLike',
    }),
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 48,
  },
  'serial-no': {
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 55,
  },
  'device-issue': {
    getQueryVariables: getAssetQueryVariablesHOF('deviceAssessment', {
      searchBy: 'iLike',
    }),
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 66,
  },
  color: {
    getQueryVariables: getAssetQueryVariablesHOF('color', {
      searchBy: 'iLike',
    }),
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 123,
  },
  'display-size': {
    getQueryVariables: getAssetQueryVariablesHOF('displaySize', {
      searchBy: 'iLike',
    }),
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 72,
  },
  processor: {
    getQueryVariables: getAssetQueryVariablesHOF('processor', {
      searchBy: 'iLike',
    }),
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 90,
  },
  memory: {
    getQueryVariables: getAssetQueryVariablesHOF('memory', {
      searchBy: 'iLike',
    }),
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 103,
  },
  storage: {
    getQueryVariables: getAssetQueryVariablesHOF('storage', {
      searchBy: 'iLike',
    }),
    lookupQuery: GET_ASSET_AUTOCOMPLETE_SUGGESTIONS,
    onCompletedHOF: getAssetTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 105,
  },
};

export const ASSET_DEVICES_DROPDOWN_HANDLERS: AssetsDropdownConfig = {
  depot: {
    asyncOptionsQuery: GET_FIRST_100_DEPOTS,
    counterOffsetRight: 51,
    inputLabel: 'Depot Location',
    makeSelectionText: 'Select Location',
    optionsType: 'async',
    presetOptions: [],
    upsertOptionsHOF: getDepotsOnCompletedHOF,
    getPreloadedEntries: (key: string, options: DropDownOption[]) => [],
  },
  status: {
    asyncOptionsQuery: DUMMY_NODE_GET_NOTHING,
    counterOffsetRight: 115,
    inputLabel: 'Status',
    makeSelectionText: 'Select status',
    optionsType: 'hard-coded',
    presetOptions: stringsToDropDownOptions(ASSET_STATUSES),
    upsertOptionsHOF: upsertDropdownOptionsHOF,
    getPreloadedEntries: getDropDownEntries,
  },
  type: {
    asyncOptionsQuery: GET_ALL_ASSET_TYPES,
    counterOffsetRight: 128,
    inputLabel: 'Type',
    makeSelectionText: 'Select type',
    optionsType: 'async',
    presetOptions: [],
    upsertOptionsHOF: getAssetTypesOnCompletedHOF,
    getPreloadedEntries: (key: string, options: DropDownOption[]) => [],
  },
  condition: {
    asyncOptionsQuery: DUMMY_NODE_GET_NOTHING,
    counterOffsetRight: 95,
    inputLabel: 'Condition',
    makeSelectionText: 'Select condition',
    optionsType: 'hard-coded',
    presetOptions: stringsToDropDownOptions(ASSET_CONDITIONS),
    upsertOptionsHOF: upsertDropdownOptionsHOF,
    getPreloadedEntries: getDropDownEntries,
  },
};

export const ASSET_DEVICES_FILTER_MODAL_CONFIG: FilterModalConfig = {
  blueprint: ASSET_DEVICES_FILTER_LAYOUT,
  typeAheadHandlers: ASSET_DEVICES_TYPEAHEAD_HANDLERS,
  dropdownHandlers: ASSET_DEVICES_DROPDOWN_HANDLERS,
  fullTextHandlers: {},
  checkboxHandlers: {},
  actions: assetFilterActions,
};
