/* eslint-disable security/detect-object-injection */

export const getEnumKeys = <T>(
  enumToDeconstruct: T
): Array<keyof typeof enumToDeconstruct> => {
  //@ts-ignore
  return Object.keys(enumToDeconstruct) as Array<
    keyof typeof enumToDeconstruct
  >;
};

export const getEnumValues = (enumToDeconstruct: any): string[] =>
  getEnumKeys(enumToDeconstruct).map((key) => enumToDeconstruct[key]);

export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null => {
  const keys = Object.keys(myEnum) as Array<keyof T>;
  const foundKey = keys.find((key) => myEnum[key] === enumValue);
  return foundKey ?? null;
};

export const isStringEnumKey = (str: string, enumToCheck: any): boolean => {
  const enumKeys = Object.keys(enumToCheck);
  return enumKeys.includes(str);
};

export const isStringEnumValue = (str: string, enumToCheck: any): boolean => {
  const enumValues = Object.values(enumToCheck);
  return enumValues.includes(str);
};

export const isStringEnum = (
  str: string,
  enumToCheck: any
): [string, boolean] => {
  const stringIsKey = isStringEnumKey(str, enumToCheck);
  const stringIsValue = isStringEnumValue(str, enumToCheck);

  if (stringIsKey && stringIsValue) {
    return ['both', true];
  }

  if (stringIsKey) {
    return ['key', stringIsKey];
  }
  if (stringIsValue) {
    return ['value', stringIsValue];
  }
  return ['', false];
};
