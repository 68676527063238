import React from 'react';
import { useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ProtectedRouteProps } from './types';

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const Component = withAuthenticationRequired(props.component, props.options);
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const appBar = document.getElementById('appbar');
  const sideBar = document.getElementById('sidebar');

  React.useEffect(() => {
    if (appBar) {
      if (props.hideAppBar) {
        appBar.style.display = 'none';
      } else {
        appBar.style.display = 'flex';
      }
    }
    if (sideBar) {
      if (props.hideSidebar) {
        sideBar.style.display = 'none';
      } else {
        sideBar.style.display = '';
      }
    }
  }, [props.hideAppBar, props.hideSidebar, appBar, sideBar]);

  return (
    <Stack direction="column" id="protected-route-container">
      <Stack>
        <Component />
      </Stack>
    </Stack>
  );
};

export default ProtectedRoute;
