import { SearchOption } from 'components/SearchScopeDropdown/types';
import { OrderManagementFilterFields } from './enum';
import { BaseFilterEntry } from 'store/shared/types';

export enum OrderManagementSuggestionFilterName {
  ORDER_NUMBER = 'orderNumber',
  RECIPIENT_NAME = 'recipientName',
  RECIPIENT_EMAIL = 'recipientEmail',
}

export const OM_SEARCH_OPTIONS: SearchOption[] = [
  {
    displayName: 'Order number',
    filterName: OrderManagementSuggestionFilterName.ORDER_NUMBER,
  },
  {
    displayName: 'Recipient name',
    filterName: OrderManagementSuggestionFilterName.RECIPIENT_NAME,
  },
  {
    displayName: 'Recipient email',
    filterName: OrderManagementSuggestionFilterName.RECIPIENT_EMAIL,
  },
];

export const OM_FILTER_SLICE_NAME = 'ORDER_MANAGEMENT_FILTERS';

export const OM_DEFAULT_FILTER_STATE = {
  [OrderManagementFilterFields.ORDER_TYPE_NAME]: null, // Order Type
  [OrderManagementFilterFields.STATUS]: null, // Status
  [OrderManagementFilterFields.ORGANIZATION_ID]: null, // Account
  [OrderManagementFilterFields.ORDER_MANAGER_ID]: null, // Assignee
  [OrderManagementFilterFields.DEPOT_ID]: null, // Depot Location
};

export const OM_CLEAR_ALL_FILTERS_BUTTON_ID =
  'order-management-filters-clear-all-button';

export const UNASSIGNED_ORDER_MANAGER_ID_VALUE = 'UNASSIGNED::ORDER_MANAGER';
// Mulit-assign Order Managers, role-based
export const UNASSIGNED_ORDER_MANAGEMENT_ID_VALUE =
  'UNASSIGNED::ORDER_MANAGEMENT';
export const UNASSIGNED_PROCUREMENT_ID_VALUE = 'UNASSIGNED::PROCUREMENT';
export const UNASSIGNED_ASSET_MANAGEMENT_ID_VALUE =
  'UNASSIGNED::ASSET_MANAGEMENT';
export const UNASSIGNED_OTHER_ID_VALUE = 'UNASSIGNED::OTHER';

export const ManagerRoles = [
  'ORDER_MANAGEMENT',
  'PROCUREMENT',
  'ASSET_MANAGEMENT',
  'OTHER',
];

export const ManagerRolesMap: { [key: string]: string } = {
  ORDER_MANAGEMENT: 'order manager',
  PROCUREMENT: 'procurement manager',
  ASSET_MANAGEMENT: 'asset manager',
  OTHER: 'other manager',
};

export const ManagerIdFields = [
  'orderManagerId',
  'procurementManagerId',
  'assetManagerId',
  'otherManagerId',
];

export const ManagerModelFields = [
  'orderManager',
  'procurementManager',
  'assetManager',
  'otherManager',
];

export const MANAGER_MODEL_FIELD_BY_ROLE = Object.fromEntries(
  ManagerRoles.map((role, index) => [role, ManagerModelFields[index]])
);

export const MANAGERS_ROLE = Object.fromEntries(
  ManagerRoles.map((role) => [role, role])
);

export const MANAGER_FOREIGN_KEYS = Object.fromEntries(
  ManagerIdFields.map((field, index) => [ManagerRoles[index], field])
);

export const MANAGERS_ROLE_ID_FIELD_MAP = Object.fromEntries(
  ManagerRoles.flatMap((role, index) => [
    [role, ManagerIdFields[index]],
    [ManagerIdFields[index], role],
  ])
);

export const MANAGER_ROLE_UNASSIGNED_OPTION: Record<string, BaseFilterEntry> = {
  ORDER_MANAGEMENT: {
    id: 'UNASSIGNED::ORDER_MANAGEMENT',
    key: 'order-management',
    displayName: 'Unassigned',
    value: 'UNASSIGNED::ORDER_MANAGEMENT',
  },
  PROCUREMENT: {
    id: 'UNASSIGNED::PROCUREMENT',
    key: 'procurement',
    displayName: 'Unassigned',
    value: 'UNASSIGNED::PROCUREMENT',
  },
  ASSET_MANAGEMENT: {
    id: 'UNASSIGNED::ASSET_MANAGEMENT',
    key: 'asset-manager',
    displayName: 'Unassigned',
    value: 'UNASSIGNED::ASSET_MANAGEMENT',
  },
  OTHER: {
    id: 'UNASSIGNED::OTHER',
    key: 'other',
    displayName: 'Unassigned',
    value: 'UNASSIGNED::OTHER',
  },
};
