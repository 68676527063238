import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { DetailViewContentContainerSx } from 'pages/OrderManagement/components/DetailedOrderView/styles';
import { executeNowThenWait } from 'services/misc';
import { OrderDetailsProps } from './types';
import { GET_ORDER_ITEMS_BY_ORDER_ID } from 'pages/OrderManagement/components/DetailedOrderView/constants';

import OrderDetailsNotes from './components/OrderDetailsNotes';
import { OrderDetailsSx } from './styles';
import OrderItemCard from './components/OrderItemCard';
import DeleteOrderItemModal from './components/DeleteOrderItemModal';
import { INSURANCE_OPTIONS } from 'pages/OrderManagement/components/DetailedOrderView/components/OrderDetails/components/OrderItemCard/constants';
import { Order } from 'types';

const OrderDetails = (props: OrderDetailsProps) => {
  if (!props.order) return null;

  const [orderItemToRemove, setOrderItemToRemove] = React.useState<
    Record<string, any> | undefined
  >();

  const {
    data,
    loading,
    refetch: refetchOrderItems,
  } = useSafeQuery(GET_ORDER_ITEMS_BY_ORDER_ID, {
    variables: {
      orderId: props.order.id,
    },
  });

  React.useEffect(() => {
    refetchOrderItems();
  }, []);

  const OrderDetailsContainerSx = {
    ...DetailViewContentContainerSx,
    ...OrderDetailsSx,
  } as SxProps;

  const orderDetailsNotesProps = {
    orderId: props.order.id,
    ...(props.order.internalNotes && {
      internalNotes: props.order.internalNotes,
    }),
  };

  const clearOrderItemRemovalQueue = () => {
    setOrderItemToRemove(undefined);
  };

  const queueOrderItemRemovalHoF = (orderItem: any) => () => {
    const orderItemId = orderItem?.id ?? '';
    const orderItemNumber = orderItem?.orderItemNumber ?? '';
    executeNowThenWait(
      clearOrderItemRemovalQueue,
      () => {
        setOrderItemToRemove(() => ({
          ...orderItem,
          orderItemId,
          orderItemNumber,
        }));
      },
      100
    );
  };

  const orderItemObjects = data?.getAllOrderItems ?? [];
  const noAvailableOrderItems = orderItemObjects.length === 0 && !loading;

  const getDeleteOrderItemModalProps = () => ({
    ...(orderItemToRemove && {
      orderItemId: orderItemToRemove.orderItemId,
      orderItemNumber: orderItemToRemove.orderItemNumber,
      insuranceRequested:
        orderItemToRemove.insuranceRequested ||
        INSURANCE_OPTIONS[0].value.insuranceRequested,
    }),
    closeModal: clearOrderItemRemovalQueue,
  });

  return (
    <>
      <Container sx={OrderDetailsContainerSx}>
        <div style={{ maxHeight: '100%', overflowY: 'scroll', width: '820px' }}>
          {noAvailableOrderItems ? (
            <Typography>No Order Items available</Typography>
          ) : (
            orderItemObjects.map((orderItem: any) => {
              return (
                <OrderItemCard
                  key={orderItem.orderItemId}
                  order={props.order as Order}
                  orderItem={orderItem}
                  queueOrderItemRemoval={queueOrderItemRemovalHoF(orderItem)}
                />
              );
            })
          )}
        </div>
        <OrderDetailsNotes {...orderDetailsNotesProps} />
      </Container>
      <DeleteOrderItemModal {...getDeleteOrderItemModalProps()} />
    </>
  );
};

export default OrderDetails;
