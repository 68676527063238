/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useGetAssetStatusTransitions from './hooks';
import AssetStatusTransitionTimeLine from '../AssetStatusTransitionTimeLine';
import { ASSET_STATUS_HISTORY_BOX_CONTAINER_ID } from './constants';
import AssetStatusHistoryPanelProps from './types';
import * as AssetStatusHistoryPanelStyles from './styles';
import { AssetStatus } from 'global-constants';

const AssetStatusHistoryPanel = (props: AssetStatusHistoryPanelProps) => {
  const styles = AssetStatusHistoryPanelStyles;
  const { assetId, createdAt, currentStatus } = props;

  if (!assetId) return <></>;

  const {
    statusTransitionRecords,
    statusTransitionLoading,
    revealMoreItems,
    revealItemsCount,
  } = useGetAssetStatusTransitions(assetId);

  const actualRecords =
    statusTransitionRecords.length > 0
      ? statusTransitionRecords.length + 1
      : statusTransitionRecords.length;

  const canFetchMoreItems = actualRecords > revealItemsCount;
  const remaining = actualRecords - revealItemsCount;

  return (
    <Grid item container direction="column" sx={{ width: '100%' }}>
      <Grid item xs={12}>
        <Stack sx={styles.AssetStatusHistoryStackSx} direction="row">
          {statusTransitionLoading ? (
            <CircularProgress color="primary" size={40} />
          ) : (
            <Box
              id={ASSET_STATUS_HISTORY_BOX_CONTAINER_ID}
              sx={styles.getAssetStatusHistoryScrollBoxSx(true)}
            >
              {/* <div>Status History goes...</div> */}
              <AssetStatusTransitionTimeLine
                statusTransitionRecords={statusTransitionRecords}
                revealItemsCount={revealItemsCount}
                createdAt={createdAt}
                currentStatus={
                  AssetStatus[currentStatus as keyof typeof AssetStatus] ||
                  currentStatus
                }
              />
            </Box>
          )}
        </Stack>
      </Grid>
      {canFetchMoreItems ? (
        <Grid item xs={12}>
          <Box sx={{ width: '100%', height: '44px' }}>
            <Button
              id="asset-details-fetch-more-status-history"
              sx={styles.FetchMoreAssetHistorySx}
              onClick={revealMoreItems}
            >
              Show {Math.min(10, remaining)} more{' '}
            </Button>
          </Box>
        </Grid>
      ) : (
        <> </>
      )}
    </Grid>
  );
};

export default AssetStatusHistoryPanel;
