import { CaseReducer } from '@reduxjs/toolkit';

import { TotalsState } from './types';
import {
  getAllOrdersTotals,
  getOpenOrdersTotals,
  getInTransitOrdersTotals,
  getCompleteOrdersTotals,
  getNeedsAttentionOrdersTotals,
  getExpiringSoonOrdersTotals,
  getAllAssetsTotals,
  getAllocatedAssetsTotals,
  getInInventoryAssetsTotals,
  getReadyToDeployAssetsTotals,
  getNeedsAttentionAssetsTotals,
} from './actions';

export const getAllOrdersPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getAllOrdersTotals.pending>
> = (state) => {
  state.allOrdersCountLoading = 'pending';
};

export const getAllOrdersFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getAllOrdersTotals.fulfilled>
> = (state, action) => {
  state.allOrdersCountLoading = 'fulfilled';
  state.allOrdersCount = action.payload.data.v2FindAndCountOrders.count;
};

export const getAllOrdersRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getAllOrdersTotals.rejected>
> = (state) => {
  state.allOrdersCountLoading = 'rejected';
};

///////
export const getOpenOrdersPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getOpenOrdersTotals.pending>
> = (state) => {
  state.openOrdersCountLoading = 'pending';
};

export const getOpenOrdersFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getOpenOrdersTotals.fulfilled>
> = (state, action) => {
  state.openOrdersCountLoading = 'fulfilled';
  state.openOrdersCount = action.payload.data.v2FindAndCountOrders.count;
};

export const getOpenOrdersRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getOpenOrdersTotals.rejected>
> = (state) => {
  state.openOrdersCountLoading = 'rejected';
};

///
export const getInTransitOrdersPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getInTransitOrdersTotals.pending>
> = (state) => {
  state.inTransitOrdersCountLoading = 'pending';
};

export const getInTransitOrdersFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getInTransitOrdersTotals.fulfilled>
> = (state, action) => {
  state.inTransitOrdersCountLoading = 'fulfilled';
  state.inTransitOrdersCount = action.payload.data.v2FindAndCountOrders.count;
};

export const getInTransitOrdersRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getInTransitOrdersTotals.rejected>
> = (state) => {
  state.inTransitOrdersCountLoading = 'rejected';
};

///
export const getCompleteOrdersPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getCompleteOrdersTotals.pending>
> = (state) => {
  state.completeOrdersCountLoading = 'pending';
};

export const getCompleteOrdersFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getCompleteOrdersTotals.fulfilled>
> = (state, action) => {
  state.completeOrdersCountLoading = 'fulfilled';
  state.completeOrdersCount = action.payload.data.v2FindAndCountOrders.count;
};

export const getCompleteOrdersRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getCompleteOrdersTotals.rejected>
> = (state) => {
  state.completeOrdersCountLoading = 'rejected';
};

///
export const getNeedsAttentionOrdersPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getNeedsAttentionOrdersTotals.pending>
> = (state) => {
  state.needsAttentionOrdersCountLoading = 'pending';
};

export const getNeedsAttentionOrdersFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getNeedsAttentionOrdersTotals.fulfilled>
> = (state, action) => {
  state.needsAttentionOrdersCountLoading = 'fulfilled';
  state.needsAttentionOrdersCount =
    action.payload.data.v2FindAndCountOrders.count;
};

export const getNeedsAttentionOrdersRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getNeedsAttentionOrdersTotals.rejected>
> = (state) => {
  state.needsAttentionOrdersCountLoading = 'rejected';
};

///
export const getExpiringSoonOrdersPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getExpiringSoonOrdersTotals.pending>
> = (state) => {
  state.expiringSoonOrdersCountLoading = 'pending';
};

export const getExpiringSoonOrdersFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getExpiringSoonOrdersTotals.fulfilled>
> = (state, action) => {
  state.expiringSoonOrdersCountLoading = 'fulfilled';
  state.expiringSoonOrdersCount =
    action.payload.data.v2FindOrdersByStatusTransition.count;
};

export const getExpiringSoonOrdersRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getExpiringSoonOrdersTotals.rejected>
> = (state) => {
  state.expiringSoonOrdersCountLoading = 'rejected';
};

//////////

export const getAllAssetsPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getAllAssetsTotals.pending>
> = (state) => {
  state.allAssetsCountLoading = 'pending';
};

export const getAllAssetsFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getAllAssetsTotals.fulfilled>
> = (state, action) => {
  state.allAssetsCountLoading = 'fulfilled';
  state.allAssetsCount = action.payload.data.v2FindAndCountAssets.count;
};

export const getAllAssetsRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getAllAssetsTotals.rejected>
> = (state) => {
  state.allAssetsCountLoading = 'rejected';
};

///

export const getAllocatedAssetsPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getAllocatedAssetsTotals.pending>
> = (state) => {
  state.allocatedAssetsCountLoading = 'pending';
};

export const getAllocatedAssetsFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getAllocatedAssetsTotals.fulfilled>
> = (state, action) => {
  state.allocatedAssetsCountLoading = 'fulfilled';
  state.allocatedAssetsCount = action.payload.data.v2FindAndCountAssets.count;
};

export const getAllocatedAssetsRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getAllocatedAssetsTotals.rejected>
> = (state) => {
  state.allocatedAssetsCountLoading = 'rejected';
};

///

export const getInInventoryAssetsPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getInInventoryAssetsTotals.pending>
> = (state) => {
  state.inInventoryAssetsCountLoading = 'pending';
};

export const getInInventoryAssetsFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getInInventoryAssetsTotals.fulfilled>
> = (state, action) => {
  state.inInventoryAssetsCountLoading = 'fulfilled';
  state.inInventoryAssetsCount = action.payload.data.v2FindAndCountAssets.count;
};

export const getInInventoryAssetsRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getInInventoryAssetsTotals.rejected>
> = (state) => {
  state.inInventoryAssetsCountLoading = 'rejected';
};

///

export const getReadyToDeployAssetsPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getReadyToDeployAssetsTotals.pending>
> = (state) => {
  state.readyToDeployAssetsCountLoading = 'pending';
};

export const getReadyToDeployAssetsFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getReadyToDeployAssetsTotals.fulfilled>
> = (state, action) => {
  state.readyToDeployAssetsCountLoading = 'fulfilled';
  state.readyToDeployAssetsCount =
    action.payload.data.v2FindAndCountAssets.count;
};

export const getReadyToDeployAssetsRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getReadyToDeployAssetsTotals.rejected>
> = (state) => {
  state.readyToDeployAssetsCountLoading = 'rejected';
};

///

export const getNeedsAttentionAssetsPendingReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getNeedsAttentionAssetsTotals.pending>
> = (state) => {
  state.needsAttentionAssetsCountLoading = 'pending';
};

export const getNeedsAttentionAssetsFulfilledReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getNeedsAttentionAssetsTotals.fulfilled>
> = (state, action) => {
  state.needsAttentionAssetsCountLoading = 'fulfilled';
  state.needsAttentionAssetsCount =
    action.payload.data.v2FindAndCountAssets.count;
};

export const getNeedsAttentionAssetsRejectedReducer: CaseReducer<
  TotalsState,
  ReturnType<typeof getNeedsAttentionAssetsTotals.rejected>
> = (state) => {
  state.needsAttentionAssetsCountLoading = 'rejected';
};
