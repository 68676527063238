import React from 'react';
import decodeJwt, { JwtPayload } from 'jwt-decode';
import { UserPermissions } from 'global-constants';
import { selectAuthToken } from '../../store/slices/global/selectors';
import { useSelector } from 'react-redux';

interface AccessTokenInterface extends JwtPayload {
  permissions: string[];
}

const useGetUserPermissions = (): UserPermissions[] => {
  const [userPermissions, setUserPermissions] = React.useState<
    UserPermissions[]
  >([UserPermissions.NOT__LOADED]);

  const authToken = useSelector(selectAuthToken);

  React.useEffect(() => {
    if (authToken) {
      try {
        const decodedAccessToken = decodeJwt<AccessTokenInterface>(authToken);
        const decodedUserPermissions =
          decodedAccessToken.permissions as UserPermissions[];
        setUserPermissions(decodedUserPermissions);
      } catch (e) {
        console.error(e);
      }
    }
  }, [authToken]);

  return userPermissions;
};

export default useGetUserPermissions;
