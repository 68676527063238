import { SxProps } from '@mui/material';
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'global-styles';

export const MainContainerDivSx: SxProps = {
  margin: 'auto',
  mt: HEADER_HEIGHT,
  position: 'relative',
  '@media (max-width: 1199px)': {
    left: '0',
  },
  '@media (min-width: 1200px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    width: '80%',
  },
  '@media (min-width: 1536px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    maxWidth: '100%',
    width: '85%',
  },
};

export const ContentDivSx: SxProps = {
  marginTop: '1rem',
  padding: '24px 72px',
  gap: '16px',
  borderRadius: '10px',
  border: '1px solid #E0E0E0',
};

export const TitleDividerSx: SxProps = {
  width: '100%',
  height: '1px',
  borderTop: '1px solid rgba(19, 20, 20, 0.12)',
  boxShadow: 'none',
};

export const EmployeeInfoStackSx: SxProps = {
  gap: '4px',
  position: 'relative',
  width: '100%',
};

export const EmployeeInfoGridSx: SxProps = {
  alignSelf: 'stretch',
  backgroundColor: 'colors.grayFA',
  borderRadius: '10px',
  padding: '12px',
  width: '100%',
  rowGap: '12px',
};
