import * as MaterialIcons from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { OrderStatus } from 'global-constants';
import BackBoxIcon from 'components/Icons/order_details/order_status_history/BackBoxIcon';
import SmoothRecycleIcon from 'components/Icons/order_details/order_status_history/SmoothRecycleIcon';

const ACTIVATION_LOCKED = 'ACTIVATION_LOCKED';

export const ORDER_STATUS_ICON_MAP: Record<string, typeof SvgIcon> = {
  [OrderStatus.COMPLETE]: MaterialIcons.CheckCircleOutlineOutlined,
  [OrderStatus.SHIPPED]: MaterialIcons.LocalShippingOutlined,
  [OrderStatus.EXPIRED_PENDING_RECIPIENT_INFORMATION]:
    MaterialIcons.ErrorOutlineOutlined,
  [OrderStatus.EXPIRED_PENDING_RETURN]: MaterialIcons.ErrorOutlineOutlined,
  [OrderStatus.CREATED]: MaterialIcons.ContentPasteOutlined,
  [OrderStatus.EXCEPTION]: MaterialIcons.ErrorOutlineOutlined,
  [OrderStatus.IN_FULFILLMENT]: MaterialIcons.AutoFixHighOutlined,
  [OrderStatus.IN_RETURN]: BackBoxIcon,
  [OrderStatus.PENDING_APPROVAL]: MaterialIcons.AccessTimeOutlined,
  [OrderStatus.PENDING_RETURN]: BackBoxIcon,
  [OrderStatus.PROCESSING_DEVICE]: MaterialIcons.SettingsOutlined,
  [OrderStatus.PENDING_RECIPIENT_INFORMATION]: MaterialIcons.AccessTimeOutlined,
  [OrderStatus.PENDING_REPAIR]: MaterialIcons.BuildOutlined,
  [OrderStatus.PENDING_RECYCLE]: SmoothRecycleIcon,
  [OrderStatus.PENDING_CERTIFICATE]: MaterialIcons.AccessTimeOutlined,
  [OrderStatus.QUOTE_IN_PROGRESS]: MaterialIcons.ComputerOutlined,
  [OrderStatus.PENDING_QUOTE_APPROVAL]: MaterialIcons.AccessTimeOutlined,
  CANCELED: MaterialIcons.Close,
  CANCELLED: MaterialIcons.Close,
  [ACTIVATION_LOCKED]: MaterialIcons.LockOutlined,
};
