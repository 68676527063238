import { Order } from 'types';

export const getShippingName = (
  orderPartial: Pick<Order, 'shippingTypeId' | 'rush'>,
  shippingTypeLookup: Record<string, { name: string; id: string }>
): string => {
  let shippingTypeName = orderPartial?.rush ? '2 Day' : 'Standard';

  if (
    orderPartial?.shippingTypeId &&
    shippingTypeLookup[orderPartial.shippingTypeId]
  ) {
    shippingTypeName = shippingTypeLookup[orderPartial.shippingTypeId].name;
  }

  return shippingTypeName;
};
