/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReviewSearchFilterProps } from './types';
import {
  ReviewNameSearchSx,
  ReviewSearchGlassSx,
  ReviewSearchClearSx,
  ReviewSearchButtonSx,
} from './styles';
import Spacer from 'components/Spacer';
import { DELAY_IN_MILLISECONDS } from './constants';
import { executeNowThenWait } from 'services/misc';

const SearchNameTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.colors.strongBlue,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.colors.strongBlue,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.colors.blackPearl,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.colors.strongBlue,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.colors.strongBlue,
    },
  },
}));

const ReviewSearchFilter = (props: ReviewSearchFilterProps) => {
  const [searchInactive, setSearchInactive] = React.useState<boolean>(true);
  const [searchValue, setSearchValue] = React.useState<string | undefined>();

  const isClearButtonActive = (): boolean => searchInactive && !!searchValue;

  const startLoading = () => props.setLoading(true);
  const stopLoading = () => props.setLoading(false);

  const loadThenExecute = (delayCallback: Function) =>
    executeNowThenWait(startLoading, delayCallback, DELAY_IN_MILLISECONDS);

  const clearSearch = () => {
    stopLoading();
    setSearchValue('');
    setSearchInactive(true);
    props.clearSearch();
  };

  const handleClearSearch = (_: any) => {
    loadThenExecute(clearSearch);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validSearch = !!event.target.value;

    if (validSearch) {
      setSearchValue(event.target.value);
      setSearchInactive(() => !event.target.value);
    }

    if (!validSearch) {
      handleClearSearch(event);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submitSearch = () => {
      props.setSearchTerm(searchValue);
      setSearchInactive(!!searchValue);
      stopLoading();
    };

    loadThenExecute(submitSearch);
  };

  return (
    <>
      <Spacer height="10px" />
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Stack direction="column" spacing={0} sx={{ position: 'relative' }}>
          <SearchIcon sx={ReviewSearchGlassSx} type="submit" />
          {!!isClearButtonActive() && (
            <IconButton sx={ReviewSearchClearSx} onClick={handleClearSearch}>
              <ClearIcon />
            </IconButton>
          )}
          <SearchNameTextField
            id="review-step-name-search"
            label="Search by name"
            sx={ReviewNameSearchSx}
            onChange={handleSearchChange}
            value={searchValue}
          />
        </Stack>
        <Spacer height="10px" />
        <Button
          id="review-search-name-button"
          variant="contained"
          sx={ReviewSearchButtonSx}
          disabled={searchInactive}
          type="submit"
        >
          <Typography>SEARCH</Typography>
        </Button>
      </form>
    </>
  );
};

export default ReviewSearchFilter;
