import React from 'react';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';

import TotalsCardLabel from '../TotalsCardLabel';
import { useHandleGoToLink } from './hooks';
import { TotalsCardProps } from './types';
import * as styles from './styles';

const TotalsCard = (props: TotalsCardProps) => {
  const { items, title, total, viewAllLink, red, yellow, loading } = props;
  const handleGoToLink = useHandleGoToLink();

  return (
    <Stack sx={styles.TotalsCardContainerSx}>
      {loading ? (
        <Stack sx={styles.LoadingOverlaySx}>
          <CircularProgress sx={styles.LoadingOverlaySpinnerSx} />
        </Stack>
      ) : null}

      <Stack sx={styles.HeaderSx}>
        <Stack sx={styles.TitleSx}>{title}</Stack>
        <Stack sx={styles.ViewAllSx}>
          <div onClick={handleGoToLink(viewAllLink)}>View all</div>
          <ArrowForwardIcon />
        </Stack>
      </Stack>
      <Stack sx={styles.ContentSx}>
        <Stack sx={styles.TotalsSection}>
          <Stack sx={styles.TotalsLeft}>
            <Stack sx={styles.TotalsTop}>Total</Stack>
            <Stack sx={styles.TotalsBottom}>{total}</Stack>
          </Stack>
          <Stack sx={styles.TotalsRight}>
            {items.map((item, index) => (
              <Stack
                key={item.name}
                sx={styles.ItemContainer(
                  index === 0 || index === items.length - 1
                )}
                onClick={handleGoToLink(item.link)}
              >
                <Stack sx={styles.ItemLeft}>{item.name}</Stack>
                <Stack sx={styles.ItemRight}>{item.total}</Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={styles.FooterSection}>
        <TotalsCardLabel info={red} type="red" />
        <TotalsCardLabel info={yellow} type="yellow" />
      </Stack>
    </Stack>
  );
};

export default TotalsCard;
