import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const ToggleMgmtButtonGroupSx: SxProps = {
  borderRadius: '20px',
  height: '40px',
  '& > .MuiButtonBase-root': {
    color: theme.palette.colors.black,
    fontWeight: 600,

    '&.Mui-selected': {
      backgroundColor: theme.palette.colors.black,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.colors.black,
      },
    },
  },

  '& > .MuiButtonBase-root:first-child': {
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
  },
  '& > .MuiButtonBase-root:last-child': {
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
  },
};
