import React from 'react';
import { ApolloError, FetchResult } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { modalSlice, notificationSlice } from 'store/slices';
import { CREATE_DETAILED_COLLABORATOR } from 'pages/Retrieval/components/EmployeeStep/queries';
import {
  AddEmployeeFormModalData,
  UseAddEmployeeModalState,
} from 'pages/Retrieval/add_employee/types';
import { EMPTY_ADD_EMPLOYEE_FORM_DATA } from 'pages/Retrieval/add_employee/constants';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';

export const useGetCloseAddEmployeeModal = () => {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch(modalSlice.actions.reset());
  }, [dispatch]);
};

export const useGetAddEmployeeForm = (
  startingData: AddEmployeeFormModalData
) => {
  return React.useCallback(() => {
    return useForm<AddEmployeeFormModalData>({
      values: { ...startingData },
    });
  }, [startingData]);
};

export const useSetEmployeeAddedNotice = () => {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: 'Employee has been added to your organization.',
      })
    );
  }, []);
};

export const useGetConstructAddEmployeePayload = () => {
  return React.useCallback(
    (
      formData: AddEmployeeFormModalData,
      organizationId: string
    ): Record<string, any> => {
      const {
        firstName,
        lastName,
        workEmail,
        personalEmail,
        phoneNumber,
        groupTeams,
        hireDate,
        startDate,
      } = formData;
      const group = groupTeams ? groupTeams.split(',') : [];

      return {
        firstName,
        lastName,
        email: workEmail || personalEmail,
        group,
        organizationId,
        ...(workEmail && { workEmail }),
        ...(personalEmail && { personalEmail }),
        ...(phoneNumber && { phoneNumber }),
        ...(hireDate && { hireDate }),
        ...(startDate && { startDate }),
      };
    },
    []
  );
};

export const useGetOnSubmit = (
  mutate: (options?: any) => Promise<FetchResult>,
  resetForm: (values?: any) => void,
  refetchEmployees: () => Promise<void>
) => {
  const dispatch = useDispatch();
  const constructAddEmployeePayload = useGetConstructAddEmployeePayload();

  return React.useCallback(
    (hasRequiredFields: boolean, orgId: string) =>
      async (formData: AddEmployeeFormModalData) => {
        if (!hasRequiredFields) return;
        const variables = constructAddEmployeePayload(formData, orgId);

        mutate({
          variables,
          onCompleted: async () => {
            await refetchEmployees()
              .then(() => {
                dispatch(
                  notificationSlice.actions.setNotice({
                    showNotice: true,
                    noticeContent:
                      'Employee has been added to your organization.',
                  })
                );
              })
              .then(() => {
                resetForm();
              })
              .then(() => {
                dispatch(modalSlice.actions.reset());
              });
          },
          onError: (errorPassed: ApolloError) => {
            const { graphQLErrors = [{ message: '' }] } = errorPassed;
            let detailedError = null;
            if (graphQLErrors[0]?.message.includes('email must be unique')) {
              detailedError =
                'Update error. Email(s) used must be unique to each employee.';
            } else if (
              graphQLErrors[0]?.message.includes('must be a valid phone number')
            ) {
              detailedError = 'Update error. Invalid phone number provided.';
            }

            dispatch(
              notificationSlice.actions.setNotice({
                showNotice: true,
                noticeContent:
                  detailedError ||
                  NotificationMessages.GENERIC_SOMETHING_WENT_WRONG,
              })
            );
          },
        });
      },
    [mutate, resetForm, dispatch, refetchEmployees, constructAddEmployeePayload]
  );
};

export const useAddEmployeeModalState = (
  refetchEmployees: () => Promise<void>
): UseAddEmployeeModalState => {
  const addEmployeeForm = useGetAddEmployeeForm(EMPTY_ADD_EMPLOYEE_FORM_DATA)();

  const [mutate, { loading, data: newCollaboratorData }] = useSafeMutation(
    CREATE_DETAILED_COLLABORATOR
  );

  const closeModal = useGetCloseAddEmployeeModal();

  const closeAddEmployeeModal = () => {
    addEmployeeForm.reset();
    closeModal();
  };

  const onSubmitHOF = useGetOnSubmit(
    mutate,
    addEmployeeForm.reset,
    refetchEmployees
  );

  return {
    addEmployeeForm,
    closeAddEmployeeModal,
    loading,
    onSubmitHOF,
    newCollaboratorData,
  };
};
