import { AddEmployeeFieldName } from '../../types';
import { AddEmployeeFieldData } from 'pages/Retrieval/add_employee/forms/ControlledEmployeeInput/types';
import * as styles from './styles';

export const ADD_EMPLOYEE_FORM_MODAL_BASE_SELECTOR = 'add-employee-form-modal';
export const FIRST_NAME_BASE_SELECTOR = 'add-employee-first-name-input-field';
export const LAST_NAME_BASE_SELECTOR = 'add-employee-last-name-input-field';
export const WORK_EMAIL_BASE_SELECTOR = 'add-employee-work-email-input-field';
export const PERSONAL_EMAIL_BASE_SELECTOR =
  'add-employee-personal-email-input-field';
export const MOBILE_PHONE_BASE_SELECTOR =
  'add-employee-mobile-phone-input-field';
export const TEAMS_BASE_SELECTOR = 'add-employee-teams-input-field';
export const HIRE_DATE_BASE_SELECTOR = 'add-employee-hire-date-input-field';
export const START_DATE_BASE_SELECTOR = 'add-employee-start-date-input-field';
export const EMAIL_FIELD_VALIDATION_REGEX =
  /^\s*([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})\s*$/i;

const emailRegisterOptions = {
  pattern: {
    value: EMAIL_FIELD_VALIDATION_REGEX,
    message: 'Please enter a valid email',
  },
};

export const ADD_EMPLOYEE_INPUT_PROPS_TABLE: Record<
  AddEmployeeFieldName,
  AddEmployeeFieldData
> = {
  firstName: {
    ariaRequired: true,
    name: 'firstName',
    registerName: 'firstName',
    label: 'First Name',
    baseSelector: FIRST_NAME_BASE_SELECTOR,
    InputFieldSx: styles.EmployeeFormModalTextInputBaseSx,
    registerOptions: { required: true },
  },
  lastName: {
    ariaRequired: true,
    name: 'lastName',
    registerName: 'lastName',
    label: 'Last Name',
    baseSelector: LAST_NAME_BASE_SELECTOR,
    InputFieldSx: styles.EmployeeFormModalTextInputBaseSx,
    registerOptions: { required: true },
  },
  workEmail: {
    ariaRequired: false,
    name: 'workEmail',
    registerName: 'workEmail',
    label: 'Work Email',
    baseSelector: WORK_EMAIL_BASE_SELECTOR,
    InputFieldSx: styles.EmployeeFormModalTextInputBaseSx,
    registerOptions: emailRegisterOptions,
  },
  personalEmail: {
    ariaRequired: false,
    name: 'personalEmail',
    registerName: 'personalEmail',
    label: 'Personal Email',
    baseSelector: PERSONAL_EMAIL_BASE_SELECTOR,
    InputFieldSx: styles.EmployeeFormModalTextInputBaseSx,
    registerOptions: emailRegisterOptions,
  },
  phoneNumber: {
    ariaRequired: false,
    name: 'phoneNumber',
    registerName: 'phoneNumber',
    label: 'Phone Number',
    baseSelector: MOBILE_PHONE_BASE_SELECTOR,
    InputFieldSx: styles.EmployeeFormModalPhoneInputSx,
  },
  groupTeams: {
    ariaRequired: false,
    name: 'groupTeams',
    registerName: 'groupTeams',
    label: 'Team(s)',
    baseSelector: TEAMS_BASE_SELECTOR,
    InputFieldSx: styles.EmployeeFormModalTextInputBaseSx,
  },
  hireDate: {
    ariaRequired: false,
    name: 'hireDate',
    registerName: 'hireDate',
    label: 'Hire Date',
    baseSelector: HIRE_DATE_BASE_SELECTOR,
    InputFieldSx: styles.EmployeeFormModalTextInputBaseSx,
  },
  startDate: {
    ariaRequired: false,
    name: 'startDate',
    registerName: 'startDate',
    label: 'Start date',
    baseSelector: START_DATE_BASE_SELECTOR,
    InputFieldSx: styles.EmployeeFormModalTextInputBaseSx,
  },
};
