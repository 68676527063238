import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAssetsCSVDownloadURL,
  resetCSVDownloadState,
} from 'store/slices/assets/csv';
import {
  getAssetsCSV,
  getAssetsCSVDirect,
} from 'store/slices/assets/csv/actions';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import { AppDispatch } from 'store';

import {
  selectAssetsCSVData,
  selectAssetsCSVErrorMessage,
  selectAssetsCSVLoadingState,
  selectAssetsCSVURL,
} from 'store/slices/assets/csv/selectors';
import { notificationSlice } from 'store/slices';
import { downloadFile, createCSVFile } from 'shared/utils';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';

export const useHandleDownloadAllDataClick = () => {
  const organization = useGetUserOrganization();
  const collaborator = useGetGlobalPurchaser();
  const dispatch = useDispatch<AppDispatch>();
  const { isFlagOn: getIsCSVWithDirectDownload } = useFeatureFlagService(
    FeatureFlagNames.RELEASE_CSV_DOWNLOAD_LINK,
    {
      debugFlags: true,
    }
  );
  const isCSVWithDirectDownload = getIsCSVWithDirectDownload();
  return React.useCallback(() => {
    if (organization && collaborator) {
      if (isCSVWithDirectDownload) {
        dispatch(
          getAssetsCSVDirect({
            userId: collaborator.id,
            orgId: organization.id,
          })
        );
        return;
      }
      dispatch(
        getAssetsCSV({
          userId: collaborator.id,
          orgId: organization.id,
        })
      );
    }
  }, [organization, collaborator]);
};

export const useHandleCSVDownloadError = () => {
  const loadingState = useSelector(selectAssetsCSVLoadingState);
  const errorMessage = useSelector(selectAssetsCSVErrorMessage);
  const dispatch = useDispatch();
  return React.useEffect(() => {
    if (loadingState === 'rejected') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: errorMessage,
        })
      );
    }
  }, [loadingState, errorMessage]);
};

export const useFileDownload = () => {
  const { isFlagOn: getIsCSVWithDirectDownload } = useFeatureFlagService(
    FeatureFlagNames.RELEASE_CSV_DOWNLOAD_LINK,
    {
      debugFlags: true,
    }
  );
  const url = useSelector(selectAssetsCSVURL);
  const data = useSelector(selectAssetsCSVData);
  const dispatch = useDispatch();
  return React.useEffect(() => {
    const isCSVWithDirectDownload = getIsCSVWithDirectDownload();
    if (data && isCSVWithDirectDownload) {
      createCSVFile(data, 'all-assets', 'csv');
    } else if (url && !isCSVWithDirectDownload) {
      downloadFile(url, 'all-assets', 'csv');
      dispatch(setAssetsCSVDownloadURL(''));
    }
    return () => {
      dispatch(resetCSVDownloadState());
    };
  }, [data, url]);
};
