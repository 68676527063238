import { InfoType } from './types';
import { CSSProperties } from 'react';

export const LabelSx = (type: InfoType) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginBottom: '15px',
  backgroundColor:
    type === 'red' ? 'rgba(242, 112, 88, 0.12)' : 'rgba(249, 197, 77, 0.12)',
  borderRadius: '10px',
  height: '40px',
  ':hover': {
    backgroundColor:
      type === 'red' ? 'rgba(242, 112, 88, 0.3)' : 'rgba(249, 197, 77, 0.3)',
    cursor: 'pointer',
  },
});

export const LabelIconSx = (type: InfoType) => ({
  display: 'flex',
  color: type === 'red' ? 'rgba(242, 112, 88, 1)' : 'rgba(249, 197, 77, 1)',
});

export const LabelLeftSx = (type: InfoType) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '70%',
  alignItems: 'center',
});

export const LabelRightSx = (type: InfoType) => ({
  display: 'flex',
  width: '30%',
  alignItems: 'flex-end',
  justifyContent: 'center',
});

export const LabelCountSx = (type: InfoType) => ({
  fontWeight: '500',
  fontSize: '24px',
  color: 'black',
});

export const LabelCopySx = (type: InfoType) => ({
  fontWeight: '600',
  color: 'rgba(96, 96, 93, 1)',
});

export const LinkSx: CSSProperties = {
  textDecoration: 'none',
  color: 'rgba(96, 96, 93, 1)',
};

export const LabelTooltipIconSx = {
  color: 'rgba(96, 96, 93, 1)',
};
