import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/system/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import {
  InternalActionsStackSx,
  InternalActionsContainerSx,
  InternalActionsTitleSx,
  InternalActionsCarrierSelectSx,
  InternalActionsCarrierSelectionStack,
} from './styles';
import { CarrierServicesOptions, UserPermissions } from 'global-constants';
import { CheckoutInputDto } from 'services/openapi/apiSchemas';
import { InternalActionsProps } from './types';
import { useProductCategoryControllerSearch } from 'services/openapi/apiComponents';
import { RETRIEVAL } from '../../constants';
import { isThereRetrievalProductInCart } from './utils';
import { styled } from '@mui/system';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.colors.woodSmoke,
}));

const InternalActions = (props: InternalActionsProps) => {
  const theme = useTheme();
  const { data: allProductCategories } = useProductCategoryControllerSearch({});

  const retrievalCategory = allProductCategories?.items.find(
    (category) => category.name.toLowerCase() === RETRIEVAL
  );

  const anyRetrievalProductsInCart = isThereRetrievalProductInCart(
    props.cart,
    retrievalCategory
  );

  const showUseRecipientAvailableAddressField =
    props.userPermissions.includes(
      UserPermissions.ORDER__RECIPIENT__USE_AVAILABLE_ADDRESS
    ) && anyRetrievalProductsInCart;

  const showCarrierSelectionDropdowns =
    props.userPermissions.includes(UserPermissions.SHIPMENT__CARRIER__SELECT) &&
    anyRetrievalProductsInCart;

  const showManageRecipientFormNotificationField =
    props.userPermissions.includes(
      UserPermissions.NOTIFICATION__RECIPIENT_FORM__MANAGE
    );

  const handleInboundCarrierServiceChange = (e: SelectChangeEvent) => {
    props.setInboundCarrierService(
      e.target.value as CheckoutInputDto['inboundCarrierService']
    );
  };

  const handleOutboundCarrierServiceChange = (e: SelectChangeEvent) => {
    props.setOutboundCarrierService(
      e.target.value as CheckoutInputDto['outboundCarrierService']
    );
  };

  const handleSendRecipientAddressEmailChange = () => {
    props.setSendRecipientAddressForm(!props.sendRecipientAddressForm);
  };

  const handleUseRecipientAvailableShippingAddressChange = () => {
    props.setUseRecipientsAvailableAddress(
      !props.useRecipientsAvailableAddress
    );
  };

  return (
    <Stack sx={InternalActionsStackSx}>
      <Box>
        <Typography sx={InternalActionsTitleSx}>Internal Actions</Typography>
      </Box>
      <Container sx={InternalActionsContainerSx}>
        {showManageRecipientFormNotificationField ? (
          <FormGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  disableRipple={true}
                  checked={props.sendRecipientAddressForm}
                  onChange={handleSendRecipientAddressEmailChange}
                  style={{
                    color: theme.palette.colors.woodSmoke,
                  }}
                />
              }
              label="Send recipient address collection email"
            />
          </FormGroup>
        ) : null}
        {showUseRecipientAvailableAddressField ? (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple={true}
                  checked={props.useRecipientsAvailableAddress}
                  onChange={handleUseRecipientAvailableShippingAddressChange}
                />
              }
              label="Use recipients shipping address(es) [if available]"
            />
          </FormGroup>
        ) : null}
        {showCarrierSelectionDropdowns ? (
          <Stack sx={InternalActionsCarrierSelectionStack}>
            <Stack width="50%">
              <Typography variant="body2" color={theme.palette.colors.grayWN}>
                Outbound carrier
              </Typography>
              <Select
                variant="filled"
                id="outbound-carrier-select"
                value={props.outboundCarrierService || ''}
                onChange={handleOutboundCarrierServiceChange}
                sx={InternalActionsCarrierSelectSx}
              >
                {CarrierServicesOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack width="50%">
              <Typography variant="body2" color={theme.palette.colors.grayWN}>
                Inbound carrier
              </Typography>
              <Select
                id="inbound-carrier-select"
                variant="filled"
                value={props.inboundCarrierService || ''}
                onChange={handleInboundCarrierServiceChange}
                sx={InternalActionsCarrierSelectSx}
              >
                {CarrierServicesOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
        ) : null}
      </Container>
      <Divider />
    </Stack>
  );
};

export default InternalActions;
