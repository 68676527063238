import React from 'react';
import { CartButtonProps } from './types';
import { Badge, IconButton, Typography } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { ProcurementFlowStep } from 'pages/Procurement/hooks/useProcurementService/constants';
import { getTotalNumberOfItemsInTheCart } from './utils';

const CartButton = (props: CartButtonProps) => {
  const numberOfItemsInCart =
    props.numberOfCartItems ??
    getTotalNumberOfItemsInTheCart(
      props.workflow.cart,
      props.workflow.selectedCollaborators
    );

  return (
    <Badge
      data-testid="cart-button"
      badgeContent={
        numberOfItemsInCart > 0 ? (
          <Typography data-testid="cart-count" variant="caption">
            {numberOfItemsInCart}
          </Typography>
        ) : null
      }
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: 'colors.white',
        },
      }}
    >
      <IconButton
        size="medium"
        sx={{ backgroundColor: 'colors.jungleG' }}
        disableRipple
        onClick={() =>
          props.workflow.updateProcurementFlowStep(
            ProcurementFlowStep.ORDER_SUMMARY
          )
        }
      >
        <ShoppingCartOutlinedIcon color="primary" />
      </IconButton>
    </Badge>
  );
};

export default CartButton;
