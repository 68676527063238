import { RootState } from 'store';
import { FilterStateKey, FilterEntry, DateTimeMode } from 'store/shared/types';

export const selectPopoverOpen = (key: FilterStateKey) => (state: RootState) =>
  state[key].filter.popover.open;

export const selectFilterEntries =
  (key: FilterStateKey) => (state: RootState) =>
    state[key].filter.entries;

export const selectStatusFilterEntries =
  (key: FilterStateKey) => (state: RootState) =>
    key === 'orders'
      ? state[key].filter.entries['order-status']
      : state[key].filter.entries.status;

export const selectAllChosenEntries =
  (stateKey: FilterStateKey) =>
  (state: RootState): FilterEntry[] =>
    Object.keys(state[stateKey].filter.entries)
      .flatMap((entryKey) => state[stateKey].filter.entries[entryKey].options)
      .filter((entry) => entry.selected)
      .sort((a, b) => a.selectDate - b.selectDate);

export const selectExpandedFilter =
  (key: FilterStateKey) => (state: RootState) =>
    state[key].filter.expandedFilter;

export const selectIsFiltered = (key: FilterStateKey) => (state: RootState) => {
  let isFiltered = false;

  Object.values(state[key].filter.entries).forEach(({ options }) => {
    if (options.some((option: FilterEntry) => option.selected)) {
      isFiltered = true;
    }
  });
  return isFiltered;
};

export const selectAvailableEntries =
  (stateKey: FilterStateKey, inputKey: string) => (state: RootState) =>
    state[stateKey].filter.entries[inputKey].options.filter(
      (option: FilterEntry) => option.available
    );

export const selectChosenEntries =
  (stateKey: FilterStateKey, key: string) => (state: RootState) =>
    state[stateKey].filter.entries[key].options.filter(
      (option: FilterEntry) => option.selected
    );

export const selectSearchInputByKey =
  (stateKey: FilterStateKey, key: string) => (state: RootState) => {
    return state[stateKey].filter.entries[key].searchInput;
  };

export const selectComplexFilterEntryDTO =
  (stateKey: FilterStateKey) => (state: RootState) =>
    state[stateKey].filter.complexSearchEntriesDTO;

export const selectDateFilterMode =
  (stateKey: FilterStateKey, key: string) =>
  (state: RootState): DateTimeMode => {
    const entry = state[stateKey].filter.entries[key];

    if ('dateMode' in entry) {
      return entry.dateMode;
    }
    return '';
  };

export const selectFilterOpen =
  (stateKey: FilterStateKey, key: string) => (state: RootState) =>
    state[stateKey].filter.expandedFilter === key;

export const selectDateRange =
  (stateKey: FilterStateKey, key: string) => (state: RootState) => {
    const entry = state[stateKey].filter.entries[key];

    if ('range' in entry) {
      return entry.range;
    }
    return undefined;
  };

export const selectPickedDate =
  (stateKey: FilterStateKey, key: string, dateDirection: 'before' | 'after') =>
  (state: RootState) => {
    const entry = state[stateKey].filter.entries[key];

    if ('beforeDate' in entry && 'afterDate' in entry) {
      return dateDirection === 'before' ? entry.beforeDate : entry.afterDate;
    }
    return undefined;
  };

export const selectIsFilterDisabled =
  (stateKey: FilterStateKey, key: string) => (state: RootState) =>
    state[stateKey].filter.entries[key]?.disabled ?? false;
