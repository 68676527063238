import { GridPaginationModel } from '@mui/x-data-grid-pro';

/* eslint-disable @typescript-eslint/ban-ts-comment */
export enum UserPermissions {
  ORGANIZATION__TOGGLE = 'organization.toggle',
  PROCUREMENT__ON_BEHALF = 'procurement.onbehalf',
  NOTIFICATION__RECIPIENT_FORM__MANAGE = 'notification.recipient_form.manage',
  ORDER__RECIPIENT__USE_AVAILABLE_ADDRESS = 'order.recipient.use_available_address',
  SHIPMENT__CARRIER__SELECT = 'shipment.carrier.select',
  BILLING__VIEW = 'billing.view',
  PRODUCT__READONLY_CATALOG__VIEW = 'product.readonly.catalog.view',
  ORDER__MANAGEMENT__VIEW = 'order.management.view',
  ASSET__MANAGEMENT__VIEW = 'asset.management.view',
  ORDER__DETAILS__VIEW = 'order.details.view',
  HOME__PAGE__Q42023__VIEW = 'homepage.q4.2023.view',
  BUY__AND__HOLD = 'buy_and_hold.view',
  EXPIRED__ORDERS__Q1_2024__VIEW = 'expired.orders.q1.2024.view',
  READ_COLLABORATORS = 'user.read',
  WRITE_COLLABORATORS = 'user.write',

  // Directly below is a temporary permission used to allows users with it to test site during scheduled maintenance
  MAINTENANCE_BYPASS_ACUMATICA = 'maintenance.bypass.acumatica',

  //This will be useful when we want to wait for the permissions to load before executing any logic
  //Most times, we may not care if permissions do not arrive immediately because React will re-render
  //once the permissions are loaded. This `permission` purposely does not exist in Auth0.
  //We may remove this once we either integrate a with feature flagging provider or when we deploy
  //the retrieval rework to real users. Whichever comes first.
  NOT__LOADED = 'not.loaded',
}

export const UNITED_STATES = 'United States';

export const StateOptions = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export enum StateEnum {
  'ALABAMA' = 'AL',
  'ALASKA' = 'AK',
  'ARIZONA' = 'AZ',
  'ARKANSAS' = 'AR',
  'CALIFORNIA' = 'CA',
  'COLORADO' = 'CO',
  'CONNECTICUT' = 'CT',
  'DELAWARE' = 'DE',
  'DISTRICT_OF_COLUMBIA' = 'DC',
  'FLORIDA' = 'FL',
  'GEORGIA' = 'GA',
  'HAWAII' = 'HI',
  'IDAHO' = 'ID',
  'ILLINOIS' = 'IL',
  'INDIANA' = 'IN',
  'IOWA' = 'IA',
  'KANSAS' = 'KS',
  'KENTUCKY' = 'KY',
  'LOUISIANA' = 'LA',
  'MAINE' = 'ME',
  'MARYLAND' = 'MD',
  'MASSACHUSETTS' = 'MA',
  'MICHIGAN' = 'MI',
  'MINNESOTA' = 'MN',
  'MISSISSIPPI' = 'MS',
  'MISSOURI' = 'MO',
  'MONTANA' = 'MT',
  'NEBRASKA' = 'NE',
  'NEVADA' = 'NV',
  'NEW_HAMPSHIRE' = 'NH',
  'NEW_JERSEY' = 'NJ',
  'NEW_MEXICO' = 'NM',
  'NEW_YORK' = 'NY',
  'NORTH_CAROLINA' = 'NC',
  'NORTH_DAKOTA' = 'ND',
  'OHIO' = 'OH',
  'OKLAHOMA' = 'OK',
  'OREGON' = 'OR',
  'PENNSYLVANIA' = 'PA',
  'RHODE_ISLAND' = 'RI',
  'SOUTH_CAROLINA' = 'SC',
  'SOUTH_DAKOTA' = 'SD',
  'TENNESSEE' = 'TN',
  'TEXAS' = 'TX',
  'UTAH' = 'UT',
  'VERMONT' = 'VT',
  'VIRGINIA' = 'VA',
  'WASHINGTON' = 'WA',
  'WEST_VIRGINIA' = 'WV',
  'WISCONSIN' = 'WI',
  'WYOMING' = 'WY',
}

export const US_STATE_MATCHER = Object.entries(StateEnum).reduce(
  (acc, [fullState, shortState]) => {
    acc[fullState] = shortState;
    acc[shortState] = fullState;
    return acc;
  },
  {} as Record<string, string>
);

export const CountryOptions = [
  'United States',
  'Afghanistan',
  'Aland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua And Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire Sint Eustatius Saba',
  'Bosnia And Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos Keeling Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo Democratic Republic',
  'Cook Islands',
  'Costa Rica',
  'Cote D Ivoire',
  'Croatia',
  'Cuba',
  'Cura\u00E7ao',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Heard Island Mcdonald Islands',
  'Holy See Vatican City State',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle Of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea',
  'Korea Democratic Peoples Republic',
  'Kuwait',
  'Kyrgyzstan',
  'Lao Peoples Democratic Republic',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Barthelemy',
  'Saint Helena',
  'Saint Kitts And Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre And Miquelon',
  'Saint Vincent And Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome And Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia And Sandwich Isl',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard And Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad And Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks And Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands British',
  'Virgin Islands U S',
  'Wallis And Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export enum CountryEnum {
  AFGHANISTAN = 'Afghanistan',
  ALAND_ISLANDS = 'Aland Islands',
  ALBANIA = 'Albania',
  ALGERIA = 'Algeria',
  AMERICAN_SAMOA = 'American Samoa',
  ANDORRA = 'Andorra',
  ANGOLA = 'Angola',
  ANGUILLA = 'Anguilla',
  ANTARCTICA = 'Antarctica',
  ANTIGUA_AND_BARBUDA = 'Antigua And Barbuda',
  ARGENTINA = 'Argentina',
  ARMENIA = 'Armenia',
  ARUBA = 'Aruba',
  AUSTRALIA = 'Australia',
  AUSTRIA = 'Austria',
  AZERBAIJAN = 'Azerbaijan',
  BAHAMAS = 'Bahamas',
  BAHRAIN = 'Bahrain',
  BANGLADESH = 'Bangladesh',
  BARBADOS = 'Barbados',
  BELARUS = 'Belarus',
  BELGIUM = 'Belgium',
  BELIZE = 'Belize',
  BENIN = 'Benin',
  BERMUDA = 'Bermuda',
  BHUTAN = 'Bhutan',
  BOLIVIA = 'Bolivia',
  BONAIRE_SINT_EUSTATIUS_SABA = 'Bonaire Sint Eustatius Saba',
  BOSNIA_AND_HERZEGOVINA = 'Bosnia And Herzegovina',
  BOTSWANA = 'Botswana',
  BOUVET_ISLAND = 'Bouvet Island',
  BRAZIL = 'Brazil',
  BRITISH_INDIAN_OCEAN_TERRITORY = 'British Indian Ocean Territory',
  BRUNEI_DARUSSALAM = 'Brunei Darussalam',
  BULGARIA = 'Bulgaria',
  BURKINA_FASO = 'Burkina Faso',
  BURUNDI = 'Burundi',
  CAMBODIA = 'Cambodia',
  CAMEROON = 'Cameroon',
  CANADA = 'Canada',
  CAPE_VERDE = 'Cape Verde',
  CAYMAN_ISLANDS = 'Cayman Islands',
  CENTRAL_AFRICAN_REPUBLIC = 'Central African Republic',
  CHAD = 'Chad',
  CHILE = 'Chile',
  CHINA = 'China',
  CHRISTMAS_ISLAND = 'Christmas Island',
  COCOS_KEELING_ISLANDS = 'Cocos Keeling Islands',
  COLOMBIA = 'Colombia',
  COMOROS = 'Comoros',
  CONGO = 'Congo',
  CONGO_DEMOCRATIC_REPUBLIC = 'Congo Democratic Republic',
  COOK_ISLANDS = 'Cook Islands',
  COSTA_RICA = 'Costa Rica',
  COTE_D_IVOIRE = 'Cote D Ivoire',
  CROATIA = 'Croatia',
  CUBA = 'Cuba',
  // CURAÇAO = 'Curaçao',
  CYPRUS = 'Cyprus',
  CZECH_REPUBLIC = 'Czech Republic',
  DENMARK = 'Denmark',
  DJIBOUTI = 'Djibouti',
  DOMINICA = 'Dominica',
  DOMINICAN_REPUBLIC = 'Dominican Republic',
  ECUADOR = 'Ecuador',
  EGYPT = 'Egypt',
  EL_SALVADOR = 'El Salvador',
  EQUATORIAL_GUINEA = 'Equatorial Guinea',
  ERITREA = 'Eritrea',
  ESTONIA = 'Estonia',
  ESWATINI = 'Eswatini',
  ETHIOPIA = 'Ethiopia',
  FALKLAND_ISLANDS = 'Falkland Islands',
  FAROE_ISLANDS = 'Faroe Islands',
  FIJI = 'Fiji',
  FINLAND = 'Finland',
  FRANCE = 'France',
  FRENCH_GUIANA = 'French Guiana',
  FRENCH_POLYNESIA = 'French Polynesia',
  FRENCH_SOUTHERN_TERRITORIES = 'French Southern Territories',
  GABON = 'Gabon',
  GAMBIA = 'Gambia',
  GEORGIA = 'Georgia',
  GERMANY = 'Germany',
  GHANA = 'Ghana',
  GIBRALTAR = 'Gibraltar',
  GREECE = 'Greece',
  GREENLAND = 'Greenland',
  GRENADA = 'Grenada',
  GUADELOUPE = 'Guadeloupe',
  GUAM = 'Guam',
  GUATEMALA = 'Guatemala',
  GUERNSEY = 'Guernsey',
  GUINEA = 'Guinea',
  GUINEA_BISSAU = 'Guinea Bissau',
  GUYANA = 'Guyana',
  HAITI = 'Haiti',
  HEARD_ISLAND_MCDONALD_ISLANDS = 'Heard Island Mcdonald Islands',
  HOLY_SEE_VATICAN_CITY_STATE = 'Holy See Vatican City State',
  HONDURAS = 'Honduras',
  HONG_KONG = 'Hong Kong',
  HUNGARY = 'Hungary',
  ICELAND = 'Iceland',
  INDIA = 'India',
  INDONESIA = 'Indonesia',
  IRAN = 'Iran',
  IRAQ = 'Iraq',
  IRELAND = 'Ireland',
  ISLE_OF_MAN = 'Isle Of Man',
  ISRAEL = 'Israel',
  ITALY = 'Italy',
  JAMAICA = 'Jamaica',
  JAPAN = 'Japan',
  JERSEY = 'Jersey',
  JORDAN = 'Jordan',
  KAZAKHSTAN = 'Kazakhstan',
  KENYA = 'Kenya',
  KIRIBATI = 'Kiribati',
  KOREA = 'Korea',
  KOREA_DEMOCRATIC_PEOPLES_REPUBLIC = 'Korea Democratic Peoples Republic',
  KUWAIT = 'Kuwait',
  KYRGYZSTAN = 'Kyrgyzstan',
  LAO_PEOPLES_DEMOCRATIC_REPUBLIC = 'Lao Peoples Democratic Republic',
  LATVIA = 'Latvia',
  LEBANON = 'Lebanon',
  LESOTHO = 'Lesotho',
  LIBERIA = 'Liberia',
  LIBYAN_ARAB_JAMAHIRIYA = 'Libyan Arab Jamahiriya',
  LIECHTENSTEIN = 'Liechtenstein',
  LITHUANIA = 'Lithuania',
  LUXEMBOURG = 'Luxembourg',
  MACAO = 'Macao',
  MACEDONIA = 'Macedonia',
  MADAGASCAR = 'Madagascar',
  MALAWI = 'Malawi',
  MALAYSIA = 'Malaysia',
  MALDIVES = 'Maldives',
  MALI = 'Mali',
  MALTA = 'Malta',
  MARSHALL_ISLANDS = 'Marshall Islands',
  MARTINIQUE = 'Martinique',
  MAURITANIA = 'Mauritania',
  MAURITIUS = 'Mauritius',
  MAYOTTE = 'Mayotte',
  MEXICO = 'Mexico',
  MICRONESIA = 'Micronesia',
  MOLDOVA = 'Moldova',
  MONACO = 'Monaco',
  MONGOLIA = 'Mongolia',
  MONTENEGRO = 'Montenegro',
  MONTSERRAT = 'Montserrat',
  MOROCCO = 'Morocco',
  MOZAMBIQUE = 'Mozambique',
  MYANMAR = 'Myanmar',
  NAMIBIA = 'Namibia',
  NAURU = 'Nauru',
  NEPAL = 'Nepal',
  NETHERLANDS = 'Netherlands',
  NEW_CALEDONIA = 'New Caledonia',
  NEW_ZEALAND = 'New Zealand',
  NICARAGUA = 'Nicaragua',
  NIGER = 'Niger',
  NIGERIA = 'Nigeria',
  NIUE = 'Niue',
  NORFOLK_ISLAND = 'Norfolk Island',
  NORTHERN_MARIANA_ISLANDS = 'Northern Mariana Islands',
  NORWAY = 'Norway',
  OMAN = 'Oman',
  PAKISTAN = 'Pakistan',
  PALAU = 'Palau',
  PALESTINIAN_TERRITORY = 'Palestinian Territory',
  PANAMA = 'Panama',
  PAPUA_NEW_GUINEA = 'Papua New Guinea',
  PARAGUAY = 'Paraguay',
  PERU = 'Peru',
  PHILIPPINES = 'Philippines',
  PITCAIRN = 'Pitcairn',
  POLAND = 'Poland',
  PORTUGAL = 'Portugal',
  PUERTO_RICO = 'Puerto Rico',
  QATAR = 'Qatar',
  REUNION = 'Reunion',
  ROMANIA = 'Romania',
  RUSSIAN_FEDERATION = 'Russian Federation',
  RWANDA = 'Rwanda',
  SAINT_BARTHELEMY = 'Saint Barthelemy',
  SAINT_HELENA = 'Saint Helena',
  SAINT_KITTS_AND_NEVIS = 'Saint Kitts And Nevis',
  SAINT_LUCIA = 'Saint Lucia',
  SAINT_MARTIN = 'Saint Martin',
  SAINT_PIERRE_AND_MIQUELON = 'Saint Pierre And Miquelon',
  SAINT_VINCENT_AND_THE_GRENADINES = 'Saint Vincent And the Grenadines',
  SAMOA = 'Samoa',
  SAN_MARINO = 'San Marino',
  SAO_TOME_AND_PRINCIPE = 'Sao Tome And Principe',
  SAUDI_ARABIA = 'Saudi Arabia',
  SENEGAL = 'Senegal',
  SERBIA = 'Serbia',
  SEYCHELLES = 'Seychelles',
  SIERRA_LEONE = 'Sierra Leone',
  SINGAPORE = 'Singapore',
  SINT_MAARTEN = 'Sint Maarten',
  SLOVAKIA = 'Slovakia',
  SLOVENIA = 'Slovenia',
  SOLOMON_ISLANDS = 'Solomon Islands',
  SOMALIA = 'Somalia',
  SOUTH_AFRICA = 'South Africa',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'South Georgia And the South Sandwich Islands',
  SOUTH_SUDAN = 'South Sudan',
  SPAIN = 'Spain',
  SRI_LANKA = 'Sri Lanka',
  SUDAN = 'Sudan',
  SURINAME = 'Suriname',
  SVALBARD_AND_JAN_MAYEN = 'Svalbard And Jan Mayen',
  SWAZILAND = 'Swaziland',
  SWEDEN = 'Sweden',
  SWITZERLAND = 'Switzerland',
  SYRIAN_ARAB_REPUBLIC = 'Syrian Arab Republic',
  TAIWAN = 'Taiwan',
  TAJIKISTAN = 'Tajikistan',
  TANZANIA = 'Tanzania',
  THAILAND = 'Thailand',
  TIMOR_LESTE = 'Timor-Leste',
  TOGO = 'Togo',
  TOKELAU = 'Tokelau',
  TONGA = 'Tonga',
  TRINIDAD_AND_TOBAGO = 'Trinidad And Tobago',
  TUNISIA = 'Tunisia',
  TURKEY = 'Turkey',
  TURKMENISTAN = 'Turkmenistan',
  TURKS_AND_CAICOS_ISLANDS = 'Turks And Caicos Islands',
  TUVALU = 'Tuvalu',
  UGANDA = 'Uganda',
  UKRAINE = 'Ukraine',
  UNITED_ARAB_EMIRATES = 'United Arab Emirates',
  UNITED_KINGDOM = 'United Kingdom',
  UNITED_STATES = 'United States',
  UNITED_STATES_OUTLYING_ISLANDS = 'United States Outlying Islands',
  URUGUAY = 'Uruguay',
  UZBEKISTAN = 'Uzbekistan',
  VANUATU = 'Vanuatu',
  VENEZUELA = 'Venezuela',
  VIET_NAM = 'Viet Nam',
  VIRGIN_ISLANDS_BRITISH = 'Virgin Islands British',
  VIRGIN_ISLANDS_US = 'Virgin Islands U S',
  WALLIS_AND_FUTUNA = 'Wallis And Futuna',
  WESTERN_SAHARA = 'Western Sahara',
  YEMEN = 'Yemen',
  ZAMBIA = 'Zambia',
  ZIMBABWE = 'Zimbabwe',
}

export const COUNTRY_MATCHER = Object.entries(CountryEnum).reduce(
  (acc, [fullState, shortState]) => {
    acc[fullState] = shortState;
    acc[shortState] = fullState;
    return acc;
  },
  {} as Record<string, string>
);

export const COUNTRY_CODE_MAPPER = {
  [CountryEnum.AFGHANISTAN]: 'AF',
  [CountryEnum.ALAND_ISLANDS]: 'AX',
  [CountryEnum.ALBANIA]: 'AL',
  [CountryEnum.ALGERIA]: 'DZ',
  [CountryEnum.AMERICAN_SAMOA]: 'AS',
  [CountryEnum.ANDORRA]: 'AD',
  [CountryEnum.ANGOLA]: 'AO',
  [CountryEnum.ANGUILLA]: 'AI',
  [CountryEnum.ANTARCTICA]: 'AQ',
  [CountryEnum.ANTIGUA_AND_BARBUDA]: 'AG',
  [CountryEnum.ARGENTINA]: 'AR',
  [CountryEnum.ARMENIA]: 'AM',
  [CountryEnum.ARUBA]: 'AW',
  [CountryEnum.AUSTRALIA]: 'AU',
  [CountryEnum.AUSTRIA]: 'AT',
  [CountryEnum.AZERBAIJAN]: 'AZ',
  [CountryEnum.BAHAMAS]: 'BS',
  [CountryEnum.BAHRAIN]: 'BH',
  [CountryEnum.BANGLADESH]: 'BD',
  [CountryEnum.BARBADOS]: 'BB',
  [CountryEnum.BELARUS]: 'BY',
  [CountryEnum.BELGIUM]: 'BE',
  [CountryEnum.BELIZE]: 'BZ',
  [CountryEnum.BENIN]: 'BJ',
  [CountryEnum.BERMUDA]: 'BM',
  [CountryEnum.BHUTAN]: 'BT',
  [CountryEnum.BOLIVIA]: 'BO',
  [CountryEnum.BONAIRE_SINT_EUSTATIUS_SABA]: 'BQ',
  [CountryEnum.BOSNIA_AND_HERZEGOVINA]: 'BA',
  [CountryEnum.BOTSWANA]: 'BW',
  [CountryEnum.BOUVET_ISLAND]: 'BV',
  [CountryEnum.BRAZIL]: 'BR',
  [CountryEnum.BRITISH_INDIAN_OCEAN_TERRITORY]: 'IO',
  [CountryEnum.BRUNEI_DARUSSALAM]: 'BN',
  [CountryEnum.BULGARIA]: 'BG',
  [CountryEnum.BURKINA_FASO]: 'BF',
  [CountryEnum.BURUNDI]: 'BI',
  [CountryEnum.CAMBODIA]: 'KH',
  [CountryEnum.CAMEROON]: 'CM',
  [CountryEnum.CANADA]: 'CA',
  [CountryEnum.CAPE_VERDE]: 'CV',
  [CountryEnum.CAYMAN_ISLANDS]: 'KY',
  [CountryEnum.CENTRAL_AFRICAN_REPUBLIC]: 'CF',
  [CountryEnum.CHAD]: 'TD',
  [CountryEnum.CHILE]: 'CL',
  [CountryEnum.CHINA]: 'CN',
  [CountryEnum.CHRISTMAS_ISLAND]: 'CX',
  [CountryEnum.COCOS_KEELING_ISLANDS]: 'CC',
  [CountryEnum.COLOMBIA]: 'CO',
  [CountryEnum.COMOROS]: 'KM',
  [CountryEnum.CONGO]: 'CG',
  [CountryEnum.CONGO_DEMOCRATIC_REPUBLIC]: 'CD',
  [CountryEnum.COOK_ISLANDS]: 'CK',
  [CountryEnum.COSTA_RICA]: 'CR',
  [CountryEnum.COTE_D_IVOIRE]: 'CI',
  [CountryEnum.CROATIA]: 'HR',
  [CountryEnum.CUBA]: 'CU',
  [CountryEnum.CYPRUS]: 'CY',
  [CountryEnum.CZECH_REPUBLIC]: 'CZ',
  [CountryEnum.DENMARK]: 'DK',
  [CountryEnum.DJIBOUTI]: 'DJ',
  [CountryEnum.DOMINICA]: 'DM',
  [CountryEnum.DOMINICAN_REPUBLIC]: 'DO',
  [CountryEnum.ECUADOR]: 'EC',
  [CountryEnum.EGYPT]: 'EG',
  [CountryEnum.EL_SALVADOR]: 'SV',
  [CountryEnum.EQUATORIAL_GUINEA]: 'GQ',
  [CountryEnum.ERITREA]: 'ER',
  [CountryEnum.ESTONIA]: 'EE',
  [CountryEnum.ESWATINI]: 'SZ',
  [CountryEnum.ETHIOPIA]: 'ET',
  [CountryEnum.FALKLAND_ISLANDS]: 'FK',
  [CountryEnum.FAROE_ISLANDS]: 'FO',
  [CountryEnum.FIJI]: 'FJ',
  [CountryEnum.FINLAND]: 'FI',
  [CountryEnum.FRANCE]: 'FR',
  [CountryEnum.FRENCH_GUIANA]: 'GF',
  [CountryEnum.FRENCH_POLYNESIA]: 'PF',
  [CountryEnum.FRENCH_SOUTHERN_TERRITORIES]: 'TF',
  [CountryEnum.GABON]: 'GA',
  [CountryEnum.GAMBIA]: 'GM',
  [CountryEnum.GEORGIA]: 'GE',
  [CountryEnum.GERMANY]: 'DE',
  [CountryEnum.GHANA]: 'GH',
  [CountryEnum.GIBRALTAR]: 'GI',
  [CountryEnum.GREECE]: 'GR',
  [CountryEnum.GREENLAND]: 'GL',
  [CountryEnum.GRENADA]: 'GD',
  [CountryEnum.GUADELOUPE]: 'GP',
  [CountryEnum.GUAM]: 'GU',
  [CountryEnum.GUATEMALA]: 'GT',
  [CountryEnum.GUERNSEY]: 'GG',
  [CountryEnum.GUINEA]: 'GN',
  [CountryEnum.GUINEA_BISSAU]: 'GW',
  [CountryEnum.GUYANA]: 'GY',
  [CountryEnum.HAITI]: 'HT',
  [CountryEnum.HEARD_ISLAND_MCDONALD_ISLANDS]: 'HM',
  [CountryEnum.HOLY_SEE_VATICAN_CITY_STATE]: 'VA',
  [CountryEnum.HONDURAS]: 'HN',
  [CountryEnum.HONG_KONG]: 'HK',
  [CountryEnum.HUNGARY]: 'HU',
  [CountryEnum.ICELAND]: 'IS',
  [CountryEnum.INDIA]: 'IN',
  [CountryEnum.INDONESIA]: 'ID',
  [CountryEnum.IRAN]: 'IR',
  [CountryEnum.IRAQ]: 'IQ',
  [CountryEnum.IRELAND]: 'IE',
  [CountryEnum.ISLE_OF_MAN]: 'IM',
  [CountryEnum.ISRAEL]: 'IL',
  [CountryEnum.ITALY]: 'IT',
  [CountryEnum.JAMAICA]: 'JM',
  [CountryEnum.JAPAN]: 'JP',
  [CountryEnum.JERSEY]: 'JE',
  [CountryEnum.JORDAN]: 'JO',
  [CountryEnum.KAZAKHSTAN]: 'KZ',
  [CountryEnum.KENYA]: 'KE',
  [CountryEnum.KIRIBATI]: 'KI',
  [CountryEnum.KOREA]: 'KR',
  [CountryEnum.KOREA_DEMOCRATIC_PEOPLES_REPUBLIC]: 'KP',
  [CountryEnum.KUWAIT]: 'KW',
  [CountryEnum.KYRGYZSTAN]: 'KG',
  [CountryEnum.LAO_PEOPLES_DEMOCRATIC_REPUBLIC]: 'LA',
  [CountryEnum.LATVIA]: 'LV',
  [CountryEnum.LEBANON]: 'LB',
  [CountryEnum.LESOTHO]: 'LS',
  [CountryEnum.LIBERIA]: 'LR',
  [CountryEnum.LIBYAN_ARAB_JAMAHIRIYA]: 'LY',
  [CountryEnum.LIECHTENSTEIN]: 'LI',
  [CountryEnum.LITHUANIA]: 'LT',
  [CountryEnum.LUXEMBOURG]: 'LU',
  [CountryEnum.MACAO]: 'MO',
  [CountryEnum.MACEDONIA]: 'MK',
  [CountryEnum.MADAGASCAR]: 'MG',
  [CountryEnum.MALAWI]: 'MW',
  [CountryEnum.MALAYSIA]: 'MY',
  [CountryEnum.MALDIVES]: 'MV',
  [CountryEnum.MALI]: 'ML',
  [CountryEnum.MALTA]: 'MT',
  [CountryEnum.MARSHALL_ISLANDS]: 'MH',
  [CountryEnum.MARTINIQUE]: 'MQ',
  [CountryEnum.MAURITANIA]: 'MR',
  [CountryEnum.MAURITIUS]: 'MU',
  [CountryEnum.MAYOTTE]: 'YT',
  [CountryEnum.MEXICO]: 'MX',
  [CountryEnum.MICRONESIA]: 'FM',
  [CountryEnum.MOLDOVA]: 'MD',
  [CountryEnum.MONACO]: 'MC',
  [CountryEnum.MONGOLIA]: 'MN',
  [CountryEnum.MONTENEGRO]: 'ME',
  [CountryEnum.MONTSERRAT]: 'MS',
  [CountryEnum.MOROCCO]: 'MA',
  [CountryEnum.MOZAMBIQUE]: 'MZ',
  [CountryEnum.MYANMAR]: 'MM',
  [CountryEnum.NAMIBIA]: 'NA',
  [CountryEnum.NAURU]: 'NR',
  [CountryEnum.NEPAL]: 'NP',
  [CountryEnum.NETHERLANDS]: 'NL',
  [CountryEnum.NEW_CALEDONIA]: 'NC',
  [CountryEnum.NEW_ZEALAND]: 'NZ',
  [CountryEnum.NICARAGUA]: 'NI',
  [CountryEnum.NIGER]: 'NE',
  [CountryEnum.NIGERIA]: 'NG',
  [CountryEnum.NIUE]: 'NU',
  [CountryEnum.NORFOLK_ISLAND]: 'NF',
  [CountryEnum.NORTHERN_MARIANA_ISLANDS]: 'MP',
  [CountryEnum.NORWAY]: 'NO',
  [CountryEnum.OMAN]: 'OM',
  [CountryEnum.PAKISTAN]: 'PK',
  [CountryEnum.PALAU]: 'PW',
  [CountryEnum.PALESTINIAN_TERRITORY]: 'PS',
  [CountryEnum.PANAMA]: 'PA',
  [CountryEnum.PAPUA_NEW_GUINEA]: 'PG',
  [CountryEnum.PARAGUAY]: 'PY',
  [CountryEnum.PERU]: 'PE',
  [CountryEnum.PHILIPPINES]: 'PH',
  [CountryEnum.PITCAIRN]: 'PN',
  [CountryEnum.POLAND]: 'PL',
  [CountryEnum.PORTUGAL]: 'PT',
  [CountryEnum.PUERTO_RICO]: 'PR',
  [CountryEnum.QATAR]: 'QA',
  [CountryEnum.REUNION]: 'RE',
  [CountryEnum.ROMANIA]: 'RO',
  [CountryEnum.RUSSIAN_FEDERATION]: 'RU',
  [CountryEnum.RWANDA]: 'RW',
  [CountryEnum.SAINT_BARTHELEMY]: 'BL',
  [CountryEnum.SAINT_HELENA]: 'SH',
  [CountryEnum.SAINT_KITTS_AND_NEVIS]: 'KN',
  [CountryEnum.SAINT_LUCIA]: 'LC',
  [CountryEnum.SAINT_MARTIN]: 'MF',
  [CountryEnum.SAINT_PIERRE_AND_MIQUELON]: 'PM',
  [CountryEnum.SAINT_VINCENT_AND_THE_GRENADINES]: 'VC',
  [CountryEnum.SAMOA]: 'WS',
  [CountryEnum.SAN_MARINO]: 'SM',
  [CountryEnum.SAO_TOME_AND_PRINCIPE]: 'ST',
  [CountryEnum.SAUDI_ARABIA]: 'SA',
  [CountryEnum.SENEGAL]: 'SN',
  [CountryEnum.SERBIA]: 'RS',
  [CountryEnum.SEYCHELLES]: 'SC',
  [CountryEnum.SIERRA_LEONE]: 'SL',
  [CountryEnum.SINGAPORE]: 'SG',
  [CountryEnum.SINT_MAARTEN]: 'SX',
  [CountryEnum.SLOVAKIA]: 'SK',
  [CountryEnum.SLOVENIA]: 'SI',
  [CountryEnum.SOLOMON_ISLANDS]: 'SB',
  [CountryEnum.SOMALIA]: 'SO',
  [CountryEnum.SOUTH_AFRICA]: 'ZA',
  [CountryEnum.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: 'GS',
  [CountryEnum.SOUTH_SUDAN]: 'SS',
  [CountryEnum.SPAIN]: 'ES',
  [CountryEnum.SRI_LANKA]: 'LK',
  [CountryEnum.SUDAN]: 'SD',
  [CountryEnum.SURINAME]: 'SR',
  [CountryEnum.SVALBARD_AND_JAN_MAYEN]: 'SJ',
  [CountryEnum.SWEDEN]: 'SE',
  [CountryEnum.SWITZERLAND]: 'CH',
  [CountryEnum.SYRIAN_ARAB_REPUBLIC]: 'SY',
  [CountryEnum.TAIWAN]: 'TW',
  [CountryEnum.TAJIKISTAN]: 'TJ',
  [CountryEnum.TANZANIA]: 'TZ',
  [CountryEnum.THAILAND]: 'TH',
  [CountryEnum.TIMOR_LESTE]: 'TL',
  [CountryEnum.TOGO]: 'TG',
  [CountryEnum.TOKELAU]: 'TK',
  [CountryEnum.TONGA]: 'TO',
  [CountryEnum.TRINIDAD_AND_TOBAGO]: 'TT',
  [CountryEnum.TUNISIA]: 'TN',
  [CountryEnum.TURKEY]: 'TR',
  [CountryEnum.TURKMENISTAN]: 'TM',
  [CountryEnum.TURKS_AND_CAICOS_ISLANDS]: 'TC',
  [CountryEnum.TUVALU]: 'TV',
  [CountryEnum.UGANDA]: 'UG',
  [CountryEnum.UKRAINE]: 'UA',
  [CountryEnum.UNITED_ARAB_EMIRATES]: 'AE',
  [CountryEnum.UNITED_KINGDOM]: 'GB',
  [CountryEnum.UNITED_STATES]: 'US',
  [CountryEnum.UNITED_STATES_OUTLYING_ISLANDS]: 'UM',
  [CountryEnum.URUGUAY]: 'UY',
  [CountryEnum.UZBEKISTAN]: 'UZ',
  [CountryEnum.VANUATU]: 'VU',
  [CountryEnum.VENEZUELA]: 'VE',
  [CountryEnum.VIET_NAM]: 'VN',
  [CountryEnum.VIRGIN_ISLANDS_BRITISH]: 'VG',
  [CountryEnum.VIRGIN_ISLANDS_US]: 'VI',
  [CountryEnum.WALLIS_AND_FUTUNA]: 'WF',
  [CountryEnum.WESTERN_SAHARA]: 'EH',
  [CountryEnum.YEMEN]: 'YE',
  [CountryEnum.ZAMBIA]: 'ZM',
  [CountryEnum.ZIMBABWE]: 'ZW',
} as Record<CountryEnum, string>;

export const RETOOL_BASE_URL =
  'https://launchanywhere.retool.com/embedded/public';

export const CarrierServicesOptions = [
  'FEDEX_2_DAY',
  'FEDEX_2_DAY_AM',
  'FEDEX_EUROPE_FIRST_INTERNATIONAL_PRIORITY',
  'FEDEX_EXPRESS_SAVER',
  'FEDEX_FIRST_FREIGHT',
  'FEDEX_FIRST_OVERNIGHT',
  'FEDEX_FREIGHT_ECONOMY',
  'FEDEX_FREIGHT_PRIORITY',
  'FEDEX_GROUND',
  'FEDEX_HOME_DELIVERY',
  'FEDEX_INTERNATIONAL_CONNECT_PLUS',
  'FEDEX_INTERNATIONAL_ECONOMY',
  'FEDEX_INTERNATIONAL_ECONOMY_FREIGHT',
  'FEDEX_INTERNATIONAL_FIRST',
  'FEDEX_INTERNATIONAL_PRIORITY',
  'FEDEX_INTERNATIONAL_PRIORITY_FREIGHT',
  'FEDEX_NEXT_DAY_FREIGHT',
  'FEDEX_PRIORITY_OVERNIGHT',
  'FEDEX_SMART_POST',
  'FEDEX_STANDARD_OVERNIGHT',
  'UPS_3_DAY_SELECT',
  'UPS_ACCESS_POINT_ECONOMY',
  'UPS_EXPEDITED',
  'UPS_EXPRESS',
  'UPS_EXPRESS_1200',
  'UPS_EXPRESS_EARLY',
  'UPS_EXPRESS_PLUS',
  'UPS_GROUND',
  'UPS_MAIL_INNOVATIONS_DOMESTIC',
  'UPS_NEXT_DAY_AIR',
  'UPS_NEXT_DAY_AIR_EARLY_AM',
  'UPS_NEXT_DAY_AIR_SAVER',
  'UPS_SAVER',
  'UPS_SECOND_DAY_AIR',
  'UPS_SECOND_DAY_AIR_AM',
  'UPS_STANDARD',
  'UPS_SUREPOST',
  'UPS_SUREPOST_BOUND_PRINTED_MATTER',
  'UPS_SUREPOST_LIGHTWEIGHT',
  'UPS_SUREPOST_MEDIA',
  'USPS_FIRST',
  'USPS_FIRST_CLASS_PACKAGE_INTERNATIONAL_SERVICE',
  'USPS_MEDIA_MAIL',
  'USPS_PARCEL_SELECT',
  'USPS_PRIORITY',
  'USPS_PRIORITY_EXPRESS',
  'USPS_PRIORITY_MAIL_EXPRESS_INTERNATIONAL',
  'USPS_PRIORITY_MAIL_INTERNATIONAL',
];

export const FINAL_DESTINATION_VARIANT_KEY = 'Final destination';

export const ALLWHERE_STORAGE_DEPOT = 'allwhere storage depot';

export enum ProductCategory {
  RETRIEVAL = 'Retrieval',
  LAPTOPS = 'Laptops',
}

export enum OrderStatus {
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  CREATED = 'CREATED',
  EXCEPTION = 'EXCEPTION',
  EXPIRED_PENDING_RECIPIENT_INFORMATION = 'EXPIRED_PENDING_RECIPIENT_INFORMATION',
  // Status below is NOT used/available in the BFF and by extension the mono-service
  EXPIRED_PENDING_RETURN = 'EXPIRED_PENDING_RETURN',
  IN_FULFILLMENT = 'IN_FULFILLMENT',
  IN_RETURN = 'IN_RETURN',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_RECIPIENT_INFORMATION = 'PENDING_RECIPIENT_INFORMATION',
  PENDING_RETURN = 'PENDING_RETURN',
  SHIPPED = 'SHIPPED',
  PROCESSING_DEVICE = 'PROCESSING_DEVICE',
  PENDING_REPAIR = 'PENDING_REPAIR',
  PENDING_RECYCLE = 'PENDING_RECYCLE',
  PENDING_CERTIFICATE = 'PENDING_CERTIFICATE',
  QUOTE_IN_PROGRESS = 'QUOTE_IN_PROGRESS',
  PENDING_QUOTE_APPROVAL = 'PENDING_QUOTE_APPROVAL',
}

export enum ClientOrderStatusEnum {
  PREPARING_ORDER = 'PREPARING_ORDER',
}

/**
 * @description client-facing Order Status used for UI only
 */
export const ClientOrderStatus = {
  [OrderStatus.PENDING_APPROVAL]: ClientOrderStatusEnum.PREPARING_ORDER,
};

export enum InternalOrderType {
  PROCUREMENT = 'PROCUREMENT',
  RETRIEVAL = 'RETRIEVAL',
  BUY_AND_HOLD = 'BUY_AND_HOLD',
  STORAGE = 'STORAGE',
  BULK_RETRIEVAL = 'BULK_RETRIEVAL',
  PROCESS_DEVICE = 'PROCESS_DEVICE',
  RESELL = 'RESELL',
  RECYCLE = 'RECYCLE',
  REPAIR = 'REPAIR',
}

export enum OrderType {
  BULK_RETRIEVAL = 'Bulk Retrieval',
  DEPLOYMENT = 'Deployment',
  DROP_RETRIEVAL = 'Drop Retrieval',
  PROCESS = 'Process',
  PROCUREMENT_TO_DEPOT = 'Procurement to Depot',
  PROCUREMENT_TO_RECIPIENT = 'Procurement to Recipient',
  RECYCLE = 'Recycle',
  REPAIR = 'Repair',
  RESELL = 'Resell',
  RETRIEVAL = 'Retrieval',
  DEVICE_UNLOCK = 'Device Unlock',
}

export enum InventoryProductTitles {
  DEVICE_FROM_INVENTORY = 'Device from inventory',
  DEVICE_PROCESS = 'Device process',
  DEVICE_REPAIR = 'Device repair',
  DEVICE_RECYCLE = 'Device recycle',
  DEVICE_RESELL = 'Device resell',
  RETRIEVAL_KIT = 'Device retrieval kit',
  PROCUREMENT = 'Device procurement',
  DEVICE_UNLOCK = 'Device unlock',
}

export enum RetrievalStepSubRoutes {
  LOCATION = 'location',
  EMPLOYEES = 'employees',
  REVIEW = 'review',
  SUBMIT = 'submit',
}
export const COLLABORATORS_PAGE_LIMIT = 10;

export const DEFAULT_NOTIFICATION_TIMEOUT_MS = 6000; // in ms

export enum SortDirection {
  ORDER_ASCENDING = 'orderAsc',
  ORDER_DESCENDING = 'orderDesc',
}

export const GRID_SORT_FIELD_TO_SERVER_TABLE: Record<string, string> = {
  asc: SortDirection.ORDER_ASCENDING,
  desc: SortDirection.ORDER_DESCENDING,
};

export const GENERIC_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const DEFAULT_PAGE_SIZE = 25;

export const EMAIL_VALIDATION =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const ALLWHERE_EMAIL = 'hello@allwhere.co';

export enum ErrorMessages {
  ENTER_VALID_EMAIL = 'Please enter a valid email',
  SERIAL_NUMBER_ALREADY_EXISTS = 'This serial number is already in the system',
}

export const DEFAULT_PAGINATION_MODEL: GridPaginationModel = {
  page: 0,
  pageSize: 25,
};

export enum AssetStatus {
  // Deprecated start
  HOLD = 'HOLD',
  PROCESSING_DEVICE = 'PROCESSING_DEVICE',
  IN_INVENTORY = 'IN_INVENTORY',
  IN_INVENTORY_DEVICE_ISSUE = 'IN_INVENTORY_DEVICE_ISSUE',
  PENDING_RECYCLE = 'PENDING_RECYCLE',
  PENDING_REPAIR = 'PENDING_REPAIR',
  PENDING_ALLOCATION = 'PENDING_ALLOCATION',
  QUOTE_IN_PROGRESS = 'QUOTE_IN_PROGRESS',
  //Deprecated end

  MONO_ACTIVATION_LOCKED = 'Activation Locked',
  MONO_ALLOCATED = 'Allocated',
  MONO_DONATED = 'Donated',
  MONO_HOLD = 'HOLD',
  MONO_IN_INVENTORY = 'In Inventory',
  MONO_IN_INVENTORY_DEVICE_ISSUE = 'In inventory - device issue',
  MONO_LOST = 'Lost',
  MONO_MDM_LOCKED = 'MDM Locked',
  MONO_PENDING_ALLOCATION = 'Pending Allocation',
  MONO_PENDING_CERTIFICATE = 'Pending Certification',
  MONO_PENDING_QUOTE_APPROVAL = 'Pending Quote Approval',
  MONO_PENDING_RECYCLE = 'Pending Recycle',
  MONO_PENDING_REPAIR = 'Pending Repair',
  MONO_PENDING_RETRIEVAL = 'Pending Retrieval',
  MONO_PENDING_SALE = 'Pending Sale',
  MONO_PENDING_WIPE = 'Pending Wipe',
  MONO_PROCESSING_DEVICE = 'Processing Device',
  MONO_QUOTE_IN_PROGRESS = 'Quote in Progress',
  RECOVERY_LOCKED = 'Recovery Locked',
  MONO_RECOVERY_LOCKED = 'Recovery Locked',
  MONO_RECYCLED = 'Recycled',
  MONO_SOLD = 'Sold',
  MONO_TRANSFERRED = 'Transferred',
  MONO_INTAKE = 'Intake',
}

export enum AssetStatusSelfDescriptive {
  ACTIVATION_LOCKED = 'Activation Locked',
  ALLOCATED = 'Allocated',
  DONATED = 'Donated',
  HOLD = 'HOLD',
  IN_INVENTORY = 'In inventory',
  IN_INVENTORY_DEVICE_ISSUE = 'In inventory - device issue',
  IN_REPAIR_APPLE = 'In Repair - Apple',
  LOST = 'Lost',
  MDM_LOCKED = 'MDM Locked',
  TRANSFERRED = 'Transferred',
  INTAKE = 'Intake',
  MONO_ALLOCATED = 'MONO_ALLOCATED',
  MONO_DONATED = 'MONO_DONATED',
  MONO_HOLD = 'MONO_HOLD',
  MONO_IN_INVENTORY = 'MONO_IN_INVENTORY',
  MONO_IN_INVENTORY_DEVICE_ISSUE = 'MONO_IN_INVENTORY_DEVICE_ISSUE',
  MONO_LOST = 'MONO_LOST',
  MONO_MDM_LOCKED = 'MONO_MDM_LOCKED',
  MONO_PENDING_ALLOCATION = 'MONO_PENDING_ALLOCATION',
  MONO_PENDING_CERTIFICATE = 'MONO_PENDING_CERTIFICATE',
  MONO_PENDING_QUOTE_APPROVAL = 'MONO_PENDING_QUOTE_APPROVAL',
  MONO_PENDING_RECYCLE = 'MONO_PENDING_RECYCLE',
  MONO_PENDING_REPAIR = 'MONO_PENDING_REPAIR',
  MONO_PENDING_RETRIEVAL = 'MONO_PENDING_RETRIEVAL',
  MONO_PENDING_SALE = 'MONO_PENDING_SALE',
  MONO_PENDING_WIPE = 'MONO_PENDING_WIPE',
  MONO_PROCESSING_DEVICE = 'MONO_PROCESSING_DEVICE',
  MONO_QUOTE_IN_PROGRESS = 'MONO_QUOTE_IN_PROGRESS',
  MONO_RECYCLED = 'MONO_RECYCLED',
  MONO_SOLD = 'MONO_SOLD',
  NEEDS_REPAIR = 'Needs Repair',
  PENDING_ALLOCATION = 'Pending allocation',
  PENDING_CERTIFICATE = 'Pending Certificate',
  PENDING_QUOTE_APPROVAL = 'Pending Quote Approval',
  PENDING_RECYCLE = 'Pending Recycle',
  PENDING_RECYCLING = 'Pending recycling',
  PENDING_REPAIR = 'Pending Repair',
  PENDING_RETRIEVAL = 'Pending retrieval',
  PENDING_SALE = 'Pending sale',
  PENDING_WIPE = 'Pending wipe',
  PHYSICAL_DAMAGE = 'Physical Damage',
  PROCESSING_DEVICE = 'Processing Device',
  QUOTE_IN_PROGRESS = 'Quote in Progress',
  RECOVERY_LOCKED = 'Recovery Locked',
  RECYCLED = 'Recycled',
  SO = 'SO',
  SOLD = 'Sold',
  MONO_ACTIVATION_LOCKED = 'MONO_ACTIVATION_LOCKED',
  MONO_RECOVERY_LOCKED = 'MONO_RECOVERY_LOCKED',
  MONO_TRANSFERRED = 'MONO_TRANSFERRED',
  MONO_INTAKE = 'MONO_INTAKE',
}

export const ALLWHERE_FULFILLMENT_SPEEDS_COPY =
  'allwhere is able to provide the following fulfillment speeds only after the address information has been collected from the recipient. Expedited orders will incur a $35 convenience fee + pass-through shipping costs.';

/**
 * @deprecated Soon to be removed enum. Remove when Premium Shipping is live.
 */
export enum ShippingType {
  STANDARD = 'standard',
  US_EXPEDITED = 'us-expedited',
}

export const CLOSE_MODAL_AFTER_SECONDS = 6000; //6 seconds

export enum Depot {
  US = 'US Depot',
  UK = 'UK Depot',
  EU = 'Spain Depot',
  COLOMBIA = 'Colombia Depot',
  MEXICO = 'Mexico Depot',
  BRAZIL = 'Brazil Depot',
  URUGUAY = 'Uruguay Depot',
  NONE = '',
}

export enum AllwhereDepot {
  ARGENTINA = 'allwhere storage Argentina',
  BRAZIL = 'allwhere storage Brazil',
  COLUMBIA = 'allwhere storage Colombia',
  CUSTOM_US = 'United States location', // Drop recipient location
  MEXICO = 'allwhere storage Mexico',
  SPAIN = 'allwhere storage Spain',
  UK = 'allwhere storage UK',
  US = 'allwhere storage US',
}

export enum AssetConditionEnum {
  S_NEW = 'S - New',
  A_LIKE_NEW = 'A - Like New',
  B_VERY_GOOD = 'B - Very Good',
  C_GOOD = 'C - Good',
  D_FAIR = 'D - Fair',
}

//TODO: Standardize this enum across the codebase; multiple places have their own flavor of this enum
export enum AssetType {
  LAPTOP = 'LAPTOP',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  CELL_PHONE = 'CELL_PHONE', //Deprecated
  MONITOR = 'MONITOR',
  PERIPHERAL = 'PERIPHERAL',
  ACCESSORY = 'ACCESSORY',
}

export const FALLBACK_LUCK_7S_DATE_STRING = '7/7/1977';

export enum Entities {
  EMPLOYEE = 'employee',
}

export enum HttpCodes {
  NOT_FOUND = 404,
}

export enum BackendOperations {
  ACUMATICA = 'acumatica',
  AIRTABLE = 'airtable',
}

//TODO: Consider splitting this file to `global-enums.ts` & `global-constants.ts`? Or something else?

export enum AssetStatusEnum {
  ACTIVATION_LOCKED = 'Activation Locked',
  ALLOCATED = 'Allocated',
  DONATED = 'Donated',
  HOLD = 'HOLD',
  IN_INVENTORY = 'In inventory',
  IN_INVENTORY_DEVICE_ISSUE = 'In inventory - device issue',
  LOST = 'Lost',
  MDM_LOCKED = 'MDM Locked',
  PENDING_ALLOCATION = 'Pending allocation',
  PENDING_CERTIFICATE = 'Pending Certificate',
  PENDING_QUOTE_APPROVAL = 'Pending Quote Approval',
  PENDING_RECYCLE = 'Pending Recycle',
  PENDING_REPAIR = 'Pending Repair',
  PENDING_RETRIEVAL = 'Pending retrieval',
  PENDING_SALE = 'Pending sale',
  PENDING_WIPE = 'Pending wipe',
  PROCESSING_DEVICE = 'Processing Device',
  QUOTE_IN_PROGRESS = 'Quote in Progress',
  RECOVERY_LOCKED = 'Recovery Locked',
  RECYCLED = 'Recycled',
  SOLD = 'Sold',
  TRANSFERRED = 'Transferred',
  INTAKE = 'Intake',
}

export enum ProductStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}

export const UNREACHABLE = 'UNREACHABLE';

export enum MouseCursorStyle {
  NOT_ALLOWED = 'not-allowed',
}

export enum NavigationRoutes {
  HOME = '/',
  SEND_EQUIPMENT = '/send-equipment',
  BUY_AND_HOLD = '/buy-and-hold',
  ORDERS = '/orders',
  ASSETS = '/assets',
  CATALOG = '/catalog',
  EMPLOYEES = '/employees',
  BILLING = '/billing',
  ORDER_MANAGEMENT = '/order-management',
  ASSET_MANAGEMENT = '/asset-management',
  REQUEST_NEW_ITEM = '/request-new-item',
  MANAGE_USERS = '/manage-users',
  ASSIGN_DEVICE = '/assign-device',
  RETRIEVAL = '/retrieval',
  VIEW_ALL = 'view=ALL',
  VIEW_RETRIEVALS = 'view=RETRIEVALS',
  VIEW_FOLLOW_UP = 'view=FOLLOW_UP',
  VIEW_DEVICES = 'view=DEVICES',
  VIEW_MERCHANDISE = 'view=MERCHANDISE',
  ORDER_UNLOCK_DEVICE_FILTER = 'orderType=DEVICE_UNLOCK',
}

export const DEFAULT_BLANK_TABLE_CELL_VALUE = '-';

export const MACRO_CASE_REGEX = /^[A-Z_\d]+$/;

export const MM_DD_YYYY_SLASH_FORMAT = 'MM/DD/YYYY';

export enum EmploymentStatusEnum {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Inactive = 'INACTIVE',
}

export enum FormModeContext {
  ADD = 'add',
  EDIT = 'edit',
}

export const ADDRESS_FIELDS_REQUIRED = [
  'streetAddress1',
  'city',
  'country',
  'zipCode',
];

export const ADDRESS_FIELDS_OPTIONAL = [
  'streetAddress2',
  'principalRegion',
  'state',
];

export enum AddressFields {
  STREET_ADDRESS_1 = 'streetAddress1',
  STREET_ADDRESS_2 = 'streetAddress2',
  CITY = 'city',
  COUNTRY = 'country',
  PRINCIPAL_REGION = 'principalRegion',
  STATE = 'state',
  ZIP_CODE = 'zipCode',
}

export const INITIAL_PAGE_NUMBER = 0;

// Infinite Scroll Settings
export const DEFAULT_SCROLL_PAGE_LIMIT = 15;
export const INFINITE_SCROLL_THRESHOLD = 0.8;

export const SHIPPING_AND_PROCESSING_LABEL_COPY = 'SHIPPING & PROCESSING';

export const ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_COPY =
  'Please note, all orders are subject to a standard processing time. Delays in recipients promptly completing the required address collection form may impact the earliest delivery date below.  If you still have questions, please read our';

export const ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_COPY =
  'FAQ shipping policy';
export const ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_URL =
  'https://faq.allwhere.co/en_us/categories/shipping-S1LTCl9ci';

export const ALLWHERE_RECIPIENT_ADDRESS_SELECTION_COPY =
  'For an optimal experience, we recommend requesting the address confirmation form.';
export const DEVICE_STATUS = {
  MONO_MDM_LOCKED: 'MONO_MDM_LOCKED',
  MONO_ACTIVATION_LOCKED: 'MONO_ACTIVATION_LOCKED',
  MONO_RECOVERY_LOCKED: 'MONO_RECOVERY_LOCKED',
};
