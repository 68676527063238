/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { delay } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Link, SelectChangeEvent, duration, styled } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import {
  RecipientCardSx,
  RecipientCardHeaderSx,
  RecipientCardHeaderButtonSx,
  RecipientCardButtonGroupSx,
  RecipientCardContentSx,
  RecipientCardActionSx,
  EmployeeAddedTextSx,
  RecipientCardEmailSx,
  RecipientCardEmailDropdownSx,
  RecipientCardSelectItemsSx,
  RecipientCardSectionCopySx,
  RecipientCardShippingSx,
} from './styles';
import { RecipientCardProps, ExpandMoreProps } from './types';
import { STANDARD, TWO_DAY, TWO_DAY_TOOLTIP_COPY } from './constants';
import { capitalizeWords, capitalizePhraseWithNum } from 'services/format';
import RecipientAssetDetails from 'pages/Retrieval/components/ReviewStep/components/RecipientAssetDetails';
import { RetrievalCollaborator } from 'pages/Retrieval/RetrievalService/types';
import Dropdown from 'components/Dropdown';
import { DropdownItem } from 'components/Dropdown/types';
import {
  HomeOutlined,
  SvgIconComponent,
  WorkOutline,
} from '@mui/icons-material';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { SendPaperPlaneOutlined } from 'components/Icons/SendPaperPlaneSVGIcon';
import { SelectableRecipientAddress } from 'types';
import {
  ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_COPY,
  ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_COPY,
  ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_URL,
  ALLWHERE_RECIPIENT_ADDRESS_SELECTION_COPY,
  SHIPPING_AND_PROCESSING_LABEL_COPY,
} from 'global-constants';
import { getAddressIcon, getAddressLabel } from 'services';

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand: _, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: { theme: Theme; expand: boolean }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const RecipientCard = (props: RecipientCardProps) => {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const [removeCard, setRemoveCard] = React.useState<boolean>(false);
  const [remountKey, setRemountKey] = React.useState<string>(() => nanoid());
  const { recipientInfo: info } = props;

  const { isFlagOn: isSelectRecipientEmailEnabled } = useFeatureFlagService(
    FeatureFlagNames.SELECT_RECIPIENT_EMAIL,
    {
      debugFlags: true,
    }
  );

  const {
    isFlagOn: isSelectRecipientAddressEnabled,
    OnFlagFalseWrapper: OnSelectRecipientAddressDisabledWrapper,
  } = useFeatureFlagService(FeatureFlagNames.SELECT_RECIPIENT_ADDRESS);

  const showRecipientInfoSelectSection =
    isSelectRecipientEmailEnabled() || isSelectRecipientAddressEnabled();

  React.useEffect(() => {
    setRemountKey(() => nanoid());
  }, [info.assets.length]);

  const getShippingInfo = () => {
    const isExpedited = props.recipientInfo?.isRush ?? false;
    return isExpedited ? TWO_DAY : STANDARD;
  };

  const [selectedEmail, setSelectedEmail] = React.useState<string>(
    info.contactEmail || info.personalEmail || info.workEmail || info.email
  );

  const defaultAddressOption: DropdownItem<SelectableRecipientAddress> = {
    icon: SendPaperPlaneOutlined as SvgIconComponent,
    label: 'Request address confirmation',
    value: {} as SelectableRecipientAddress,
  };

  const getAddressDisplay = (address: SelectableRecipientAddress) => {
    return (
      <span>
        {`${getAddressLabel(address)};`}{' '}
        <span style={address.phoneNumber ? {} : { color: 'red' }}>
          {address.phoneNumber
            ? `Phone: ${address.phoneNumber}`
            : 'Missing phone number'}
        </span>
      </span>
    );
  };

  const getCollaboratorAddressItems =
    (): DropdownItem<SelectableRecipientAddress>[] => {
      return [
        defaultAddressOption,
        ...(info.shippingAddressOptions
          ? info.shippingAddressOptions.map((address) => ({
              label: getAddressDisplay(address),
              icon: getAddressIcon(address),
              value: address as SelectableRecipientAddress,
            }))
          : []),
      ];
    };

  const getCollaboratorEmails = (): DropdownItem<string>[] => {
    const collaboratorEmails: DropdownItem<string>[] = [];
    if (info.personalEmail) {
      collaboratorEmails.push({
        label: info.personalEmail,
        icon: HomeOutlined,
        value: info.personalEmail,
      });
    }
    if (info.workEmail) {
      collaboratorEmails.push({
        label: info.workEmail,
        icon: WorkOutline,
        value:
          info.workEmail === info.personalEmail
            ? `${info.workEmail}@work`
            : info.workEmail,
      });
    }
    if (
      collaboratorEmails.length === 0 ||
      (!info.personalEmail && info.email !== info.workEmail)
    ) {
      collaboratorEmails.push({
        icon: HomeOutlined,
        label: info.email,
        value: info.email,
      });
    }
    return collaboratorEmails;
  };

  const handleExpandClick = () => setExpanded((prevState) => !prevState);

  const handleShippingToggle = (
    event: React.MouseEvent<Element, MouseEvent>,
    value: any
  ) => {
    const employeeId = props.recipientInfo.id;
    const rushValue = value === TWO_DAY;
    props.setRecipientShipping(employeeId, rushValue);
  };

  const showAddEmployeeAddressCta =
    props.recipientInfo.shippingAddress === undefined;

  const handleDeleteEmployee = () => {
    setRemoveCard(true);

    const collaboratorId = props.recipientInfo.id;
    delay(
      (id) => {
        props.removeSelectedEmployee(id);
      },
      duration.standard,
      collaboratorId
    );
  };

  const handleEmailChange = (event: SelectChangeEvent<string>) => {
    setSelectedEmail(event.target.value);
  };

  const handleAddressChange = (
    event: SelectChangeEvent<SelectableRecipientAddress>
  ) => {
    const address = event.target.value as SelectableRecipientAddress;
    props.setRecipientAddress(info.id, address);
  };

  const getShippingToggleId = (collaboratorInfo: RetrievalCollaborator) =>
    `${collaboratorInfo.firstName.toLowerCase()}-${collaboratorInfo.lastName.toLowerCase()}-shipping-toggle
    }`;

  React.useEffect(() => {
    const value = selectedEmail.split('@work');
    props.setRecipientEmail(info.id, value ? value[0] : selectedEmail);
  }, [selectedEmail]);

  return (
    <div style={{ width: '100%', ...(removeCard && { marginTop: 0 }) }}>
      <Collapse in={!removeCard} exit={removeCard}>
        <Card sx={RecipientCardSx(theme)}>
          <CardHeader
            sx={RecipientCardHeaderSx}
            title={
              <Button
                sx={RecipientCardHeaderButtonSx(theme)}
                disableRipple
                onClick={handleExpandClick}
              >
                <Typography variant="h5">{`${info.firstName} ${info.lastName}`}</Typography>
              </Button>
            }
          />
          <CardContent sx={RecipientCardContentSx}>
            <Stack
              direction="column"
              className="retrieval-recipient-card-expedited-stack"
            >
              <Collapse in={expanded}>
                {showRecipientInfoSelectSection && (
                  <>
                    {isSelectRecipientAddressEnabled() && (
                      <Typography sx={RecipientCardSectionCopySx}>
                        {`${ALLWHERE_RECIPIENT_ADDRESS_SELECTION_COPY}`}
                      </Typography>
                    )}
                    <Stack direction="row" sx={RecipientCardSelectItemsSx}>
                      {isSelectRecipientEmailEnabled() && (
                        <Box sx={RecipientCardEmailSx}>
                          <InputLabel>EMAIL ADDRESS</InputLabel>
                          <Dropdown
                            type="Select"
                            items={getCollaboratorEmails()}
                            selectedItem={selectedEmail}
                            handleSearchOptionChange={handleEmailChange}
                            dropdownSx={RecipientCardEmailDropdownSx}
                            showCheckIcon={true}
                          />
                        </Box>
                      )}
                      {isSelectRecipientAddressEnabled() && (
                        <Box sx={RecipientCardEmailSx}>
                          <InputLabel>SHIPPING ADDRESS</InputLabel>
                          <Dropdown
                            type="Select"
                            items={getCollaboratorAddressItems()}
                            selectedItem={
                              info.shippingAddress || defaultAddressOption.value
                            }
                            handleSearchOptionChange={handleAddressChange}
                            dropdownSx={RecipientCardEmailDropdownSx}
                            showCheckIcon={true}
                            allowAddSelectItem={true}
                            addSelectItemText="Add address manually"
                            handleAddSelectItem={() =>
                              props.openAddAddressModal(
                                info.firstName,
                                info.lastName,
                                info.id
                              )
                            }
                          />
                        </Box>
                      )}
                    </Stack>
                  </>
                )}
                <Box sx={RecipientCardShippingSx}>
                  <InputLabel htmlFor={getShippingToggleId(info)}>
                    {SHIPPING_AND_PROCESSING_LABEL_COPY}
                  </InputLabel>
                  <Typography sx={RecipientCardSectionCopySx}>
                    {`${ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_COPY}${' '}`}
                    <Link
                      color="secondary"
                      href={ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_URL}
                      target="_blank"
                    >
                      {ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_COPY}
                    </Link>
                  </Typography>
                  <ToggleButtonGroup
                    className="retrieval-recipient-card-expedited-button-group"
                    exclusive
                    id={getShippingToggleId(info)}
                    defaultValue={TWO_DAY}
                    value={getShippingInfo()}
                    onChange={handleShippingToggle}
                  >
                    <ToggleButton value={STANDARD}>
                      {capitalizeWords(STANDARD)}
                    </ToggleButton>
                    <ToggleButton value={TWO_DAY}>
                      {capitalizePhraseWithNum(TWO_DAY)}{' '}
                      <span className="retrieval-recipient-card-expedited-tooltip">
                        <Tooltip
                          title={TWO_DAY_TOOLTIP_COPY}
                          placement="bottom"
                          arrow
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                      </span>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Collapse>
            </Stack>
            <Box pt="16px">
              <RecipientAssetDetails
                key={remountKey}
                employeeId={info.id}
                firstName={info.firstName}
                lastName={info.lastName}
                assets={info.assets}
                expanded={expanded}
                toggleAsset={props.toggleAsset}
                answerRetrievalOption={props.answerRetrievalOption}
                retrievalOptionsByType={props.retrievalOptionsByType}
                retrievalOptionsAnswerKey={props.retrievalOptionsAnswerKey}
              />
            </Box>
          </CardContent>
          <CardActions sx={RecipientCardActionSx}>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show device details"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Stack direction="column" spacing={0}>
            <Stack direction="row" justifyContent="space-between">
              <ButtonGroup sx={RecipientCardButtonGroupSx(theme)}>
                <Button variant="text" onClick={handleDeleteEmployee}>
                  <Typography>DELETE</Typography>
                </Button>
                <OnSelectRecipientAddressDisabledWrapper>
                  {showAddEmployeeAddressCta ? (
                    <Button
                      variant="text"
                      startIcon={<AddIcon />}
                      onClick={() =>
                        props.openAddAddressModal(
                          info.firstName,
                          info.lastName,
                          info.id
                        )
                      }
                    >
                      <Typography>ADD EMPLOYEE ADDRESS</Typography>
                    </Button>
                  ) : (
                    <>
                      <Typography sx={EmployeeAddedTextSx}>
                        employees address added
                      </Typography>
                      <Button
                        variant="text"
                        onClick={() =>
                          props.removeShippingAddressFromEmployee(info.id)
                        }
                      >
                        <Typography>REMOVE</Typography>
                      </Button>
                    </>
                  )}
                </OnSelectRecipientAddressDisabledWrapper>
              </ButtonGroup>
              <ButtonGroup sx={RecipientCardButtonGroupSx(theme)}>
                <Button
                  data-testid="add-device-button"
                  variant="text"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    props.openAddAssetModal(
                      info.firstName,
                      info.lastName,
                      info.id
                    )
                  }
                >
                  <Typography>ADD DEVICE</Typography>
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
        </Card>
      </Collapse>
    </div>
  );
};

export default RecipientCard;
