import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DataGrid from 'components/DataGrid';
import { AssetPrice, SubmitStepProps } from './types';
// WARNING: DO NOT just REMOVE the TEST ID
import { TEST_ID_STEP_COMPONENT_SUBMIT } from 'pages/Retrieval/constants';
import { SubmitStepSx } from './styles';
import { EMPLOYEE_COLUMNS } from './constants';
import Footer from './components/Footer';
import { pruneUncheckedAssets } from './utils';
import { RetrievalContext } from 'context/RetrievalServiceProvider';

const SubmitStep = (props: SubmitStepProps) => {
  const [assetPrices, setAssetPrices] = React.useState<AssetPrice[]>([]);
  const employees = props.retrievalFlow.selectedEmployees;

  const retrievalRouteId = props.retrievalFlow.retrievalDestination
    ?.retrievalRouteId as string;

  const originCountry = props.retrievalFlow.retrievalOrigin?.countryName;

  const retrievalDestinationName =
    props.retrievalFlow.retrievalDestination?.name;

  const columns = EMPLOYEE_COLUMNS(
    retrievalRouteId,
    setAssetPrices,
    props.retrievalFlow.assetsOptionAnswerKey
  );

  const employeesSansRemovedAssets = employees.map(pruneUncheckedAssets);

  return (
    <>
      <Grid
        container
        data-testid={TEST_ID_STEP_COMPONENT_SUBMIT}
        sx={SubmitStepSx}
      >
        <Grid item xs={12} id="submit-step-title-block">
          <Typography id="submit-step-title-copy">Submit retrieval</Typography>
          <Typography
            id="submit-step-subtitle-copy"
            data-testid="submit-step-subtitle"
          >
            Retrieving from {originCountry} to {retrievalDestinationName}
          </Typography>
        </Grid>
        <Stack width="100%">
          <DataGrid
            hideToolbar
            pagination
            disableRowSelectionOnClick
            enableDynamicRowHeight
            filterMode="server"
            paginationMode="client"
            rows={employeesSansRemovedAssets}
            rowCount={employeesSansRemovedAssets.length}
            columns={columns}
            paginationModel={{ page: 0, pageSize: 100 }}
          />
        </Stack>
      </Grid>
      <Footer
        retrievalRouteId={retrievalRouteId}
        assetPrices={assetPrices}
        employees={employeesSansRemovedAssets}
        optionAnswerKey={props.retrievalFlow.assetsOptionAnswerKey}
      />
    </>
  );
};

export const SubmitStepWrapped = () => {
  const retrievalFlow = React.useContext(RetrievalContext);

  return <SubmitStep retrievalFlow={retrievalFlow} />;
};

export default SubmitStep;
