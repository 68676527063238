import React from 'react';
import { useApolloClient } from '@apollo/client';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { useDispatch } from 'react-redux';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { notificationSlice } from 'store/slices';
import Spacer from 'components/Spacer';
import { MD_XL_MODAL_INNER_HEIGHT_OFFSET } from 'pages/OrderManagement/components/DetailedOrderView/constants';
import { getDetailViewContentContainerSx } from 'pages/OrderManagement/components/DetailedOrderView/styles';
import { OrderType as OrderTypeEnum } from 'global-constants';
import * as services from 'services';
import { SEARCH_ORDERS } from 'pages/OrderManagement/queries';
import { RETRIEVE_RECIPIENT } from './queries';
import { UPDATE_ORDER } from './mutations';
import {
  RecipientInfoProps,
  FetchedRecipientInfo,
  RecipientChangeState,
} from './types';
import { RecipientInfoDepotTitleSx } from './styles';
import {
  RECIPIENT_INFO_PROP_NAMES,
  DepotSelect,
  RECIPIENT,
  DROP_RECIPIENT,
} from './constants';
import { getDefaultRecipientChangeState } from './utils';
import RecipientAddressForm from './components/RecipientAddressForm';
import ConfirmRecipientSourceModal from './components/ConfirmRecipientSourceModal';
import { getAllDepotsQuery } from 'api/apollo/queries/v1';
import { GET_ALL_DEPOTS_OUTPUT } from 'pages/OrderManagement/filters/shared/constants';

const RecipientInfo = (props: RecipientInfoProps) => {
  const client = useApolloClient();
  const availablePropsTable = services.getTableFromArray(Object.keys(props));
  const hasAnyProps = RECIPIENT_INFO_PROP_NAMES.some(
    // eslint-disable-next-line security/detect-object-injection
    (propName) => !!availablePropsTable[propName]
  );

  if (!hasAnyProps)
    return (
      <div
        style={{ height: `calc(100vh - ${MD_XL_MODAL_INNER_HEIGHT_OFFSET})` }}
      >
        No Recipient Info Available
      </div>
    );

  const dispatch = useDispatch();
  const [fetchedRecipientInfo, setFetchedRecipientInfo] = React.useState<
    Partial<FetchedRecipientInfo> | undefined
  >();

  const [fetchedDropRecipientInfo, setFetchedDropRecipientInfo] =
    React.useState<Partial<FetchedRecipientInfo> | undefined>();

  // loading state(s) for phone number flicker issue
  const [recipientPhoneLoading, setRecipientPhoneLoading] =
    React.useState<boolean>(false);
  const [dropRecipientPhoneLoading, setDropRecipientPhoneLoading] =
    React.useState<boolean>(false);

  // Choose & change recipient-collaborator section
  const [recipientChangeState, setRecipientChangeState] =
    React.useState<RecipientChangeState>(() =>
      getDefaultRecipientChangeState()
    );

  // choose depot
  const [selectedDepotId, setSelectedDepotId] = React.useState<string>(
    props.depotId
  );
  const [selectableDepots, setSelectableDepots] = React.useState<
    [string, string][]
  >([]);

  const setRecipientChangeStateHOF =
    (origin: string) =>
    ({
      name,
      email,
      id,
      open,
      changed = false,
    }: {
      name: string;
      email: string;
      id: string;
      open: boolean;
      changed: boolean;
    }) => {
      setRecipientChangeState((prevState) => ({
        ...prevState,
        origin,
        collaboratorName: name,
        collaboratorEmail: email,
        collaboratorId: id,
        openModal: open,
        changed,
      }));
    };

  const clearRecipientChangeState = () => {
    setRecipientChangeState(() => getDefaultRecipientChangeState());
  };

  const showChangesSavedSuccessfully = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
      })
    );
  };

  const [
    fetchRecipientInfo,
    { loading: loadingRecipientInfo, refetch: refetchRecipientInfo },
  ] = useSafeLazyQuery(RETRIEVE_RECIPIENT, {
    onCompleted: (data) => {
      const { retrieveRecipient } = data;
      const { address, ...rest } = retrieveRecipient;
      const tempRecipient = { ...rest, recipientId: props.recipientId };
      const tempAddress = address ? { ...address, addressId: address.id } : {};

      setFetchedRecipientInfo(() => ({
        ...tempRecipient,
        ...tempAddress,
      }));
    },
  });

  const refetchV2Orders = async () => {
    await client.refetchQueries({
      include: ['getPathOrders'],
    });
  };

  const handleUpdateCompletion = () =>
    refetchV2Orders().then(() => {
      showChangesSavedSuccessfully();
    });

  const [updateOrder] = useSafeMutation(UPDATE_ORDER, {
    onCompleted: handleUpdateCompletion,
  });

  const [
    fetchDropRecipientInfo,
    { loading: loadingDropRecipientInfo, refetch: refetchDropRecipientInfo },
  ] = useSafeLazyQuery(RETRIEVE_RECIPIENT, {
    onCompleted: (data) => {
      const { retrieveRecipient } = data;
      const { address, ...rest } = retrieveRecipient;
      const tempRecipient = { ...rest, recipientId: props.dropRecipientId };
      const tempAddress = address ? { ...address, addressId: address.id } : {};

      setFetchedDropRecipientInfo(() => ({
        ...tempRecipient,
        ...tempAddress,
      }));
    },
  });

  const [fetchAllDepots, { loading: loadingAllDepots }] = useSafeLazyQuery(
    getAllDepotsQuery(GET_ALL_DEPOTS_OUTPUT),
    {
      onCompleted: (data) => {
        const depots = data?.getAllDepots || [];
        if (!depots.length) return;
        const tupleDepotOptions = depots.map(
          (depot: any) => [depot.id, depot.name] as [string, string]
        );
        setSelectableDepots(tupleDepotOptions);
      },
    }
  );

  const handleDepotChange = (event: SelectChangeEvent<string | null>) => {
    const newDepotId = event.target.value || '';
    if (selectedDepotId === newDepotId) return;

    setSelectedDepotId(newDepotId);

    updateOrder({
      variables: {
        id: props.orderId,
        depotId: newDepotId,
      },
    });
  };

  const renderDepotsSelect = () =>
    props.toDepot && !loadingAllDepots && !!selectableDepots.length;

  const refetchOMOrders = async () => {
    await client.refetchQueries({
      include: [SEARCH_ORDERS],
    });
  };

  const refetchAllRecipientInfo = () => {
    refetchOMOrders();
    if (props.recipientId) {
      refetchRecipientInfo();
    }
    setTimeout(() => {
      setRecipientPhoneLoading(false);
    }, 500);
  };

  const refetchAllDropRecipientInfo = () => {
    refetchOMOrders();
    if (props.dropRecipientId) {
      refetchDropRecipientInfo();
    }
    setTimeout(() => {
      setDropRecipientPhoneLoading(false);
    }, 500);
  };

  React.useEffect(() => {
    if (!props.toDepot) return;
    fetchAllDepots();
  }, [props.toDepot]);

  React.useEffect(() => {
    if (!props.recipientId) return;
    fetchRecipientInfo({
      variables: {
        id: props.recipientId,
      },
    });
  }, [props.recipientId]);

  React.useEffect(() => {
    if (!props.dropRecipientId) return;
    fetchDropRecipientInfo({
      variables: {
        id: props.dropRecipientId,
      },
    });
  }, [props.dropRecipientId]);

  React.useEffect(() => {
    clearRecipientChangeState();
  }, []);

  const renderRecipientInfo = (): boolean => {
    const isBulkRetrieval =
      OrderTypeEnum.BULK_RETRIEVAL === props.order.orderType.name;
    if (props?.recipientId) {
      return !loadingRecipientInfo && (props.toRecipient || isBulkRetrieval);
    }

    return isBulkRetrieval || props.toRecipient;
  };

  const renderDropRecipientInfo = (): boolean =>
    props?.dropRecipientId
      ? !loadingRecipientInfo && props.toDropRecipient
      : props.toDropRecipient;

  const RecipientInfoContainerSx = getDetailViewContentContainerSx({
    overflowY: 'scroll',
  });

  return (
    <>
      <Container sx={RecipientInfoContainerSx}>
        {loadingRecipientInfo ? (
          <div>Loading Recipient information...</div>
        ) : null}
        {renderRecipientInfo() ? (
          <RecipientAddressForm
            recipientType={RECIPIENT}
            formTitle="Recipient information"
            tagIdName="recipient-info-form"
            setRecipientChangeState={setRecipientChangeStateHOF(RECIPIENT)}
            recipientChangeState={recipientChangeState}
            refetchAll={refetchAllRecipientInfo}
            {...fetchedRecipientInfo}
            recipientCollaborators={props.recipientCollaborators}
            getCollaboratorById={props.getCollaboratorById}
            orderId={props.orderId}
            clearRecipientChangeState={clearRecipientChangeState}
            collaboratorId={props.recipientCollaboratorId}
            order={props.order}
            setPhoneLoading={setRecipientPhoneLoading}
            phoneLoading={recipientPhoneLoading}
          />
        ) : null}
        {renderDropRecipientInfo() ? (
          <>
            <Spacer />
            <RecipientAddressForm
              recipientType={DROP_RECIPIENT}
              formTitle="Final destination information"
              tagIdName="final-destination-info-form"
              refetchAll={refetchAllDropRecipientInfo}
              setRecipientChangeState={setRecipientChangeStateHOF(
                DROP_RECIPIENT
              )}
              recipientChangeState={recipientChangeState}
              {...fetchedDropRecipientInfo}
              recipientCollaborators={props.recipientCollaborators}
              getCollaboratorById={props.getCollaboratorById}
              orderId={props.orderId}
              clearRecipientChangeState={clearRecipientChangeState}
              collaboratorId={props.dropRecipientCollaboratorId}
              order={props.order}
              setPhoneLoading={setDropRecipientPhoneLoading}
              phoneLoading={dropRecipientPhoneLoading}
            />
          </>
        ) : null}
        {loadingDropRecipientInfo ? (
          <div>Loading Final destination information...</div>
        ) : null}
        {renderDepotsSelect() ? (
          <>
            <Spacer />
            <Typography sx={RecipientInfoDepotTitleSx}>
              Final destination information
            </Typography>
            <FormControl sx={{ width: '325px' }}>
              <InputLabel id={DepotSelect.LABEL_ID}>
                {DepotSelect.LABEL}
              </InputLabel>
              <Select
                id={DepotSelect.SELECT_ID}
                label={DepotSelect.LABEL}
                value={selectedDepotId}
                onChange={handleDepotChange}
              >
                {selectableDepots.map(([id, depotName]) => (
                  <MenuItem key={id} value={id}>
                    {depotName}
                  </MenuItem>
                ))}
              </Select>
              <Spacer />
            </FormControl>
          </>
        ) : (
          <></>
        )}
      </Container>
      <ConfirmRecipientSourceModal
        recipientChangeState={recipientChangeState}
        setRecipientChangeState={setRecipientChangeState}
      />
    </>
  );
};

export default RecipientInfo;
