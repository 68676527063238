export enum AddNewPersonCopy {
  TITLE = 'Add new employee',
  SUBTITLE = 'Please complete the information below to add an employee to the system.',
}

/* DATA-TESTIDs */
export const ADD_NEW_PERSON_MODAL_TESTID = 'add-new-person-modal-testid';
export const ADD_NEW_PERSON_FORM_TESTID = 'add-new-person--modal-form-testid';

export const EMAIL_FIELD_VALIDATION_REGEX =
  /^\s*([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})\s*$/i;
