import { onError } from '@apollo/client/link/error';
import { store } from 'store';
import { setAuthToken } from 'store/slices';
import { captureSentryException } from 'vendor/sentry';

export const errorMiddleware = onError((errorResponse) => {
  const { graphQLErrors, networkError, operation } = errorResponse;
  if (graphQLErrors) {
    console.log('graphQLErrors', JSON.stringify(graphQLErrors));
    for (const err of graphQLErrors) {
      if (
        err?.extensions?.exception?.thrownValue?.statusCode === 502 ||
        err?.extensions?.exception?.thrownValue?.statusCode === 404
      ) {
        return;
      }
      if (err.extensions.code === 'UNAUTHENTICATED') {
        store.dispatch(setAuthToken('unauthenticated'));
      } else {
        const fingerprint = [
          '{{ default }}',
          operation.operationName,
          operation.variables,
          operation.extensions,
          operation.query,
          err.message,
        ];
        captureSentryException(errorResponse, err, fingerprint);
      }
    }
  }
  if (networkError) {
    const fingerprint = [
      '{{ default }}',
      operation.operationName,
      networkError.message,
    ];
    captureSentryException(errorResponse, networkError, fingerprint);
    console.log(`[Network error]: ${networkError}`);
  }
});
