/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FilterEntry } from 'store/shared/types';
import { CLASS_NAMES_SUFFIX } from 'components/complex_filter/fields/styles';
import * as CheckBoxStyles from './styles';
import CheckBoxFieldProps from './types';

const CheckBoxField = (props: CheckBoxFieldProps) => {
  const styles = CheckBoxStyles;
  const myLabelPlacement = props?.labelPlacement ?? 'end';
  const checkBoxSelectorPrefix = `${props.baseSelectorPrefix}-${props.filterKey}-checkbox-field-`;

  const selectDisabledFn = props.selectIsCheckboxDisabled(
    props.filterSubTree,
    props.filterKey
  );

  const isDisabled = useSelector(selectDisabledFn);

  const getAvailableEntries = props.selectAvailableEntries(
    props.filterSubTree,
    props.filterKey
  );

  const availableEntries: FilterEntry[] =
    useSelector(getAvailableEntries) || [];

  const selectedEntries: FilterEntry[] =
    availableEntries.length === 0
      ? []
      : availableEntries.slice().filter((entry) => entry.selected);

  if (availableEntries.length > 1 || availableEntries.length === 0) {
    console.error(
      'Checkbox field should have only one entry available to be selected.'
    );
    return <></>;
  }

  return (
    <FormControl
      data-testid={`${checkBoxSelectorPrefix}${CLASS_NAMES_SUFFIX.checkBoxMain}`}
      sx={styles.CheckBoxFromControlSx}
    >
      <FormControlLabel
        data-testid={`${checkBoxSelectorPrefix}${CLASS_NAMES_SUFFIX.checkBoxLabel}`}
        value="top"
        control={
          <Checkbox
            checked={selectedEntries.length === 1}
            sx={styles.MainCheckBoxSx}
            onChange={props.handleCheckboxChangeHOF(
              props.filterKey,
              availableEntries[0].id
            )}
            disabled={isDisabled}
          />
        }
        label={props.optionLabel}
        labelPlacement={myLabelPlacement}
        sx={styles.CheckBoxLabelSx}
      />
    </FormControl>
  );
};

export default CheckBoxField;
