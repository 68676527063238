import * as MaterialIcons from '@mui/icons-material';
import { BuyAndHoldCTAItem } from './types';

export const BUY_HOLD_CTA_TITLE_ID = 'buy-hold-cta-title-id';
export const BUY_AND_HOLD_CTA_ITEM_ICON = 'buy-and-hold-cta-item-icon';
export const BUY_AND_HOLD_CTA_ITEM_TITLE = 'buy-and-hold-cta-item-title';
export const BUY_AND_HOLD_CTA_ITEM_COPY = 'buy-and-hold-cta-item-copy';

export const BUY_HOLD_CTA_ITEMS: Array<BuyAndHoldCTAItem> = [
  [
    'Easy tracking',
    'Avoid supply chain constraints and shipping delays by having access to devices when you need them.',
    MaterialIcons.LocalShippingOutlined,
  ],
  [
    'Consistent experience ',
    'Provide a consistent onboarding experience to new hires and upgrades for existing employees.',
    MaterialIcons.SentimentSatisfiedOutlined,
  ],
  [
    'Accountability and oversight',
    'Have accountability and oversight of all your devices and deploy them with one click in the Asset Dashboard.',
    MaterialIcons.ListAltOutlined,
  ],
];
