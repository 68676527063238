/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { AssetStatusTransition } from 'pages/Assets/components/DevicesDatagrid/types';
import { StatusHistoryStep } from 'types';
import { convertStatusToStep } from './utils';

import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import AssetStatusStepper from './components/AssetStatusStepper';
import { GET_ALL_ASSET_STATUS_TRANSITIONS_BY_ASSET } from './queries';
import { AssetStatusProps } from './types';
import * as AssetStatusHistoryStyles from './styles';

const AssetStatusHistory = (props: AssetStatusProps) => {
  if (!props.asset) return null;
  const styles = AssetStatusHistoryStyles;

  const [statusHistorySteps, setStatusHistorySteps] = React.useState<
    StatusHistoryStep[]
  >([]);

  const [
    fetchAllAssetStatusTransitionsByOrder,
    { loading: fetchingAllAssetStatusTransitions },
  ] = useSafeLazyQuery(GET_ALL_ASSET_STATUS_TRANSITIONS_BY_ASSET, {
    variables: {
      assetId: props.asset.id,
    },
    skip: !props.asset?.id,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: ({ getAllAssetStatusTransitionsByAssetId = [] }) => {
      const convertedOrderHistory: StatusHistoryStep[] =
        getAllAssetStatusTransitionsByAssetId.reduce(
          (
            acc: StatusHistoryStep[],
            assetTransition: AssetStatusTransition,
            index: number,
            array: AssetStatusTransition[]
          ) => {
            const options = {
              isActive: index === 0,
              assetCreatedAt: props.asset?.createdAt ?? '',
              lastStatus: index === array.length - 1,
            };
            const step = convertStatusToStep(assetTransition, options);
            acc = [...acc, ...step];
            return acc;
          },
          [] as StatusHistoryStep[]
        );
      setStatusHistorySteps(() => convertedOrderHistory);
    },
  });

  const fetchAssetStatusHistory = () => {
    fetchAllAssetStatusTransitionsByOrder();
  };

  React.useEffect(() => {
    if (!props.asset?.id) return;
    fetchAssetStatusHistory();
  }, [props.asset?.id]);

  const stepperProps = {
    stepperTitle: 'Asset status history',
    steps: statusHistorySteps,
  };

  const canRenderAssetStatusHistory = () =>
    statusHistorySteps.length > 0 && !fetchingAllAssetStatusTransitions;

  return (
    <Container sx={styles.StepperContainerSx}>
      {fetchingAllAssetStatusTransitions ? (
        <div style={styles.ProgressDivCSS}>
          <CircularProgress sx={styles.CircularProgressSx} size={85} />
        </div>
      ) : null}
      {canRenderAssetStatusHistory() ? (
        <>
          <AssetStatusStepper {...stepperProps} />
        </>
      ) : null}
    </Container>
  );
};

export default AssetStatusHistory;
