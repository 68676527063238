import React from 'react';

import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AutoCompleteOptionTuple } from 'types';
import GenericAutocomplete from 'components/GenericAutocomplete';
import * as styles from './styles';
import { SearchScopeDropdownProps } from './types';
import {
  ORDERS_SEARCH_BAR_CLEAR_INDICATOR_ID,
  SEARCH_BAR_TYPE_SELECT_WIDTH,
  SEARCH_BAR_WHOLE_WIDTH,
} from './constants';

const SearchScopeDropdown = (props: SearchScopeDropdownProps) => {
  return (
    <Stack
      direction="row"
      sx={{ ...styles.AutoCompleteStackSx, mt: 'rem' }}
      divider={<Divider orientation="vertical" variant="middle" flexItem />}
    >
      <SearchIcon sx={styles.AutoCompleteSearchIconSx} />
      <GenericAutocomplete
        placeholder={props.placeholder}
        sx={styles.AutoCompleteSx}
        value={props.selectedSuggestion}
        onChange={props.handleSuggestionSelection}
        onInputChange={props.handleSearchTermChange}
        onBlur={props.resetSearchTerm}
        componentsProps={{
          popper: {
            style: {
              width: SEARCH_BAR_WHOLE_WIDTH,
            },
          },
          // STYLING - for options dropdown below
          paper: {
            style: {
              position: 'relative',
              top: '3px',
              left: '85px',
            },
          },
          clearIndicator: {
            id: ORDERS_SEARCH_BAR_CLEAR_INDICATOR_ID,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            value={props.searchTerm}
            placeholder={props.placeholder}
            sx={styles.AutoCompleteSearchTextFieldSx}
          />
        )}
        filterOptions={(options, { inputValue }) => {
          return options.filter((option) => {
            return option
              .toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase());
          });
        }}
        // @ts-ignore
        getOptionLabel={(option: AutoCompleteOptionTuple) => {
          return option[2];
        }}
        options={props.suggestions}
      />
      <Select
        sx={styles.AutoCompleteSelectSx}
        value={props.selectedSearchOption.filterName}
        onChange={props.handleSearchOptionChange}
        MenuProps={{
          sx: styles.AutoCompleteSelectMenuSx,
        }}
        SelectDisplayProps={{
          style: {
            width: SEARCH_BAR_TYPE_SELECT_WIDTH,
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        }}
      >
        {props.searchOptions.map((searchOption) => {
          return (
            <MenuItem
              value={searchOption.filterName}
              key={searchOption.filterName}
            >
              {searchOption.displayName}
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};

export default SearchScopeDropdown;
