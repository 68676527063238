import { SxProps, Theme } from '@mui/material';
import { DRAWER_WIDTH } from 'global-styles';
import theme from 'themes/theme';

export const SidebarContainerSx = (
  theme: Theme,
  showGlobalNotificationBanner: boolean
): SxProps => {
  return {
    width: DRAWER_WIDTH,
    height: '100%',
    display: 'flex',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      display: 'flex',
      flex: 1,
      backgroundColor: theme.palette.colors.woodSmoke,
      width: DRAWER_WIDTH,
      boxSizing: 'border-box',
      height: '100%',
      borderRight: 'none',
      ...(showGlobalNotificationBanner ? { paddingTop: '78px' } : {}),
    },
  };
};

export const LinkTextSx = (theme: Theme, isSubLink?: boolean): SxProps => {
  return {
    color: theme.palette.colors.white,
    paddingLeft: isSubLink ? '2.5rem' : '1.5rem',
    width: '100%',
    '& .MuiTypography-root': {
      fontWeight: 600,
      fontSize: isSubLink ? '16px' : '18px',
      lineHeight: isSubLink ? '19.36px' : '24px',
      fontFamily: ['PPPangramSans'].join(','),
    },
  };
};

export const SignOutButtonSx: SxProps = {
  paddingLeft: '1rem',
  paddingTop: '1.5rem',
  ':hover': {
    backgroundColor: 'transparent',
  },
};

export const SignOutTextSx = (theme: Theme): SxProps => {
  return {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    '&:hover': {
      color: theme.palette.colors.maizeYO,
    },
  };
};

export const NavLinkListItemSx = (
  theme: Theme,
  isActive?: boolean
): SxProps => {
  return {
    width: '95%',
    height: '50px',
    '&:hover': {
      ...(!isActive && {
        backgroundColor: theme.palette.colors.limedSpruce,
        border: `1px solid ${theme.palette.colors.jellyBeanBlue}`,
        borderLeft: 'none',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
      }),
    },
    ...(isActive && {
      backgroundColor: theme.palette.colors.jellyBeanBlue,
      borderLeft: 'none',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    }),
  };
};

export const SubNavLinkListItemSx = (
  theme: Theme,
  isActive?: boolean
): SxProps => {
  const baseStyles = NavLinkListItemSx(theme, isActive);
  // @ts-ignore
  baseStyles.height = '33px';
  return baseStyles;
};

export const SideBarDividerSx: SxProps = {
  borderColor: theme.palette.colors.grayD5,
  my: '16px !important',
};

export const NavLinkWIconSx: SxProps = {
  maxHeight: '33px',
  pr: 0,
  '& .MuiListItemIcon-root': {
    pl: '24px',
    minWidth: '10px',
  },
  '& *': {
    fontSize: '16px !important',
  },
  '& .MuiListItemText-root': {
    pl: '12px',
  },
};

export const getBuyNHoldCartCounterSx = (itemsCount: number): SxProps => {
  return {
    backgroundColor: 'colors.maizeYO',
    height: '28px',
    position: 'relative',
    right: '3px',
    width: '28px',
    '&>.MuiTypography-root': {
      transform: `scale(${itemsCount > 9 ? 0.9 : 1})`,
    },
  };
};

export const BuyNHoldCartCounterNumberSx: SxProps = {
  fontSize: '12px',
  fontWeight: 600,
  position: 'relative',
  color: 'colors.woodSmoke',
};

export const NavLinkFooterSx: SxProps = {
  maxHeight: '33px',
  pr: 0,
  '& *': {
    fontSize: '16px !important',
  },
  '& .MuiListItemText-root': {
    pl: '24px',
  },
};

export const NavLinkCopyrightFooterSx: SxProps = {
  maxHeight: '33px',
  pr: 0,
};

export const SidebarFooterContainerSx: SxProps = {
  height: 'calc(100%)',
  justifyContent: 'flex-end',
};

export const FulLHeightSx = {
  height: '100%',
};

export const SidebarStackContainerSx = {
  height: '100%',
  justifyContent: 'flex-end',
  pb: '20px',
};
