import { FilterEntry } from 'store/shared/types';
import { OrderFilterKeys } from 'pages/Orders/filters/shared/constants';
import moment from 'moment';
import { NewOrderItem } from 'types';
import { FlattenedShipment } from './types';

export * from 'pages/Orders/utils';

export const getWhereClauseFromFilterEntries = (
  filterEntries: FilterEntry[]
) => {
  const whereClauses: any[] = [];
  if (!filterEntries.length) return whereClauses;
  const filterLookup: Record<string, FilterEntry[]> = {};
  filterEntries.forEach((entry: FilterEntry) => {
    const { key } = entry;
    if (!filterLookup[key]) {
      filterLookup[key] = [entry];
    } else {
      filterLookup[key].push(entry);
    }
  });

  const filterKeys = Object.keys(filterLookup);
  let recipientIds: string[] = [];

  filterKeys.forEach((filterKey) => {
    const filterValues = filterLookup[filterKey].map((entry) => entry.value);
    const filterEntry = filterLookup[filterKey][0];
    const filterOp = filterValues.length === 1 ? 'equals' : 'in';
    const passedValue =
      filterValues.length === 1 ? filterValues[0] : filterValues;
    switch (filterKey) {
      case OrderFilterKeys.ORDER_NUMBER:
        whereClauses.push({ id: { [filterOp]: passedValue } });
        break;
      case OrderFilterKeys.ORDER_TYPE:
        whereClauses.push({ orderTypeId: { [filterOp]: passedValue } });
        break;
      case OrderFilterKeys.ORDER_DATE:
        applyOrderDateQuery(filterEntry.id, whereClauses);
        break;
      case OrderFilterKeys.STATUS:
        whereClauses.push({ status: { [filterOp]: passedValue } });
        break;
      case OrderFilterKeys.SHIPPING_SELECTION:
        whereClauses.push({ shippingTypeId: { [filterOp]: passedValue } });
        break;
      case OrderFilterKeys.TRACKING_NUMBER:
        // do stuff
        break;
      case OrderFilterKeys.PURCHASER:
        whereClauses.push({ purchaserId: { [filterOp]: passedValue } });
        break;
      case OrderFilterKeys.ASSET_NUMBER:
        whereClauses.push({
          orderItems: { some: { assetId: { [filterOp]: passedValue } } },
        });
        break;
      case OrderFilterKeys.SHIPMENT_STATUS:
        whereClauses.push({
          shipments: { some: { status: { [filterOp]: passedValue } } },
        });
        break;
      case OrderFilterKeys.RECIPIENT:
      case OrderFilterKeys.RECIPIENT_EMAIL:
        recipientIds = recipientIds.concat(filterValues as string[]);
        break;
      default:
        break;
    }
  });

  if (recipientIds.length) {
    const filterOp = recipientIds.length === 1 ? 'equals' : 'in';
    whereClauses.push({
      recipient: {
        is: {
          collaboratorId: {
            [filterOp]:
              recipientIds.length === 1 ? recipientIds[0] : recipientIds,
          },
        },
      },
    });
  }

  return whereClauses;
};

const applyOrderDateQuery = (dateString: string, whereClauses: any[]) => {
  const dateParts = dateString.split('::');
  const dateType = dateParts[1];

  if (dateType === 'after') {
    const selectedDate = moment(dateParts[2], 'MM/DD/YYYY').utc();
    if (selectedDate.isValid()) {
      whereClauses.push({ createdAt: { gt: selectedDate.format() } });
    }
  } else if (dateType === 'before') {
    const selectedDate = moment(dateParts[2], 'MM/DD/YYYY').add(1, 'day').utc();
    if (selectedDate.isValid()) {
      whereClauses.push({ createdAt: { lt: selectedDate.format() } });
    }
  } else if (dateType === 'range') {
    const rangeParts = dateParts[2]?.split('-') || [];
    const selectedAfterDate = moment(rangeParts[0], 'MM/DD/YYYY').utc();
    const selectedBeforeDate = moment(rangeParts[1], 'MM/DD/YYYY')
      .add(1, 'day')
      .utc();
    if (selectedAfterDate.isValid() && selectedBeforeDate.isValid()) {
      whereClauses.push({
        createdAt: {
          gt: selectedAfterDate.format(),
          lt: selectedBeforeDate.format(),
        },
      });
    }
  }
};

export const getFlattenedOrderItems = (
  flattenedShipments: FlattenedShipment[]
): NewOrderItem[] => {
  return flattenedShipments.flatMap((shipment) => {
    return shipment.orderItems.map((orderItem: NewOrderItem) => ({
      ...orderItem,
      shipmentStatus: shipment.status,
      shipment: {
        id: shipment.id,
        shipmentItems: shipment.shipmentItems,
        status: shipment.status,
        substatus: shipment.substatus,
        substatusDescription: shipment.substatusDescription,
      },
      ...(shipment.trackings?.length && {
        tracking: {
          id: shipment.trackings?.[0]?.id,
          trackingNumber: shipment.trackings?.[0]?.trackingNumber,
          trackingLink: shipment.trackings?.[0]?.trackingLink,
          shipmentId: shipment.id,
        },
      }),
    }));
  });
};
