/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useDispatch } from 'react-redux';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { useSafeMutation } from 'hooks/useSafeMutation';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import {
  CountryOptions,
  StateOptions,
  US_STATE_MATCHER,
  UNITED_STATES,
  COUNTRY_MATCHER,
  OrderType,
  OrderStatus,
} from 'global-constants';
import { Collaborator } from 'types';
import {
  RECIPIENT,
  DROP_RECIPIENT,
} from 'pages/OrderManagement/components/DetailedOrderView/components/RecipientInfo/constants';
import {
  UPDATE_RECIPIENT_INFO,
  CREATE_RECIPIENT,
  UPDATE_ORDER,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
} from './mutations';
import {
  getRecipientAddressAttributes,
  getRecipientAddressFormValues,
} from './utils';
import {
  RecipientAddressFormProps,
  IRecipientAddressFormValues,
} from './types';
import * as RecipientAddressFormStyles from './styles';
import {
  RECIPIENT_ADDRESS_FORM_FIELDS,
  REQUIRED_RECIPIENT_ADDRESS_FIELDS,
  REQUIRED_RECIPIENT_INFO_FORM_FIELDS,
} from './constants';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { RETRIEVE_COLLABORATOR, SEARCH_COLLABORATORS } from './queries';
import GenericAutocomplete from 'components/GenericAutocomplete';

const RecipientAddressForm = (props: RecipientAddressFormProps) => {
  const myStyles = RecipientAddressFormStyles;

  const { addressId } = props;

  const formValues = getRecipientAddressFormValues(props);

  const {
    control,
    register,
    handleSubmit,
    watch,
    resetField,
    setValue,
    formState: { errors, isDirty, isValid, dirtyFields },
  } = useForm<IRecipientAddressFormValues>({
    values: { ...formValues },
    mode: 'onSubmit',
  });

  const updatingRecipientAddress = RECIPIENT_ADDRESS_FORM_FIELDS.some(
    (addressField) => addressField in dirtyFields
  );

  const areAddressFieldsRequired = !!addressId || updatingRecipientAddress;

  const hasRequiredFormFieldValues = () => {
    const watched = watch();

    const cleanPhoneNumber =
      watched.phoneNumber?.replaceAll(/([^0-9+]|\s)/g, '') ?? '';
    const phoneNumberValid =
      !cleanPhoneNumber || matchIsValidTel(cleanPhoneNumber);
    const hasAddressFields = [
      ...REQUIRED_RECIPIENT_ADDRESS_FIELDS,
      'phoneNumber',
    ].every((field) => !!watched[field as keyof typeof watched]);
    const hasStateOrPrincipalRegion = !!(
      watched.state || watched.principalRegion
    );
    const addressValid =
      ![
        ...REQUIRED_RECIPIENT_ADDRESS_FIELDS,
        'state',
        'principalRegion',
        'phoneNumber',
      ].find((field) => !!dirtyFields[field as keyof typeof dirtyFields]) ||
      (hasAddressFields && hasStateOrPrincipalRegion);

    const hasRecipientFields = REQUIRED_RECIPIENT_INFO_FORM_FIELDS.every(
      (field) => !!watched[field as keyof typeof watched]
    );

    return hasRecipientFields && addressValid && phoneNumberValid;
  };

  const enableState = watch()?.country?.trimEnd() === UNITED_STATES;
  const resetPhoneNumber = () => resetField('phoneNumber');
  const fixPhoneValue = () => {
    const phoneNumber = watch('phoneNumber');
    setValue('phoneNumber', phoneNumber);
  };

  const dispatch = useDispatch();

  const {
    formId,
    emailInputId,
    firstNameInputId,
    lastNameInputId,
    streetAddress1InputId,
    streetAddress2InputId,
    cityInputId,
    stateInputId,
    principalRegionInputId,
    zipCodeInputId,
    countryInputId,
    phoneNumberInputId,
  } = getRecipientAddressAttributes(props.tagIdName);

  const [recipientCollaboratorId, setRecipientCollaboratorId] =
    React.useState<string>(
      (props.recipientType === DROP_RECIPIENT &&
        props.order?.dropRecipient?.collaboratorId) ||
        (props.recipientType === RECIPIENT &&
          props.order?.recipient?.collaboratorId) ||
        ''
    );

  const refetchAll = () => {
    props.refetchAll();
  };

  const onSuccessNotification = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
      })
    );
  };

  const onCollaboratorChange = (
    _e: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    const newCollaboratorId = value?.id || '';
    // eslint-disable-next-line security/detect-object-injection
    const {
      firstName = '',
      lastName = '',
      email = '',
    } = (data?.collaborators?.collaborators || []).find(
      (collaborator: Collaborator) => collaborator.id === newCollaboratorId
    );
    if (
      newCollaboratorId === recipientCollaboratorId ||
      !firstName ||
      !lastName
    )
      return;

    props.setRecipientChangeState({
      id: newCollaboratorId,
      email,
      name: `${firstName} ${lastName}`,
      open: true,
    });
  };

  const [updateOrder, { loading: loadingOrderUpdate }] = useSafeMutation(
    UPDATE_ORDER,
    {
      onCompleted() {
        onSuccessNotification();
        refetchAll();
        props.clearRecipientChangeState();
        resetPhoneNumber();
      },
    }
  );

  const [updateRecipientInfo, { loading: updateRecipientInfoLoading }] =
    useSafeMutation(UPDATE_RECIPIENT_INFO, {
      onCompleted(data) {
        onSuccessNotification();
        const { updateRecipient } = data;
        const isDropRecipient = props.recipientType === DROP_RECIPIENT;
        const isRecipient = props.recipientType === RECIPIENT;
        const updateOrderStatus =
          !addressId &&
          updateRecipient.addressId &&
          (props.order.orderType.name === OrderType.PROCUREMENT_TO_RECIPIENT
            ? OrderStatus.PENDING_APPROVAL
            : OrderStatus.IN_FULFILLMENT);

        updateOrder({
          variables: {
            id: props.orderId,
            ...(isDropRecipient && { dropRecipientId: updateRecipient.id }),
            ...(isRecipient && { recipientId: updateRecipient.id }),
            ...(updateOrderStatus && { status: updateOrderStatus }),
          },
        });
      },
    });

  const [createAddress, { loading: createAddressIsLoading }] = useSafeMutation(
    CREATE_ADDRESS,
    {
      onCompleted() {
        onSuccessNotification();
      },
    }
  );

  const [updateAddress, { loading: updateAddressIsLoading }] = useSafeMutation(
    UPDATE_ADDRESS,
    {
      onCompleted() {
        onSuccessNotification();
      },
    }
  );

  const [createRecipient, { loading: createRecipientLoading }] =
    useSafeMutation(CREATE_RECIPIENT, {
      onCompleted(data) {
        onSuccessNotification();
        const isDropRecipient = props.recipientType === DROP_RECIPIENT;
        const isRecipient = props.recipientType === RECIPIENT;
        const { createRecipient } = data;
        const updateOrderStatus =
          !addressId &&
          createRecipient.addressId &&
          (props.order.orderType.name === OrderType.PROCUREMENT_TO_RECIPIENT
            ? OrderStatus.PENDING_APPROVAL
            : OrderStatus.IN_FULFILLMENT);
        updateOrder({
          variables: {
            id: props.orderId,
            ...(isDropRecipient && { dropRecipientId: createRecipient.id }),
            ...(isRecipient && { recipientId: createRecipient.id }),
            ...(updateOrderStatus && { status: updateOrderStatus }),
          },
        });
      },
    });

  React.useEffect(() => {
    if (
      !props.recipientChangeState.changed ||
      props.recipientChangeState.origin !== props.recipientType
    )
      return;
    const { collaboratorId } = props.recipientChangeState;
    setRecipientCollaboratorId(collaboratorId);
  }, [props.recipientChangeState.changed]);

  React.useEffect(() => {
    resetPhoneNumber();
  }, [props.recipientId]);

  const isUpdateDisabled = () => !isDirty || !hasRequiredFormFieldValues();

  const onUpdateSubmit = async (formData: IRecipientAddressFormValues) => {
    props.setPhoneLoading(true);

    let addressData;

    if (updatingRecipientAddress) {
      const addressDataVariables = {
        streetAddress1: formData.streetAddress1,
        streetAddress2: formData.streetAddress2,
        city: formData.city,
        zipCode: formData.zipCode,
        ...(formData.country && {
          country: COUNTRY_MATCHER[formData.country],
        }),
        principalRegion: (!enableState && formData.principalRegion) || null,
        ...(enableState && formData.state
          ? { state: US_STATE_MATCHER[formData.state] }
          : { state: null }),
      };
      if (!addressId) {
        addressData = await createAddress({
          variables: addressDataVariables,
        });
      } else {
        addressData = await updateAddress({
          variables: { id: addressId, ...addressDataVariables },
        });
      }
    }

    const tempAddressId = addressId || addressData?.data.createAddress.id;

    const plainPhoneNumber = formData.phoneNumber?.replace(/([^0-9+]|\s)/g, '');
    if (props.recipientId && !props.recipientChangeState.changed) {
      await updateRecipientInfo({
        variables: {
          id: props.recipientId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          ...(tempAddressId && { addressId: tempAddressId }),
          email: formData.email,
          phoneNumber: plainPhoneNumber,
        },
      });
    } else {
      const { firstName, lastName } = retrievedCollaborator.collaborator;

      await createRecipient({
        variables: {
          ...(tempAddressId && { addressId: tempAddressId }),
          collaboratorId: recipientCollaboratorId,
          email: formData.email,
          firstName,
          lastName,
          phoneNumber: plainPhoneNumber,
        },
      });
    }
  };

  const getEqualWidthAddressRowSx = myStyles.getAddressFormInsideRowSx();
  const getCityAddressRowSx = myStyles.getAddressFormInsideRowSx(63);
  const getZipCodeAddressRowSx = myStyles.getAddressFormInsideRowSx(37);

  const [collaboratorFirstNameSearchTerm, setCollaboratorFirstNameSearchTerm] =
    React.useState('');
  const debouncedCollaboratorFirstNameSearchTerm = useDebouncedQuery(
    collaboratorFirstNameSearchTerm
  );

  const organizationId =
    props.order.organizationObject?.id ?? props.order?.organization?.id ?? '';

  const { data, loading: searchCollaboratorsLoading } = useSafeQuery(
    SEARCH_COLLABORATORS,
    {
      variables: {
        organizationId,
        limit: 100,
        offset: 0,
        orderAsc: 'firstName',
        ...(debouncedCollaboratorFirstNameSearchTerm
          ? { firstName: debouncedCollaboratorFirstNameSearchTerm }
          : {}),
      },
      skip: !organizationId,
    }
  );

  const { data: retrievedCollaborator, loading: collaboratorLoading } =
    useSafeQuery(RETRIEVE_COLLABORATOR, {
      variables: {
        id: recipientCollaboratorId,
      },
      skip: !recipientCollaboratorId,
    });

  const collaboratorOptions: Collaborator[] =
    data?.collaborators?.collaborators || [];

  const selectedItem = retrievedCollaborator
    ? retrievedCollaborator?.collaborator
    : null;

  const mutationLoading =
    loadingOrderUpdate ||
    createRecipientLoading ||
    updateRecipientInfoLoading ||
    createAddressIsLoading ||
    updateAddressIsLoading;
  React.useEffect(() => {
    if (!retrievedCollaborator || collaboratorLoading) return;
    const { firstName, lastName, email } = retrievedCollaborator.collaborator;
    if (props.recipientChangeState.changed) {
      setValue('email', email);
      setValue('firstName', firstName);
      setValue('lastName', lastName);
    }
    resetPhoneNumber();
  }, [recipientCollaboratorId, collaboratorLoading]);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Typography sx={myStyles.AddressFormTitleSx}>
        {props.formTitle}
      </Typography>
      <Stack direction="row" sx={{ mb: 2, mt: 1, position: 'relative' }}>
        <FormControl sx={{ width: '490px', mb: '4px' }}>
          <GenericAutocomplete
            selectedItem={selectedItem}
            placeholder={'Search recipient by first name'}
            onChange={onCollaboratorChange}
            onInputChange={(_e, value) =>
              setCollaboratorFirstNameSearchTerm(value)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select recipient"
                placeholder="Search by employee first name"
                sx={myStyles.AutoCompeteSearchTextField}
              />
            )}
            // @ts-ignore
            getOptionLabel={(option: Collaborator) =>
              `${option.firstName} ${option.lastName}`
            }
            options={collaboratorOptions}
            loading={searchCollaboratorsLoading}
          />
        </FormControl>
      </Stack>
      <form onSubmit={handleSubmit(onUpdateSubmit)} data-testid={formId}>
        <Stack
          direction="column"
          spacing="20px"
          sx={myStyles.getAddressFormRowSx(mutationLoading)}
        >
          <Stack direction="row" spacing={0} justifyContent="space-between">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  id={emailInputId}
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  aria-required
                  required
                  {...field}
                  sx={getEqualWidthAddressRowSx()}
                  {...register('email', { required: true })}
                  error={!!errors.email}
                />
              )}
            />
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  id={firstNameInputId}
                  label="First name"
                  placeholder="First name"
                  variant="outlined"
                  aria-required
                  required
                  {...field}
                  {...register('firstName', { required: true })}
                  error={!!errors.firstName}
                  sx={getEqualWidthAddressRowSx(false)}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  id={lastNameInputId}
                  label="Last name"
                  placeholder="Last name"
                  variant="outlined"
                  aria-required
                  required
                  {...field}
                  {...register('lastName', { required: true })}
                  error={!!errors.lastName}
                  sx={getEqualWidthAddressRowSx(false)}
                />
              )}
            />
          </Stack>
          <Stack direction="row" spacing={0} justifyContent="space-between">
            <Controller
              name="streetAddress1"
              control={control}
              render={({ field }) => (
                <TextField
                  id={streetAddress1InputId}
                  label="Street Address 1"
                  placeholder="Street Address 1"
                  variant="outlined"
                  aria-required={areAddressFieldsRequired}
                  required={areAddressFieldsRequired}
                  {...field}
                  {...register('streetAddress1', {
                    required: areAddressFieldsRequired,
                  })}
                  error={!!errors.streetAddress1}
                  sx={getEqualWidthAddressRowSx()}
                />
              )}
            />
            <Controller
              name="streetAddress2"
              control={control}
              render={({ field }) => (
                <TextField
                  id={streetAddress2InputId}
                  label="Street Address 2"
                  placeholder="Street Address 2"
                  variant="outlined"
                  {...field}
                  {...register('streetAddress2', { required: false })}
                  error={!!errors.streetAddress2}
                  sx={getEqualWidthAddressRowSx(false)}
                />
              )}
            />
          </Stack>
          <Stack direction="row" spacing={0} justifyContent="space-between">
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  id={cityInputId}
                  label="City"
                  placeholder="City"
                  aria-required={areAddressFieldsRequired}
                  required={areAddressFieldsRequired}
                  variant="outlined"
                  {...field}
                  {...register('city', { required: areAddressFieldsRequired })}
                  error={!!errors.city}
                  sx={getCityAddressRowSx()}
                />
              )}
            />
            {enableState ? (
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <TextField
                    id={stateInputId}
                    label="State"
                    aria-required={enableState && areAddressFieldsRequired}
                    required={enableState && areAddressFieldsRequired}
                    select
                    sx={getCityAddressRowSx(false)}
                    {...field}
                    {...register('state', {
                      required: enableState && areAddressFieldsRequired,
                    })}
                  >
                    {StateOptions.map((stateOption) => (
                      <MenuItem key={stateOption} value={stateOption}>
                        {stateOption}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            ) : (
              <Controller
                name="principalRegion"
                control={control}
                render={({ field }) => (
                  <TextField
                    id={principalRegionInputId}
                    label="Principal region"
                    aria-required={!enableState && areAddressFieldsRequired}
                    required={!enableState && areAddressFieldsRequired}
                    sx={getCityAddressRowSx(false)}
                    {...field}
                    {...register('principalRegion', {
                      required: !enableState && areAddressFieldsRequired,
                    })}
                  />
                )}
              />
            )}
          </Stack>
          <Stack direction="row" spacing={0} justifyContent="space-between">
            <Controller
              name="zipCode"
              control={control}
              render={({ field }) => (
                <TextField
                  id={zipCodeInputId}
                  label="Postal Code"
                  placeholder="zipCode"
                  variant="outlined"
                  aria-required={areAddressFieldsRequired}
                  required={areAddressFieldsRequired}
                  {...field}
                  {...register('zipCode', {
                    required: areAddressFieldsRequired,
                  })}
                  error={!!errors.zipCode}
                  sx={getZipCodeAddressRowSx()}
                />
              )}
            />
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <TextField
                  id={countryInputId}
                  label="Country"
                  aria-required
                  required
                  select
                  sx={getZipCodeAddressRowSx(false)}
                  {...field}
                  {...register('country', {
                    required: areAddressFieldsRequired,
                  })}
                  {...register('country')}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'center',
                        horizontal: 'right',
                      },
                    },
                  }}
                >
                  {CountryOptions.map((countryOption) => (
                    <MenuItem key={countryOption} value={countryOption}>
                      {countryOption}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Stack>
          <Controller
            name="phoneNumber"
            //@ts-ignore
            control={control}
            render={({ field }) => (
              <MuiTelInput
                id={phoneNumberInputId}
                label="Phone number"
                aria-required={areAddressFieldsRequired}
                defaultCountry="US"
                sx={myStyles.getAddressFormPhoneSx(
                  mutationLoading || props.phoneLoading
                )}
                {...field}
                {...register('phoneNumber', {
                  required: false,
                })}
                onChange={(value, info) => field.onChange(value, info)}
                error={!!errors.phoneNumber}
              />
            )}
          />
          {mutationLoading ? (
            <CircularProgress
              sx={{
                bottom: '75px',
                left: '60px',
                position: 'absolute',
                width: '56px',
                height: '56px',
                opacity: 0.6,
              }}
            />
          ) : null}
          <LoadingButton
            variant="contained"
            type="submit"
            value="submit"
            disabled={isUpdateDisabled()}
            loading={mutationLoading}
            color="primary"
            loadingIndicator={<CircularProgress color="primary" size={20} />}
            sx={myStyles.AddressFormUpdateButtonSx}
          >
            <Typography>Update</Typography>
          </LoadingButton>
        </Stack>
      </form>
    </div>
  );
};

export default RecipientAddressForm;
