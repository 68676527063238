import React from 'react';
import { FilterStateKey } from 'store/shared/types';
import UseGetFilterDrawerConfig from 'components/complex_filter/ComplexFilterDrawer/types';
import { useGetFilterDrawerConfig as useGetFilterDrawerConfigBase } from 'components/complex_filter/hooks';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';
import { OMDashFilterKeys } from 'pages/OrderManagement/filters/shared/constants';

export const useGetFilterDrawerConfig = (
  filterSubTree: FilterStateKey
): UseGetFilterDrawerConfig | undefined => {
  const baseFilterDrawerConfig = useGetFilterDrawerConfigBase(filterSubTree);

  if (!baseFilterDrawerConfig) return undefined;

  const { isFlagOn } = useFeatureFlagService(
    FeatureFlagNames.ASSIGN_MULTIPLE_ORDER_MANAGERS_RELEASE
  );
  const assignsMultipleOMs = isFlagOn();

  React.useEffect(() => {
    if (assignsMultipleOMs) {
      baseFilterDrawerConfig.filterModalConfig.blueprint =
        baseFilterDrawerConfig.filterModalConfig.blueprint.map(
          (layoutBlock) => {
            if (layoutBlock.section === 'Allwhere Info') {
              layoutBlock.layout = layoutBlock.layout
                .slice()
                .filter(
                  (subSection) =>
                    subSection.filterKey !== OMDashFilterKeys.ORDER_MANAGER
                );
            }
            return layoutBlock;
          }
        );
    }
  }, [assignsMultipleOMs]);

  React.useEffect(() => {
    if (!assignsMultipleOMs) {
      baseFilterDrawerConfig.filterModalConfig.blueprint =
        baseFilterDrawerConfig.filterModalConfig.blueprint
          .slice()
          .filter((layoutBlock) => layoutBlock.section !== 'Assignee');
    }
  }, [assignsMultipleOMs]);

  return {
    ...baseFilterDrawerConfig,
  } as UseGetFilterDrawerConfig;
};
