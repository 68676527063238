import { gql } from '@apollo/client';

export const SEARCH_ORDERS_BY_ORDER_NUMBER = gql`
  query searchOrders($orderNumber: String) {
    searchOrders(searchOrdersInput: { orderNumber: $orderNumber }) {
      orders {
        id
        orderNumber
      }
    }
  }
`;

export const GET_COLLABORATORS_BY_NAME_OR_EMAIL = gql`
  query collaborators(
    $firstOrLastName: String
    $email: String
    $limit: Int = 20
  ) {
    collaborators(
      collaboratorSearchInput: {
        firstOrLastName: $firstOrLastName
        email: $email
        limit: $limit
      }
    ) {
      collaborators {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_COLLABORATORS_BY_FUZZY_EMAIL = gql`
  query collaborators($collaboratorFullTextSearch: String, $limit: Int = 20) {
    collaborators(
      collaboratorSearchInput: {
        collaboratorFullTextSearch: $collaboratorFullTextSearch
        limit: $limit
      }
    ) {
      collaborators {
        id
        firstName
        lastName
        email
        personalEmail
        workEmail
      }
    }
  }
`;
