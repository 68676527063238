import React from 'react';
import { Card, Divider, Grid } from '@mui/material';
import { SummaryCardProps } from './types';
import {
  calculateSalesTax,
  calculateServiceFee,
  getTotalPrice,
} from '../../utils';
import { ZERO_ESTIMATED_TAX } from '../../constants';
import { formatNumberToDecimalString } from 'services/format';
import { ShippingType } from 'global-constants';
import { SummaryCardSx, InfoIconSx } from './styles';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SummaryCard = (props: SummaryCardProps) => {
  const subtotal = getTotalPrice(
    props.cart,
    props.isBuyAndHoldOrder ? 1 : props.numberOfSelectedCollaborators
  );
  const getTotalPriceOfAllProductVariants =
    formatNumberToDecimalString(subtotal);

  const isRushOrder = props.shippingType === ShippingType.US_EXPEDITED;
  const serviceFee = calculateServiceFee(subtotal, isRushOrder);

  const formattedServiceFee = formatNumberToDecimalString(serviceFee);

  const estimatedTax = calculateSalesTax(subtotal, serviceFee);

  const formattedEstimatedTax = formatNumberToDecimalString(
    calculateSalesTax(subtotal, serviceFee)
  );

  const formattedTotalLeftRange = formatNumberToDecimalString(
    subtotal + serviceFee + ZERO_ESTIMATED_TAX
  );
  const formattedTotalRightRange = formatNumberToDecimalString(
    subtotal + serviceFee + estimatedTax
  );

  return (
    <Card sx={SummaryCardSx}>
      <Grid container>
        <Grid item xs={7}>
          Subtotal (est)
        </Grid>
        <Grid item xs={5} textAlign="end">
          ${getTotalPriceOfAllProductVariants}
        </Grid>
        <Grid item xs={7}>
          Service fee (est)
        </Grid>
        <Grid item xs={5} textAlign="end">
          ${formattedServiceFee}
        </Grid>
        <Grid item xs={7}>
          Shipping (est)
        </Grid>
        <Grid item xs={5} textAlign="end">
          Pending
        </Grid>
        <Grid item xs={4}>
          Tax (est)
        </Grid>
        <Grid item xs={8} textAlign="end">
          $0.00 - ${formattedEstimatedTax}
        </Grid>
        <Grid item xs={12} py="16px">
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <strong>Total (est)</strong>
        </Grid>
        <Grid item xs={8} textAlign="end">
          <strong>
            ${formattedTotalLeftRange} - ${formattedTotalRightRange}
          </strong>
        </Grid>
        <Grid item xs={12}>
          <span>excl. </span>
          <span>shipping</span>
          <Tooltip
            title="Allwhere will select the most expedient and cost effective vendor for your purchase, and directly pass through any additional shipping costs. In order to meet your requested SLA, we cannot verify shipping cost prior to placing the order."
            arrow
          >
            <InfoOutlinedIcon fontSize="small" sx={InfoIconSx} />
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SummaryCard;
