export const ContainerSx = {
  boxSizing: 'border-box',
  margin: 'auto',
  position: 'relative',
  px: '36px !important',
  padding: '60px 100px',
};

export const ContainerStackSx = {
  flexWrap: 'nowrap',
};

export const GoToOrdersButtonSx = {
  height: '36px',
  pl: 0,
  pr: 0,
};

export const GoToOrdersTypographySx = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  top: '7px',
  position: 'absolute',
};

export const OrderSubmittedTitleSx = {
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: 'normal',
};

export const OrderSubmittedSubtitleSx = {
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
};

export const PaperSx = {
  '& .MuiDialog-paper': {
    borderRadius: '22px !important',
    width: '100%',
    maxWidth: '700px',
  },
};

export const ImageSx = {
  transform: 'scaleX(-1)',
  height: '190px',
  width: '204px',
  userSelect: 'none',
};

export const CloseButtonSx = {
  bgcolor: 'theme.palette.colors.gray75',
  position: 'absolute',
  right: '18px',
  top: '18px',
};
