import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Typeahead from 'components/complex_filter/fields/Typeahead';
import DropDown from 'components/complex_filter/fields/DropDown';
import DateTime from 'components/complex_filter/fields/DateTime';
import FullText from 'components/complex_filter/fields/FullText';
import CheckBoxField from 'components/complex_filter/fields/CheckBoxField';
import { getKebabCase } from 'services/format';
import {
  FilterLayoutSection,
  FilterLayoutItem,
} from 'components/complex_filter/types';
import { FILTER_FIELD_TYPES } from 'store/constants';
import {
  getTypeaheadProps,
  getDropDownProps,
  getDateTimeProps,
  getFullTextProps,
  getCheckBoxFieldProps,
} from 'components/complex_filter/utils';
import FilterDrawerContentProps from './types';
import * as FilterDrawerContentStyles from './styles';

const FilterDrawerContent = (props: FilterDrawerContentProps) => {
  const styles = FilterDrawerContentStyles;
  const isFiltered = useSelector(props.selectIsFiltered(props.filterSubTree));

  const getSectionSelector = (sectionName: string) =>
    getKebabCase([
      props.filterSubTree,
      'filter',
      sectionName,
      'popover section',
    ]);

  return (
    <DialogContent sx={styles.getFilterDrawerContentSx(isFiltered)}>
      <Grid container sx={styles.DialogContentContainerSx}>
        <Grid item container xs={12}>
          {props.blueprint.map((layoutSection: FilterLayoutSection) => (
            <Grid
              item
              container
              xs={12}
              key={`${props.filterSubTree}-${layoutSection.section}-section-grid-key`}
            >
              <Grid
                item
                container
                xs={12}
                sx={styles.DialogSectionGridSx}
                id={getSectionSelector(`${layoutSection.section} id`)}
              >
                <Typography sx={styles.DialogLayoutTypeSx}>
                  {layoutSection.section}
                </Typography>
              </Grid>
              <Grid item container xs={12} spacing="12px">
                {layoutSection.layout.map((layoutItem: FilterLayoutItem) => (
                  <Grid
                    item
                    xs={layoutItem.span === 'full' ? 12 : 6}
                    key={`${props.filterSubTree}-${layoutSection.section}-${layoutItem.filterKey}-${layoutItem.fieldType}-grid-key`}
                  >
                    <Box sx={styles.GridItemBoxSx}>
                      {layoutItem.fieldType === FILTER_FIELD_TYPES.TYPEAHEAD ? (
                        <Typeahead {...getTypeaheadProps(layoutItem, props)} />
                      ) : (
                        <></>
                      )}
                      {layoutItem.fieldType === FILTER_FIELD_TYPES.DROPDOWN ? (
                        <DropDown {...getDropDownProps(layoutItem, props)} />
                      ) : (
                        <></>
                      )}
                      {layoutItem.fieldType === FILTER_FIELD_TYPES.DATETIME ? (
                        <DateTime {...getDateTimeProps(layoutItem, props)} />
                      ) : (
                        <></>
                      )}
                      {layoutItem.fieldType === FILTER_FIELD_TYPES.FULL_TEXT ? (
                        <FullText {...getFullTextProps(layoutItem, props)} />
                      ) : (
                        <></>
                      )}
                      {layoutItem.fieldType === FILTER_FIELD_TYPES.CHECKBOX ? (
                        <CheckBoxField
                          {...getCheckBoxFieldProps(layoutItem, props)}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default FilterDrawerContent;
