import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { RetrievalDestination } from 'pages/Retrieval/RetrievalService/types';
import {
  COUNTRY_CODE_MAPPER,
  CountryEnum,
  StateOptions,
} from 'global-constants';
import { CustomAddressProps, IDropLocationAddressFormValues } from './types';
import { AddressTextFieldBaseSx, TextFieldSx } from './styles';
import { RecipientAddress } from 'types';
import { getCountry } from './utils';
import { CountryCode } from 'libphonenumber-js';

const CustomAddress = (props: CustomAddressProps) => {
  const country = getCountry(props.retrievalFlow.retrievalDestination?.name);
  const {
    control,
    register,
    watch,
    formState: { isValid, isValidating },
  } = useForm<IDropLocationAddressFormValues>({
    mode: 'onChange',
    defaultValues: {
      country,
    },
  });

  const enableState = country === CountryEnum.UNITED_STATES;

  React.useEffect(() => {
    if (isValid) {
      props.retrievalFlow.assignRetrievalDestination({
        ...(props.retrievalFlow.retrievalDestination as RetrievalDestination),
        customAddress: {
          ...(watch() as RecipientAddress),
        },
      });
    }
  }, [isValid, isValidating]);

  return (
    <div>
      <form>
        <Grid container rowSpacing={1.25} columnSpacing={1.25} maxWidth="600px">
          <Grid item xs={6}>
            <FormControl sx={{ width: '100%' }}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="firstName"
                    label="First name"
                    aria-required
                    required
                    {...field}
                    {...register('firstName', {
                      required: true,
                    })}
                    sx={TextFieldSx}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: '100%' }}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="lastName"
                    label="Last name"
                    aria-required
                    required
                    {...field}
                    {...register('lastName', {
                      required: true,
                    })}
                    sx={TextFieldSx}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%' }}>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="companyName"
                    label="Company"
                    {...field}
                    {...register('companyName')}
                    sx={TextFieldSx}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: '100%' }}>
              <Controller
                name="streetAddress1"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="street-line-one"
                    label="Street address 1"
                    aria-required
                    required
                    {...field}
                    {...register('streetAddress1', {
                      required: true,
                    })}
                    sx={TextFieldSx}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: '100%' }}>
              <Controller
                name="streetAddress2"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="street-line-two"
                    label="Street address 2"
                    {...field}
                    {...register('streetAddress2', {
                      required: false,
                    })}
                    sx={TextFieldSx}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={7.5}>
            <FormControl sx={{ width: '100%' }}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="city"
                    label="City"
                    aria-required
                    required
                    {...field}
                    {...register('city', {
                      required: true,
                    })}
                    sx={TextFieldSx}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4.5}>
            <FormControl sx={{ width: '100%' }}>
              {enableState ? (
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="state"
                      label="State"
                      aria-required
                      required={enableState}
                      select
                      {...field}
                      {...register('state', {
                        required: enableState,
                      })}
                      sx={TextFieldSx}
                    >
                      {StateOptions.map((stateOption) => (
                        <MenuItem key={stateOption} value={stateOption}>
                          {stateOption}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              ) : (
                <Controller
                  name="principalRegion"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="principalRegion"
                      label="Region"
                      aria-required
                      required={!enableState}
                      data-testid="principal-region-test-id"
                      sx={TextFieldSx}
                      {...field}
                      {...register('principalRegion', {
                        required: !enableState,
                      })}
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4.5}>
            <FormControl sx={{ width: '100%' }}>
              <Controller
                name="zipCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="zip-code"
                    label="Postal code"
                    aria-required
                    required
                    {...field}
                    {...register('zipCode', {
                      required: true,
                    })}
                    sx={TextFieldSx}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={7.5}>
            <FormControl sx={{ width: '100%' }}>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="country"
                    label="Country"
                    disabled
                    {...field}
                    {...register('country')}
                    sx={TextFieldSx}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%', marginTop: '0.35rem' }}>
              <Controller
                name="phoneNumber"
                //@ts-ignore
                control={control}
                rules={{ validate: matchIsValidTel }}
                render={({ field }) => (
                  <MuiTelInput
                    id="phoneNumber"
                    label="Phone number"
                    aria-required
                    required
                    defaultCountry={
                      (COUNTRY_CODE_MAPPER[country] || 'US') as CountryCode
                    }
                    sx={AddressTextFieldBaseSx}
                    {...field}
                    {...register('phoneNumber', {
                      required: true,
                    })}
                    onChange={(value, info) => field.onChange(value, info)}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CustomAddress;
