import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { CTAProps } from './types';
import { useHandleOnClick } from './hooks';
import * as styles from './styles';

const CTA = (props: CTAProps) => {
  const handleOnClick = useHandleOnClick();
  const { ContainerSx } = props;
  return (
    <Stack sx={{ ...styles.CTAContainerSx, ...ContainerSx }}>
      <Stack sx={styles.CTACupSx}>
        <img src={props.homepageCTACupSrc} alt="CTA Cup" />
      </Stack>
      <Stack sx={styles.CTATitleSx}>{props.homepageCTATitle}</Stack>
      <Stack sx={styles.CTASubtitleSx}>{props.homepageCTASubtitle}</Stack>
      <Stack sx={styles.CTAButtonContainerSx}>
        {props.buttons.map((button, index) => {
          let icon = null;
          const PrimaryIcon = button.icon;
          const SecondaryIcon = button.customIcon;
          if (PrimaryIcon) {
            icon = <PrimaryIcon />;
          }
          if (SecondaryIcon) {
            icon = SecondaryIcon({ width: 18, fill: '#000', stroke: '#000' });
          }

          return (
            <Stack
              key={index}
              sx={styles.CTAButtonSx}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                sx={styles.CTAButtonSx}
                variant="outlined"
                startIcon={icon}
                onClick={handleOnClick(button)}
              >
                {button.copy}
              </Button>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default CTA;
