import { useDispatch } from 'react-redux';
import { TextField, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ApolloError, useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { Button as MyButton, Spacer } from 'components';
import { AddNewPersonFormInputs, AddNewPersonModalProps } from './types';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import {
  constructCreateCollaboratorPayload,
  constructCreateCollaboratorErrorMessage,
} from './utils';
import { CREATE_COLLABORATOR } from './mutations';
import {
  AddNewPersonCopy,
  ADD_NEW_PERSON_MODAL_TESTID,
  ADD_NEW_PERSON_FORM_TESTID,
  EMAIL_FIELD_VALIDATION_REGEX,
} from './constants';
import {
  NewEmployeeDialogSx,
  NewEmployeeDialogPaperSx,
  NewEmployeeMainStackSx,
  NewEmployeeMainTitleSx,
  NewEmployeeFormStackSx,
  NewEmployeeInnerFormStackSx,
  NewEmployeeTextInputBaseSx,
  NewEmployeeButtonBaseSx,
  NewEmployeeSubmitButtonSx,
} from './styles';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';
import GALoadingButton from '../buttons/google_analytics/GALoadingButton';

const AddNewPersonModal = (props: AddNewPersonModalProps) => {
  const userOrganization = useGetUserOrganization();
  const dispatch = useDispatch();

  const {
    isFlagOn: EmployeeEmailOptionsEnabled,
    isFlagOff: EmployeeEmailOptionsDisabled,
  } = useFeatureFlagService(FeatureFlagNames.EMPLOYEE_EMAIL_OPTIONS, {
    debugFlags: true,
  });

  const {
    reset,
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AddNewPersonFormInputs>();

  const { workEmail, personalEmail, email, ...restOfForm } = watch();

  const isNewPersonSubmitDisabled = () =>
    Object.values(restOfForm).some((watchItem) => !watchItem) ||
    (!workEmail && !personalEmail && !email);

  const [mutate, { loading: isLoading }] = useMutation(CREATE_COLLABORATOR);

  const onSubmit = (formData: AddNewPersonFormInputs) => {
    const payload = constructCreateCollaboratorPayload(
      formData.firstName,
      formData.lastName,
      formData.personalEmail || formData.workEmail || formData.email,
      userOrganization?.id as string,
      formData.workEmail || undefined,
      formData.personalEmail || undefined
    );

    mutate({
      variables: payload,
      onCompleted() {
        props.handleClose();
        reset();
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
          })
        );
      },
      onError(apolloErr: ApolloError) {
        const errorReason = constructCreateCollaboratorErrorMessage(
          apolloErr.message
        );

        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: errorReason,
          })
        );
      },
    });
  };

  return (
    <Dialog
      open={props.open}
      sx={NewEmployeeDialogSx}
      onClose={props.handleClose}
      data-testid={ADD_NEW_PERSON_MODAL_TESTID}
      PaperProps={{ sx: { ...NewEmployeeDialogPaperSx } }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid={ADD_NEW_PERSON_FORM_TESTID}
      >
        <Stack
          direction="column"
          spacing={0}
          justifyContent="space-between"
          sx={NewEmployeeMainStackSx}
        >
          <Stack direction="column" spacing={0}>
            <Typography variant="h5" sx={NewEmployeeMainTitleSx}>
              {AddNewPersonCopy.TITLE}
            </Typography>
            <Typography>{AddNewPersonCopy.SUBTITLE}</Typography>
            <Stack
              direction="row"
              spacing="20px"
              justifyContent="space-between"
              sx={NewEmployeeFormStackSx}
            >
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={NewEmployeeInnerFormStackSx}
              >
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="first-name"
                      label="First name"
                      aria-required
                      required
                      variant="outlined"
                      {...field}
                      sx={NewEmployeeTextInputBaseSx}
                      {...register('firstName', { required: true })}
                      error={!!errors.firstName}
                    />
                  )}
                />
                {!!errors.firstName && (
                  <Typography variant="body2" color="red">
                    Please enter a first name
                  </Typography>
                )}
                <Spacer />
                {EmployeeEmailOptionsEnabled() && (
                  <>
                    <Controller
                      name="workEmail"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          id="workEmail"
                          label="Work email"
                          aria-required
                          required={!personalEmail}
                          variant="outlined"
                          {...field}
                          sx={NewEmployeeTextInputBaseSx}
                          {...register('workEmail', {
                            pattern: {
                              value: EMAIL_FIELD_VALIDATION_REGEX,
                              message: 'Please enter a valid email',
                            },
                          })}
                          error={!!errors.workEmail}
                        />
                      )}
                    />
                    {!!errors.workEmail && (
                      <Typography variant="body2" color="red">
                        Please enter a valid email
                      </Typography>
                    )}
                  </>
                )}
                {EmployeeEmailOptionsDisabled() && (
                  <>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          id="email"
                          label="Email"
                          aria-required
                          required
                          variant="outlined"
                          {...field}
                          sx={NewEmployeeTextInputBaseSx}
                          {...register('email', {
                            required: true,
                            pattern: {
                              value: EMAIL_FIELD_VALIDATION_REGEX,
                              message: 'Please enter a valid email',
                            },
                          })}
                          error={!!errors.email}
                        />
                      )}
                    />
                    {!!errors.email && (
                      <Typography variant="body2" color="red">
                        Please enter a valid email
                      </Typography>
                    )}
                  </>
                )}
              </Stack>
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={NewEmployeeInnerFormStackSx}
              >
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="last-name"
                      label="Last name"
                      aria-required
                      required
                      variant="outlined"
                      {...field}
                      sx={NewEmployeeTextInputBaseSx}
                      {...register('lastName', { required: true })}
                      error={!!errors.lastName}
                    />
                  )}
                />
                {!!errors.lastName && (
                  <Typography variant="body2" color="red">
                    Please enter a last name
                  </Typography>
                )}
                {EmployeeEmailOptionsEnabled() && (
                  <>
                    <Spacer />
                    <Controller
                      name="personalEmail"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          id="personalEmail"
                          label="Personal email"
                          aria-required
                          variant="outlined"
                          required={!workEmail}
                          {...field}
                          sx={NewEmployeeTextInputBaseSx}
                          {...register('personalEmail', {
                            pattern: {
                              value: EMAIL_FIELD_VALIDATION_REGEX,
                              message: 'Please enter a valid email',
                            },
                          })}
                          error={!!errors.workEmail}
                        />
                      )}
                    />
                    {!!errors.workEmail && (
                      <Typography variant="body2" color="red">
                        Please enter a valid email
                      </Typography>
                    )}
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={0}
            justifyContent="flex-end"
            sx={{ width: '100%' }}
          >
            <MyButton
              gaContext={{
                textCopy: 'Cancel',
                navigates_to: 'N/A',
                purpose: 'Cancels New Employee Modal',
              }}
              color="secondary"
              onClick={props.handleClose}
              sx={NewEmployeeButtonBaseSx}
            >
              <Typography variant="button">CANCEL</Typography>
            </MyButton>
            <GALoadingButton
              gaContext={{
                textCopy: 'Save',
                navigates_to: 'N/A',
                purpose: 'Adds New Employee',
              }}
              disabled={isNewPersonSubmitDisabled()}
              variant="contained"
              type="submit"
              value="submit"
              loading={isLoading}
              color="primary"
              sx={NewEmployeeSubmitButtonSx}
              loadingIndicator={<CircularProgress color="primary" size={20} />}
            >
              <Typography variant="button">SAVE</Typography>
            </GALoadingButton>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default AddNewPersonModal;
