/* eslint-disable sort-exports/sort-exports */
import useFeatureFlagConfig from 'hooks/useFeatureFlagConfig';

const FeatureFlagConfigProvider = (props: any) => {
  console.warn(
    'DO NOT REMOVE {FeatureFlagConfigProvider}! NEEDS TO BE TRIGGERED W/N THE APP HIERARCHY TO SET FEATURE-FLAGGING UP.'
  );
  useFeatureFlagConfig();

  return null;
};

export default FeatureFlagConfigProvider;
