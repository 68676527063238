import React from 'react';
import { useApolloClient, ApolloError } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {
  Asset,
  Collaborator,
  HomeAddress,
  SelectableRecipientAddress,
  WorkAddress,
} from 'types';
import Button from 'components/Button';
import {
  ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_COPY,
  ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_COPY,
  ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_URL,
  ALLWHERE_RECIPIENT_ADDRESS_SELECTION_COPY,
  ShippingType as ObsoleteShippingType,
  OrderType as OrderTypeNames,
} from 'global-constants';
import { GlobalModals } from 'store/constants';
import { modalSlice, notificationSlice } from 'store/slices';
import { useSafeMutation } from 'hooks/useSafeMutation';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import EmployeeSearch from 'components/EmployeeSearch';
import PremiumShippingRadioBox from 'components/PremiumShippingRadioBox';
import { GET_ASSETS } from 'pages/Assets/components/DevicesDatagrid/queries';
import AssignDeviceInfo from './components/AssignDeviceInfo';
import * as AssignFlowStyles from './styles';
import {
  ASSIGN_DEVICE_COST_COPY,
  DEFAULT_DFI_INSURANCE_OPTION_VALUE,
  DFI_INSURANCE_OPTIONS,
} from './components/AssignDeviceInfo/constants';
import { CREATE_DFI_ORDER } from './mutations';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import usePremiumShippingPerOrderType from 'hooks/usePremiumShippingPerOrderType';
import {
  PREMIUM_SHIPPING_TYPE_NAMES,
  DEFAULT_SHIPPING_TYPE,
} from './constants';
import { getPremiumShippingDisplayInfo } from './utils';
import Dropdown from 'components/Dropdown';
import {
  WorkOutline,
  HomeOutlined,
  SvgIconComponent,
} from '@mui/icons-material';
import { DropdownItem } from 'components/Dropdown/types';
import { RETRIEVE_COLLABORATOR_ADDRESSES } from './queries';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { SendPaperPlaneOutlined } from 'components/Icons/SendPaperPlaneSVGIcon';
import AddRecipientAddressModal from 'components/AddRecipientAddressModal';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { getAddressIcon, getAddressLabel, isAddressIncomplete } from 'services';
import GALoadingButton from 'components/buttons/google_analytics/GALoadingButton';
import { NotificationMessages } from '../../components/GlobalToastNotification/constants';

const AssignFlow = () => {
  const styles = AssignFlowStyles;
  const dispatch = useDispatch();
  //Navigation
  const navigate = useNavigate();

  // Apollo client
  const client = useApolloClient();

  //Device selected from assets dashboard
  const location = useLocation();
  const device = location.state.device as Asset;

  //Purchaser & organization
  const purchaser = useGetGlobalPurchaser();
  const organization = useGetUserOrganization();

  //Form state
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<Collaborator | null>(null);

  const [dfiInsurance, setDFIInsurance] = React.useState<string>(
    DEFAULT_DFI_INSURANCE_OPTION_VALUE
  );

  const [obsoleteShippingType, setObsoleteShippingType] =
    React.useState<ObsoleteShippingType>(ObsoleteShippingType.STANDARD);

  // data-driven shippingTypeId
  const [shippingTypeId, setShippingTypeId] = React.useState<string>('');

  const [recipientSelectedEmail, setRecipientSelectedEmail] =
    React.useState<string>('');

  const [recipientEmailItems, setRecipientEmailItems] = React.useState<
    DropdownItem<string>[]
  >([]);

  const defaultAddressOption: DropdownItem<SelectableRecipientAddress> = {
    icon: SendPaperPlaneOutlined as SvgIconComponent,
    label: 'Request address confirmation',
    value: {} as SelectableRecipientAddress,
  };

  const [recipientSelectedAddress, setRecipientSelectedAddress] =
    React.useState<SelectableRecipientAddress>(defaultAddressOption.value);

  const [recipientAddressItems, setRecipientAddressItems] = React.useState<
    DropdownItem<SelectableRecipientAddress>[]
  >([defaultAddressOption]);

  const [
    recipientAddressFormDataPrepopulate,
    setRecipientAddressFormDataPrepopulate,
  ] = React.useState<SelectableRecipientAddress>(defaultAddressOption.value);

  const [openAddressModal, setOpenAddressModal] =
    React.useState<boolean>(false);

  // TODO: Temporary feature flag implementations; remove when feature flags are removed
  const {
    isFlagOff: isSelectRecipientEmailDisabled,
    isFlagOn: isSelectRecipientEmailEnabled,
  } = useFeatureFlagService(FeatureFlagNames.SELECT_RECIPIENT_EMAIL);

  const {
    isFlagOff: isSelectRecipientAddressDisabled,
    isFlagOn: isSelectRecipientAddressEnabled,
  } = useFeatureFlagService(FeatureFlagNames.SELECT_RECIPIENT_ADDRESS);

  //Event handlers
  const handleRadioButtonChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setShippingTypeId(value);
  };

  const handleInsuranceSelectOnChange = (event: SelectChangeEvent<string>) => {
    setDFIInsurance(event.target.value);
  };

  const handleOnCancelClick = () => {
    navigate(-1);
  };

  const {
    shippingInfoTable,
    loadingPremiumShippingInfo,
    hasShippingInfo,
    availableShippingTypeNames,
  } = usePremiumShippingPerOrderType(OrderTypeNames.DEPLOYMENT, {
    skip: false,
    debugConsole: false,
  });

  const initShippingTypeId = () => {
    if (!hasShippingInfo) return;
    const defaultShippingTypeId =
      shippingInfoTable[DEFAULT_SHIPPING_TYPE].shippingTypeId;
    setShippingTypeId(defaultShippingTypeId);
  };
  const [isOrderAPIFinished, setIsOrderAPIFinished] =
    React.useState<boolean>(false);

  const [createDFIOrder, { loading: loadingNewDFIOrder }] = useSafeMutation(
    CREATE_DFI_ORDER,
    { fetchPolicy: 'network-only' }
  );

  const handleOnAssignClick = () => {
    handleOnAcumaticaAssignClick();
  };

  const { data: collaboratorData, loading: loadingAddresses } = useSafeQuery(
    RETRIEVE_COLLABORATOR_ADDRESSES,
    {
      variables: { id: selectedEmployee?.id },
      fetchPolicy: 'network-only',
      // TODO: remove feature flag condition when feature flag removed
      skip: isSelectRecipientAddressDisabled() || !selectedEmployee?.id,
      onCompleted: (data) => {
        const availableAddresses = [
          ...(data?.collaborator?.homeAddresses?.map(
            (address: HomeAddress) => ({
              ...address,
              isHome: true,
              isWork: false,
            })
          ) || []),
          ...(data?.collaborator?.workAddresses?.map(
            (address: WorkAddress) => ({
              ...address,
              isHome: false,
              isWork: true,
            })
          ) || []),
        ];
        availableAddresses.sort((a, b) =>
          b.id === data?.collaborator?.primaryAddress?.id ? 1 : -1
        );
        if (availableAddresses.length === 0) return;
        setRecipientAddressItems(
          getCollaboratorAddressItems(availableAddresses)
        );
        setRecipientSelectedAddress(defaultAddressOption.value);
      },
    }
  );

  const isAssignBtnDisabled =
    loadingPremiumShippingInfo ||
    loadingAddresses ||
    loadingNewDFIOrder ||
    isOrderAPIFinished ||
    !shippingTypeId ||
    !selectedEmployee;

  const collaboratorAddresses = [
    ...((collaboratorData?.collaborator as Collaborator)?.homeAddresses || []),
    ...((collaboratorData?.collaborator as Collaborator)?.workAddresses || []),
  ];

  const handleOnAcumaticaAssignClick = () => {
    const targetCollaborator = selectedEmployee as Collaborator;
    // mutation variables
    const purchaserId = purchaser?.id as string;
    const isRush = obsoleteShippingType === ObsoleteShippingType.US_EXPEDITED;
    const assetId = device?.id;
    // TODO: remove feature flag condition when feature flag removed
    const collaboratorId = isSelectRecipientEmailDisabled()
      ? targetCollaborator?.id
      : undefined;
    const recipient = generateSelectedRecipient(targetCollaborator);
    const insuranceRequested = JSON.parse(dfiInsurance)?.insuranceRequested;
    const insuranceRequestedAmount = Number.parseFloat(
      insuranceRequested.replace('$', '')
    ).toFixed(2);

    if (insuranceRequestedAmount === 'NaN')
      throw new Error('Amount of insurance requested is not a number');

    const variables = {
      purchaserId,
      isRush,
      assetId,
      collaboratorId,
      recipient,
      insuranceRequested: `${insuranceRequestedAmount}`,
      ...(shippingTypeId ? { shippingTypeId } : {}),
    };
    createDFIOrder({
      variables,
      onError: async (error: ApolloError) => {
        setIsOrderAPIFinished(true);
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: NotificationMessages.GENERIC_SOMETHING_WENT_WRONG,
          })
        );
        console.log('error: \n', error);
        navigate(-1);
      },
      async onCompleted() {
        setTimeout(() => {
          dispatch(
            modalSlice.actions.setCurrentModal(
              GlobalModals.DEVICE_ASSIGNED_CONFIRMATION
            )
          );
        });
        setIsOrderAPIFinished(true);

        await client.refetchQueries({
          include: [GET_ASSETS],
        });

        navigate(-1);
      },
    });
  };

  const getCollaboratorEmailItems = (
    collaborator: Collaborator
  ): DropdownItem<string>[] => {
    const collaboratorEmails: DropdownItem<string>[] = [];
    if (collaborator.personalEmail) {
      collaboratorEmails.push({
        label: collaborator.personalEmail,
        icon: HomeOutlined,
        value: collaborator.personalEmail,
      });
    }
    if (collaborator.workEmail) {
      collaboratorEmails.push({
        label: collaborator.workEmail,
        icon: WorkOutline,
        value:
          collaborator.workEmail === collaborator.personalEmail
            ? `${collaborator.workEmail}@work`
            : collaborator.workEmail,
      });
    }
    if (
      collaboratorEmails.length === 0 ||
      (!collaborator.personalEmail &&
        collaborator.email !== collaborator.workEmail)
    ) {
      collaboratorEmails.push({
        icon: HomeOutlined,
        label: collaborator.email,
        value: collaborator.email,
      });
    }
    return collaboratorEmails;
  };

  const getCollaboratorAddressItems = (
    availableAddresses: SelectableRecipientAddress[]
  ): DropdownItem<SelectableRecipientAddress>[] => {
    return [
      defaultAddressOption,
      ...availableAddresses.map((address) => ({
        icon: getAddressIcon(address),
        label: getAddressDisplay(address),
        value: address,
      })),
    ];
  };

  const onRecipientAddressChange = (
    event: SelectChangeEvent<SelectableRecipientAddress>
  ) => {
    const address = event.target.value as SelectableRecipientAddress;
    setRecipientSelectedAddress(address);
    if (address.id && !address.phoneNumber) setOpenAddressModal(true);
  };

  const onAddNewRecipientAddress = (address: SelectableRecipientAddress) => {
    setRecipientAddressItems((prev) => {
      const existing = prev.findIndex((item) => item.value.id === address.id);
      if (existing > -1) {
        return [
          ...prev.slice(0, existing),
          {
            icon: getAddressIcon(address),
            label: getAddressDisplay(address),
            value: address,
          },
          ...prev.slice(existing + 1),
        ];
      }
      return [
        ...prev,
        {
          icon: getAddressIcon(address),
          label: getAddressDisplay(address),
          value: address,
        },
      ];
    });
    setRecipientSelectedAddress(address);
  };

  const onCancelAddRecipientAddress = () => {
    if (isAddressIncomplete(recipientSelectedAddress)) {
      setRecipientSelectedAddress(recipientAddressItems[0].value);
    }
  };

  const getAddressDisplay = (address: SelectableRecipientAddress) => {
    return (
      <span>
        {`${getAddressLabel(address)};`}{' '}
        <span style={address.phoneNumber ? {} : { color: 'red' }}>
          {address.phoneNumber
            ? `Phone: ${address.phoneNumber}`
            : 'Missing phone number'}
        </span>
      </span>
    );
  };

  const generateSelectedRecipient = (collaborator: Collaborator) => {
    // TODO: remove feature flag condition when feature flag removed
    if (isSelectRecipientAddressDisabled() && isSelectRecipientEmailDisabled())
      return undefined;

    const address = recipientSelectedAddress.id && {
      streetAddress1: recipientSelectedAddress.streetAddress1,
      streetAddress2: recipientSelectedAddress.streetAddress2,
      city: recipientSelectedAddress.city,
      state: recipientSelectedAddress.state,
      principalRegion: recipientSelectedAddress.principalRegion,
      zipCode: recipientSelectedAddress.zipCode,
      country: recipientSelectedAddress.country,
      isHome: recipientSelectedAddress.isHome,
      isWork: recipientSelectedAddress.isWork,
    };

    const emailValue = recipientSelectedEmail.split('@work');
    return {
      collaboratorId: collaborator?.id,
      email: emailValue?.length > 0 ? emailValue[0] : recipientSelectedEmail, // remove @work if it exists; should always have an array with at least 1 element, but checking just in case
      addressId: collaboratorAddresses.find(
        (address) => address.id === recipientSelectedAddress.id
      )?.id,
      address,
      phoneNumber: recipientSelectedAddress?.phoneNumber,
      persistInfoToCollaborator: !organization?.hrisEmployeeDataSyncedAt,
    };
  };

  React.useEffect(() => {
    if (shippingTypeId || !hasShippingInfo) return;
    initShippingTypeId();
  }, [hasShippingInfo, shippingTypeId, availableShippingTypeNames.length]);

  React.useEffect(() => {
    if (!selectedEmployee) {
      setRecipientSelectedEmail('');
      setRecipientEmailItems([]);
      setRecipientSelectedAddress(defaultAddressOption.value);
      setRecipientAddressItems([defaultAddressOption]);
      return;
    }
    setRecipientSelectedEmail(
      selectedEmployee.personalEmail ||
        selectedEmployee.workEmail ||
        selectedEmployee.email
    );
    setRecipientEmailItems(getCollaboratorEmailItems(selectedEmployee));
  }, [selectedEmployee]);

  React.useEffect(() => {
    if (
      !recipientSelectedAddress ||
      isAddressIncomplete(recipientSelectedAddress)
    ) {
      setRecipientAddressFormDataPrepopulate(recipientSelectedAddress);
    } else {
      setRecipientAddressFormDataPrepopulate({} as SelectableRecipientAddress);
    }
  }, [recipientSelectedAddress]);

  return (
    <>
      <Container maxWidth="xl" sx={styles.AssignFlowMainContainerSx}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          spacing={3.25}
          sx={styles.AssignFlowTopButtonStackSx}
        >
          <Button
            gaContext={{
              textCopy: 'Cancel',
              navigates_to: 'N/A',
              purpose: 'Cancels Assign Device Flow',
            }}
            color="secondary"
            onClick={handleOnCancelClick}
            size="small"
            variant="text"
            transparentBackgroundOnHover
          >
            <Typography variant="inherit">cancel</Typography>
          </Button>
          <GALoadingButton
            gaContext={{
              textCopy: 'Assign',
              navigates_to: 'N/A',
              purpose: 'Assigns Device To User',
            }}
            color="primary"
            onClick={handleOnAssignClick}
            size="small"
            variant="contained"
            loading={loadingNewDFIOrder}
            disabled={isAssignBtnDisabled}
          >
            <Typography variant="inherit">assign</Typography>
          </GALoadingButton>
        </Stack>
        <Container maxWidth="lg" sx={styles.AssignFlowInnerContainerSx}>
          <Typography className="assign-flow-title">Assign device</Typography>
          <AssignDeviceInfo
            make={device.make}
            model={device.model}
            serialNumber={device.serialNumber}
          />
          <Stack sx={styles.EmployeeSearchBoxSx}>
            <Typography className="assign-flow-question-label">
              Who is this device going to?
            </Typography>
            <EmployeeSearch
              selectedEmployee={selectedEmployee}
              setSelectedEmployee={setSelectedEmployee}
            />
          </Stack>
          {/* TODO: remove feature flag condition when feature flag removed */}
          {isSelectRecipientEmailEnabled() && (
            <Stack
              sx={styles.EmployeeSearchBoxSx}
              className={recipientSelectedEmail ? 'label-top' : ''}
            >
              <Typography className="assign-flow-question-label">
                Select email address?
              </Typography>
              <Dropdown
                type="Select"
                items={recipientEmailItems}
                selectedItem={recipientSelectedEmail}
                placeholderText="Email address"
                disabled={!recipientSelectedEmail}
                handleSearchOptionChange={(event) =>
                  setRecipientSelectedEmail(event.target.value)
                }
                dropdownSx={styles.RecipientEmailSelectSx}
                labelSx={styles.RecipientEmailSelectLabelSx}
              />
            </Stack>
          )}
          {/* TODO: remove feature flag condition when feature flag removed */}
          {isSelectRecipientAddressEnabled() && (
            <Stack
              sx={styles.EmployeeSearchBoxSx}
              className={recipientSelectedAddress ? 'label-top' : ''}
            >
              <Stack sx={styles.EmployeeAddressCopyBoxSx}>
                <Typography className="assign-flow-question-label">
                  Select shipping address
                </Typography>
                <Typography className="new-assign-flow-question-sub-copy">
                  {ALLWHERE_RECIPIENT_ADDRESS_SELECTION_COPY}
                </Typography>
              </Stack>
              <Dropdown
                type="Select"
                items={recipientAddressItems}
                selectedItem={recipientSelectedAddress}
                disabled={!selectedEmployee || loadingAddresses}
                handleSearchOptionChange={onRecipientAddressChange}
                dropdownSx={styles.RecipientEmailSelectSx}
                labelSx={styles.RecipientEmailSelectLabelSx}
                allowAddSelectItem={true}
                addSelectItemText="Add address manually"
                placeholderText="Shipping address"
                handleAddSelectItem={() => setOpenAddressModal(true)}
                showCheckIcon={true}
              />
            </Stack>
          )}
          <Stack sx={styles.InsuranceBoxSx}>
            <Typography className="assign-flow-question-label">
              Please select insurance for this device:
            </Typography>
            <FormControl sx={{ width: '60%' }}>
              <InputLabel id="insurance-coverage-amount" sx={{ left: '-14px' }}>
                Insurance coverage amount
              </InputLabel>
              <Select
                variant="standard"
                labelId="insurance-coverage-amount"
                sx={{
                  borderBottom: '1px solid black',
                }}
                defaultValue={DEFAULT_DFI_INSURANCE_OPTION_VALUE}
                value={dfiInsurance}
                onChange={handleInsuranceSelectOnChange}
              >
                {DFI_INSURANCE_OPTIONS.map((dfiInsuranceOption) => (
                  <MenuItem value={JSON.stringify(dfiInsuranceOption.value)}>
                    {dfiInsuranceOption.optionText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack sx={styles.ShippingAndProcessingBoxSx}>
            <Stack sx={styles.ShippingAndProcessingCopyBoxSx}>
              <Typography className="assign-flow-question-label">
                Please select shipping & processing:
              </Typography>

              <Typography className="new-assign-flow-question-sub-copy">
                {`${ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_COPY}${' '}`}
                <Link
                  color="secondary"
                  href={ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_URL}
                  target="_blank"
                >
                  {ALLWHERE_PREMIUM_FULFILLMENT_SPEEDS_LINK_COPY}
                </Link>
              </Typography>
            </Stack>

            {hasShippingInfo && (
              <RadioGroup
                row
                name="premium-shipping-type"
                value={shippingTypeId}
                sx={styles.NewShippingRadioGroupSx}
                onChange={handleRadioButtonChange}
              >
                {hasShippingInfo
                  ? PREMIUM_SHIPPING_TYPE_NAMES.map((typeName: string) => {
                      const premiumCopyInfo = getPremiumShippingDisplayInfo(
                        typeName,
                        { lookup: shippingInfoTable }
                      );

                      return (
                        <FormControlLabel
                          label=""
                          key={premiumCopyInfo.shippingTypeId}
                          value={premiumCopyInfo.shippingTypeId}
                          control={
                            <PremiumShippingRadioBox
                              selectedShippingTypeId={shippingTypeId}
                              shippingTypeId={premiumCopyInfo.shippingTypeId}
                              title={premiumCopyInfo.title}
                              subTexts={premiumCopyInfo.subTexts}
                              bottomText={premiumCopyInfo.bottomText}
                              bottomSubText={premiumCopyInfo.bottomSubText}
                            />
                          }
                        />
                      );
                    })
                  : null}
              </RadioGroup>
            )}
            {/* END of OLD IMPLEMENTATION SHIPPING */}
          </Stack>
          <Typography className="assign-cost-copy">
            {ASSIGN_DEVICE_COST_COPY}
          </Typography>
          <Box sx={{ height: '75px' }} />
        </Container>
      </Container>
      <AddRecipientAddressModal
        addAddress={onAddNewRecipientAddress}
        cancelAddAddress={onCancelAddRecipientAddress}
        open={openAddressModal}
        closeModal={() => setOpenAddressModal(false)}
        collaboratorId={selectedEmployee?.id || ''}
        address={recipientAddressFormDataPrepopulate}
        addAddressToCollaborator={!organization?.hrisEmployeeDataSyncedAt}
      />
    </>
  );
};

export default AssignFlow;
