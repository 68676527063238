import React from 'react';
import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StatusTransitionItemProps from './types';
import * as StatusTransitionItemStyles from './styles';

const StatusTransitionItem = (props: StatusTransitionItemProps) => {
  const {
    keyProp,
    displayStatus,
    IconAvatar,
    lastStep,
    MoreDetails,
    dateTimeStr,
  } = props;
  const styles = StatusTransitionItemStyles;
  const IconBoxBackdropSx: SxProps = {
    backgroundColor: lastStep ? 'white' : 'transparent',
  };

  const AvatarBoxSx = {
    ...styles.AvatarBoxPartialSx,
    ...IconBoxBackdropSx,
  } as SxProps;

  const StatusInfoStackSx = {
    ...(lastStep ? { pb: '12px' } : {}),
  } as SxProps;

  const DetailsRendered = MoreDetails ?? <></>;

  return (
    <Stack direction="row" columnGap="8px" key={keyProp}>
      <Stack sx={AvatarBoxSx}>{IconAvatar}</Stack>
      <Stack direction="column" sx={StatusInfoStackSx}>
        <Typography align="left" sx={styles.StatusNameSx}>
          {displayStatus}
        </Typography>
        {DetailsRendered}
        <Typography align="left" sx={styles.StatusDateTimeSx}>
          {dateTimeStr}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default StatusTransitionItem;
