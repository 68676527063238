export const DEFAULT_ADDRESS_FORM_RECORD = {
  streetLine1: null,
  streetLine2: null,
  city: null,
  state: null,
  principalRegion: null,
  country: null,
  zipCode: null,
};

export const EMPLOYEE_ADDRESS_BASE_IDS = {
  street1: 'address-street-1',
  street2: 'address-street-2',
  city: 'address-city',
  state: 'address-state',
  principalRegion: 'address-principal-region',
  postalCode: 'address-postal-code',
  country: 'address-country',
};
