/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import moment from 'moment';
import { useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';
import { NavigationContext } from 'context/NavigationContext';
import Avatar from 'components/Avatar';
import OrderStatusPill from 'components/OrderStatusPill';
import { OrderTypeOptions } from 'pages/Orders/constants';
import { OrderStatusMapping } from 'globals/constants';
import { formatDate } from 'services/date';
import { useGetConfig } from '../useGetOrderDataGridConfig';
import ShippingDetailsPopover from '../../components/ShippingDetailsPopover';

export const useGetOrdersColumns = (
  canViewDetails: boolean,
  columnOptions: {
    rowHoveringModel: string;
    onFollowUpView?: boolean;
  } = {
    rowHoveringModel: '',
    onFollowUpView: false,
  }
): GridColDef[] => {
  const refs = React.createRef<(HTMLInputElement | null)[]>();
  const theme = useTheme();
  const navigator = React.useContext(NavigationContext);

  const { hoveredOrder, setHoveredOrder } = useGetConfig();

  let orderColumns = [
    {
      field: 'orderNumber',
      headerName: 'Order number',
      minWidth: 100,
      renderCell: (data: any) => {
        const numberOfOrderItems = data.row.orderItems?.length ?? 0;
        const { id, orderNumber, shipments, refs } = data.row;

        const popoverOpen = hoveredOrder === id && shipments?.length > 0;

        const handleHovered = (hovered: boolean) => {
          if (refs.current[data.id] && hovered) {
            setHoveredOrder(id);
          } else {
            setHoveredOrder(null);
          }
        };
        const rowIndex = data.api.getSortedRowIds().indexOf(data.id);
        return (
          <>
            <Stack
              ref={(el: HTMLInputElement | null) => {
                // @ts-ignore
                refs.current[data.id] = el;
                // @ts-ignore
                return refs.current[data.id];
              }}
              direction="row"
              spacing={1}
            >
              <Avatar
                text={
                  <Typography
                    variant="caption"
                    data-testid={`number-of-items-${rowIndex}`}
                  >
                    {numberOfOrderItems}
                  </Typography>
                }
                cursor="pointer"
                width={24}
                height={24}
                color={theme.palette.colors.black}
                bgcolor={theme.palette.colors.gray200}
                hoverBgcolor={theme.palette.colors.gray300}
                onMouseEnter={() => handleHovered(true)}
                onMouseLeave={() => handleHovered(false)}
              />
              <Typography variant="body2">{orderNumber}</Typography>
            </Stack>
            <ShippingDetailsPopover
              order={data.row}
              open={popoverOpen}
              anchorEl={refs.current[data.id]}
              handlePopoverClose={() => handleHovered(false)}
              handleMouseEnter={() => handleHovered(true)}
              handleMouseLeave={() => handleHovered(false)}
            />
          </>
        );
      },
    },
    {
      field: 'orderType',
      headerName: 'Type',
      minWidth: 270,
      filterable: false,
      type: 'singleSelect',
      sortable: false,
      valueOptions: Object.entries(OrderTypeOptions).map(([value, label]) => {
        return {
          label,
          value,
        };
      }),
      renderCell: (data: any) => {
        const rowIndex = data.api.getSortedRowIds().indexOf(data.id);
        return (
          <Typography variant="body2" data-testid={`order-type-${rowIndex}`}>
            {data.row.orderType.name}
          </Typography>
        );
      },
    },
    {
      field: 'recipientName',
      headerName: 'Recipient',
      minWidth: 135,
      filterable: false,
      sortable: false,
      valueGetter(params: any) {
        if (params.row.dropRecipient) {
          return `${params.row.dropRecipient.firstName} ${params.row.dropRecipient.lastName}`;
        }
        if (params.row.recipient) {
          return `${params.row.recipient.firstName} ${params.row.recipient.lastName}`;
        }
        return '';
      },
    },
    {
      field: 'createdAt',
      headerName: 'Order date',
      minWidth: 170,
      type: 'date',
      filterable: false,
      renderCell: (data: any) => {
        const formattedDate = formatDate(data.value);
        const rowIndex = data.api.getSortedRowIds().indexOf(data.id);
        return (
          <Typography variant="body2" data-testid={`order-date-${rowIndex}`}>
            {formattedDate}
          </Typography>
        );
      },
      valueGetter(params: any) {
        return new Date(formatDate(params.row.createdAt));
      },
    },
    {
      field: 'finalDestination',
      headerName: 'Final destination',
      minWidth: 270,
      filterable: false,
      sortable: false,
      renderCell: (data: any) => {
        const rowIndex = data.api.getSortedRowIds().indexOf(data.id);
        return (
          <Typography
            variant="body2"
            data-testid={`order-final-destination-${rowIndex}`}
          >
            {data.value}
          </Typography>
        );
      },
      valueGetter(params: any) {
        if (params.row?.finalDestination) {
          return params.row?.finalDestination;
        }
        return '';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 135,
      type: 'singleSelect',
      sortable: false,
      filterable: false,
      valueOptions: Object.entries(OrderStatusMapping).map(([value, label]) => {
        return {
          label,
          value,
        };
      }),
      renderCell: (data: any) => {
        const rowIndex = data.api.getSortedRowIds().indexOf(data.id);
        return (
          <OrderStatusPill
            showClientStatus
            status={data.value}
            data-testid={`order-status-${rowIndex}`}
          />
        );
      },
    },
    {
      // Moves the row expand/collapse to the right since defined last here
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    },
  ];

  if (columnOptions.onFollowUpView) {
    orderColumns.splice(3, 0, {
      field: 'recipientEmail',
      headerName: 'Contact email',
      minWidth: 90,
      filterable: false,
      sortable: false,
      valueGetter(params: any) {
        if (params.row.recipient?.email) {
          return params.row.recipient.email;
        }
        return '';
      },
    });

    orderColumns.splice(5, 0, {
      field: 'daysUntilExpirationDate',
      headerName: 'Days until expiration',
      minWidth: 10,
      filterable: false,
      sortable: false,
      valueGetter(params: any) {
        if (params.row.lastOrderStatusTransition?.createdAt) {
          const dateString = (
            params.row.lastOrderStatusTransition?.createdAt ?? ''
          ).split('T')[0];
          const daysPassed = moment().diff(dateString, 'days');
          return daysPassed >= 30 ? 0 : 30 - daysPassed;
        }
        return '';
      },
    });
  }

  if (canViewDetails) {
    orderColumns.splice(1, 0, {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    });
    orderColumns.splice(orderColumns.length - 1, 1);
    orderColumns = [
      ...orderColumns,
      {
        field: 'details',
        headerName: '',
        type: 'string',
        width: 120,
        filterable: false,
        sortable: false,
        renderCell(params: any) {
          const isRowHovered = params.id === columnOptions.rowHoveringModel;
          const handleCellClick = (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            if (!isRowHovered) return;

            const securePathToOrderDetails =
              navigator.constructSecurePathToOrderDetails(
                params.id.toString(),
                params.row.organizationId
              );

            window.open(securePathToOrderDetails, '_blank');
          };

          return (
            <Tooltip title="Order Details" arrow>
              <span
                onClick={handleCellClick}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                {isRowHovered ? (
                  <>
                    <InfoOutlinedIcon
                      fontSize="small"
                      style={{ marginRight: '4px', cursor: 'pointer' }}
                    />
                    <span
                      className="details-text"
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        borderBottom: '1.5px solid rgba(0, 0, 0, 0.4)',
                      }}
                    >
                      <Typography
                        style={{ fontWeight: 'bold', cursor: 'pointer' }}
                      >
                        Details
                      </Typography>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </span>
            </Tooltip>
          );
        },
      },
    ];
  }
  return orderColumns;
};
