import DepotView from './components/DepotView';

import MarketplaceView from './components/MarketplaceView';
import { useProcurementService } from 'pages/Procurement/hooks/useProcurementService';
import { ProcurementFlowStep } from 'pages/Procurement/hooks/useProcurementService/constants';
import OrderSummary from 'pages/Procurement/components/OrderSummary';
import { OrderSubmitted } from './components/OrderSubmitted';

export const BuyAndHold = () => {
  const workflow = useProcurementService('buy_and_hold');

  const content = () => {
    switch (workflow.flowStep) {
      case ProcurementFlowStep.COLLABORATOR_VIEW:
        return <DepotView workflow={workflow} />;
      case ProcurementFlowStep.PRODUCT_CATALOG:
        return <MarketplaceView workflow={workflow} />;
      case ProcurementFlowStep.ORDER_SUMMARY:
        return <OrderSummary workflow={workflow} />;
      case ProcurementFlowStep.ORDER_SUBMITTED:
        return <OrderSubmitted workflow={workflow} />;
      default:
        throw new Error(
          `ProcurementFlowStep: ${workflow.flowStep} does not exist`
        );
    }
  };

  return (
    <>
      {content()}{' '}
      <input type="hidden" value="BUY-AND-HOLD-SUCCESS-MODAL-PLACEHOLDER" />
    </>
  );
};
export default BuyAndHold;
