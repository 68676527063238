import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useProductCatalogControllerGetByOrganization,
  useProductCategoryControllerSearch,
} from 'services/openapi/apiComponents';
import { ProductCatalogItem as ProductCatalogItemType } from 'services/openapi/apiSchemas';
import { ProductCatalogProps } from './types';
import PeopleBar from '../PeopleBar';
import SelectedCollaboratorsModal from './components/SelectedCollaboratorsModal';
import {
  ProcurementFlowModals,
  ProcurementFlowStep,
} from 'pages/Procurement/hooks/useProcurementService/constants';
import ProductCatalogItem from './components/ProductCatalogItem';
import { DEFAULT_PAGE_LIMIT } from './components/constants';
import ProductDetailsModal from './components/ProductDetailsModal';
import SearchAndFilters from './components/SearchAndFilters';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import {
  BackButtonSx,
  CatalogContentContainerSx,
  CatalogHeaderSx,
  MainCatalogContainerSx,
  ScrollableDivSx,
} from './styles';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import {
  INFINITE_SCROLL_THRESHOLD,
  KITS,
  PRODUCT_CATALOG_CONTAINER_IDS_MAP,
} from './constants';
import CartButton from './components/CartButton';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import useRenderAppbarComponents from 'hooks/useRenderAppbarComponents';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { Button } from 'components';
import { NavigationContext } from 'context/NavigationContext';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';

const ProductCatalog = (props: ProductCatalogProps) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const [page, setPage] = React.useState(0);

  const [products, setProducts] = React.useState<ProductCatalogItemType[]>([]);

  const [productCatalogItemForModal, setProductCatalogItemForModal] =
    React.useState<ProductCatalogItemType | undefined>(undefined);

  const [productNameQuery, setProductNameQuery] = React.useState('');

  const debouncedProductNameQuery = useDebouncedQuery(productNameQuery);

  const [currentFilterId, setCurrentFilterId] = React.useState<string>('All');

  const userOrganization = useGetUserOrganization();

  const userPermissions = useGetUserPermissions();

  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const getMainContainerId = () =>
    PRODUCT_CATALOG_CONTAINER_IDS_MAP.get(pathname);

  useRenderAppbarComponents(
    // Do not show cart button if catalog is in read only mode
    props.readonly ? null : (
      <CartButton workflow={props.workflow} userPermissions={userPermissions} />
    ),
    [props.workflow.cart, props.workflow.selectedCollaborators]
  );

  const showSelectedCollaboratorsModal =
    props.workflow.currentModal ===
    ProcurementFlowModals.SELECTED_COLLABORATORS_EDIT;

  /**
   * Using two variables to handle showing/hiding the modal
   * This enables us to keep the sliding up/down transition and
   * to ensure that when the PDP closes, we can unmount it from the DOM
   * so that when an user opens the PDP for another product, react-hook-form's
   * state is resetted.
   */
  const openProductDetailsModal =
    props.workflow.currentModal === ProcurementFlowModals.PRODUCT_DETAILS;

  const showProductDetailsModal = useDebouncedValue(
    openProductDetailsModal,
    theme.transitions.duration.enteringScreen + 1
  );

  const { data: productCategoryData } = useProductCategoryControllerSearch({});

  const { data, isLoading } = useProductCatalogControllerGetByOrganization(
    {
      queryParams: {
        organizationId: userOrganization?.id as string,
        limit: DEFAULT_PAGE_LIMIT,
        offset: page * DEFAULT_PAGE_LIMIT,
        ...(currentFilterId !== 'All'
          ? {
              productCategoryId: currentFilterId,
            }
          : {}),
        ...(debouncedProductNameQuery
          ? {
              name: debouncedProductNameQuery,
            }
          : {}),
      },
    },
    {
      enabled: !!userOrganization?.id,
    }
  );

  React.useEffect(() => {
    //Remove current filter when search term changes so that the user sees results against all products
    setCurrentFilterId('All');
  }, [debouncedProductNameQuery]);

  React.useEffect(() => {
    // We want to show "Kits" to users by default; will fall back to show everything if something goes wrong
    if (productCategoryData) {
      const retrievalCategory = productCategoryData?.items.find(
        (productCategory) => productCategory?.name === KITS
      );
      setCurrentFilterId(retrievalCategory?.id as string);
    }
  }, [productCategoryData]);

  // If a user selects a filter, we want to reset the page count to be zero and to remove
  // existing products from state so that only relavant items are shown to the user
  React.useEffect(() => {
    setPage(0);
    setProducts([]);
  }, [currentFilterId, debouncedProductNameQuery]);

  // Cannot leverage RQ's onSuccess function to set product state because
  // it is only invoke when the query fetches from the server and not cache
  // the data property will always be updated whether read from server or cache
  React.useEffect(() => {
    setProducts((previousProducts) => {
      const doesProductsContainData = previousProducts.some(
        (product) => product?.id === data?.items[0]?.id
      );

      if (!doesProductsContainData) {
        return [...previousProducts, ...(data?.items || [])];
      }
      return [...previousProducts];
    });
  }, [data?.items]);

  const canFetchMoreProducts = (data?.count || 0) > products.length;

  const handleCloseModal = () => {
    props.workflow.dismissCurrentModal();
  };

  const fetchNextPage = () => {
    if (!isLoading) setPage(page + 1);
  };

  const onClickHandleBack = () => {
    props.workflow.updateProcurementFlowStep(
      ProcurementFlowStep.COLLABORATOR_VIEW
    );
  };

  const onClickRequestNewItem = () => {
    navigate(navigator.pathToRequestNewItem());
  };

  return (
    <>
      <Container sx={MainCatalogContainerSx} id={getMainContainerId()}>
        <Stack
          spacing={1.25}
          flexDirection="column"
          flexWrap="nowrap"
          marginTop="3.5rem"
          width="100%"
        >
          {props.readonly ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom="1rem"
            >
              <Typography variant="h4" sx={CatalogHeaderSx}>
                Catalog
              </Typography>
              <GAIconButton
                gaContext={{
                  navigates_to: 'N/A',
                  textCopy: 'Request New Item',
                  purpose: 'Requests a New Item',
                }}
                aria-label="request new item"
                color="secondary"
                size="small"
                disableRipple
                onClick={onClickRequestNewItem}
              >
                <AddCircleOutlineIcon fontSize="small" /> &nbsp;
                <Typography
                  variant="button"
                  fontSize="18px"
                  fontWeight="600"
                  lineHeight="21.78px"
                >
                  request new item
                </Typography>
              </GAIconButton>
            </Stack>
          ) : (
            <>
              <div>
                <Button
                  gaContext={{
                    textCopy: 'Back',
                    navigates_to: 'Procurement',
                    purpose: 'Navigates to Procurement View',
                  }}
                  variant="text"
                  color="secondary"
                  startIcon={<ArrowBackIcon />}
                  onClick={onClickHandleBack}
                  sx={BackButtonSx(theme)}
                >
                  BACK
                </Button>
              </div>
              <Typography variant="h4">Add items to your cart</Typography>
              <Typography variant="body2" style={{ marginBottom: '10px' }}>
                Once you've added the products or retrievals to your shopping
                cart, you can submit the order for processing through check out.
              </Typography>
              <PeopleBar
                selectedCollaborators={props.workflow.selectedCollaborators}
                removeSelectedCollaborator={
                  props.workflow.removeSelectedCollaborator
                }
                setCurrentModal={props.workflow.setCurrentModal}
              />
            </>
          )}
        </Stack>
        <Stack sx={CatalogContentContainerSx}>
          <SearchAndFilters
            productCategories={productCategoryData}
            query={productNameQuery}
            setQuery={setProductNameQuery}
            currentFilterId={currentFilterId}
            setCurrentFilterId={setCurrentFilterId}
          />
          <Divider orientation="vertical" flexItem />
          <Box id="scrollableDiv" sx={ScrollableDivSx}>
            <InfiniteScroll
              dataLength={products.length}
              next={fetchNextPage}
              hasMore={canFetchMoreProducts}
              loader={<></>}
              scrollableTarget="scrollableDiv"
              scrollThreshold={INFINITE_SCROLL_THRESHOLD}
            >
              <Grid container rowSpacing={3}>
                {products.map((item) => (
                  <ProductCatalogItem
                    key={item.id}
                    item={item}
                    setCurrentModal={props.workflow.setCurrentModal}
                    addProductVariantToCart={
                      props.workflow.addProductVariantToCart
                    }
                    setProductCatalogItemForModal={
                      setProductCatalogItemForModal
                    }
                    showCartButton={!props.readonly}
                  />
                ))}
              </Grid>
            </InfiniteScroll>
          </Box>
        </Stack>
      </Container>
      <SelectedCollaboratorsModal
        open={showSelectedCollaboratorsModal}
        handleClose={() => handleCloseModal()}
        removeSelectedCollaborator={props.workflow.removeSelectedCollaborator}
        selectedCollaborators={props.workflow.selectedCollaborators}
      />
      {/* Using OR with both values to avoid delay upon opening the PDP */}
      {showProductDetailsModal || openProductDetailsModal ? (
        <ProductDetailsModal
          cart={props.workflow.cart}
          open={openProductDetailsModal}
          handleClose={() => handleCloseModal()}
          productCatalogItem={productCatalogItemForModal}
          addProductVariantToCart={props.workflow.addProductVariantToCart}
          showAddToOrderButton={!props.readonly}
        />
      ) : null}
    </>
  );
};

export default ProductCatalog;
