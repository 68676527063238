/* eslint-disable no-alert */
import moment from 'moment';
import {
  DEFAULT_DATE_FORMAT,
  HOLIDAYS_UNTIL_CHRISTMAS_EOY as holidays,
  HOLIDAYS_LIST_WILL_EXPIRE_WARNING,
  EARLIEST_DATE_ENGINEER_CAN_UPDATE_HOLIDAYS_LIST,
} from './constants';

export const getTodaysDate = (dateFormat?: string): string => {
  return moment(new Date()).format(dateFormat ?? DEFAULT_DATE_FORMAT);
};

/**
 * @param date string separated by 'T' and in the format of YYYY-MM-DDTHH:MM:SSZ
 * @returns date string as-is in the format of MM/DD/YYYY format
 */
export const formatToMonthDayYear = (date: string) => {
  const [dateString, _] = date.split('T');
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
};

export const formatDate = (date: string, dateFormat?: string): string => {
  return moment(date).format(dateFormat ?? DEFAULT_DATE_FORMAT);
};

export const getISOStandardDate = (date: string): string => {
  const dateObject = new Date(date);
  const month =
    dateObject.getMonth() < 9
      ? `0${dateObject.getMonth() + 1}`
      : `${dateObject.getMonth() + 1}`;
  const day =
    dateObject.getDate() < 9
      ? `0${dateObject.getDate()}`
      : `${dateObject.getDate()}`;
  return `${dateObject.getFullYear()}-${month}-${day}`;
};

export const isWeekend = (date: Date): boolean => {
  const day = date.getDay();
  return day === 0 || day === 6;
};

export const isHoliday = (date: Date): boolean => {
  const dateStr = date.toISOString().split('T')[0];
  return holidays.includes(dateStr);
};

export const addWorkDays = (startDate: Date, daysToAdd: number): Date => {
  const newDate = new Date(startDate.getTime());
  let addedDays = 0;

  while (addedDays < daysToAdd) {
    newDate.setDate(newDate.getDate() + 1);
    if (!isWeekend(newDate) && !isHoliday(newDate)) {
      addedDays += 1;
    }
  }

  return newDate;
};

export const checkHolidaysListIsOutOfDate = (mustUpdateDate: string) => {
  const isInDevelopment = process.env.NODE_ENV === 'development';
  const pastLastHoliday =
    new Date().getTime() > new Date(mustUpdateDate).getTime();

  if (isInDevelopment && pastLastHoliday) {
    setTimeout(() => {
      console.log(HOLIDAYS_LIST_WILL_EXPIRE_WARNING);
    }, 3000);
  }
};

export const getNDaysFromNow = (
  days: number,
  options: {
    dateFormat: string;
  } = {
    dateFormat: 'MMM D', // ex: 'Dec 25'
  }
) => {
  checkHolidaysListIsOutOfDate(EARLIEST_DATE_ENGINEER_CAN_UPDATE_HOLIDAYS_LIST);
  const shippingBySLADate = addWorkDays(new Date(), days);

  return moment(shippingBySLADate).format(options.dateFormat);
};

export const dateUTCStringToMediumDate = (stringUTC: string): string => {
  const utcDate = moment.utc(stringUTC);
  return utcDate.format('MMM D, YYYY');
};

export const getEasternUSTime = (
  utcDateString: string,
  showSeconds: boolean = false
) => {
  const utcDate = moment.utc(utcDateString);
  // between March and November, Eastern Time is UTC-4
  const isDST = utcDate.month() > 2 && utcDate.month() < 10;

  const hoursRemoved = isDST ? 4 : 5;

  const estTime = utcDate.clone().subtract(hoursRemoved, 'hours');

  const formatString = showSeconds ? 'h:mm:ssA' : 'h:mmA';
  return estTime.format(formatString);
};

export const dayUTCIsToday = (dateUTC: string): boolean =>
  new Date().toLocaleString() === new Date(dateUTC).toLocaleString();

export const getDateTimeInMS = (dateString?: string): number =>
  dateString ? new Date(dateString).getTime() : new Date().getTime();
