import {
  FileDownloadService,
  FileDownloadServiceDirect,
} from 'api/rest/config';
import { AxiosResponse } from 'axios';

export const getAssetsCSV = async (userId: string, orgId: string) => {
  return FileDownloadService.get<
    { userId: string; orgId: string },
    Promise<{ downloadUrl: string }>
  >(`/assets/${orgId}/collaborators/${userId}`);
};

export const getAssetsCSVDirect = async (userId: string, orgId: string) => {
  return FileDownloadServiceDirect.get<
    { userId: string; orgId: string },
    Promise<AxiosResponse>
  >(`/assets/${orgId}/collaborators/${userId}/file`);
};
