/* eslint-disable sort-exports/sort-exports */
/* eslint-disable prefer-destructuring */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import { SearchScopeDropdown } from 'components';
import { AutoCompleteOptionTuple, DEFAULT_AUTOCOMPLETE_OPTIONS } from 'types';
import { useGetAssetManagementSuggestions } from 'pages/AssetManagement/hooks/useGetAssetManagementSuggestions';
import { SEARCH_OPTION_SEPARATOR } from 'components/SearchScopeDropdown/constants';
import { SearchOption } from 'components/SearchScopeDropdown/types';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import {
  AM_SEARCH_OPTIONS,
  ASSET_UUID,
  AssetManagementSuggestionFilterName,
} from './constants';
import { assetManagementSlice } from 'store/slices';
import { GlobalState } from 'store/types';
import { AssetManagementDashSearchBarProps } from './types';
import { RETRIEVE_ASSET } from './queries';
import { getAMOldFilterActionPayload } from './utils';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';
import { GenericAutocompleteProps } from 'components/GenericAutocomplete/types';

const AssetManagementSearchBar = (props: AssetManagementDashSearchBarProps) => {
  const dispatch = useDispatch();
  const oldSearchTerm = useSelector<GlobalState, string | null>(
    (state) => state.assetManagement.oldSearchTerm
  );
  const { isFlagOn: complexFilterOn } = useFeatureFlagService(
    FeatureFlagNames.COMPLEX_ASSET_FILTERING
  );
  // Searching state - START
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const debouncedSearchTerm = useDebouncedQuery(searchTerm);
  const [searchOption, setSearchOption] = React.useState<SearchOption>(
    AM_SEARCH_OPTIONS[0]
  );
  const [autocompleteOptions, setAutocompleteOptions] = React.useState<
    AutoCompleteOptionTuple[]
  >(DEFAULT_AUTOCOMPLETE_OPTIONS);
  const [selectedSuggestion, setSelectedSuggestion] =
    React.useState<AutoCompleteOptionTuple | null>(null);

  const getSearchPlaceholder = () => {
    const searchDataModel = [
      AssetManagementSuggestionFilterName.SERIAL_NUMBER,
      AssetManagementSuggestionFilterName.ASSET_NUMBER,
    ].some((filterName) => filterName === searchOption.filterName)
      ? 'asset'
      : 'inventory receipt';
    return `Search for ${searchDataModel} by ${searchOption.displayName.toLowerCase()}`;
  };

  const [retrieveAssetById] = useSafeLazyQuery(RETRIEVE_ASSET);

  useGetAssetManagementSuggestions(
    debouncedSearchTerm,
    searchOption,
    setAutocompleteOptions
  );

  const handleSearchTermChange = (e: any) => {
    if (e) {
      setSearchTerm(e.target.value);
    }
  };

  const resetSearchFields = () => {
    resetSearchTerm();
    setSelectedSuggestion(null);
    const removeFilters = [
      AssetManagementSuggestionFilterName.SERIAL_NUMBER,
      AssetManagementSuggestionFilterName.ASSET_NUMBER,
      ASSET_UUID,
    ];
    props.setClearFiltersBar(true);
    props.resetFilterFields(removeFilters);

    if (complexFilterOn()) {
      dispatch(assetManagementSlice.actions.resetOldFiltersReducer());
    }
  };

  const resetSearchTerm = () => {
    setSearchTerm('');
    setAutocompleteOptions(DEFAULT_AUTOCOMPLETE_OPTIONS);
  };

  const handleSearchOptionChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    const option = AM_SEARCH_OPTIONS.find(
      (searchOption) => searchOption.filterName === value
    );
    if (option) {
      setSearchOption(option);
      setSelectedSuggestion(null);
    }
  };

  const setFilterField = (filterFieldKey: string, filterFieldValue: string) => {
    props.setFilterField({ [filterFieldKey]: filterFieldValue });

    if (complexFilterOn()) {
      const oldFilterPayload = getAMOldFilterActionPayload(
        filterFieldKey,
        filterFieldValue
      );

      dispatch(
        assetManagementSlice.actions.setOldFiltersReducer(oldFilterPayload)
      );
    }
  };

  const process3PLAssetIdSelection = (
    select3PLAssetId: string,
    assetId: string
  ) => {
    retrieveAssetById({
      variables: {
        assetId,
      },
      onError(error) {
        console.warn('#retrieveAssetById error: \n', error);
      },
      onCompleted(data) {
        const { getAsset } = data;
        const currentAssetMatches =
          getAsset?.thirdPartyAssetId === select3PLAssetId;

        if (currentAssetMatches) {
          setFilterField(ASSET_UUID, getAsset.id);
        }
      },
    });
  };

  const handleSuggestionSelection: GenericAutocompleteProps<string>['onChange'] =
    (event, value) => {
      if (value === null) {
        resetSearchFields();
      } else {
        props.setClearFiltersBar(true);
      }

      const { displayName } = searchOption;
      // eslint-disable-next-line
      let _optionKey: string = '';
      let optionKeyValue: string = '';
      let optionName: string = '';

      if (value) {
        // eslint-disable-next-line
        _optionKey = value[0];
        optionKeyValue = value[1];
        optionName = value[2];
      }
      const prefixOptionName = `${displayName}${SEARCH_OPTION_SEPARATOR}`;
      const rightSideOptionValue = optionName.split(prefixOptionName)[1];

      if (
        searchOption.filterName ===
          AssetManagementSuggestionFilterName.THIRD_PARTY_ASSET &&
        value !== null
      ) {
        process3PLAssetIdSelection(rightSideOptionValue, optionKeyValue);
      }

      if (
        searchOption.filterName ===
          AssetManagementSuggestionFilterName.SERIAL_NUMBER &&
        value !== null
      ) {
        setFilterField(
          AssetManagementSuggestionFilterName.SERIAL_NUMBER,
          optionKeyValue
        );
      }

      if (
        searchOption.filterName ===
          AssetManagementSuggestionFilterName.ASSET_NUMBER &&
        value !== null
      ) {
        setFilterField(
          AssetManagementSuggestionFilterName.ASSET_NUMBER,
          optionKeyValue
        );
      }

      // @ts-ignore
      setSelectedSuggestion(value);
    };

  React.useEffect(() => {
    if (oldSearchTerm) return;
    resetSearchFields();
  }, [oldSearchTerm]);

  return (
    <Box>
      <SearchScopeDropdown
        searchTerm={searchTerm}
        resetSearchTerm={resetSearchTerm}
        handleSearchTermChange={handleSearchTermChange}
        selectedSearchOption={searchOption}
        searchOptions={AM_SEARCH_OPTIONS}
        handleSearchOptionChange={handleSearchOptionChange}
        suggestions={autocompleteOptions}
        selectedSuggestion={selectedSuggestion}
        handleSuggestionSelection={handleSuggestionSelection}
        placeholder={getSearchPlaceholder()}
      />
    </Box>
  );
};

export default AssetManagementSearchBar;
