import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer';
import { Order } from 'types';
import OrderManagementDetailsState, { OMDetailsOrgPurchasers } from './types';

export const clearSelectedOrderReducer = (
  state: WritableDraft<OrderManagementDetailsState>
) => {
  state.selectedOrder = undefined;
};

export const setOMSelectedOrderReducer = (
  state: WritableDraft<OrderManagementDetailsState>,
  action: PayloadAction<Order>
) => {
  state.selectedOrder = action.payload;
};

export const setOMOrderOpenDetailsReducer = (
  state: WritableDraft<OrderManagementDetailsState>,
  action: PayloadAction<Order>
) => {
  state.selectedOrder = action.payload;
  state.detailsModalOpen = true;
};

export const updateSelectedOrderReducer = (
  state: WritableDraft<OrderManagementDetailsState>,
  action: PayloadAction<Partial<Order>>
) => {
  if (state.selectedOrder) {
    state.selectedOrder = { ...state.selectedOrder, ...action.payload };
  }
};

export const toggleOMCancelModalReducer = (
  state: WritableDraft<OrderManagementDetailsState>,
  action: PayloadAction<boolean>
) => {
  state.cancelOrderOpen = action.payload;
};

export const toggleOMCreateModalReducer = (
  state: WritableDraft<OrderManagementDetailsState>,
  action: PayloadAction<boolean>
) => {
  state.createOrderOpen = action.payload;
};

export const toggleOMDetailsModalReducer = (
  state: WritableDraft<OrderManagementDetailsState>,
  action: PayloadAction<boolean>
) => {
  state.detailsModalOpen = action.payload;
};

export const clearOMPurchasersReducer = (
  state: WritableDraft<OrderManagementDetailsState>
) => {
  state.selectablePurchasers = [];
};

export const assignOMPurchasersReducer = (
  state: WritableDraft<OrderManagementDetailsState>,
  action: PayloadAction<OMDetailsOrgPurchasers[]>
) => {
  state.selectablePurchasers = action.payload;
};
