import React from 'react';
import * as storage from 'api/storage';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import { OnDragEndResponder } from '@hello-pangea/dnd';
import {
  setColumnsOpen,
  setSearchInput,
  setColumns,
  swapColumns,
  setAllColumns,
  setAllColumnsActive,
  setColumnsChanged,
  resetState,
  setInitialized,
} from 'store/slices/assets/columns';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectColumnsOpen,
  selectColumns,
  selectSearchInput,
  selectColumnsChanged,
  selectInitialized,
} from 'store/slices/assets/columns/selectors';
import { ColumnValue } from 'store/slices/shared/types';

import { COLUMN_LOCALSTORAGE_KEY } from './constants';
import { AssetColumnValue } from 'store/slices/assets/columns/types';

export const useGetOnPopoverOpen = () => {
  const dispatch = useDispatch();
  return React.useCallback((_: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setColumnsOpen(true));
  }, []);
};

export const useGetOnPopoverClose = () => {
  const dispatch = useDispatch();
  return React.useCallback((_: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setColumnsOpen(false));
  }, []);
};

export const useGetOnHandleDragEnd = () => {
  const dispatch = useDispatch();

  return React.useCallback<OnDragEndResponder>((dropResult) => {
    const destinationIndex = dropResult.destination?.index;
    const sourceIndex = dropResult.source.index;
    if (
      destinationIndex !== undefined &&
      destinationIndex >= 0 &&
      sourceIndex >= 0
    ) {
      dispatch(swapColumns({ sourceIndex, destinationIndex }));
    }
  }, []);
};

export const useGetOnShowAllClick = () => {
  const dispatch = useDispatch();
  return React.useCallback((_: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setAllColumnsActive(true));
    dispatch(setColumnsChanged(true));
  }, []);
};

export const useGetOnHideAllClick = () => {
  const dispatch = useDispatch();
  return React.useCallback((_: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setAllColumnsActive(false));
    dispatch(setColumnsChanged(true));
  }, []);
};

export const useGetOnSearchInput = () => {
  const dispatch = useDispatch();
  return React.useCallback((searchInput: string) => {
    dispatch(setSearchInput(searchInput));
  }, []);
};

export const useGetOnColumnToggle = () => {
  const dispatch = useDispatch();
  return React.useCallback(
    (column: ColumnValue) =>
      (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        dispatch(
          setColumns({
            id: column.id,
            active: checked,
          })
        );
        dispatch(setColumnsChanged(true));
      },
    []
  );
};

export const useGetOnRestoreColumnDefaults = () => {
  const dispatch = useDispatch();
  return React.useCallback(() => {
    dispatch(resetState({ path: 'popover.open', value: true }));
  }, []);
};

export const useGetConfig = () => {
  const open = useSelector(selectColumnsOpen);
  const searchInput = useSelector(selectSearchInput);
  const changed = useSelector(selectColumnsChanged);
  const columns = useSelector(selectColumns);
  const onPopoverOpen = useGetOnPopoverOpen();
  const onPopoverClose = useGetOnPopoverClose();
  const onHandleDragEnd = useGetOnHandleDragEnd();
  const onShowAllClick = useGetOnShowAllClick();
  const onHideAllClick = useGetOnHideAllClick();
  const onSearchInput = useGetOnSearchInput();
  const onColumnToggle = useGetOnColumnToggle();
  const onRestoreColumnDefaults = useGetOnRestoreColumnDefaults();

  return {
    columns,
    onShowAllClick,
    onHideAllClick,
    onPopoverClose,
    onPopoverOpen,
    onHandleDragEnd,
    onColumnToggle,
    onSearchInput,
    onRestoreColumnDefaults,
    open,
    searchInput,
    changed,
  };
};

export const useUpdateColumnsLocalStorage = () => {
  const columns = useSelector(selectColumns);
  const collaborator = useGetGlobalPurchaser();
  const columnsChanged = useSelector(selectColumnsChanged);
  const initialized = useSelector(selectInitialized);
  const dispatch = useDispatch();

  return React.useEffect(() => {
    const collaboratorId = collaborator?.id;
    if (collaboratorId && !initialized) {
      const columnsData = {
        columns,
        columnsChanged,
      };
      const key = `${COLUMN_LOCALSTORAGE_KEY}-${collaboratorId}`;
      storage.setLocalStorageValue(key, columnsData);
    } else if (initialized) {
      dispatch(setInitialized(false));
    }
  }, [columns, collaborator, columnsChanged, initialized]);
};

export const useLoadInitialStateFromLocalStorage = () => {
  const collaborator = useGetGlobalPurchaser();
  const dispatch = useDispatch();
  return React.useEffect(() => {
    const collaboratorId = collaborator?.id;
    if (collaboratorId) {
      const key = `${COLUMN_LOCALSTORAGE_KEY}-${collaboratorId}`;
      const columnsData = storage.getLocalStorageValue(key) as {
        columnsChanged: boolean;
        columns: AssetColumnValue[];
      };
      const columns = columnsData.columns || [];
      if (columnsData.columns) {
        dispatch(setAllColumns(columns));
        dispatch(setColumnsChanged(columnsData.columnsChanged));
      }
    }
  }, [collaborator]);
};
