import { BORDER_COLOR, FONT_SIZE } from '../../../styles';

export const TotalsCardContainerSx = {
  minHeight: '150px',
  width: '380px',
  border: `1px solid ${BORDER_COLOR}`,
  borderRadius: '5px',
  paddingTop: '15px',
  paddingLeft: '15px',
  paddingRight: '15px',
  fontSize: `${FONT_SIZE}px`,
  position: 'relative',
};

export const LoadingOverlaySx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  zIndex: '100',
  top: '0px',
  left: '0px',
  height: '100%',
  borderRadius: '5px',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  width: '100%',
};

export const LoadingOverlaySpinnerSx = {
  display: 'flex',
};

export const HeaderSx = {
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: '20px',
};

export const ContentSx = {
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '20px',
};
export const TitleSx = {
  display: 'flex',
  fontSize: '18px',
  width: '50%',
  fontWeight: '700',
};

export const ViewAllSx = {
  display: 'flex',
  width: '50%',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  fontWeight: '600',
  alignItems: 'center',
  fontSize: '12px',
  cursor: 'pointer',
  '& div': {
    marginRight: '10px',
  },
  '& svg': {
    height: '16px',
    width: '16px',
  },
};

export const TotalsSection = {
  display: 'flex',
  flexDirection: 'row',
};

export const TotalsLeft = {
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
};

export const TotalsTop = {
  fontWeight: '500',
  fontSize: '12px',
  color: 'rgba(96, 96, 93, 1)',
};

export const TotalsBottom = {
  fontWeight: '500',
  fontSize: '22px',
};

export const TotalsRight = {
  width: '60%',
};

export const ItemContainer = (boundary: boolean) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '5px',
  paddingBottom: '5px',
  padding: '5px',
  borderBottom: boundary ? 'none' : `1px solid ${BORDER_COLOR}`,
  borderTop: boundary ? 'none' : `1px solid ${BORDER_COLOR}`,
  cursor: 'pointer',
  borderRadius: '2px',
  ':hover': {
    backgroundColor: 'rgba(200, 200, 200, 0.1)',
  },
});

export const ItemLeft = {
  display: 'flex',
  width: '80%',
  color: 'rgba(96, 96, 93, 1)',
  fontWeight: '500',
};

export const ItemRight = {
  display: 'flex',
  width: '20%',
  alignItems: 'flex-end',
  fontWeight: '500',
};

export const FooterSection = {};
