import { SxProps } from '@mui/material';
import { DRAWER_WIDTH } from 'global-styles';

export const MainContainerSx: SxProps = {
  boxSizing: 'border-box',
  paddingTop: '42px',
  margin: 'auto',
  marginTop: '50px',
  position: 'relative',
  px: '36px !important',
  '@media (max-width: 1199px)': {
    left: '0',
  },
  '@media (min-width: 1200px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    width: '80%',
  },
  '@media (min-width: 1536px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    maxWidth: '100%',
    width: '85%',
  },
};

export const getContentStackSx = (
  paddingTop: string = '5px',
  optionalSx: SxProps = {}
): SxProps => ({
  flexWrap: 'nowrap',
  paddingTop,
  ...optionalSx,
});
