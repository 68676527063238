import React from 'react';
import { FilterStateKey } from 'store/shared/types';

import UseGetFilterDrawerConfig from 'components/complex_filter/ComplexFilterDrawer/types';
import useGetUserOrganization from 'hooks/useGetUserOrganization';

import {
  getAssetQueryVariablesHOF,
  getAssetAssigneeQueryVariablesHOF,
} from 'pages/Assets/filters/utils';
import { useGetFilterDrawerConfig as useGetFilterDrawerConfigBase } from 'components/complex_filter/hooks';

export const useGetFilterDrawerConfig = (
  filterSubTree: FilterStateKey
): UseGetFilterDrawerConfig | undefined => {
  const userOrg = useGetUserOrganization();
  const baseFilterDrawerConfig = useGetFilterDrawerConfigBase(filterSubTree);

  if (!baseFilterDrawerConfig) return undefined;

  React.useEffect(() => {
    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers[
      'asset-number'
    ].getQueryVariables = getAssetQueryVariablesHOF('assetNumber', {
      searchBy: 'iLike',
      ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
    });

    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers[
      'serial-no'
    ].getQueryVariables = getAssetQueryVariablesHOF('serialNumber', {
      searchBy: 'iLike',
      ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
    });

    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers[
      'assignee-email'
    ].getQueryVariables = getAssetAssigneeQueryVariablesHOF({
      ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
    });

    baseFilterDrawerConfig.filterModalConfig.typeAheadHandlers.assignee.getQueryVariables =
      getAssetAssigneeQueryVariablesHOF({
        ...(userOrg?.id ? { organizationId: userOrg?.id } : {}),
      });
  }, [userOrg?.id]);

  return { ...baseFilterDrawerConfig } as UseGetFilterDrawerConfig;
};
