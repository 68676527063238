import React from 'react';
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  FILTER_BANK_CONFIG,
  COMPLEX_FILTER_MODAL_CONFIG,
  COMPLEX_FILTER_TEST_IDS,
  COMPLEX_FILTER_OVERRIDE_SX,
  DATE_TIME_MODES,
} from 'components/complex_filter/constants';
import {
  FilterStateKey,
  BaseFilterEntry,
  FilterEntry,
  DateTimeMode,
} from 'store/shared/types';
import {
  selectPopoverOpen,
  selectExpandedFilter,
  selectIsFiltered,
  selectAvailableEntries,
  selectChosenEntries,
  selectAllChosenEntries,
  selectSearchInputByKey,
  selectDateFilterMode,
  selectFilterOpen,
  selectPickedDate,
  selectDateRange,
  selectIsFilterDisabled,
} from 'store/shared/selectors';
import {
  UseGetFilterBankConfig,
  DropdownHandlersConfig,
} from 'components/complex_filter/types';
import {
  renderCommonChipHOC,
  getFilterDateValue,
  getRangeDateValue,
  checkValidDate,
} from './utils';
import UseGetFilterDrawerConfig from 'components/complex_filter/ComplexFilterDrawer/types';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { Moment } from 'moment';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';

export const useGetSoftResetFilter = (
  softResetFilterAction: ActionCreatorWithPayload<{
    closeFilterDrawer: boolean;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback((closeFilterDrawer: boolean) => {
    dispatch(softResetFilterAction({ closeFilterDrawer }));
  }, []);
};

export const useGetToggleFilterButton = (
  toggleFilterDrawerAction: ActionCreatorWithoutPayload
) => {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch(toggleFilterDrawerAction());
  }, []);
};

export const useGetResetFilter = (
  resetFilterAction: ActionCreatorWithPayload<{ closeFilterDrawer: boolean }>
) => {
  const dispatch = useDispatch();

  return React.useCallback((closeFilterDrawer: boolean) => {
    dispatch(resetFilterAction({ closeFilterDrawer }));
  }, []);
};

export const useGetClosePopover = (
  closeFilterAction: ActionCreatorWithoutPayload
) => {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch(closeFilterAction());
  }, []);
};

export const useGetClearSelectionsByKey = (
  clearFilterEntriesAction: ActionCreatorWithPayload<{ key: string }>
) => {
  const dispatch = useDispatch();

  return React.useCallback((key: string) => {
    dispatch(clearFilterEntriesAction({ key }));
  }, []);
};

export const useGetOpenInput = (
  openFilterInputAction: ActionCreatorWithPayload<string>
) => {
  const dispatch = useDispatch();

  return React.useCallback((filterInput: string) => {
    dispatch(openFilterInputAction(filterInput));
  }, []);
};

export const useGetCloseInput = (
  closeFilterInputAction: ActionCreatorWithoutPayload
) => {
  const dispatch = useDispatch();

  React.useCallback(() => {
    dispatch(closeFilterInputAction());
  }, []);
};

export const useGetBatchUpsertFilterEntries = (
  batchUpsertAction: ActionCreatorWithPayload<{
    key: string;
    options: BaseFilterEntry[];
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback((key: string, options: BaseFilterEntry[]) => {
    dispatch(batchUpsertAction({ key, options }));
  }, []);
};

export const useGetSearchChangeByKey = (
  setSearchInputAction: ActionCreatorWithPayload<{
    key: string;
    input: string | null;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string) => (evt: any, value: any, reason: string) => {
      dispatch(setSearchInputAction({ key, input: value }));
    },
    []
  );
};

export const useGetSelectOption = (
  selectOptionAction: ActionCreatorWithPayload<{ id: string; key: string }>
) => {
  const dispatch = useDispatch();

  return React.useCallback((id: string, key: string) => {
    dispatch(selectOptionAction({ id, key }));
  }, []);
};

export const useGetDeselectByIdAndKey = (
  deselectOptionAction: ActionCreatorWithPayload<{
    id: string;
    key: string;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback((id: string, key: string) => {
    dispatch(deselectOptionAction({ id, key }));
  }, []);
};

export const useGetClearAvailableOptionsByKey = (
  clearOptionsByKeyAction: ActionCreatorWithPayload<string>
) => {
  const dispatch = useDispatch();

  return React.useCallback((filterKey: string) => {
    dispatch(clearOptionsByKeyAction(filterKey));
  }, []);
};

export const useGetClearAllByKey = (
  clearAllByKeyAction: ActionCreatorWithPayload<string>
) => {
  const dispatch = useDispatch();

  return React.useCallback((filterKey: string) => {
    dispatch(clearAllByKeyAction(filterKey));
  }, []);
};

export const useGetBlurClearByKey = (
  clearOnBlurByKeyAction: ActionCreatorWithPayload<{
    key: string;
    input: string | null;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string) => () => {
      dispatch(clearOnBlurByKeyAction({ key, input: '' }));
    },
    []
  );
};

export const useGetBatchUpsertFilterOptions = (
  batchUpsertFiltersAction: ActionCreatorWithPayload<{
    key: string;
    options: BaseFilterEntry[];
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback((key: string, options: BaseFilterEntry[]) => {
    dispatch(batchUpsertFiltersAction({ key, options }));
  }, []);
};

export const useGetToggleFilterByKeyId = (
  toggleFilterByKeyIdAction: ActionCreatorWithPayload<{
    id: string;
    key: string;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback((id: string, key: string) => {
    dispatch(toggleFilterByKeyIdAction({ id, key }));
  }, []);
};

export const useGetSelectDropdown = (
  toggleFilterByKeyIdAction: ActionCreatorWithPayload<{
    id: string;
    key: string;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (filterKey: string) =>
      (_event: SelectChangeEvent<FilterEntry[]>, child: React.ReactNode) => {
        if (!React.isValidElement(child)) return;
        const childValue = child.props?.value ?? '';
        dispatch(toggleFilterByKeyIdAction({ id: childValue, key: filterKey }));
      },
    []
  );
};

export const useGetLoadAsyncDropDownOptions = (
  batchUpsertFiltersAction: ActionCreatorWithPayload<{
    key: string;
    options: BaseFilterEntry[];
  }>,
  dropDownHandler: DropdownHandlersConfig
) => {
  const dispatch = useDispatch();

  return React.useCallback((filterKey: string) => {
    const { presetOptions, optionsType } = dropDownHandler[filterKey];

    if (optionsType === 'hard-coded') return;

    const loadOptions = dropDownHandler[filterKey].upsertOptionsHOF(
      filterKey,
      (key: string, options: BaseFilterEntry[]) => {
        dispatch(batchUpsertFiltersAction({ key, options }));
      },
      presetOptions
    );

    useQuery(dropDownHandler[filterKey].asyncOptionsQuery, {
      onCompleted: loadOptions,
    });
  }, []);
};

export const useGetLoadDropDownOptions = (
  batchUpsertFiltersAction: ActionCreatorWithPayload<{
    key: string;
    options: BaseFilterEntry[];
  }>,
  dropDownHandlers: DropdownHandlersConfig
) => {
  const dispatch = useDispatch();

  return React.useCallback((filterKey: string, filled: boolean) => {
    const { presetOptions, optionsType, getPreloadedEntries } =
      dropDownHandlers[filterKey];

    if (optionsType === 'async' || filled) return;

    const options = getPreloadedEntries(filterKey, presetOptions);

    dispatch(batchUpsertFiltersAction({ key: filterKey, options }));
  }, []);
};

// START of datetime-related HOOKS

export const useGetDateEntryChange = (
  replaceDateFilterEntryAction: ActionCreatorWithPayload<{
    key: string;
    option: BaseFilterEntry;
    selected?: boolean;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string, option: BaseFilterEntry, selected = false) => {
      dispatch(replaceDateFilterEntryAction({ key, option, selected }));
    },
    []
  );
};

export const useGetDateModeChange = (
  setDateModeAction: ActionCreatorWithPayload<{
    key: string;
    mode: DateTimeMode;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string) =>
      (_event: React.MouseEvent<HTMLElement>, value: string | null) => {
        const mode = (value ?? DATE_TIME_MODES.range) as DateTimeMode;
        dispatch(setDateModeAction({ key, mode }));
      },
    []
  );
};

export const useGetSetDateRangeInputChange = (
  setDateRangeAction: ActionCreatorWithPayload<{
    key: string;
    startDate: Moment | null | string;
    endDate: Moment | null | string;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string) => (value: DateRange<Moment>, _context: any) => {
      const [startDate, endDate]: DateRange<Moment> = value;
      const startDateInput = getRangeDateValue(startDate);
      const endDateInput = getRangeDateValue(endDate);

      if (checkValidDate(startDate) && checkValidDate(endDate)) {
        dispatch(
          setDateRangeAction({
            key,
            startDate: startDateInput,
            endDate: endDateInput,
          })
        );
      }
    },
    []
  );
};

export const useGetHandleRangeCalendarChange = (
  setDateRangeAction: ActionCreatorWithPayload<{
    key: string;
    startDate: null | string;
    endDate: null | string;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string) =>
      (
        value: any,
        selectionState?: PickerSelectionState | undefined,
        selectedView?: 'day' | undefined
      ) => {
        const [startDate, endDate]: DateRange<Moment> = value;

        const startDateInput = getFilterDateValue(startDate);
        const endDateInput = getFilterDateValue(endDate);

        dispatch(
          setDateRangeAction({
            key,
            startDate: startDateInput,
            endDate: endDateInput,
          })
        );
      },
    []
  );
};

export const useGetSetBeforeDateInput = (
  setSingleDateAction: ActionCreatorWithPayload<{
    key: string;
    entryDate: null | string;
    dateDirection: 'before' | 'after';
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string) => (value: Moment, _context: any) => {
      const startDate: Moment = value;
      const startDateInput = getFilterDateValue(startDate);

      dispatch(
        setSingleDateAction({
          key,
          entryDate: startDateInput,
          dateDirection: 'before',
        })
      );
    },
    []
  );
};

export const useGetSetAfterDateInput = (
  setSingleDateAction: ActionCreatorWithPayload<{
    key: string;
    entryDate: null | string;
    dateDirection: 'before' | 'after';
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string) => (value: Moment, _context: any) => {
      const startDate: Moment = value;
      const startDateInput = getFilterDateValue(startDate);

      dispatch(
        setSingleDateAction({
          key,
          entryDate: startDateInput,
          dateDirection: 'after',
        })
      );
    },
    []
  );
};

export const useGetSetBeforeDateCalendar = (
  setSingleDateAction: ActionCreatorWithPayload<{
    key: string;
    entryDate: null | string;
    dateDirection: 'before' | 'after';
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string) =>
      (
        value: any,
        selectionState?: PickerSelectionState | undefined,
        selectedView?: 'day' | undefined
      ) => {
        const [startDate]: DateRange<Moment> = value;

        const startDateInput = getFilterDateValue(startDate);

        dispatch(
          setSingleDateAction({
            key,
            entryDate: startDateInput,
            dateDirection: 'before',
          })
        );
      },
    []
  );
};

export const useGetSetAfterDateCalendar = (
  setSingleDateAction: ActionCreatorWithPayload<{
    key: string;
    entryDate: null | string;
    dateDirection: 'before' | 'after';
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string) =>
      (
        value: any,
        selectionState?: PickerSelectionState | undefined,
        selectedView?: 'day' | undefined
      ) => {
        const [startDate]: DateRange<Moment> = value;

        const startDateInput = getFilterDateValue(startDate);

        dispatch(
          setSingleDateAction({
            key,
            entryDate: startDateInput,
            dateDirection: 'after',
          })
        );
      },
    []
  );
};

export const useGetClearDateByMode = (
  clearDateByModeKeyAction: ActionCreatorWithPayload<{
    key: string;
    mode: DateTimeMode;
  }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string, mode: DateTimeMode) => () => {
      dispatch(clearDateByModeKeyAction({ key, mode }));
    },
    []
  );
};

export const useResetFilterByEntryKey = (
  resetFilterByEntryKeyAction: ActionCreatorWithPayload<{ key: string }>
) => {
  const dispatch = useDispatch();

  return React.useCallback(
    (key: string) => () => {
      dispatch(resetFilterByEntryKeyAction({ key }));
    },
    []
  );
};
// END of datetime-related HOOKS

export const useGetFilterBankConfig = (
  filterSubTree: FilterStateKey
): UseGetFilterBankConfig | undefined => {
  const errorMessage = `FilterBankWrapper/hooks: ${filterSubTree} is not a valid filter/config key.`;
  const matchingConfig = FILTER_BANK_CONFIG[filterSubTree];

  if (!matchingConfig) {
    console.error(errorMessage);
    return undefined;
  }

  const { actions, ...restOfConfig } = matchingConfig;

  const deselectByIdAndKey = useGetDeselectByIdAndKey(
    actions.deselectFilterByIdAndKeyReducer
  );

  const renderBankContents = renderCommonChipHOC(deselectByIdAndKey);

  const restFilters = useGetResetFilter(actions.resetFilterStateReducer);

  const clearFilterBank = React.useCallback(() => {
    restFilters(true);
  }, []);

  return {
    filterSubTree,
    selectAllChosenEntries,
    renderBankContents,
    clearFilterBank,
    ...restOfConfig,
  };
};

export const useGetFilterDrawerConfig = (
  filterSubTree: FilterStateKey
): UseGetFilterDrawerConfig | undefined => {
  const errorMessage = `FilterDrawerWrapper/hooks: ${filterSubTree} is not a valid filter/config key.`;
  const filterModalConfig = COMPLEX_FILTER_MODAL_CONFIG[filterSubTree];

  const testIds =
    filterSubTree in COMPLEX_FILTER_TEST_IDS
      ? COMPLEX_FILTER_TEST_IDS[filterSubTree]
      : {};

  const overrideSx =
    filterSubTree in COMPLEX_FILTER_OVERRIDE_SX
      ? COMPLEX_FILTER_OVERRIDE_SX[filterSubTree]
      : {};

  if (!filterModalConfig) {
    console.error(errorMessage);
    return undefined;
  }

  const { actions, dropdownHandlers } = filterModalConfig;

  const onSoftClearAllFilters = useGetSoftResetFilter(
    actions.softResetFilterStateReducer
  );

  const onToggleFilterButton = useGetToggleFilterButton(
    actions.toggleFilterDrawerReducer
  );
  const onClearAllFilters = useGetResetFilter(actions.resetFilterStateReducer);
  const onClosePopover = useGetClosePopover(actions.closeFilterDrawerReducer);
  const onClearSelectionsByKey = useGetClearSelectionsByKey(
    actions.clearFilterEntriesByKeyReducer
  );
  const onOpenInput = useGetOpenInput(actions.openFilterInputReducer);
  const onCloseInput = useGetOpenInput(actions.closeFilterInputReducer);
  const onBatchUpsertFilterEntries = useGetBatchUpsertFilterEntries(
    actions.batchUpsertFilterEntriesReducer
  );
  const onSearchChangeByKey = useGetSearchChangeByKey(
    actions.setSearchInputReducer
  );
  const selectOption = useGetSelectOption(actions.selectOptionReducer);
  const deselectByIdAndKey = useGetDeselectByIdAndKey(
    actions.deselectFilterByIdAndKeyReducer
  );
  const clearAvailableOptionsByKey = useGetClearAvailableOptionsByKey(
    actions.clearAvailableOptionsByKeyReducer
  );

  const onCheckBoxChange = React.useCallback(
    (filterKey: string, id: string) =>
      (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
          selectOption(id, filterKey);
        } else {
          clearAvailableOptionsByKey(filterKey);
        }
      },
    []
  );

  const onTypeaheadOptionsChange = React.useCallback(
    (filterKey: string) => (event: any, value: any, reason: string) => {
      if (reason === 'selectOption') {
        const { key, id } = value.slice(-1).pop();
        selectOption(id, key);
      }

      const isEmpty = value.length === 0;

      if (reason === 'removeOption' && !isEmpty) {
        const id = event.currentTarget.dataset.optionUuid;
        deselectByIdAndKey(id, filterKey);
      }

      if (reason === 'removeOption' && isEmpty) {
        clearAvailableOptionsByKey(filterKey);
      }
    },
    []
  );

  const clearAllByKey = useGetClearAllByKey(
    actions.clearAvailableOptionsByKeyReducer
  );

  const onBlurClearByKey = useGetBlurClearByKey(actions.setSearchInputReducer);

  const onDateModeChange = useGetDateModeChange(actions.setDateModeReducer);
  const onReplaceDateFilterEntry = useGetDateEntryChange(
    actions.replaceDateFilterEntryReducer
  );

  const setDateRangeInputChange = useGetSetDateRangeInputChange(
    actions.setDateRangeReducer
  );

  const onRangeCalendarChange = useGetHandleRangeCalendarChange(
    actions.setDateRangeReducer
  );

  const onAfterDateCalendarChange = useGetSetAfterDateCalendar(
    actions.setSingleDateReducer
  );

  const onBeforeDateCalendarChange = useGetSetBeforeDateCalendar(
    actions.setSingleDateReducer
  );

  const onSetBeforeDateInputChange = useGetSetBeforeDateInput(
    actions.setSingleDateReducer
  );

  const onSetAfterDateInputChange = useGetSetAfterDateInput(
    actions.setSingleDateReducer
  );

  const onClearDateByModeKey = useGetClearDateByMode(
    actions.clearDateByModeKeyReducer
  );

  const onResetFilterByKey = useResetFilterByEntryKey(
    actions.resetFilterByEntryKeyReducer
  );

  const loadAsyncDropdownOptions = useGetLoadAsyncDropDownOptions(
    actions.batchUpsertFilterEntriesReducer,
    dropdownHandlers
  );

  const loadDropDownOptions = useGetLoadDropDownOptions(
    actions.batchUpsertFilterEntriesReducer,
    dropdownHandlers
  );

  const onSelectDropdown = useGetSelectDropdown(
    actions.toggleFilterByKeyIdReducer
  );

  return {
    filterModalConfig,
    filterSubTree,
    // !*! handlers "on-"
    onToggleFilterButton,
    onClosePopover,
    onClearAllFilters,
    onClearSelectionsByKey,
    onBlurClearByKey,
    onBatchUpsertFilterEntries,
    loadAsyncDropdownOptions,
    loadDropDownOptions,
    clearAllByKey,
    onSelectDropdown,
    onFullTextOptionsChange: onTypeaheadOptionsChange,
    onTypeaheadOptionsChange,
    onSearchChangeByKey,
    onOpenInput,
    onCloseInput,
    //date-related-handlers
    onDateModeChange,
    onReplaceDateFilterEntry,
    setDateRangeInputChange,
    onRangeCalendarChange,
    onAfterDateCalendarChange,
    onBeforeDateCalendarChange,
    onSetBeforeDateInputChange,
    onSetAfterDateInputChange,
    onResetFilterByKey,
    onSoftClearAllFilters,
    // !*! selectors
    selectIsFiltered,
    selectAvailableEntries,
    selectChosenEntries,
    selectPopoverOpen,
    selectExpandedFilter,
    selectAllChosenEntries,
    selectSearchInputByKey,
    selectDateFilterMode,
    selectFilterOpen,
    selectPickedDate,
    selectDateRange,
    selectIsFilterDisabled,
    onClearDateByModeKey,
    onCheckBoxChange,
    ...testIds,
    ...overrideSx,
  } as UseGetFilterDrawerConfig;
};
