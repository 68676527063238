import React from 'react';
//@ts-ignore
import { useSearchParams } from 'react-router-dom';
import { UserPermissions } from 'global-constants';
import PickGlobalDataRetoolPrompt from 'components/PickGlobalDataRetoolPrompt';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { AssetsView } from './enums';
import Merchandise from './components/Merchandise';
import DevicesDatagrid from './components/DevicesDatagrid';

const Assets = () => {
  const globalPurchaser = useGetGlobalPurchaser();
  const userOrganization = useGetUserOrganization();
  const userPermissions = useGetUserPermissions();
  const canToggleOrganization = userPermissions.includes(
    UserPermissions.ORGANIZATION__TOGGLE
  );

  const [searchParams] = useSearchParams();

  //Admin users
  if (
    (!globalPurchaser?.email || !userOrganization?.id) &&
    canToggleOrganization
  )
    return <PickGlobalDataRetoolPrompt />;

  //Non-admin users
  if (!globalPurchaser?.email || !userOrganization?.id) return <></>;

  const assetsTab = searchParams.get('view') || '';

  if (assetsTab === AssetsView.MERCHANDISE) return <Merchandise />;

  return <DevicesDatagrid />;
};

export default Assets;
