import theme from 'themes/theme';
import { SPACE_BETWEEN_COLUMNS } from './constants';

export const AddressFormTitleSx = {
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '18.24px',
  mb: '16px',
};

export const AddressFormRowSx = {
  width: {
    md: '100%',
    lg: '80%',
  },
};

export const getAddressFormRowSx = (inLoadingState: boolean) => ({
  width: {
    md: '100%',
    lg: '80%',
  },
  pointerEvents: inLoadingState ? 'none' : 'all',
});

export const getAddressFormInsideRowSx =
  (leftSidePercentage: number = 50) =>
  (
    isLeftSide: boolean = true,
    spaceBetween: string = SPACE_BETWEEN_COLUMNS
  ) => ({
    borderRadius: 0,
    mr: spaceBetween,
    p: 0,
    width: isLeftSide
      ? `calc(${leftSidePercentage}% - ${spaceBetween})`
      : `${100 - leftSidePercentage}%`,
  });

export const AddressFormUpdateButtonSx = {
  minWidth: '110px',
  width: '110px',
  '& .MuiTypography-root': {
    fontWeight: 700,
  },
};

export const AddressFormPhoneSx = {
  width: '100%',
  backgroundColor: theme.palette.colors.alabasterN,
  borderRadius: 0,
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
};

export const getAddressFormPhoneSx = (inLoadingState: boolean) => ({
  width: '100%',
  backgroundColor: theme.palette.colors.alabasterN,
  borderRadius: 0,
  p: 0,
  '& .MuiInputBase-input': {
    color: inLoadingState ? 'transparent' : 'inherit',
  },
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
});

export const AutoCompeteSearchTextField = {
  padding: '0',

  '&.MuiTextField-root.MuiFormControl-root': {
    borderRadius: '0',
  },
};
