import React from 'react';
import { Alert as MuiAlert, Slide, Snackbar } from '@mui/material';
import { useGlobalState } from 'state';

const HIDE_TOAST_AFTER_MILLISECONDS = 5000;

const Alert = () => {
  const [alert, setGlobalState] = useGlobalState('alert');

  if (alert?.severity === 'success') {
    return (
      <Snackbar
        open={!!alert}
        onClose={() => setGlobalState(undefined)}
        autoHideDuration={HIDE_TOAST_AFTER_MILLISECONDS}
        message={alert.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
      />
    );
  }

  return (
    <Snackbar
      open={!!alert}
      onClose={() => setGlobalState(undefined)}
      autoHideDuration={HIDE_TOAST_AFTER_MILLISECONDS}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      TransitionComponent={(props) => <Slide {...props} direction="down" />}
    >
      <MuiAlert severity={alert?.severity}>{alert?.message}</MuiAlert>
    </Snackbar>
  );
};

export default Alert;
