import { Collaborator } from 'api/rest/collaborators/types';
import uniqBy from 'lodash/uniqBy';
import { CollaboratorOptionsMap } from './types';
import { AutoCompleteOptionTuple } from 'types';

export const updateCollaboratorOptionsMap = (
  map: CollaboratorOptionsMap,
  collaborator: Collaborator,
  key: 'email' | 'personalEmail' | 'workEmail'
) => {
  if (
    collaborator &&
    collaborator[key] &&
    map[collaborator.id]?.emails &&
    map[collaborator.id]?.emails.length >= 0
  ) {
    if (collaborator[key]) {
      map[collaborator.id].emails.push(collaborator[key] as string);
      map[collaborator.id].firstName = collaborator.firstName;
      map[collaborator.id].lastName = collaborator.lastName;
    }
  } else if (
    collaborator &&
    collaborator[key] &&
    !map[collaborator.id]?.emails
  ) {
    map[collaborator.id] = {
      emails: [collaborator[key] as string],
      firstName: collaborator.firstName,
      lastName: collaborator.lastName,
    };
  }
};

export const getExtendedCollaboratorOptions = (
  map: CollaboratorOptionsMap,
  collaboratorOptions: AutoCompleteOptionTuple[]
) => {
  const options = collaboratorOptions.concat();
  collaboratorOptions.forEach((option: string[]) => {
    const id = option[1];
    if (map[id]?.emails.length) {
      map[id]?.emails.forEach((email: string) => {
        const { firstName } = map[id];
        const { lastName } = map[id];
        options.push([
          'recipientCollaboratorId',
          id,
          `${firstName} ${lastName} (${email})`,
        ]);
      });
    }
  });

  return uniqBy(options, (option) => option.toString());
};
