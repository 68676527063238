import { Order, OrderManager } from 'types';

export const getFormattedDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  });
};

export const getFormattedTime = (date: string) => {
  return new Date(date).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const hasAssigneeManager = (order: Order) => {
  const {
    orderManagerId,
    procurementManagerId,
    assetManagerId,
    otherManagerId,
  } = order;

  return [
    orderManagerId,
    procurementManagerId,
    assetManagerId,
    otherManagerId,
  ].some((id) => !!id);
};

export const getAssigneeName = (manager: OrderManager | null) => {
  if (!manager) return '';

  const { role, firstName, lastName } = manager;

  const abbr = role
    ? role
        .split('_')
        .map((str: string) => str[0])
        .join('')
    : '';

  return `${abbr}${abbr ? ': ' : ''}${firstName} ${lastName}`;
};
