export enum OrderDetailsTab {
  ORDER_DETAILS = 'Order details',
  ORDER_ITEMS = 'Order items',
  ORDER_STATUS_HISTORY = 'Order status history',
}

export enum AsyncMatchStatus {
  PENDING = 'PENDING',
  MATCH = 'MATCH',
  NO_MATCH = 'NO_MATCH',
}

export const ORDER_STATUS_HISTORY_QUERY_OUTPUT = `
  count
  items {
    to: toStatus
    createdAt
    orderId: recordId
  } 
`;

export const FETCH_MORE_STATUS_HISTORY_BTN_HEIGHT = '44px';

export const ORDER_DETAILS_ORDER_ITEMS_TAB_BUTTON_SELECT_ID =
  'order-details-order-items-tab-button';
