import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectStatusFilterEntries } from 'store/shared/selectors';
import { orderFilterActions } from 'store/slices/orders/filter';
import { assetFilterActions } from 'store/slices/assets/filter';

const useOrderOrAssetFilterSync = (page: string) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [done, setDone] = useState<Set<string>>(new Set());
  const clearedRef = useRef(false);
  const acceptedParams = ['order-status', 'status', 'order-date'];

  const addParamToDone = (item: string) => {
    setDone((prevDone) => new Set(prevDone).add(item)); // Create a new Set to avoid mutation
  };

  const getParams = (
    urlParams: URLSearchParams
  ): Record<string, string | string[]> => {
    const paramsObject: Record<string, string | string[]> = {};
    urlParams.forEach((value, key) => {
      if (value.includes(',')) {
        paramsObject[key] = value
          .split(',')
          .map((item) => item.trim().toLowerCase());
      } else if (['order-status', 'status'].includes(key)) {
        paramsObject[key] = [value.toLowerCase()];
      } else {
        paramsObject[key] = value.toLowerCase();
      }
    });
    return paramsObject;
  };

  // Reset on load - turn off hasParams if you want it to clear on each load
  useEffect(() => {
    if (clearedRef.current) return;
    const params = getParams(searchParams);
    const hasReset = Object.prototype.hasOwnProperty.call(
      params,
      'reset-filters'
    );
    if (page === 'orders' && hasReset) {
      dispatch(
        orderFilterActions.softResetFilterStateReducer({
          closeFilterDrawer: true,
        })
      );
    } else if (page === 'assets' && hasReset) {
      dispatch(
        assetFilterActions.softResetFilterStateReducer({
          closeFilterDrawer: true,
        })
      );
    }
    clearedRef.current = true;
  }, [searchParams]);

  // Handle Orders Filter Management
  const orderFilters = useSelector(selectStatusFilterEntries('orders'));
  useEffect(() => {
    if (page !== 'orders') return;
    const params = getParams(searchParams);

    // Handle ORDERS order-status
    if (
      !done.has('order-status') &&
      Array.isArray(params['order-status']) &&
      params['order-status'].length > 0 &&
      orderFilters.options?.length > 0
    ) {
      dispatch(
        orderFilterActions.setFilterBatch({
          entry: 'order-status',
          values: params['order-status'],
        })
      );
      addParamToDone('order-status');
    }

    // Handle ORDERS order-date
    if (
      !done.has('order-date') &&
      typeof params['order-date'] === 'string' &&
      params['order-date']?.length > 0
    ) {
      const [startDate, endDate] = params['order-date'].split(' - ');
      dispatch(
        orderFilterActions.setDateRangeReducer({
          key: 'order-date',
          startDate,
          endDate,
        })
      );
      addParamToDone('order-date');
    }
  }, [searchParams, orderFilters, page]);

  // Handle Assets Filter Management
  const assetFilters = useSelector(selectStatusFilterEntries('assets'));
  useEffect(() => {
    if (page !== 'assets') return;
    const params = getParams(searchParams);
    if (
      !done.has('status') &&
      Array.isArray(params.status) &&
      params.status?.length > 0 &&
      assetFilters.options?.length > 0
    ) {
      dispatch(
        assetFilterActions.setFilterBatch({
          entry: 'status',
          values: params.status,
        })
      );
      addParamToDone('status');
    }
  }, [searchParams, assetFilters, page]);
};

export default useOrderOrAssetFilterSync;
