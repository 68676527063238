/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { procurementToDepotSlice } from 'store/slices';
import BuyAndHoldBanner from 'pages/BuyAndHold/components/Banner';
import { Bubble, Button } from 'components';
import * as BuyAndHoldStyles from 'pages/BuyAndHold/styles';
import {
  ProcurementFlowModals,
  ProcurementFlowStep,
} from 'pages/Procurement/hooks/useProcurementService/constants';
import { useProductCatalogControllerGetByOrganization } from 'services/openapi/apiComponents';
import {
  ProductCategoryDtoList,
  ProductCategoryDto,
  ProductCatalogItem as ProductCatalogItemType,
} from 'services/openapi/apiSchemas';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import ProductDetailsModal from 'pages/Procurement/components/ProductCatalog/components/ProductDetailsModal';
import SearchAndFilters from './components/SearchAndFilters';
import ProductCatalogItem from './components/ProductCatalogItem';
import * as MarketplaceViewStyles from './styles';
import { MarketplaceViewProps } from './types';
import { DEFAULT_PAGE_LIMIT, INFINITE_SCROLL_THRESHOLD } from './constants';
import { GET_ALL_ORDER_TYPES, GET_ALL_PRODUCT_CATEGORIES } from './queries';
import { OrderType } from 'global-constants';
import useRenderAppbarComponents from 'hooks/useRenderAppbarComponents';
import CartButton from 'pages/Procurement/components/ProductCatalog/components/CartButton';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import NoFlagSVGIcon from 'components/Icons/flags/NoFlagSVGIcon';
import { DEPOT_FLAG_MAPPING } from '../DepotView/components/DepotTable/constants';

const MarketplaceView = (props: MarketplaceViewProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const parentStyles = BuyAndHoldStyles;
  const styles = MarketplaceViewStyles;
  const userOrganization = useGetUserOrganization();
  const firstCategoryRef = React.useRef<undefined | string>();
  const [page, setPage] = React.useState(0);
  const { selectedDepotLocation } = props.workflow;

  const depotName = selectedDepotLocation?.displayName || '';
  const FlagIcon =
    DEPOT_FLAG_MAPPING[selectedDepotLocation?.address?.country as string] ||
    NoFlagSVGIcon;

  const [products, setProducts] = React.useState<ProductCatalogItemType[]>([]);

  const [productCatalogItemForModal, setProductCatalogItemForModal] =
    React.useState<ProductCatalogItemType | undefined>(undefined);

  const [productNameQuery, setProductNameQuery] = React.useState('');

  const debouncedProductNameQuery = useDebouncedQuery(productNameQuery);

  const userPermissions = useGetUserPermissions();

  const numberOfCartItems = props.workflow.getCartItemsCount();

  useRenderAppbarComponents(
    // Do not show cart button if catalog is in read only mode
    <CartButton
      workflow={props.workflow}
      userPermissions={userPermissions}
      numberOfCartItems={numberOfCartItems}
      data-testid="cart-button-123abc"
    />,
    [props.workflow.getCartItemsCount(), props.workflow.selectedDepotLocation]
  );

  const [currentFilterId, setCurrentFilterId] = React.useState<string>('');

  const [officialOrderTypeId, setOfficialOrderTypeId] = React.useState<
    undefined | string
  >();

  const [
    searchAndFilterProductCategories,
    setSearchAndFilterProductCategories,
  ] = React.useState<undefined | ProductCategoryDtoList>();

  const openProductDetailsModal =
    props.workflow.currentModal === ProcurementFlowModals.PRODUCT_DETAILS;

  const showProductDetailsModal = useDebouncedValue(
    openProductDetailsModal,
    theme.transitions.duration.enteringScreen + 1
  );

  const [
    getAllProductCategoryData,
    { loading: loadingProductCategoryData, data: productCategoryData },
  ] = useSafeLazyQuery(GET_ALL_PRODUCT_CATEGORIES, {
    variables: {
      orderTypeId: officialOrderTypeId,
    },
    onCompleted: (productCategoryData) => {
      const productCategories =
        productCategoryData?.getAllProductCategories ?? [];
      if (!productCategories.length) return;
      const firstCategory = productCategoryData?.getAllProductCategories?.[0];
      setCurrentFilterId(firstCategory?.id as string);
      firstCategoryRef.current = firstCategory?.id as string;

      const items = productCategories.map(
        (category: any) => category as ProductCategoryDto
      );
      setSearchAndFilterProductCategories(() => ({
        count: productCategories.length,
        items,
      }));
    },
  });

  const assignBuyAndHoldOrderTypeId = (orderTypesData: any) => {
    const orderTypes = orderTypesData?.getAllOrderTypes ?? [];
    if (!orderTypes.length) return;

    const buyAndHoldOrderType = orderTypes.find(
      (orderType: any) => orderType.name === OrderType.PROCUREMENT_TO_DEPOT
    );
    setOfficialOrderTypeId(buyAndHoldOrderType?.id ?? '');
  };

  useQuery(GET_ALL_ORDER_TYPES, {
    onCompleted: (data) => {
      assignBuyAndHoldOrderTypeId(data);
    },
    onError: (error) => {},
  });

  const { data, isLoading } = useProductCatalogControllerGetByOrganization(
    {
      queryParams: {
        organizationId: userOrganization?.id as string,
        limit: DEFAULT_PAGE_LIMIT,
        offset: page * DEFAULT_PAGE_LIMIT,
        ...(currentFilterId !== 'All'
          ? {
              productCategoryId: currentFilterId,
            }
          : {}),
        ...(debouncedProductNameQuery
          ? {
              name: debouncedProductNameQuery,
            }
          : {}),
      },
    },
    {
      // enabled: [userOrganization?.id, currentFilterId].every(Boolean),
      enabled: !!userOrganization?.id,
    }
  );

  const getBackupFilterId = () => firstCategoryRef?.current ?? '';

  const showProductDetailModal = () =>
    showProductDetailsModal || openProductDetailsModal;

  const handleCloseModal = () => {
    props.workflow.dismissCurrentModal();
  };

  const itemsTotal = props.workflow.getCartItemsCount();

  React.useEffect(() => {
    dispatch(procurementToDepotSlice.actions.setCartTotal(itemsTotal));
  }, [itemsTotal]);

  React.useEffect(() => {
    //Remove current filter when search term changes so that the user sees results against all products
    setCurrentFilterId(() =>
      debouncedProductNameQuery ? 'All' : getBackupFilterId()
    );
  }, [debouncedProductNameQuery]);

  React.useEffect(() => {
    if (!officialOrderTypeId) return;
    firstCategoryRef.current = undefined;
    getAllProductCategoryData();
  }, [officialOrderTypeId]);

  // If a user selects a filter, we want to reset the page count to be zero and to remove
  // existing products from state so that only relavant items are shown to the user
  React.useEffect(() => {
    setPage(0);
    setProducts([]);
  }, [currentFilterId, debouncedProductNameQuery]);

  // Cannot leverage RQ's onSuccess function to set product state because
  // it is only invoke when the query fetches from the server and not cache
  // the data property will always be updated whether read from server or cache
  React.useEffect(() => {
    setProducts((previousProducts) => {
      const doesProductsContainData = previousProducts.some(
        (product) => product?.id === data?.items[0]?.id
      );

      if (!doesProductsContainData) {
        return [...previousProducts, ...(data?.items || [])];
      }
      return [...previousProducts];
    });
  }, [data?.items]);

  const canFetchMoreProducts = (data?.count || 0) > products.length;

  const fetchNextPage = () => {
    if (!isLoading) setPage(page + 1);
  };

  const onClickHandleBack = () => {
    props.workflow.updateProcurementFlowStep(
      ProcurementFlowStep.COLLABORATOR_VIEW
    );
  };

  return (
    <>
      <Container
        data-testid="marketplace-view-container-456def"
        sx={parentStyles.MainContainerSx}
      >
        <Stack
          sx={parentStyles.getContentStackSx()}
          direction="column"
          data-testid="marketplace-view-stack-789ghi"
        >
          <BuyAndHoldBanner data-testid="buy-and-hold-banner-101jkl" />
          <Stack
            sx={styles.MarketplaceViewLowerStackSx}
            data-testid="marketplace-view-lower-stack-202mno"
          >
            <Box data-testid="marketplace-view-upper-box-303pqr">
              <div data-testid="back-button-container-404stu">
                <Button
                  gaContext={{
                    textCopy: 'Back',
                    navigates_to: 'Depot',
                    purpose: 'Navigates To Depot',
                  }}
                  variant="text"
                  color="secondary"
                  startIcon={<ArrowBackIcon />}
                  onClick={onClickHandleBack}
                  sx={styles.BackButtonSx}
                  data-testid="back-button-505vwx"
                >
                  BACK
                </Button>
              </div>
              <Typography variant="h5" data-testid="add-items-header-606yz1">
                Add items to your cart
              </Typography>
              <Typography
                variant="h6"
                data-testid="select-devices-header-707abc"
              >
                Select devices to add to cart
              </Typography>
            </Box>
            <Stack
              sx={styles.CatalogContainerSx}
              data-testid="catalog-container-stack-808def"
            >
              <Stack
                sx={styles.getInnerCatalogStackSx()}
                data-testid="inner-catalog-stack-909ghi"
              >
                <Bubble
                  text="To"
                  color="colors.woodSmoke"
                  data-testid="bubble-to-101jkl"
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing="16px"
                  marginLeft="24px"
                  data-testid="flag-and-name-stack-202mno"
                >
                  <FlagIcon data-testid="flag-icon-303pqr" />
                  <Typography
                    variant="body2"
                    className="depot-name-text"
                    data-testid="depot-name-text-404stu"
                  >
                    {depotName}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                sx={{ ...styles.getInnerCatalogStackSx(false), mt: '20px' }}
                data-testid="search-filters-stack-505vwx"
              >
                <SearchAndFilters
                  productCategories={searchAndFilterProductCategories}
                  query={productNameQuery}
                  setQuery={setProductNameQuery}
                  currentFilterId={currentFilterId}
                  setCurrentFilterId={setCurrentFilterId}
                  data-testid="search-and-filters-606yz1"
                />
                <Divider
                  orientation="vertical"
                  flexItem
                  data-testid="vertical-divider-707abc"
                />
                <Box
                  id="scrollableDiv"
                  sx={styles.ScrollableBoxSx}
                  data-testid="scrollable-box-808def"
                >
                  <InfiniteScroll
                    dataLength={products.length}
                    next={fetchNextPage}
                    hasMore={canFetchMoreProducts}
                    loader={<></>}
                    scrollableTarget="scrollableDiv"
                    scrollThreshold={INFINITE_SCROLL_THRESHOLD}
                    data-testid="infinite-scroll-909ghi"
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      data-testid="product-grid-101jkl"
                    >
                      {products.map((item, index) => (
                        <ProductCatalogItem
                          key={item.id}
                          item={item}
                          index={index}
                          setCurrentModal={props.workflow.setCurrentModal}
                          addProductVariantToCart={
                            props.workflow.addProductVariantToCart
                          }
                          setProductCatalogItemForModal={
                            setProductCatalogItemForModal
                          }
                          showCartButton
                          data-testid={`product-item-${item.id}`}
                        />
                      ))}
                    </Grid>
                  </InfiniteScroll>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      {showProductDetailModal() ? (
        <ProductDetailsModal
          cart={props.workflow.cart}
          open={openProductDetailsModal}
          handleClose={() => handleCloseModal()}
          productCatalogItem={productCatalogItemForModal}
          addProductVariantToCart={props.workflow.addProductVariantToCart}
          showAddToOrderButton
          addProductButtonText="Add to cart"
          data-testid="product-details-modal-202mno"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MarketplaceView;
