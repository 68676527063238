import React from 'react';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { ExpandedRowOrderItemDisplayProps } from './types';
import {
  ExpandableRowCellDetailTextSx,
  ExpandableRowCellTrackingLinkSx,
  ExpandedRowCellHeaderTextSx,
  ExpandedTableRowSx,
  OrderItemTableSx,
  ExpandableProductTitleRowCellTextSx,
  ShipmentPillSx,
  FailedPillTypeSx,
} from './styles';
import { getItemProductTitle } from '../../utils';
import { ShipmentStatusPill } from 'components';
import { getOrderItemSource } from './utils';
import { ShipmentStatus, ShipmentStatuses } from 'globals/constants/shipment';

const ExpandedRowOrderItemDisplay = (
  props: ExpandedRowOrderItemDisplayProps
) => {
  return (
    <Container sx={{ width: '97%' }}>
      <Table sx={OrderItemTableSx}>
        {props.orderItems.map((orderItem) => (
          <TableRow sx={ExpandedTableRowSx}>
            <TableCell>
              <Stack>
                <Typography
                  color="colors.gray94"
                  sx={ExpandedRowCellHeaderTextSx}
                >
                  PRODUCT TITLE
                </Typography>
                <Typography
                  color="colors.black"
                  sx={ExpandableProductTitleRowCellTextSx}
                  data-testid={`ordered-item`}
                >
                  {getItemProductTitle(orderItem, props.order.orderType.name)}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack>
                <Typography
                  color="colors.gray94"
                  sx={ExpandedRowCellHeaderTextSx}
                >
                  SOURCE
                </Typography>
                <Typography
                  color="colors.black"
                  sx={ExpandableRowCellDetailTextSx}
                >
                  {getOrderItemSource(orderItem)}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack>
                <Typography
                  color="colors.gray94"
                  sx={ExpandedRowCellHeaderTextSx}
                >
                  SERIAL NUMBER
                </Typography>
                <Typography
                  color="colors.black"
                  sx={ExpandableRowCellDetailTextSx}
                >
                  {orderItem.serialNumber ?? 'Not available'}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack>
                <Typography
                  color="colors.gray94"
                  sx={ExpandedRowCellHeaderTextSx}
                >
                  TRACKING
                </Typography>
                {orderItem.tracking?.trackingLink ? (
                  <Link
                    color="colors.curiousBlue"
                    underline="hover"
                    target="_blank"
                    rel="noopener"
                    href={orderItem.tracking.trackingLink}
                  >
                    <Typography
                      color="colors.curiousBlue"
                      sx={ExpandableRowCellTrackingLinkSx}
                    >
                      {orderItem.tracking?.trackingNumber ?? 'Not Available'}
                    </Typography>
                  </Link>
                ) : (
                  <Typography
                    color="colors.black"
                    sx={ExpandableRowCellDetailTextSx}
                  >
                    Not available
                  </Typography>
                )}
              </Stack>
            </TableCell>
            <TableCell>
              <Stack>
                <Typography
                  color="colors.gray94"
                  sx={ExpandedRowCellHeaderTextSx}
                >
                  SHIPMENT STATUS
                </Typography>
                <Typography
                  color="colors.black"
                  sx={ExpandableRowCellDetailTextSx}
                >
                  <ShipmentStatusPill
                    status={orderItem?.shipmentStatus as ShipmentStatus}
                    statusText={
                      orderItem.shipment?.substatus ||
                      orderItem.shipmentStatus ||
                      ''
                    }
                    overrideSx={ShipmentPillSx}
                  />
                </Typography>
                {orderItem.shipmentStatus ===
                ShipmentStatuses.ATTEMPT_FAILED ? (
                  <Typography sx={FailedPillTypeSx}>
                    Logistics issue delaying order fulfillment. Please see Order
                    items tab for more information about this exception. If you
                    need help, please contact support.
                  </Typography>
                ) : (
                  <></>
                )}
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Container>
  );
};

export default ExpandedRowOrderItemDisplay;
