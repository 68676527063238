import { RetrievalStepSubRoutes, NavigationRoutes } from 'global-constants';
import Path from 'path-browserify';
import React from 'react';

export class Navigator {
  private basePath: string;

  constructor(basePath: string = NavigationRoutes.HOME) {
    this.basePath = basePath;
  }

  pathToProcurement() {
    return Path.join(this.basePath, NavigationRoutes.HOME);
  }

  pathToHome() {
    return Path.join(this.basePath, NavigationRoutes.HOME);
  }

  pathToSendEquipment() {
    return Path.join(this.basePath, NavigationRoutes.SEND_EQUIPMENT);
  }

  pathToBuyAndHold() {
    return Path.join(this.basePath, NavigationRoutes.BUY_AND_HOLD);
  }

  pathToOrders() {
    return Path.join(this.basePath, NavigationRoutes.ORDERS);
  }

  constructPathToOrderDetails(orderId: string) {
    return Path.join(this.basePath, `${NavigationRoutes.ORDERS}/${orderId}`);
  }

  constructSecurePathToOrderDetails(
    orderId: string,
    orgId: string,
    panelTab?: string
  ) {
    const tabString = panelTab ? `&panelTab=${panelTab}` : '';
    return Path.join(
      this.basePath,
      `${NavigationRoutes.ORDERS}/${orderId}?orgId=${orgId}${tabString}`
    );
  }

  pathToAllOrders() {
    const allOrdersParam = `?${NavigationRoutes.VIEW_ALL}`;
    return this.pathToOrders().concat(allOrdersParam);
  }

  pathToUnlockDeviceOrders() {
    const unlockDeviceOrdersParam = `?${NavigationRoutes.VIEW_ALL}&${NavigationRoutes.ORDER_UNLOCK_DEVICE_FILTER}`;
    return this.pathToOrders().concat(unlockDeviceOrdersParam);
  }

  pathToAllRetrievals() {
    const retrievalsParam = `?${NavigationRoutes.VIEW_RETRIEVALS}`;
    return this.pathToOrders().concat(retrievalsParam);
  }

  pathToFollowUpRequired() {
    const followUpParam = `?${NavigationRoutes.VIEW_FOLLOW_UP}`;
    return this.pathToOrders().concat(followUpParam);
  }

  pathToAssets() {
    return Path.join(this.basePath, NavigationRoutes.ASSETS);
  }

  constructPathToAssetDetails(assetId: string) {
    return Path.join(this.basePath, `${NavigationRoutes.ASSETS}/${assetId}`);
  }

  pathToAssetsDevices() {
    return Path.join(
      this.basePath,
      `${NavigationRoutes.ASSETS}?${NavigationRoutes.VIEW_DEVICES}`
    );
  }

  pathToAssetsMerchandise() {
    return Path.join(
      this.basePath,
      `${NavigationRoutes.ASSETS}?${NavigationRoutes.VIEW_MERCHANDISE}`
    );
  }

  pathToRequestNewItem() {
    return Path.join(this.basePath, NavigationRoutes.REQUEST_NEW_ITEM);
  }

  pathToManageUsers() {
    return Path.join(this.basePath, NavigationRoutes.MANAGE_USERS);
  }

  pathToEmployees() {
    return Path.join(this.basePath, NavigationRoutes.EMPLOYEES);
  }

  constructPathToEmployeeDetails(employeeId: string) {
    return Path.join(
      this.basePath,
      `${NavigationRoutes.EMPLOYEES}/${employeeId}`
    );
  }

  pathToRetrieval(subRoute?: RetrievalStepSubRoutes) {
    const urlSubRoute = subRoute ? `/${subRoute}` : '/*';
    return Path.join(
      this.basePath,
      `${NavigationRoutes.RETRIEVAL}${urlSubRoute}`
    );
  }

  pathToAssignDeviceFlow() {
    return Path.join(this.basePath, NavigationRoutes.ASSIGN_DEVICE);
  }

  pathToOscarDemo() {
    return Path.join(this.basePath, '/acme-equipment');
  }

  pathToBilling() {
    return Path.join(this.basePath, NavigationRoutes.BILLING);
  }

  pathToCatalog() {
    return Path.join(this.basePath, NavigationRoutes.CATALOG);
  }

  pathToOrderManagement() {
    return Path.join(this.basePath, NavigationRoutes.ORDER_MANAGEMENT);
  }

  pathToAssetManagement() {
    return Path.join(this.basePath, NavigationRoutes.ASSET_MANAGEMENT);
  }
}

export const NavigationContext = React.createContext<Navigator>(
  new Navigator()
);
