/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import NewModal from 'components/NewModal';
import ControlledEmployeeInput from 'pages/Retrieval/add_employee/forms/ControlledEmployeeInput';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { HomeOutlined, WorkOutline } from '@mui/icons-material';
import { useAddEmployeeModalState } from 'pages/Retrieval/add_employee/modals/AddEmployeeModal/hooks';
import { GlobalState } from 'store/types';
import { GlobalModals } from 'store/constants';
import {
  ADD_EMPLOYEE_INPUT_PROPS_TABLE,
  ADD_EMPLOYEE_FORM_MODAL_BASE_SELECTOR,
} from './constants';
import AddEmployeeModalProps from './types';
import * as AddEmployeeModalStyles from './styles';

const AddEmployeeModal = (props: AddEmployeeModalProps) => {
  const styles = AddEmployeeModalStyles;
  const {
    addEmployeeForm,
    closeAddEmployeeModal,
    loading,
    onSubmitHOF,
    newCollaboratorData,
  } = useAddEmployeeModalState(props.refetchEmployees);

  const userOrganization = useGetUserOrganization();
  const currentModal = useSelector<GlobalState>(
    (state) => state.modal.currentModal
  );

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = addEmployeeForm;

  const newEmployeeId = newCollaboratorData?.collaboratorCreate?.id ?? '';

  React.useEffect(() => {
    if (newEmployeeId) {
      props.selectNewEmployeeById(newEmployeeId);
    }
  }, [newEmployeeId]);

  const orgId = userOrganization?.id ?? '';

  const { firstName, lastName, workEmail, personalEmail } = watch();

  const hasRequiredFields = [
    firstName,
    lastName,
    workEmail || personalEmail,
    userOrganization?.id ?? '',
  ].every(Boolean);

  return (
    <NewModal
      form
      open={currentModal === GlobalModals.ADD_EMPLOYEE_INFORMATION}
      title={props?.modalTitle ?? 'Add Employee'}
      handleClose={closeAddEmployeeModal}
      onSubmit={handleSubmit(onSubmitHOF(hasRequiredFields, orgId))}
      disableSaveButton={!hasRequiredFields}
      loading={loading}
      dividerPlacement="both"
      formChildrenBoxSx={{
        maxHeight: '420px',
        overflowY: 'scroll',
      }}
      dataTestId={ADD_EMPLOYEE_FORM_MODAL_BASE_SELECTOR}
    >
      <Grid container pt="16px">
        <Grid container item xs={12} columnSpacing="25px">
          <Grid item xs={12} pb="12px">
            <Typography variant="body2">Personal Information</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledEmployeeInput
              register={register}
              control={control}
              addEmployeeFieldData={ADD_EMPLOYEE_INPUT_PROPS_TABLE.firstName}
              selectorPrefix={props?.selectorPrefix ?? ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledEmployeeInput
              register={register}
              control={control}
              addEmployeeFieldData={ADD_EMPLOYEE_INPUT_PROPS_TABLE.lastName}
            />
          </Grid>
          <Grid item xs={12} pb="12px" pt="40px">
            <Typography variant="body2">Contact Information</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledEmployeeInput
              register={register}
              control={control}
              addEmployeeFieldData={ADD_EMPLOYEE_INPUT_PROPS_TABLE.workEmail}
              error={errors.workEmail}
              errorMessage="Please enter a valid email"
              selectorPrefix={props?.selectorPrefix ?? ''}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WorkOutline />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} pl="20px">
            <ControlledEmployeeInput
              register={register}
              control={control}
              addEmployeeFieldData={
                ADD_EMPLOYEE_INPUT_PROPS_TABLE.personalEmail
              }
              error={errors.personalEmail}
              errorMessage="Please enter a valid email"
              selectorPrefix={props?.selectorPrefix ?? ''}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.EmployeeFormModalPhoneGridSx}>
            <ControlledEmployeeInput
              register={register}
              control={control}
              addEmployeeFieldData={ADD_EMPLOYEE_INPUT_PROPS_TABLE.phoneNumber}
              selectorPrefix={props?.selectorPrefix ?? ''}
            />
          </Grid>
          <Grid item xs={12} pt="40px" pb="12px">
            <Typography variant="body2">Employment Information</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledEmployeeInput
              register={register}
              control={control}
              addEmployeeFieldData={ADD_EMPLOYEE_INPUT_PROPS_TABLE.groupTeams}
              selectorPrefix={props?.selectorPrefix ?? ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledEmployeeInput
              register={register}
              control={control}
              addEmployeeFieldData={ADD_EMPLOYEE_INPUT_PROPS_TABLE.hireDate}
              selectorPrefix={props?.selectorPrefix ?? ''}
              inputLabelProps={{
                shrink: true,
                placeholder: '',
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledEmployeeInput
              register={register}
              control={control}
              addEmployeeFieldData={ADD_EMPLOYEE_INPUT_PROPS_TABLE.startDate}
              selectorPrefix={props?.selectorPrefix ?? ''}
              inputLabelProps={{
                shrink: true,
                placeholder: '',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </NewModal>
  );
};

export default AddEmployeeModal;
