import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RetrievalState,
  AddAssetPayload,
  ToggleAssetPayload,
  AddAddressPayload,
  GenericIdPayload,
  AnswerRetrievalPayload,
  RetrievalCollaborator,
  RetrievalOptionAnswer,
} from './types';
import { RetrievalFlowStep } from './constants';
import { RetrievalOption } from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueForm/types';
import { HomeAddress, SelectableRecipientAddress, WorkAddress } from 'types';

export const initialState = {
  currentStep: `${RetrievalFlowStep.CHOOSE_TO_FROM_LOCATIONS}`,
  retrievalOrigin: undefined,
  selectedEmployees: [],
  leavingReviewStepTo: '',
  retrievalDestination: undefined,
  stepCompleteIsEmployee: false,
  stepCompleteIsLocation: false,
  stepCompleteIsReview: false,
  stepCompleteIsSubmit: false,
  assetsOptionAnswerKey: {},
  assetOptionsByType: {},
} as RetrievalState;

const ERROR_OUTPUT_STRING =
  'ERROR_OCCURRED_IN_RETRIEVAL_SERVICE_STORE (src/pages/Retrieval/RetrievalService/store.ts)';
const getErrorMessage = (errorSource: string): string => {
  return `${ERROR_OUTPUT_STRING}: in ${errorSource}.`;
};

export const retrievalServiceSlice = createSlice({
  name: 'retrievalService',
  initialState: () => initialState,
  reducers: {
    addAssetToEmployee: (state, action: PayloadAction<AddAssetPayload>) => {
      const employee = state.selectedEmployees.find(
        (staff) => staff.id === action.payload.id
      );
      if (employee) {
        employee?.assets.unshift(action.payload.asset);
        state.assetsOptionAnswerKey[action.payload.asset.id] =
          state.assetsOptionAnswerKey[action.payload.asset.type];
      }
    },
    addShippingAddressOptionsToEmployee: (
      state,
      action: PayloadAction<{
        id: string;
        addresses: SelectableRecipientAddress[];
      }>
    ) => {
      const employee = state.selectedEmployees.find(
        (staff) => staff.id === action.payload.id
      );
      // @ts-ignore
      employee.shippingAddressOptions = action.payload.addresses;
    },
    addHomeAndWorkAddressesToEmployee: (
      state,
      action: PayloadAction<{
        id: string;
        homeAddresses: HomeAddress[];
        workAddresses: WorkAddress[];
      }>
    ) => {
      const employee = state.selectedEmployees.find(
        (staff) => staff.id === action.payload.id
      );
      // @ts-ignore
      employee.homeAddresses = action.payload.homeAddresses;
      // @ts-ignore
      employee.workAddresses = action.payload.workAddresses;
    },
    addShippingAddressToEmployee: (
      state,
      action: PayloadAction<AddAddressPayload>
    ) => {
      const employee = state.selectedEmployees.find(
        (staff) => staff.id === action.payload.id
      );
      // @ts-ignore
      employee.shippingAddress = action.payload.address;
    },
    removeShippingAddressFromEmployee: (
      state,
      action: PayloadAction<GenericIdPayload>
    ) => {
      const employee = state.selectedEmployees.find(
        (staff) => staff.id === action.payload.id
      );
      // @ts-ignore
      employee.shippingAddress = undefined;
    },
    assignRetrievalOrigin: (state, action) => {
      state.retrievalOrigin = action.payload;
    },
    assignRetrievalDestination: (state, action) => {
      state.retrievalDestination = action.payload;
    },
    cancelReviewStep: (state) => {
      state.currentStep = RetrievalFlowStep.EMPLOYEE_SELECTION;
    },
    cancelSelectEmployeesStep: (state) => {
      state.currentStep = RetrievalFlowStep.CHOOSE_TO_FROM_LOCATIONS;
    },
    cancelSelectLocationsStep: () => initialState,
    cancelSubmitStep: (state, _) => {
      state.currentStep = RetrievalFlowStep.REVIEW_SELECTIONS;
    },
    clearEmployees: (state) => {
      state.selectedEmployees = [];
    },
    initOptionValuesByType: (
      state,
      action: PayloadAction<Record<string, RetrievalOption[]>>
    ) => {
      state.assetOptionsByType = action.payload;
    },
    initAssetsOptionAnswerKey: (
      state,
      action: PayloadAction<Record<string, RetrievalOptionAnswer[]>>
    ) => {
      Object.keys(action.payload).forEach((optionAnswerKey) => {
        state.assetsOptionAnswerKey[optionAnswerKey] =
          action.payload[optionAnswerKey];
      });
    },
    removeEmployee: (state, action) => {
      const newList = state.selectedEmployees.filter(
        (employee) => employee.id !== action.payload
      );

      state.selectedEmployees = newList;
    },
    setEmployeeShipping: (state, action: PayloadAction<[string, boolean]>) => {
      const [employeeId, rushValue] = action.payload;

      const foundEmployee = state.selectedEmployees.find(
        (employee) => employee.id === employeeId
      );

      if (!foundEmployee) return;

      foundEmployee.isRush = rushValue;
    },
    setEmployeeEmail: (state, action: PayloadAction<[string, string]>) => {
      const [employeeId, email] = action.payload;

      const foundEmployee = state.selectedEmployees.find(
        (employee) => employee.id === employeeId
      );

      if (!foundEmployee) return;

      foundEmployee.contactEmail = email;
    },
    resetRetrievalState: () => initialState,
    selectEmployees: (
      state,
      action: PayloadAction<RetrievalCollaborator[]>
    ) => {
      // eslint-disable-next-line sonarjs/no-unused-collection
      const addedAssets: any[] = [];
      const notEmpty = !!state.selectedEmployees.length;
      if (notEmpty) {
        const associateByIdTable = state.selectedEmployees.reduce(
          (acc: Record<string, number>, employee: RetrievalCollaborator) => {
            acc[employee.id] = 1;
            return acc;
          },
          {}
        );
        action.payload.forEach((associate: RetrievalCollaborator) => {
          const hasAssets = associate.assets.length > 0;
          const employeeIsNew = !(associate.id in associateByIdTable);
          if (employeeIsNew) {
            state.selectedEmployees.push(associate);
          }

          if (employeeIsNew && hasAssets) {
            addedAssets.push(associate.assets);
          }
        });
      } else {
        state.selectedEmployees = action.payload;
        action.payload.forEach(({ assets }) => {
          addedAssets.push(assets);
        });
      }

      // add assets of newly selected employees to answer lookup w/ default values
      addedAssets
        .flatMap((assetArrays) => assetArrays)
        .forEach((asset) => {
          if (!(asset.id in state.assetsOptionAnswerKey)) {
            state.assetsOptionAnswerKey[asset.id] =
              state.assetsOptionAnswerKey[asset.type];
          }
        });
    },
    answerRetrievalOptions: (
      state,
      action: PayloadAction<AnswerRetrievalPayload>
    ) => {
      const { assetId, retrievalAnswer } = action.payload;
      const optionAnswers =
        state.assetsOptionAnswerKey[
          assetId as keyof typeof state.assetsOptionAnswerKey
        ];

      const foundAnswerIndex = optionAnswers.findIndex(
        (answer: RetrievalOptionAnswer) =>
          answer.optionId === retrievalAnswer.optionId
      );

      if (foundAnswerIndex !== -1) {
        state.assetsOptionAnswerKey[
          assetId as keyof typeof state.assetsOptionAnswerKey
        ][foundAnswerIndex] = retrievalAnswer;
      }
    },
    toggleEmployeeAsset: (_, __) => {},
    toggleGlobalAssetSelection: (
      state,
      action: PayloadAction<ToggleAssetPayload>
    ) => {
      state.selectedEmployees = state.selectedEmployees.map((employee) => {
        if (employee.id === action.payload.employeeId) {
          employee.assets = employee.assets.map((asset) => {
            if (asset.id === action.payload.assetId) {
              asset.removeAsset = !action.payload.selected;
            }
            return asset;
          });
        }
        return employee;
      });
    },
    updateActiveStep: (state, action) => {
      state.currentStep = action.payload;
    },
    updateStepCompletion: (state, action) => {
      const { step, complete } = action.payload;
      switch (step) {
        case step === RetrievalFlowStep.CHOOSE_TO_FROM_LOCATIONS:
          state.stepCompleteIsLocation = complete;
          break;
        case step === RetrievalFlowStep.EMPLOYEE_SELECTION:
          state.stepCompleteIsEmployee = complete;
          break;
        case step === RetrievalFlowStep.REVIEW_SELECTIONS:
          state.stepCompleteIsReview = complete;
          break;
        case step === RetrievalFlowStep.RETRIEVAL_SUBMISSION:
          state.stepCompleteIsSubmit = complete;
          break;
        default:
          throw new Error(getErrorMessage('updateStepCompletion action'));
      }
    },
  },
});
