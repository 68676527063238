import { FilterEntry, IndexedFilterEntry, ComplexSearchEntry } from './types';

export const getIndexedEntryLookupByUniqueKey = (
  filterEntries: FilterEntry[],
  uniqueKey: 'id' | 'displayName' | 'value' // keys unique to sub-sub filter entries
): Record<string, IndexedFilterEntry> => {
  if (!filterEntries.length) return {};
  const lookupEntriesMap = filterEntries.map((entry, index) => {
    return [entry[uniqueKey], { ...entry, index }];
  });
  return Object.fromEntries(lookupEntriesMap);
};

export const getComplexSearchEntriesDTO = (
  selectEntries: FilterEntry[],
  mappingTable: Record<string, string>
): ComplexSearchEntry[] => {
  const complexEntries: ComplexSearchEntry[] = [];
  const entryKeySet: Set<string> = new Set();
  selectEntries.forEach(({ key }) => {
    entryKeySet.add(key);
  });

  const entryKeys = Array.from(entryKeySet);
  const completeTable = entryKeys.every((k) => mappingTable[k]);

  if (!completeTable) {
    console.error(
      'Incomplete mapping table. Some Keys in selected entries were not found in mapping table.'
    );
    return complexEntries;
  }

  entryKeys.forEach((key) => {
    const complexSearchTerm = mappingTable[key];
    const complexSearchValues = selectEntries
      .filter((entry) => entry.key === key)
      .map((entry) => entry.value);

    complexEntries.push({ complexSearchTerm, complexSearchValues });
  });

  return complexEntries;
};

export const emptyEntriesWithExceptions = (
  currentEntries: FilterEntry[]
): FilterEntry[] => {
  const exemptEntries: FilterEntry[] = [];

  currentEntries.forEach((entry) => {
    if (entry?.softReset) {
      exemptEntries.push({
        ...entry,
        selected: false,
      });
    }
  });

  return exemptEntries;
};

export const getFilterOpFromEntries = (options: string | string[]) =>
  Array.isArray(options) && options.length > 1 ? 'in' : 'equals';
