import { MANAGERS_ROLE } from 'pages/OrderManagement/constants';
import themeColors from 'themes/colors';

const getBadgeColorsByOMRole = (role: string) => {
  const badgeColors = {
    bgcolor: themeColors.grayBD,
    color: themeColors.white,
  };

  switch (role) {
    case MANAGERS_ROLE.ORDER_MANAGEMENT:
      badgeColors.bgcolor = 'colors.primaryDkYellow';
      badgeColors.color = 'colors.woodSmoke';
      break;
    case MANAGERS_ROLE.PROCUREMENT:
      badgeColors.bgcolor = 'colors.infoLtBlue';
      break;
    case MANAGERS_ROLE.ASSET_MANAGEMENT:
      badgeColors.bgcolor = 'colors.successLtGreen';
      break;
    case MANAGERS_ROLE.OTHER:
      badgeColors.bgcolor = 'colors.warnLtYellow';
      break;
    default:
      console.error(`Role ${role} is not a valid Order Management role.`);
      break;
  }

  return badgeColors;
};

export default getBadgeColorsByOMRole;
