const INSURANCE_OPTION_NAME = 'Insurance- Maximum Coverage';
const INSURANCE_OPTION_EXCEMPTION_LOCATIONS = ['Canada location'];

const retrievalOptionExcemptions: Record<string, string[]> = {
  [INSURANCE_OPTION_NAME]: INSURANCE_OPTION_EXCEMPTION_LOCATIONS,
};

export const excludeExcemptedOptionsFilter = (
  option: string,
  location?: string
) => !retrievalOptionExcemptions[option]?.includes(location ?? '');
