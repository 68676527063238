/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import GenericAutocomplete from 'components/GenericAutocomplete';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import useDebouncedSearch from 'hooks/useDebouncedSearch';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { DEVICE_FILTERS_WIDTH } from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/constants';
import { GET_ASSETS_BY_MAKE } from './queries';
import { AssetMakeFilterProps } from './types';
import { ASSET_MAKE_INPUT_ID } from './constants';
import * as AssetMakeFilterStyles from './styles';

const AssetMakeFilter = (props: AssetMakeFilterProps) => {
  const styles = AssetMakeFilterStyles;
  const organization = useGetUserOrganization();
  const [assetMake, setAssetMake] = React.useState('');
  const [makeOptions, setMakeOptions] = React.useState<string[]>([]);
  const debouncedAssetMake = useDebouncedSearch(assetMake, 300);

  const [fetchAssetsByMake] = useSafeLazyQuery(GET_ASSETS_BY_MAKE, {
    onCompleted(data) {
      const { searchAssets } = data;
      const assetSet = new Set<string>();
      const assets = searchAssets?.assets || [];
      if (!assets.length) return;
      const tempOptions = assets
        .map((asset: any) => asset.make)
        .filter((make: string) => {
          if (assetSet.has(make)) return false;
          assetSet.add(make);
          return true;
        });

      setMakeOptions(tempOptions);
    },
  });

  const handleInputChange = (_e: any, value: any) => {
    if (value.length >= 3) {
      setAssetMake(value);
    }
  };

  const clearAllAutoCompleteOptions = () => {
    const clearTextIconButton: HTMLElement = document.querySelector(
      `#${ASSET_MAKE_INPUT_ID} .MuiAutocomplete-clearIndicator`
    ) as HTMLElement;
    if (clearTextIconButton) {
      clearTextIconButton.click();
    }
    setAssetMake('');
    setMakeOptions([]);
    props.setChosenAssetMake('');
  };

  const handleAutoCompleteChange = (event: any, value: any) => {
    if (value === null) {
      clearAllAutoCompleteOptions();
      return;
    }

    props.setChosenAssetMake(value);
  };

  React.useEffect(() => {
    if (!organization?.id || !debouncedAssetMake) return;
    fetchAssetsByMake({
      variables: {
        organizationId: organization.id,
        iLike: {
          field: 'make',
          value: `%${debouncedAssetMake}%`,
        },
      },
    });
  }, [organization?.id, debouncedAssetMake]);

  React.useEffect(() => {
    if (props.chosenAssetMake) return;
    clearAllAutoCompleteOptions();
  }, [props.chosenAssetMake]);

  return (
    <Stack
      direction="column"
      sx={styles.AutoCompleteStackSx}
      id={ASSET_MAKE_INPUT_ID}
    >
      <GenericAutocomplete
        loading={false}
        onChange={handleAutoCompleteChange}
        onInputChange={handleInputChange}
        componentsProps={{
          popper: {
            style: {
              width: DEVICE_FILTERS_WIDTH,
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Start typing"
            label="Make"
            aria-label="Make"
            sx={styles.AutoCompleteSearchTextFieldSx}
          />
        )}
        sx={styles.AutoCompleteSx}
        options={makeOptions}
      />
    </Stack>
  );
};

export default AssetMakeFilter;
