import React from 'react';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import { StatusTransitionConfigTable, StatusTransitionRow } from 'types';
import { GetIconCallback, UseGetOrderStatusTimeLineMapper } from './types';
import { StatusTransitionItem } from 'components/status_history_timeline/types';
import { ORDER_DETAILS_ORDER_ITEMS_TAB_BUTTON_SELECT_ID } from 'pages/OrderDetails/constants';
import AdditionalOrderStatusDetails from 'pages/OrderDetails/order_status_history/AdditionalOrderStatusDetails';

export const useClickOrderItemsTab = () => {
  const orderItemsTab = document.querySelector(
    `[data-selectid="${ORDER_DETAILS_ORDER_ITEMS_TAB_BUTTON_SELECT_ID}"]`
  ) as HTMLElement;
  if (orderItemsTab) {
    orderItemsTab.click();
  }
};

const useGetOrderStatusTimelineMapper: UseGetOrderStatusTimeLineMapper =
  (
    statusConfigTable: StatusTransitionConfigTable,
    getIconCallback: GetIconCallback
  ) =>
  (
    transitionItem: StatusTransitionRow,
    isActive: boolean
  ): StatusTransitionItem => {
    const { toStatus, createdAt } = transitionItem;
    const transitionConfig =
      toStatus in statusConfigTable
        ? statusConfigTable[toStatus]
        : statusConfigTable.FALLBACK;
    const displayStatus =
      toStatus in statusConfigTable
        ? transitionConfig.displayStatus
        : toStatus.split('_').map(capitalize).join(' ');
    const { activeBGColor, activeTextColor } = transitionConfig;
    const dateTimeStr = moment(createdAt).format('MM/DD/YYYY h:mm A');

    return {
      IconAvatar: getIconCallback({
        isActive,
        activeBGColor,
        activeTextColor,
        toStatus,
      }),
      displayStatus,
      dateTimeStr,
      MoreDetails: <AdditionalOrderStatusDetails toStatus={toStatus} />,
    };
  };

export default useGetOrderStatusTimelineMapper;
