/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import OrderStatusTransitionTimeLineProps from './types';
import StatusTransitionTimeline from 'components/status_history_timeline/StatusTransitionTimeLine';
import StatusHistoryIcon from 'pages/OrderDetails/order_status_history/StatusHistoryIcon';
import useGetOrderStatusTimelineMapper from './hooks';
import { ORDER_STATUS_TRANSITION_CONFIG } from './constants';

const OrderStatusTransitionTimeLine = (
  props: OrderStatusTransitionTimeLineProps
) => {
  const {
    statusTransitionRecords,
    revealItemsCount,
    createdAt,
    currentStatus,
  } = props;
  const convertToTimelineItem = useGetOrderStatusTimelineMapper(
    ORDER_STATUS_TRANSITION_CONFIG,
    StatusHistoryIcon
  );

  const doNotRender = !statusTransitionRecords || !revealItemsCount;

  if (doNotRender) return <section>No Order Status Transition History</section>;

  const transitionRecords = props.statusTransitionRecords.slice(
    0,
    revealItemsCount
  );

  const transitionTimeLineItems = transitionRecords.map((rec, i) =>
    convertToTimelineItem(rec, i === 0)
  );

  if (!transitionRecords.length) {
    const onlyRecord = {
      toStatus: currentStatus,
      fromStatus: 'CREATED',
      createdAt: createdAt as unknown as Date,
      recordId: 'current',
    };
    transitionTimeLineItems.push(convertToTimelineItem(onlyRecord, true));
    return (
      <StatusTransitionTimeline
        transitionTimeLineItems={transitionTimeLineItems}
      />
    );
  }

  // Add Initial Entry
  const showLast = revealItemsCount >= props.statusTransitionRecords.length;
  const lastRecord = transitionRecords[transitionRecords.length - 1];
  if (lastRecord?.fromStatus && showLast) {
    const finalRecord = {
      ...lastRecord,
      toStatus: lastRecord.fromStatus,
      createdAt: createdAt as unknown as Date,
    };
    transitionTimeLineItems.push(convertToTimelineItem(finalRecord, false));
  }

  return (
    <StatusTransitionTimeline
      transitionTimeLineItems={transitionTimeLineItems}
    />
  );
};

export default OrderStatusTransitionTimeLine;
