import { AssetConditionEnum } from 'global-constants';
import { useSafeMutation } from 'hooks/useSafeMutation';
import React from 'react';
import { getEnumKeyByEnumValue } from 'services';
import { Asset, AssetType, Collaborator } from 'types';
import { UPDATE_ASSET } from '../../mutations';
import { CreateAssetInput, UpdateAssetInput, UseDeviceModal } from './types';

export const useDeviceModal = (device?: Asset): UseDeviceModal => {
  const [assetStatus, setAssetStatus] = React.useState(
    // TODO cleanup MONO replacement post ERP integration
    device?.status?.replace('MONO_', '')
  );

  const [serialNumber, setSerialNumber] = React.useState(device?.serialNumber);

  const [assetType, setAssetType] = React.useState<AssetType | undefined>(
    device?.assetType
  );

  const [cosmeticCondition, setCosmeticCondition] = React.useState(
    device?.cosmeticCondition?.trim()
  );

  const [make, setMake] = React.useState(device?.make);

  const [model, setModel] = React.useState(device?.model);

  const [deviceAssessment, setDeviceAssessment] = React.useState(
    device?.deviceAssessment
  );

  const [color, setColor] = React.useState(device?.color);

  const [displaySize, setDisplaySize] = React.useState(device?.displaySize);

  const [memory, setMemory] = React.useState(device?.memory);

  const [storage, setStorage] = React.useState(device?.storage);

  const [processor, setProcessor] = React.useState(device?.processor);

  const [keyboard, setKeyboard] = React.useState(device?.keyboard);

  const [purchaseDate, setPurchaseDate] = React.useState(device?.purchaseDate);

  const [warrantyExpiration, setWarrantyExpiration] = React.useState(
    device?.warrantyExpiration
  );

  const [selectedEmployee, setSelectedEmployee] =
    React.useState<Collaborator | null>(device?.assignee ?? null);

  const [executeUpdateAssetDetails, { loading }] =
    useSafeMutation(UPDATE_ASSET);

  const isDirty = () => {
    return (
      (assetStatus !== device?.status?.replace('MONO_', '') ||
        serialNumber !== device?.serialNumber ||
        assetType?.name !== device?.assetType?.name ||
        cosmeticCondition !== device?.cosmeticCondition?.trim() ||
        make !== device?.make ||
        model !== device?.model ||
        deviceAssessment !== device?.deviceAssessment ||
        color !== device?.color ||
        displaySize !== device?.displaySize ||
        memory !== device?.memory ||
        storage !== device?.storage ||
        processor !== device?.processor ||
        keyboard !== device?.keyboard ||
        selectedEmployee !== device?.assignee ||
        purchaseDate !== device?.purchaseDate ||
        warrantyExpiration !== device?.warrantyExpiration) ??
      null
    );
  };

  const constructUpdateAssetPayload = (): UpdateAssetInput => {
    return {
      id: device?.id as string,
      status: `MONO_${assetStatus}`,
      assigneeId: selectedEmployee?.id ? selectedEmployee?.id : null,
      serialNumber,
      assetTypeId: assetType?.id,
      cosmeticCondition: getEnumKeyByEnumValue(
        AssetConditionEnum,
        cosmeticCondition ?? ''
      ),
      make,
      model,
      deviceAssessment,
      color,
      displaySize,
      memory,
      storage,
      processor,
      keyboard,
      ...(purchaseDate && {
        purchaseDate,
      }),
      ...(warrantyExpiration && {
        warrantyExpiration,
      }),
    };
  };

  const constructCreateAssetPayload = (
    organizationId: string
  ): CreateAssetInput => {
    return {
      organizationId,
      status: `MONO_${assetStatus}`,
      assigneeId: selectedEmployee?.id,
      serialNumber,
      assetTypeId: assetType?.id as string,
      cosmeticCondition: getEnumKeyByEnumValue(
        AssetConditionEnum,
        cosmeticCondition ?? ''
      ),
      make,
      model,
      deviceAssessment,
      color,
      displaySize,
      memory,
      storage,
      processor,
      keyboard,
      ...(purchaseDate && {
        purchaseDate,
      }),
      ...(warrantyExpiration && {
        warrantyExpiration,
      }),
    };
  };

  const clearForm = () => {
    setAssetStatus(undefined);
    setSerialNumber(undefined);
    setAssetType(undefined);
    setCosmeticCondition(undefined);
    setMake(undefined);
    setModel(undefined);
    setDeviceAssessment(undefined);
    setColor(undefined);
    setDisplaySize(undefined);
    setMemory(undefined);
    setStorage(undefined);
    setProcessor(undefined);
    setKeyboard(undefined);
    setSelectedEmployee(null);
    setPurchaseDate(undefined);
    setWarrantyExpiration(undefined);
  };

  const executeUpdateAssetDetailsMutation = async (onCompleted: any) => {
    await executeUpdateAssetDetails({
      variables: constructUpdateAssetPayload(),
      update: (cache, { data: { updateAsset } }) => {
        cache.modify({
          optimistic: true,
          fields: {
            searchAssets(data) {
              const assets = data.assets || [];
              return assets.map((asset: Asset) =>
                asset.recordId === device?.recordId
                  ? {
                      ...asset,
                      ...updateAsset,
                    }
                  : asset
              );
            },
          },
        });
      },
      onCompleted,
    });
  };

  return {
    assetStatus,
    setAssetStatus,
    serialNumber,
    setSerialNumber,
    assetType,
    setAssetType,
    cosmeticCondition,
    setCosmeticCondition,
    make,
    setMake,
    model,
    setModel,
    deviceAssessment,
    setDeviceAssessment,
    color,
    setColor,
    displaySize,
    setDisplaySize,
    memory,
    setMemory,
    storage,
    setStorage,
    processor,
    setProcessor,
    keyboard,
    setKeyboard,
    selectedEmployee,
    setSelectedEmployee,
    purchaseDate,
    setPurchaseDate,
    warrantyExpiration,
    setWarrantyExpiration,
    isDirty,
    constructCreateAssetPayload,
    clearForm,
    updateAssetDetails: executeUpdateAssetDetailsMutation,
    updateAssetDetailsLoading: loading,
  };
};
