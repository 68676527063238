/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MuiTelInput } from 'mui-tel-input';
import { AddEmployeeFormModalData } from '../../types';
import ControlledEmployeeInputProps from './types';

const ControlledEmployeeInput = (
  props: ControlledEmployeeInputProps<AddEmployeeFormModalData>
) => {
  const {
    ariaRequired,
    name,
    registerName,
    label,
    baseSelector,
    registerOptions = {},
    InputFieldSx,
  } = props.addEmployeeFieldData;

  const selectorPrefix = props?.selectorPrefix
    ? `${props.selectorPrefix}-`
    : '';
  const inputId = `${selectorPrefix}${baseSelector}-id`;
  const testId = `${selectorPrefix}${baseSelector}-test-id`;

  const inputProps = { InputProps: props?.inputProps ?? {} };
  const inputLabelProps = { InputLabelProps: props?.inputLabelProps ?? {} };
  const isDateType = name === 'hireDate' || name === 'startDate';

  if (name === 'phoneNumber') {
    return (
      <Controller
        name={name}
        control={props.control}
        render={({ field }) => (
          <MuiTelInput
            id={inputId}
            label={label}
            sx={InputFieldSx}
            {...field}
            {...props.register(registerName, registerOptions)}
            onChange={(value, info) => field.onChange(value, info)}
          />
        )}
      />
    );
  }

  return (
    <>
      {ariaRequired ? (
        <Controller
          name={name}
          control={props.control}
          render={({ field }) => (
            <TextField
              id={inputId}
              label={label}
              {...(isDateType && { type: 'date' })}
              data-testid={testId}
              aria-required
              required
              variant="outlined"
              {...field}
              {...props.register(registerName, registerOptions)}
              {...inputProps}
              {...inputLabelProps}
              sx={InputFieldSx}
            />
          )}
        />
      ) : (
        <Controller
          name={name}
          control={props.control}
          render={({ field }) => (
            <TextField
              id={inputId}
              label={label}
              data-testid={testId}
              {...(isDateType && { type: 'date' })}
              variant="outlined"
              {...field}
              {...props.register(registerName, registerOptions)}
              sx={InputFieldSx}
              {...inputProps}
              {...inputLabelProps}
            />
          )}
        />
      )}
      {!!props?.error && !!props?.errorMessage ? (
        <Typography variant="body2" color="red">
          {props.errorMessage}
        </Typography>
      ) : (
        <></>
      )}
    </>
  );
};

export default ControlledEmployeeInput;
