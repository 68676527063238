import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearOMSelfAssignSwitch,
  resetOMComboSearch,
} from 'store/slices/order_management/search';
import {
  OMDashFilterKeys,
  V2_FIND_AND_COUNT_ORDERS_OUTPUT,
} from 'pages/OrderManagement/filters/shared/constants';
import { FILTER_STATE_KEYS } from 'store/constants';
import {
  selectAllChosenEntries,
  selectChosenEntries,
  selectIsFilterDisabled,
} from 'store/shared/selectors';
import { orderManagementFilterActions } from 'store/slices/order_management/filter';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { MANAGER_MODEL_FIELD_BY_ROLE } from 'pages/OrderManagement/constants';
import { getV2FindAndCountOrders } from 'api/apollo/queries/v2';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { Order } from 'types';
import {
  toggleOMDetailsModal,
  setOMSelectedOrder,
} from 'store/slices/order_management/details';

export const useClearSelfAssignSwitch = () => {
  const dispatch = useDispatch();
  const selectedFilterEntries =
    useSelector(selectAllChosenEntries(FILTER_STATE_KEYS.ORDER_MANAGEMENT)) ||
    [];

  const hasOMSelection = selectedFilterEntries.some(
    (entry) => entry.key === OMDashFilterKeys.ORDER_MANAGER
  );

  return React.useEffect(() => {
    if (!hasOMSelection) return;
    dispatch(clearOMSelfAssignSwitch());
  }, [hasOMSelection]);
};

export const useClearOMOrderStatusEffect = (clearOrDisableStatus: boolean) => {
  const dispatch = useDispatch();

  const selectedOrderStatuses = useSelector(
    selectChosenEntries(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.STATUS
    )
  );

  return React.useEffect(() => {
    if (selectedOrderStatuses.length && clearOrDisableStatus) {
      dispatch(
        orderManagementFilterActions.clearFilterEntriesByKeyReducer({
          key: OMDashFilterKeys.STATUS,
        })
      );
    }
  }, [clearOrDisableStatus, selectedOrderStatuses.length]);
};

export const useClearOMOrderTypeEffect = (clearOrDisableOrderType: boolean) => {
  const dispatch = useDispatch();

  const selectedOrderTypes = useSelector(
    selectChosenEntries(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.ORDER_TYPE
    )
  );

  return React.useEffect(() => {
    if (selectedOrderTypes.length && clearOrDisableOrderType) {
      dispatch(
        orderManagementFilterActions.clearFilterEntriesByKeyReducer({
          key: OMDashFilterKeys.ORDER_TYPE,
        })
      );
    }
  }, [clearOrDisableOrderType, selectedOrderTypes.length]);
};

export const useDisableOrderStatus = (tabDisablesStatus: boolean) => {
  const dispatch = useDispatch();

  const orderStatusAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.STATUS
    )
  );

  return React.useEffect(() => {
    if (tabDisablesStatus && !orderStatusAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.disableFilterReducer(
          OMDashFilterKeys.STATUS
        )
      );
    }
  }, [tabDisablesStatus, orderStatusAlreadyDisabled]);
};

export const useEnableOrderStatus = (tabDisablesStatus: boolean) => {
  const dispatch = useDispatch();

  const orderStatusAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.STATUS
    )
  );

  return React.useEffect(() => {
    if (!tabDisablesStatus && orderStatusAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.enableFilterReducer(
          OMDashFilterKeys.STATUS
        )
      );
    }
  }, [tabDisablesStatus, orderStatusAlreadyDisabled]);
};

export const useEnableOrderTypeFilter = (
  tabShouldDisableOrderType: boolean
) => {
  const dispatch = useDispatch();

  const orderTypeAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.ORDER_TYPE
    )
  );

  return React.useEffect(() => {
    if (!tabShouldDisableOrderType && orderTypeAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.enableFilterReducer(
          OMDashFilterKeys.ORDER_TYPE
        )
      );
    }
  }, [tabShouldDisableOrderType, orderTypeAlreadyDisabled]);
};

export const useDisableOrderTypeFilter = (
  tabShouldDisableOrderType: boolean
) => {
  const dispatch = useDispatch();

  const orderTypeAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.ORDER_TYPE
    )
  );

  return React.useEffect(() => {
    if (tabShouldDisableOrderType && !orderTypeAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.disableFilterReducer(
          OMDashFilterKeys.ORDER_TYPE
        )
      );
    }
  }, [tabShouldDisableOrderType, orderTypeAlreadyDisabled]);
};

export const useResetComboSearch = () => {
  const dispatch = useDispatch();

  const selectedFilterEntries =
    useSelector(selectAllChosenEntries(FILTER_STATE_KEYS.ORDER_MANAGEMENT)) ||
    [];

  return React.useEffect(() => {
    if (!selectedFilterEntries.length) return;
    dispatch(resetOMComboSearch());
  }, [selectedFilterEntries.length]);
};

export const useGetOMOrderTableSort = () => {
  const defaultSort = { createdAt: 'desc' };
  const [orderSortModel, setOrderSortModel] =
    React.useState<Record<string, any>>(defaultSort);

  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel): void => {
      if (!sortModel.length) {
        setOrderSortModel(defaultSort);
      } else {
        const { field, sort: sortDirection } = sortModel[0];

        setOrderSortModel({
          [MANAGER_MODEL_FIELD_BY_ROLE[field]]: {
            lastName: sortDirection === 'asc' ? 'asc' : 'desc',
          },
        });
      }
    },
    []
  );

  return { orderSortModel, handleSortModelChange };
};

export const useGetTimelySelectedOrderInfo = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [getSelectedOrdersLazyQuery] = useSafeLazyQuery(
    getV2FindAndCountOrders(V2_FIND_AND_COUNT_ORDERS_OUTPUT)
  );

  const getSelectedOrders = React.useCallback(
    (selectOrderId: string) => {
      getSelectedOrdersLazyQuery({
        variables: {
          where: {
            AND: [{ createdAt: { gt: 1 } }, { id: { in: [selectOrderId] } }],
          },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: (data) => {
          const getPathOrders =
            data?.v2FindAndCountOrders?.orders ?? ([] as Order[]);

          if (getPathOrders.length) {
            dispatch(setOMSelectedOrder(getPathOrders[0]));
            dispatch(toggleOMDetailsModal(true));
          }
        },
      });
    },
    [dispatch, getSelectedOrdersLazyQuery]
  );

  return { getSelectedOrders };
};
