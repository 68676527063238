import { SxProps } from '@mui/material';
import {
  MAIN_STACK_SIZE_MD,
  MAIN_STACK_SIZE_SM,
} from 'components/NewModal/constants';

export const CancelModalPaperSx: SxProps = {
  height: '306px',
  width: '532px',
};

export const CancelModalMainStackSx: SxProps = {
  height: {
    xs: MAIN_STACK_SIZE_SM,
    sm: MAIN_STACK_SIZE_SM,
    md: MAIN_STACK_SIZE_MD,
    lg: MAIN_STACK_SIZE_MD,
    xl: MAIN_STACK_SIZE_MD,
  },
};

export const CancelModalTitleSx: SxProps = {
  fontWeight: 700,
  lineHeight: '38.4px',
};

export const CancelModalButtonBaseSx: SxProps = {
  padding: '6px 16px',
  width: 'fit-content',
  height: '36px',
  '& .MuiTypography-root': {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
  },
  '&#close-cancel-order-button': {
    mr: '16px',
  },
  '&#submit-cancel-order-button': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};
