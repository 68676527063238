/* eslint-disable sort-exports/sort-exports */
import React, { ReactNode } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OptionInfoTooltip from '../OptionTooltip';
import { SingleSelectOuterSx, SingleSelectInputSx } from './styles';
import { SINGLE_SELECT_ID_PREFIX, SINGLE_SELECT_LABEL } from './constants';
import { RetrievalOptionValue } from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueForm/types';
import { RetrievalOptionInputProps } from 'pages/Retrieval/components/ReviewStep/components/RetrievalOptionValueInput/types';

const SingleSelectOption = (props: RetrievalOptionInputProps) => {
  /**
   * @description sort function that arranges by index and accounts for TypeScript
   * not playing well with possibly undefined values (index)
   */
  const arrangeByIndex = (
    optionValueA: RetrievalOptionValue,
    optionValueB: RetrievalOptionValue
  ) => {
    const noIndexA = !('index' in optionValueA);
    const noIndexB = !('index' in optionValueB);

    if (noIndexA || noIndexB) return 0;

    const valueA = optionValueA?.index ?? 0;
    const valueB = optionValueB?.index ?? 0;

    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  };

  const getDefaultOptionValue = () =>
    props.retrievalOptionValues?.find((option) => option.selected)?.value ??
    null;

  const handleSelectChange = (
    event: SelectChangeEvent<string | null>,
    _: ReactNode
  ) => {
    const selectedOption = props.retrievalOptionValues?.find(
      (option) => option.value === event.target.value
    );

    if (!selectedOption) return;

    props.answerRetrievalOption({
      optionId: props.id,
      optionName: props.name,
      optionValueId: selectedOption.id,
      optionValue: selectedOption.value,
    });
  };

  const getLabelId = () => {
    const suffix = props?.assetId ?? '';
    return `${SINGLE_SELECT_LABEL}-${SINGLE_SELECT_ID_PREFIX}${suffix}`;
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={SingleSelectOuterSx}
    >
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Typography>{props.prompt}</Typography>
        <OptionInfoTooltip description={props.description} />
      </Stack>

      <FormControl sx={SingleSelectInputSx}>
        <InputLabel id={getLabelId()}>{props.name}</InputLabel>
        <Select
          labelId={getLabelId()}
          defaultValue={getDefaultOptionValue()}
          onChange={handleSelectChange}
          value={props.retrievalAnswer.optionValue}
        >
          {props.retrievalOptionValues
            .slice()
            .sort(arrangeByIndex)
            .map((optionValue) => {
              return (
                <MenuItem value={optionValue.value}>
                  {optionValue.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default SingleSelectOption;
