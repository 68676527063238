/* eslint-disable security/detect-object-injection */
import { intersection } from 'lodash';
import {
  CartItem,
  UseProcurementService,
} from 'pages/Procurement/hooks/useProcurementService/types';
import { UserPermissions } from 'global-constants';
import { PremiumShippingTypeNames } from 'enums';
import { EARLIEST_DELIVERY_DATE } from './constants';

export const getLineItemTotalPrice = (
  productVariantPrice: number,
  numberOfSelectedCollaborators: number
): number => {
  return productVariantPrice * numberOfSelectedCollaborators;
};

// Valid prices can only contain either numbers or a decimal point
const legitPriceChars: Record<string, number> = {
  '.': 1,
  '0': 1,
  '1': 1,
  '2': 1,
  '3': 1,
  '4': 1,
  '5': 1,
  '6': 1,
  '7': 1,
  '8': 1,
  '9': 1,
};

export const isPriceValid = (price: any) =>
  String(price)
    .split('')
    .every((char) => !!legitPriceChars[char]);

const calculateBasePrice = (next: CartItem) => {
  const insuranceCharged = next?.insuranceCharge ?? 0;
  return parseFloat(next.productVariantPrice) + insuranceCharged;
};

export const getTotalPrice = (
  cart: UseProcurementService['cart'],
  numberOfSelectedCollaborators: number
): number => {
  return cart
    .filter((cartItem) => isPriceValid(cartItem.productVariantPrice))
    .reduce(
      (previousValue, currentValue) =>
        previousValue +
        getLineItemTotalPrice(
          calculateBasePrice(currentValue),
          numberOfSelectedCollaborators * currentValue.quantity
        ),
      0
    );
};

export const calculateServiceFee = (
  subtotal: number,
  isRushOrder: boolean
): number => {
  const RUSH_ORDER_CHARGE = 35;
  const SERVICE_FEE_PERCENTAGE = 0.04;

  return isRushOrder
    ? subtotal * SERVICE_FEE_PERCENTAGE + RUSH_ORDER_CHARGE
    : subtotal * SERVICE_FEE_PERCENTAGE;
};

export const calculateSalesTax = (
  subtotal: number,
  serviceFee: number
): number => {
  const SALES_TAX_RATE = 0.0725;

  return (subtotal + serviceFee) * SALES_TAX_RATE;
};

export const shouldShowInternalActions = (
  userPermissions: string[]
): boolean => {
  const internalActionsUserPermissions = [
    UserPermissions.NOTIFICATION__RECIPIENT_FORM__MANAGE,
    UserPermissions.ORDER__RECIPIENT__USE_AVAILABLE_ADDRESS,
    UserPermissions.SHIPMENT__CARRIER__SELECT,
  ];

  return (
    intersection(userPermissions, internalActionsUserPermissions).length > 0
  );
};

export const getPremiumShippingDisplayInfo = (
  premiumShippingName: string,
  options: { lookup: Record<string, any> }
) => {
  const tempInfo = options.lookup[premiumShippingName];
  const { shippingTypeName, description, formattedDate, shippingTypeId } =
    tempInfo;

  const title =
    PremiumShippingTypeNames.OVERNIGHT === shippingTypeName
      ? `Request ${shippingTypeName.toLowerCase()} shipping`
      : `${shippingTypeName} shipping`;

  const subTexts = description.split(';') as string[];

  return {
    shippingTypeId,
    title,
    subTexts,
    bottomText: 'Pricing may vary',
    bottomSubText: `${EARLIEST_DELIVERY_DATE} ${formattedDate}`,
  };
};
