import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from 'state';
import { useCollaboratorControllerRetrieve } from 'services/openapi/apiComponents';

export const useGetGlobalPurchaser = () => {
  const { user } = useAuth0();

  const [globalPurchaser, setGlobalPurchaser] =
    useGlobalState('globalPurchaser');

  //TODO Refactor userOrganization to be globalOrganization in another PR because it is more fitting
  const [globalOrganization] = useGlobalState('userOrganization');

  useCollaboratorControllerRetrieve(
    {
      pathParams: {
        id: user?.collaboratorId,
      },
    },
    {
      enabled: !!user?.collaboratorId && !!globalOrganization?.id,
      onSuccess: (data) => {
        // global purchaser must be in the global organization
        if (data.organizationId === globalOrganization?.id)
          setGlobalPurchaser(data);
      },
    }
  );

  return globalPurchaser;
};

export default useGetGlobalPurchaser;
