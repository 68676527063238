import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UseFeatureFlagService } from './types';

/**
 *
 * @param {string} flagName as it appears in the LaunchDarkly dashboard
 * @param {Object} options - The useFeatureFlagService options object. In production, these options are ignored.
 * @param {boolean} [options.debugFlags=false] - Specifies whether to log the available flags to the console when true. By default false.
 * @param {null | boolean} [options.forceFlagValue=null] - If a boolean value is provided, it will be used as the flag value instead of the one from LaunchDarkly. By default null so it doesn't overwrite the flag value.
 * @returns {UseFeatureFlagService} UseFeatureFlagService
 * @returns {function} {isFlagOn} - Returns true if the flag is on, false otherwise.
 * @returns
 */
const useFeatureFlagService = (
  flagName: string,
  options: { debugFlags?: boolean; forceFlagValue?: null | boolean } = {
    debugFlags: false,
    forceFlagValue: null,
  }
): UseFeatureFlagService => {
  const flags = useFlags();
  const overwriteFlagValue = typeof options.forceFlagValue === 'boolean';
  const notInProduction = process.env.NODE_ENV !== 'production';
  const debugOff = process.env.REACT_APP_DEBUG_OFF === 'true';

  if (options.debugFlags && notInProduction && !debugOff) {
    console.log('Available flags:\n', flags);
  }

  if (overwriteFlagValue && notInProduction && !debugOff) {
    console.warn(
      `Overwriting flag "${flagName}" with value of: ${options.forceFlagValue}.`
    );
  }

  const getFlagValue = () => {
    if (notInProduction) {
      return overwriteFlagValue ? options.forceFlagValue : !!flags[flagName];
    }

    return flags[flagName];
  };

  const isFlagOn = () => getFlagValue();

  const isFlagOff = () => !getFlagValue();

  const OnFlagTrueWrapper: React.FC<{
    children: React.ReactNode;
    showIf?: boolean;
  }> = ({ children = '', showIf = true }) => {
    if (!showIf || isFlagOff()) return null;
    return <>{children}</>;
  };

  const OnFlagFalseWrapper: React.FC<{
    children: React.ReactNode;
    showIf?: boolean;
  }> = ({ children = '', showIf = true }) => {
    if (!showIf || isFlagOn()) return null;
    return <>{children}</>;
  };

  return {
    isFlagOn,
    isFlagOff,
    OnFlagTrueWrapper,
    OnFlagFalseWrapper,
  } as UseFeatureFlagService;
};

export default useFeatureFlagService;
