import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Stack, Typography, FilledInput } from '@mui/material';
import {
  FiltersContainerSx,
  ProductCatalogSearchBarSx,
  SearchAndFiltersContainerSx,
} from './styles';
import { SearchAndFiltersProps } from './types';
import Filter from './components/Filter';

const SearchAndFilters = (props: SearchAndFiltersProps) => {
  const onChangeSetQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setQuery(e.target.value);
  };

  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Stack sx={SearchAndFiltersContainerSx}>
      <Typography variant="h5">Items</Typography>
      <FilledInput
        placeholder="Search for products..."
        startAdornment={<SearchIcon />}
        disableUnderline={true}
        value={props.query}
        onChange={onChangeSetQuery}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-testid="product-search-ogmfvw9v8w"
        sx={{
          ...ProductCatalogSearchBarSx,
          border: `2px solid ${isFocused ? 'black' : 'transparent'}`,
        }}
      />
      <Stack sx={FiltersContainerSx}>
        {props.productCategories?.items.map((filterItem: any) => (
          <Filter
            key={filterItem.id}
            item={filterItem}
            currentFilterId={props.currentFilterId}
            setCurrentFilterId={props.setCurrentFilterId}
            data-testid="catalog-section"
          />
        ))}
        <Filter
          currentFilterId={props.currentFilterId}
          setCurrentFilterId={props.setCurrentFilterId}
          data-testid="catalog-section"
        />
      </Stack>
    </Stack>
  );
};

export default SearchAndFilters;
