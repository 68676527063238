import {
  useQuery,
  OperationVariables,
  DocumentNode,
  TypedDocumentNode,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import { useDispatch } from 'react-redux';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';

export const useSafeQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  const dispatch = useDispatch();
  return useQuery(query, {
    ...options,
    onError(error) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: NotificationMessages.GENERIC_SOMETHING_WENT_WRONG,
        })
      );
    },
  });
};
