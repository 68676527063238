import { SxProps } from '@mui/material';

export const StepperMainTitleSx: SxProps = {
  fontWeight: 500,
  maxHeight: '24px',
};

export const getStepperLabelSx = (iconColor: string): SxProps => ({
  '& .MuiStepLabel-iconContainer': {
    backgroundColor: iconColor,
    color: 'white',
    borderRadius: '50%',
    boxSizing: 'border-box',
    height: '24px',
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 0,
    zIndex: 2,

    '& .MuiTypography-caption': {
      color: '#60605D',
      fontSize: '12px',
      lineHeight: '20px',
    },

    '& .MuiSvgIcon-root': {
      width: '18px',
    },
  },

  // special styling for non material icons
  '&.in-return-icon-bespoke-label, &.pending-return-icon-bespoke-label, &.in-return-icon-bespoke-label, &.pending-recycle-icon-bespoke-label':
    {
      '& .MuiSvgIcon-root': {
        transform: 'translate(3px, 3px)',
        width: '20px',
      },
    },
});
