import moment from 'moment';
import { Navigator } from 'context/NavigationContext';
import { NavLinkItem, PostDividerLinkOptions } from './types';
import {
  NavLinkWIconSx,
  getBuyNHoldCartCounterSx,
  BuyNHoldCartCounterNumberSx,
  NavLinkFooterSx,
  NavLinkCopyrightFooterSx,
} from './styles';
import Bubble from 'components/Bubble';
import { BoxSVGIcon, BuyAndHoldSVGIcon, GiftSVGIcon } from 'components/Icons';
import { RetrievalStepSubRoutes } from 'global-constants';
import { feedbackTrigger } from 'vendor/sentry';

export const getPostDividerNavLinks = (
  navigator: Navigator,
  activePathname: string,
  options: PostDividerLinkOptions = {}
): NavLinkItem[] => {
  const cartItemsCount = options?.buyNHoldCartItemCount ?? 0;
  const items: NavLinkItem[] = [
    {
      key: 'send-equipment',
      to: navigator.pathToSendEquipment(),
      text: 'Send equipment',
      isActive: activePathname === navigator.pathToSendEquipment(),
      isCollapsible: false,
      subNavLinkItems: [],
      extraSx: NavLinkWIconSx,
      prefixIcon: GiftSVGIcon({ width: 16 }),
    },
    {
      key: 'start-retrieval',
      to: navigator.pathToRetrieval(RetrievalStepSubRoutes.LOCATION),
      text: 'Retrieve equipment',
      isActive: activePathname.includes('/retrieval'),
      isCollapsible: false,
      subNavLinkItems: [],
      extraSx: NavLinkWIconSx,
      prefixIcon: BoxSVGIcon({}),
    },
  ];

  if (options?.canSeeBuyAndHold) {
    items.push({
      key: 'buy-and-hold',
      to: navigator.pathToBuyAndHold(),
      text: 'Buy & Hold',
      isActive: activePathname === navigator.pathToBuyAndHold(),
      isCollapsible: false,
      subNavLinkItems: [],
      extraSx: NavLinkWIconSx,
      prefixIcon: BuyAndHoldSVGIcon({ width: 16 }),
      suffixIcon: Bubble({
        show: !!cartItemsCount,
        text: `${cartItemsCount > 9 ? '9+' : cartItemsCount}`,
        bubbleSx: getBuyNHoldCartCounterSx(cartItemsCount),
        textSx: BuyNHoldCartCounterNumberSx,
      }),
    });
  }

  return items;
};

export const getFooterNavLinkItems = (): NavLinkItem[] => {
  const items: NavLinkItem[] = [
    {
      key: 'contact-us',
      to: 'mailto:hello@allwhere.co',
      text: 'Contact Us',
      rel: 'noopener',
      isCollapsible: false,
      subNavLinkItems: [],
      extraSx: NavLinkFooterSx,
    },
    {
      key: 'sentry-report-bug',
      to: '',
      js: async () => {
        await feedbackTrigger();
      },
      hide: process.env.REACT_APP_SENTRY_FEEDBACK !== 'ON',
      text: 'Report a Bug',
      isCollapsible: false,
      subNavLinkItems: [],
      extraSx: NavLinkFooterSx,
    },
    {
      key: 'faq',
      to: 'https://faq.allwhere.co/en_us/categories/faq-S1PKSbsci',
      text: 'FAQ Section',
      target: '_blank',
      rel: 'noopener',
      isCollapsible: false,
      subNavLinkItems: [],
      extraSx: NavLinkFooterSx,
    },
    {
      key: 'terms-and-privacy',
      to: 'https://www.allwhere.co/terms',
      text: 'Terms and Privacy',
      target: '_blank',
      rel: 'noopener',
      isCollapsible: false,
      subNavLinkItems: [],
      extraSx: NavLinkFooterSx,
    },
    {
      key: 'copyright',
      to: '',
      text: `© Copyright ${moment().year()}`,
      noLink: true,
      isCollapsible: false,
      subNavLinkItems: [],
      extraSx: NavLinkCopyrightFooterSx,
    },
  ];
  return items;
};

export const calculateBuyNHoldCartItemCount = (cartItems: any[]): number => {
  return cartItems.reduce((acc, item) => {
    return acc + (item?.quantity ?? 0);
  }, 0);
};
