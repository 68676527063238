import { gql } from '@apollo/client';

// DO NOT REMOVE, Breaks GQL startup
export const DUMMY_NODE_GET_NOTHING = gql`
  query {
    searchDepots(input: { limit: 0 }) {
      count
    }
  }
`;

export const GET_ALL_ORDER_TYPES = gql`
  query {
    getAllOrderTypes {
      id
      name
    }
  }
`;

export const GET_SHIPPING_SELECTION_TYPES = gql`
  query {
    searchShippingTypes(searchShippingTypesInput: { limit: 100 }) {
      shippingTypes {
        id
        name
      }
    }
  }
`;

export const GET_PURCHASER_OPTIONS = gql`
  query getCollaboratorAutocompleteSuggestions(
    $searchTerm: String!
    $limit: Int = 15
    $organizationId: ID
  ) {
    getCollaboratorAutocompleteSuggestions(
      collaboratorFieldSuggestionsInput: {
        organizationId: $organizationId
        searchTerm: $searchTerm
        limit: $limit
        searchBy: "iLike"
        searchField: "firstOrLastName"
      }
    ) {
      field
      fields
      matches
      matchIds
      count
      entity
    }
  }
`;

export const GET_RECIPIENT_EMAIL_OPTIONS = gql`
  query getCollaboratorAutocompleteSuggestions(
    $searchTerm: String!
    $limit: Int = 15
    $organizationId: ID
  ) {
    getCollaboratorAutocompleteSuggestions(
      collaboratorFieldSuggestionsInput: {
        organizationId: $organizationId
        searchTerm: $searchTerm
        limit: $limit
        searchBy: "iLike"
        searchField: "anyEmail"
      }
    ) {
      field
      fields
      matches
      matchIds
      count
      entity
    }
  }
`;

export const GET_RECIPIENT_OPTIONS = gql`
  query getRecipientAutocompleteSuggestions(
    $searchTerm: String!
    $limit: Int = 15
    $searchField: String!
    $organizationId: ID
  ) {
    getRecipientAutocompleteSuggestions(
      recipientSuggestionsInput: {
        searchTerm: $searchTerm
        limit: $limit
        searchField: $searchField
        organizationId: $organizationId
      }
    ) {
      field
      fields
      matches
      matchIds
      count
      entity
    }
  }
`;

export const GET_ORDER_NUMBER_OPTIONS = gql`
  query searchOrders(
    $limit: Int = 15
    $orderNumber: String
    $organizationId: String
  ) {
    searchOrders(
      searchOrdersInput: {
        limit: $limit
        orderNumber: $orderNumber
        organizationId: $organizationId
      }
    ) {
      orders {
        id
        orderNumber
      }
    }
  }
`;

export const GET_ASSET_AUTOCOMPLETE_SUGGESTIONS = gql`
  query getAssetAutocompleteSuggestions(
    $organizationId: ID
    $searchTerm: String!
    $searchBy: String!
    $limit: Int = 15
    $searchField: String!
  ) {
    getAssetAutocompleteSuggestions(
      assetFieldSuggestionsInput: {
        organizationId: $organizationId
        searchTerm: $searchTerm
        skipValues: []
        searchBy: $searchBy
        limit: $limit
        searchField: $searchField
      }
    ) {
      field
      matches
      matchIds
    }
  }
`;
