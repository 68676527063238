/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import GenericAutocomplete from 'components/GenericAutocomplete';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import useDebouncedSearch from 'hooks/useDebouncedSearch';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { DEVICE_FILTERS_WIDTH } from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/constants';
import { GET_ASSETS_BY_MODEL } from './queries';
import { AssetModelFilterProps } from './types';
import { ASSET_MODEL_INPUT_ID } from './constants';
import * as AssetModelFilterStyles from './styles';

const AssetModelFilter = (props: AssetModelFilterProps) => {
  const styles = AssetModelFilterStyles;
  const organization = useGetUserOrganization();
  const [assetMake, setAssetModel] = React.useState('');
  const [modelOptions, setModelOptions] = React.useState<string[]>([]);
  const debouncedAssetModel = useDebouncedSearch(assetMake, 300);

  const [fetchAssetsByModel, { loading: loadingModels }] = useSafeLazyQuery(
    GET_ASSETS_BY_MODEL,
    {
      onCompleted(data) {
        const { searchAssets } = data;
        const assetSet = new Set<string>();
        const assets = searchAssets?.assets || [];
        if (!assets.length) return;
        const tempOptions = assets
          .map((asset: any) => asset.model)
          .filter((model: string) => {
            if (assetSet.has(model)) return false;
            assetSet.add(model);
            return true;
          });

        setModelOptions(tempOptions);
      },
    }
  );

  const handleInputChange = (_e: any, value: any) => {
    if (value.length >= 3) {
      setAssetModel(value);
    }
  };

  const clearAllAutoCompleteOptions = () => {
    const clearTextIconButton: HTMLElement = document.querySelector(
      `#${ASSET_MODEL_INPUT_ID} .MuiAutocomplete-clearIndicator`
    ) as HTMLElement;
    if (clearTextIconButton) {
      clearTextIconButton.click();
    }
    setAssetModel('');
    setModelOptions([]);
    props.setChosenAssetModel('');
  };

  const handleAutoCompleteChange = (event: any, value: any) => {
    if (value === null) {
      clearAllAutoCompleteOptions();
      return;
    }

    props.setChosenAssetModel(value);
  };

  React.useEffect(() => {
    if (!organization?.id || !debouncedAssetModel) return;
    fetchAssetsByModel({
      variables: {
        organizationId: organization.id,
        iLike: {
          field: 'model',
          value: `%${debouncedAssetModel}%`,
        },
      },
    });
  }, [organization?.id, debouncedAssetModel]);

  React.useEffect(() => {
    if (props.chosenAssetModel) return;
    clearAllAutoCompleteOptions();
  }, [props.chosenAssetModel]);

  return (
    <Stack
      direction="column"
      sx={styles.AutoCompleteStackSx}
      id={ASSET_MODEL_INPUT_ID}
    >
      <GenericAutocomplete
        loading={loadingModels}
        onChange={handleAutoCompleteChange}
        onInputChange={handleInputChange}
        componentsProps={{
          popper: {
            style: {
              width: DEVICE_FILTERS_WIDTH,
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Start typing"
            label="Model"
            aria-label="Model"
            sx={styles.AutoCompleteSearchTextFieldSx}
          />
        )}
        sx={styles.AutoCompleteSx}
        options={modelOptions}
      />
    </Stack>
  );
};

export default AssetModelFilter;
