import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/immer';

import { LoadingState } from 'store/slices/global/types';
import { AssetsState, Asset } from './types';
import { getAssets } from './actions';

export const setInitialAssetsReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<Asset[]>
) => {
  state.assets = action.payload;
};

export const setAssetsReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<Asset[]>
) => {
  state.assets = state.assets.concat(action.payload);
};

export const setAssetsCountReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<number>
) => {
  state.count = action.payload;
};

export const setAssetsSkipReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<number>
) => {
  state.skip = action.payload;
};

export const setAssetsTakeReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<number>
) => {
  state.take = action.payload;
};

export const setAssetsLoadingReducer = (
  state: WritableDraft<AssetsState>,
  action: PayloadAction<LoadingState>
) => {
  state.loading = action.payload;
};

export const getAssetsPendingReducer: CaseReducer<
  AssetsState,
  ReturnType<typeof getAssets.pending>
> = (state) => {
  state.loading = 'pending';
};

export const getAssetsFulfilledReducer: CaseReducer<
  AssetsState,
  ReturnType<typeof getAssets.fulfilled>
> = (state, action) => {
  state.loading = 'fulfilled';
  if (!action.meta.arg.append) {
    state.assets = action.payload.data.v2FindAssetsByStatusTransition.assets;
    state.count = action.payload.data.v2FindAssetsByStatusTransition.count;
    state.skip = state.take;
  } else {
    state.assets = state.assets.concat(
      action.payload.data.v2FindAssetsByStatusTransition.assets
    );
    state.count = action.payload.data.v2FindAssetsByStatusTransition.count;
    state.skip += state.take;
  }
};

export const getAssetsRejectedReducer: CaseReducer<
  AssetsState,
  ReturnType<typeof getAssets.rejected>
> = (state) => {
  state.loading = 'rejected';
};
