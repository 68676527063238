import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ManageUserTableState } from './types';
import {
  getCredentialedCollaborators,
  patchCredentialedCollaborators,
  postCredentialedCollaborators,
} from './actions';
import { WritableDraft } from 'immer';
import { CredentialedCollaboratorRole } from 'api/rest/credentialedCollaborators/types';
import { LoadingState } from '../../global/types';

export const getCredentialedCollaboratorsPendingReducer: CaseReducer<
  ManageUserTableState,
  ReturnType<typeof getCredentialedCollaborators.pending>
> = (state) => {
  state.patchCredentialedCollaboratorsLoadingState = 'idle';
  state.postCredentialedCollaboratorsLoadingState = 'idle';
  state.getCredentialedCollaboratorsLoadingState = 'pending';
};

export const getCredentialedCollaboratorsFulfilledReducer: CaseReducer<
  ManageUserTableState,
  ReturnType<typeof getCredentialedCollaborators.fulfilled>
> = (state, action) => {
  state.getCredentialedCollaboratorsLoadingState = 'fulfilled';
  if (action?.payload?.items) {
    state.credentialedCollaborators = action?.payload?.items;
    state.credentialedCollaborators.forEach((collaborator) => {
      state.buttonActionDropdownById[collaborator.id] = {
        selected: 'RESEND_INVITE',
      };
    });
    state.credentialedCollaboratorsCount = action?.payload?.count;
  }
};

export const getCredentialedCollaboratorsRejectedReducer: CaseReducer<
  ManageUserTableState,
  ReturnType<typeof getCredentialedCollaborators.rejected>
> = (state) => {
  state.getCredentialedCollaboratorsLoadingState = 'rejected';
  state.credentialedCollaborators = [];
};

export const patchCredentialedCollaboratorsPendingReducer: CaseReducer<
  ManageUserTableState,
  ReturnType<typeof patchCredentialedCollaborators.pending>
> = (state) => {
  state.getCredentialedCollaboratorsLoadingState = 'idle';
  state.patchCredentialedCollaboratorsLoadingState = 'pending';
};

export const patchCredentialedCollaboratorsFulfilledReducer: CaseReducer<
  ManageUserTableState,
  ReturnType<typeof patchCredentialedCollaborators.fulfilled>
> = (state, action) => {
  state.patchCredentialedCollaboratorsLoadingState = 'fulfilled';
  if (!action?.payload?.role) {
    state.credentialedCollaborators = state.credentialedCollaborators.filter(
      (collaborator) => collaborator.id !== action?.payload?.id
    );
    state.credentialedCollaboratorsCount -= 1;
  } else {
    state.credentialedCollaborators = state.credentialedCollaborators.map(
      (collaborator) => {
        if (collaborator.id === action?.payload?.id) {
          return { ...collaborator, role: action?.payload?.role };
        }
        return collaborator;
      }
    );
  }
};

export const patchCredentialedCollaboratorsRejectedReducer: CaseReducer<
  ManageUserTableState,
  ReturnType<typeof patchCredentialedCollaborators.rejected>
> = (state) => {
  state.patchCredentialedCollaboratorsLoadingState = 'rejected';
};

export const postCredentialedCollaboratorsPendingReducer: CaseReducer<
  ManageUserTableState,
  ReturnType<typeof postCredentialedCollaborators.pending>
> = (state, action) => {
  const { workEmail } = action.meta.arg.data;
  state.postCredentialedCollaboratorsErrorCode = 200;
  state.credentialedCollaborators.forEach((collaborator) => {
    if (collaborator.email === workEmail) {
      state.postCredentialedCollaboratorsLoadingStateMap[collaborator.id] =
        'pending';
    }
  });
  state.getCredentialedCollaboratorsLoadingState = 'idle';
  state.postCredentialedCollaboratorsLoadingState = 'pending';
};

export const postCredentialedCollaboratorsFulfilledReducer: CaseReducer<
  ManageUserTableState,
  ReturnType<typeof postCredentialedCollaborators.fulfilled>
> = (state, action) => {
  state.postCredentialedCollaboratorsLoadingState = 'fulfilled';
  const admin = action.payload;
  state.postCredentialedCollaboratorsLoadingStateMap[admin.id] = 'fulfilled';
  const foundAdmin = state.credentialedCollaborators.find(
    (collaborator) => collaborator.id === admin.id
  );
  if (!foundAdmin) {
    state.credentialedCollaborators.unshift(action.payload);
    state.buttonActionDropdownById[action.payload.id] = {
      selected: 'RESEND_INVITE',
    };
  } else {
    state.credentialedCollaborators = state.credentialedCollaborators.map(
      (collaborator) => {
        if (collaborator.id === action?.payload?.id) {
          return action?.payload;
        }
        return collaborator;
      }
    );
  }
  state.credentialedCollaboratorsCount += 1;
};

export const postCredentialedCollaboratorsRejectedReducer: CaseReducer<
  ManageUserTableState,
  ReturnType<typeof postCredentialedCollaborators.rejected>
> = (state, action) => {
  const { workEmail } = action.meta.arg.data;
  const { error } = action;
  if (error?.message?.match('409')) {
    state.postCredentialedCollaboratorsErrorCode = 409;
  }
  state.credentialedCollaborators.forEach((collaborator) => {
    if (collaborator.email === workEmail) {
      state.postCredentialedCollaboratorsLoadingStateMap[collaborator.id] =
        'rejected';
    }
  });
  state.postCredentialedCollaboratorsLoadingState = 'rejected';
};

export const setPaginationModelReducer = (
  state: WritableDraft<ManageUserTableState>,
  action: PayloadAction<{ page?: number; pageSize?: number }>
) => {
  const { page, pageSize } = action.payload;
  if (page !== undefined) {
    state.paginationModel.page = page;
  }
  if (pageSize !== undefined) {
    state.paginationModel.pageSize = pageSize;
  }
};

export const setShowRoleChangeConfirmationModalReducer = (
  state: WritableDraft<ManageUserTableState>,
  action: PayloadAction<boolean>
) => {
  state.showRoleChangeConfirmationModal = action.payload;
};

export const setRoleConfirmationDataReducer = (
  state: WritableDraft<ManageUserTableState>,
  action: PayloadAction<{
    id: string;
    role: CredentialedCollaboratorRole;
  }>
) => {
  state.roleConfirmationData = action.payload;
};

export const setShowRevokeConfirmationModalReducer = (
  state: WritableDraft<ManageUserTableState>,
  action: PayloadAction<boolean>
) => {
  state.showRevokeConfirmationModal = action.payload;
};

export const setPostCredentialedCollaboratorsLoadingStateReducer = (
  state: WritableDraft<ManageUserTableState>,
  action: PayloadAction<LoadingState>
) => {
  state.postCredentialedCollaboratorsLoadingState = action.payload;
};

export const setButtonActionDropdownByIdReducer = (
  state: WritableDraft<ManageUserTableState>,
  action: PayloadAction<{ key: string; value: string }>
) => {
  const { key, value } = action.payload;
  state.buttonActionDropdownById[key] = {
    selected: value,
  };
};
