/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { NavigationContext } from 'context/NavigationContext';
import { Pill } from 'components';
import {
  SKIP_SHOWING_DETAILS_LINK_FAKE_ASSET_ID,
  SKIP_SHOWING_LINK_FAKE_TRACKING_ID,
} from 'pages/OrderDetails/components/OrderItemsPanel/constants';
import { capitalize, formatSnakeCaseToSentenceCase } from 'services/string';
import { OrderItemPanelCardProps } from './types';
import { getBackupIconByOrderTypeName } from './utils';
import * as OrderItemsPanelStyles from './styles';
import { FlattenedOrderItem } from 'pages/OrderDetails/components/OrderItemsPanel/types';

const OrderItemPanelCard = (props: OrderItemPanelCardProps) => {
  const styles = OrderItemsPanelStyles;
  const theme = useTheme();
  const navigator = React.useContext(NavigationContext);

  const SHIPMENT_STATUS_COLORS: { [key: string]: string } = {
    EXCEPTION: theme.palette.colors.chiGong,
  };

  const getTrackingLink = () => {
    const { trackingLinkUrl, trackingId, trackingNumber } =
      props.orderItems?.[0] ?? {};
    const hasFakeAbsenteeTrackingId =
      trackingId.indexOf(SKIP_SHOWING_LINK_FAKE_TRACKING_ID) > -1;

    const hasAllTrackingParts = [
      trackingLinkUrl,
      trackingId,
      trackingNumber,
    ].every((field: string | undefined) => !!field);

    const showBlankLink = !hasAllTrackingParts || hasFakeAbsenteeTrackingId;

    return (
      <Box sx={styles.TrackingLinkInnerBoxSx}>
        {showBlankLink ? (
          <Typography color="colors.woodSmoke">-</Typography>
        ) : (
          <Link
            href={trackingLinkUrl}
            target="_blank"
            underline="always"
            color="colors.woodSmoke"
          >
            {trackingNumber}
          </Link>
        )}
      </Box>
    );
  };

  const getAssetLink = (orderItem: FlattenedOrderItem) => {
    const { assetId, assetNumber, isOutboundOrderItem } = orderItem;
    const hasFakeAbsenteeAssetId =
      assetId.indexOf(SKIP_SHOWING_DETAILS_LINK_FAKE_ASSET_ID) > -1;

    if (hasFakeAbsenteeAssetId || !assetNumber || isOutboundOrderItem) {
      return <Typography color="colors.woodSmoke">-</Typography>;
    }

    return (
      <Box>
        <Link
          href={navigator.constructPathToAssetDetails(assetId)}
          target="_blank"
          underline="always"
          color="colors.woodSmoke"
        >
          {assetNumber}
        </Link>
      </Box>
    );
  };

  const getShipmentStatusPill = () => {
    const { shipmentStatus, shipmentSubStatus, shipmentStatusDescription } =
      props.orderItems?.[0] ?? {};

    const descriptionText = shipmentStatusDescription || '';
    const shipmentStatusText =
      (shipmentSubStatus || shipmentStatus) ?? 'PENDING';

    const pillText = capitalize(
      formatSnakeCaseToSentenceCase(shipmentStatusText)
    );

    return (
      <Box sx={styles.ShipmentStatusInnerBoxSx(!!shipmentStatusDescription)}>
        {shipmentStatus ? (
          <>
            <Pill
              chipOverrideSx={styles.ShipmentStatusPillSx}
              text={pillText}
              toolTipHoverText={pillText}
              toolTipArrow
              toolTipPlacement="right"
              color={
                SHIPMENT_STATUS_COLORS[
                  shipmentStatus as keyof typeof SHIPMENT_STATUS_COLORS
                ] || theme.palette.colors.pelorous
              }
              textColor={theme.palette.colors.white}
            />
            {shipmentStatusDescription && (
              <Typography sx={styles.ShipmentStatusDescriptionSx}>
                {descriptionText}
              </Typography>
            )}
          </>
        ) : (
          <Typography color="colors.woodSmoke">-</Typography>
        )}
      </Box>
    );
  };

  return (
    <Grid
      item
      container
      xs={12}
      key={props.key}
      sx={styles.OrderItemPanelCardContainerSx}
    >
      <Grid item xs={12} py="12px" sx={styles.OrderItemPanelInnerGridSx}>
        <Stack sx={styles.OrderItemsPanelContentStackSx} direction="row">
          <Box sx={styles.TrackingLinkBoxSx}>{getTrackingLink()}</Box>
          <Box sx={styles.ShipmentStatusBoxSx}>{getShipmentStatusPill()}</Box>
          <Stack sx={styles.OrderItemsBoxSx} direction="column">
            {(props.orderItems?.length ?? 0) > 0 ? (
              props.orderItems.map(
                (orderItem: FlattenedOrderItem, index: number) => (
                  <Box
                    key={orderItem.id}
                    data-testid={orderItem.id}
                    sx={styles.getOrderItemBoxSx(
                      index,
                      props.orderItems.length
                    )}
                  >
                    <Box sx={styles.OrderItemImageBoxSx}>
                      {props.isProcurement && orderItem.imageUrl ? (
                        <img
                          src={orderItem.imageUrl}
                          style={styles.ProcurementImageStyle}
                        />
                      ) : (
                        getBackupIconByOrderTypeName(
                          props.orderType,
                          orderItem.isInboundOrderItem
                        )
                      )}
                    </Box>
                    <Box sx={styles.OrderItemTitleBoxSx}>
                      <Stack direction="column">
                        {props.isRetrieval ? (
                          <Typography
                            variant="overline"
                            color="colors.gray60"
                            sx={styles.OrderItemRetrievalTypeSx}
                          >
                            {`${
                              orderItem.isOutboundOrderItem
                                ? 'OUTBOUND'
                                : 'INBOUND'
                            }`}
                          </Typography>
                        ) : (
                          <></>
                        )}
                        <Typography>
                          {orderItem.orderItemDisplayName}
                        </Typography>
                      </Stack>
                    </Box>

                    <Box sx={styles.OrderItemAssetBoxSx}>
                      {getAssetLink(orderItem)}
                    </Box>
                  </Box>
                )
              )
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={styles.OrderItemPanelDividerSx} />
      </Grid>
    </Grid>
  );
};

export default OrderItemPanelCard;
